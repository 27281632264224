@import "base";
@import "../../../shared/style/base";
@import "../../components/cells/Layout/variables";

html[is_mobile="false"] {
  .title {
    color: #111;

    /* Body/B2 : 15px Semibold */
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 22px; /* 146.667% */
  }

  .container {
    display: flex;
    margin-top: ptr(12);
    border: 1px solid var(--Gray-200, #f2f2f2);
    border-radius: 4px;
    background: #fff;
  }

  .container_title {
    display: flex;
    align-items: center;
    width: 14.72%;
    padding: ptr(19) 0 ptr(19) ptr(24);
    background-color: #f2f2f2;

    /* Body/B3 : 14px Semibold */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 150% */
  }

  .container_input_wrapper {
    display: flex;
    align-items: center;
    width: 35.27%;
    padding: ptr(10);
    column-gap: ptr(8);
    color: var(--Gray-700, #4e4e4e);

    /* Body/B3 : 14px Regular */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.3px;
    line-height: 21px; /* 150% */
  }

  .container_input {
    min-width: 200px;
    max-width: 100%;
    height: 100%;
    padding-left: ptr(12);
    border: 1px solid var(--Gray-10, #e7e7e7);
    border-radius: 4px;
    background: var(--White, #fff);

    &[disabled] {
      background: var(--Gray-10, #e7e7e7);
    }
  }

  .button_container {
    display: flex;
    justify-content: center;
    margin-top: ptr(40);
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 48px;
    padding: 14px 0;
    border-radius: 4px;
    background-color: #1c75ff;
    color: white;

    /* Body/B3 : 14px Medium */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 150% */
    gap: 10px;
  }
}

html[is_mobile="true"] {
  .page {
    width: 100%;
  }

  .title {
    color: #111;

    /* Body/B2 : 15px Semibold */
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 22px; /* 146.667% */
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    margin-top: ptr(12);
    margin-right: $marppleshop-studio-layout-padding-mo;
    margin-left: $marppleshop-studio-layout-padding-mo;
    border: 1px solid var(--Gray-200, #f2f2f2);
    border-radius: 4px;
    background: #fff;
  }

  .container_title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 29.44%;
    padding: ptr(19) 0;
    background-color: #f2f2f2;

    /* Body/B3 : 14px Semibold */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 150% */
  }

  .container_input_wrapper {
    display: flex;
    align-items: center;
    width: 70.54%;
    padding: ptr(10);
    column-gap: ptr(8);
    color: var(--Gray-700, #4e4e4e);

    /* Body/B3 : 14px Regular */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.3px;
    line-height: 21px; /* 150% */
  }

  .container_input {
    min-width: 200px;
    max-width: 100%;
    height: 100%;
    padding-left: ptr(12);
    border: 1px solid var(--Gray-10, #e7e7e7);
    border-radius: 4px;
    background: var(--White, #fff);

    &[disabled] {
      background: var(--Gray-10, #e7e7e7);
    }
  }

  .button_container {
    display: flex;
    justify-content: center;
    margin-top: ptr(40);
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 48px;
    padding: 14px 0;
    border-radius: 4px;
    background-color: #1c75ff;
    color: white;

    /* Body/B3 : 14px Medium */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 150% */
    gap: 10px;
  }
}
