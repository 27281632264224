@import "base";
html[is_mobile="false"] {
  .body_container {
    padding-top: $default-header-pc_height;
  }

  .page_title {
    margin: ptr(24) 0;
  }

  .creator_view_container {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: ptr(48) ptr(24);
  }

  .pagination_container {
    display: flex;
    justify-content: center;
    margin-top: ptr(56);
  }
}

html[is_mobile="true"] {
  .body_container {
    margin-top: $default-sub-header-pc_height;
  }

  .creator_view_container {
    display: flex;
    flex-direction: column;
    row-gap: ptr(48);
  }

  .page_title {
    margin: ptr(24) 0;
  }

  .pagination_container {
    display: flex;
    justify-content: center;
    margin-top: ptr(48);
  }
}
