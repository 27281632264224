@import "base";
@keyframes marquee-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee-right {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

html[is_mobile="false"] section.container {
  padding: ptr(60) 0;
  background-color: $brown-100;
  gap: ptr(40);
  @include flex(center, center, column);

  .title_wrapper {
    @include flex(center, center, column);

    margin-bottom: ptr(40);
    padding-top: ptr(80);
    gap: ptr(24);

    .title {
      color: $brown-10;
      text-align: center;
    }

    .description {
      color: $brown-10;
      text-align: center;
    }
  }

  .content {
    position: relative;
    z-index: 0;
    width: 100%;
    height: ptr(600);

    .marquee-wrapper {
      display: flex;
      position: absolute;
      z-index: 0;
      top: 0;
      width: 100%;
      overflow-x: hidden;

      .marquee-background {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-around;
        min-width: 100%;
        height: ptr(300);
        max-height: ptr(300);
        animation: marquee-left 50s linear infinite;
        will-change: transform;
        animation-fill-mode: both;

        .exhibition_card {
          @include flex(center, center);

          width: ptr(288);
          height: ptr(300);

          img.exhibition_image {
            max-height: 100%;
          }
        }
      }

      .marquee-background[aria-hidden="true"] {
        margin-left: ptr(16);
      }

      &.top {
        top: 0;
      }

      &.bottom {
        top: ptr(300);

        .marquee-background {
          animation: marquee-right 50s linear infinite;
          animation-fill-mode: both;
        }
      }
    }
  }
}

html[is_mobile="true"] section.container {
  padding: ptr(40) 0;
  background-color: $brown-100;
  gap: ptr(60);
  @include flex(center, center, column);

  .title_wrapper {
    @include flex(center, center, column);

    margin-bottom: ptr(40);
    padding: ptr(30) ptr(32) 0;
    gap: ptr(16);

    .title {
      color: $brown-10;
      text-align: center;
    }

    .description {
      color: $brown-10;
      text-align: center;
    }
  }

  .content {
    position: relative;
    z-index: 0;
    width: 100%;
    height: ptr(420);

    .marquee-wrapper {
      display: flex;
      position: absolute;
      z-index: 0;
      top: 0;
      width: 100%;
      overflow-x: hidden;

      .marquee-background {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-around;
        min-width: 100%;
        height: ptr(140);
        max-height: ptr(140);
        animation: marquee-left 50s linear infinite;
        will-change: transform;
        animation-fill-mode: both;

        .exhibition_card {
          @include flex(center, center);

          width: ptr(135);
          height: ptr(140);

          img.exhibition_image {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            scale: 0.72;
          }
        }
      }

      .marquee-background[aria-hidden="true"] {
        margin-left: ptr(16);
      }

      &.top {
        top: 0;
      }

      &.middle {
        top: ptr(140);

        .marquee-background {
          animation: marquee-right 50s linear infinite;
          animation-fill-mode: both;

          .exhibition_card {
            img.exhibition_image {
              scale: 0.85;
            }
          }
        }
      }

      &.bottom {
        top: ptr(280);
      }
    }
  }
}
