@import "base";
.container {
  @include flex(flex-start, center, column);

  width: 100%;
  gap: ptr(8);

  .input_container {
    @include flex(space-between, center);

    width: 100%;
    gap: ptr(8);

    .product_add_button {
      @include flex(center, center);

      width: ptr(40);
      height: ptr(40);
      border-radius: ptr(4);
      background: $blue-100;

      & > svg {
        width: ptr(20);
        height: ptr(20);
        color: $white;
      }
    }
  }

  .description {
    width: 100%;
    color: $gray-50;
    text-align: start;
  }

  .product_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    padding: ptr(8) 0;
    gap: ptr(8);

    .product_thumbnail_item {
      position: relative;

      .product_thumbnail {
        border-radius: ptr(8);

        img {
          border-radius: ptr(8);
        }
      }

      .trash_icon {
        position: absolute;
        top: ptr(8);
        right: ptr(8);
        width: ptr(32);
        height: ptr(32);
        padding: ptr(6);
        border-radius: ptr(99);
        background-color: $dim-30;
        cursor: pointer;
        backdrop-filter: blur(15px);

        & > svg {
          width: 100%;
          height: 100%;
          color: $white;
        }
      }
    }
  }
}

html[is_mobile="true"] .container {
  .product_container {
    grid-template-columns: repeat(2, 1fr);
  }
}
