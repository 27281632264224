@import "base";
@import "../../../../shared/style/base";

.quick_menu_list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: ptr(24) ptr(16) ptr(48);
  overflow-x: auto;
  gap: ptr(4);
  @include hiddenScroll;

  html[is_mobile="false"] & {
    justify-content: center;
    max-width: $pc-layout-body-max-width;
    padding: 0 ptr(32);
    gap: ptr(16);
  }

  html[is_mobile="true"] & {
    scroll-padding-left: ptr(16);
    scroll-snap-type: x mandatory;
  }

  &[data-fit="true"] {
    padding: ptr(8) ptr(16) ptr(24);
    overflow-x: auto;
    @include hiddenScroll;

    .quick_menu {
      flex: 1 1 0;
      padding: 0 ptr(4);
    }
  }
}

.quick_menu {
  @include flex(center, center, column);

  flex-shrink: 0;
  width: ptr(80);
  height: ptr(100);
  padding: 0 ptr(4);
  gap: ptr(8);

  html[is_mobile="false"] & {
    width: ptr(158);
    height: ptr(190);
    gap: ptr(16);
    padding: 0 ptr(4);
  }

  html[is_mobile="true"] & {
    scroll-snap-align: start;
  }

  .image_container {
    flex-shrink: 0;
    width: ptr(72);
    overflow: hidden;
    border-radius: 999px;
    aspect-ratio: 1/1;

    html[is_mobile="false"] & {
      width: 100%;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    width: 100%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
