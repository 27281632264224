@import "base";
html[is_mobile="false"] {
  .container {
    background: inherit;
  }

  .terms {
    padding: 16px;
    font-size: 14px;
    line-height: 1.6;

    ul,
    ol {
      padding-left: 0;
      list-style: none;

      li {
        padding-left: 1.5em;

        /* 중첩된 리스트에 대한 스타일 */
        > ul,
        > ol {
          padding-left: 1em; /* 하위 리스트의 들여쓰기 */
        }
      }
    }

    ul {
      li {
        margin-bottom: 0.5em; /* 리스트 간 간격 조정 */
        color: #6b6b6b;
        font-size: 12px;
        font-weight: 500;
      }
    }

    h2,
    h3 {
      all: revert;
    }
  }

  .terms.is_modal {
    height: ptr(232);
    overflow-y: scroll;
    border: 1px solid #ddd;

    ul,
    ol {
      margin-left: 1em; /* 적절한 들여쓰기 추가 */
      padding-left: 0; /* 왼쪽 여백 제거 */
      list-style-position: inside; /* 번호를 텍스트 안쪽으로 정렬 */
    }

    .chapter {
      h2 {
        margin-bottom: 16px;
        color: #6b6b6b;
        font-size: 14px;
        font-weight: 700;
      }

      h3 {
        margin-top: 12px;
        color: #6b6b6b;
        font-size: 12px;
        font-weight: 700;
      }
    }

    .terms_body {
      & > .body {
        height: ptr(232);
        margin: 0 !important;
      }
    }
  }

  .article p {
    margin-bottom: 8px;
    color: #6b6b6b;
    font-size: 12px;
    font-weight: 500;
  }

  .introduction {
    margin-bottom: 16px;

    p {
      color: #6b6b6b;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

html[is_mobile="true"] {
  .container {
    height: 100%;
    padding-bottom: calc(env(safe-area-inset-bottom) + 64px);
    background: inherit;
  }

  .terms {
    height: 100%;
    padding: 16px;
    overflow-y: scroll;
    border: 1px solid #ddd;
    font-size: 14px;
    line-height: 1.6;

    ul,
    ol {
      margin-left: 1em; /* 적절한 들여쓰기 추가 */
      padding-left: 0; /* 왼쪽 여백 제거 */
      list-style-position: inside; /* 번호를 텍스트 안쪽으로 정렬 */
    }

    ul {
      li {
        margin-bottom: 0.5em; /* 리스트 간 간격 조정 */
        padding-left: 1.5em; /* 텍스트와 번호 사이 여백 */
        color: #6b6b6b;
        font-size: 12px;
        font-weight: 500;
        text-indent: -1.5em; /* 텍스트 정렬 맞춤 */
      }
    }
  }

  .chapter {
    h2 {
      margin-bottom: 16px;
      color: #6b6b6b;
      font-size: 14px;
      font-weight: 700;
    }

    h3 {
      margin-top: 12px;
      color: #6b6b6b;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .article p {
    margin-bottom: 8px;
    color: #6b6b6b;
    font-size: 12px;
    font-weight: 500;
  }

  .introduction {
    margin-bottom: 16px;

    p {
      color: #6b6b6b;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .terms_body {
    & > .body {
      height: ptr(232);
      margin: 0 !important;
    }
  }
}
