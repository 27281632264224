@import "base";
html[is_mobile="false"] section.exhibition {
  @include flex(center, center, column);

  padding: ptr(176) ptr(188);
  gap: ptr(80);

  .title_wrapper {
    @include flex(center, center, column);

    gap: ptr(24);

    .title {
      color: $black;
      text-align: center;
    }

    .description {
      width: ptr(680);
      color: $black;
      text-align: center;
    }
  }

  .card_list {
    @include flex(center, center);

    gap: ptr(24);

    .card {
      @include flex(center, center, column);

      width: ptr(380);
      height: ptr(368);

      .card_image {
        width: ptr(240);
        height: ptr(240);
        margin-bottom: ptr(32);
      }

      .title {
        margin-bottom: ptr(16);
        color: $black;
        text-align: center;
      }

      .description {
        color: $gray-80;
        text-align: center;
      }
    }
  }
}

html[is_mobile="true"] section.exhibition {
  @include flex(center, center, column);

  padding: ptr(40) ptr(24);
  gap: ptr(24);

  .title_wrapper {
    @include flex(center, center, column);

    gap: ptr(16);

    .title {
      color: $black;
      text-align: center;
    }

    .description {
      max-width: ptr(311);
      color: $black;
      text-align: center;
    }
  }

  .card_list {
    @include grid(2, ptr(16));

    row-gap: ptr(24);

    .card {
      @include flex(center, center, column);

      width: 100%;
      max-width: ptr(156);
      height: ptr(213);
      padding: 0 ptr(8);
      gap: ptr(8);

      .card_image {
        width: 100%;
        max-width: ptr(124);
        max-height: ptr(124);
      }

      .title {
        color: $black;
        text-align: center;
      }

      .description {
        color: $gray-80;
        text-align: center;
      }
    }
  }
}
