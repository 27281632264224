@import "base";
.studio_welcome {
  .background {
    display: flex;
    position: fixed;
    z-index: 999;
    inset: 0;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 30%);
  }

  .content {
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    width: ptr(343);
    padding: ptr(32) ptr(24) ptr(24);
    border-radius: ptr(16);
    background-color: $blue-100;
    color: $white;
    gap: ptr(24);
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: content-box;
    width: ptr(24);
    height: ptr(24);
    margin: 0;
    padding: ptr(24);
    border: none;
    background: none;

    > img {
      width: 100%;
    }
  }

  .image {
    width: ptr(280);
    object-fit: cover;

    > img {
      width: 100%;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: ptr(8);
  }

  .start {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ptr(52);
    padding: ptr(16) ptr(24);
    border: none;
    border-radius: ptr(8);
    background-color: #005dec;
    color: $white;
  }
}
