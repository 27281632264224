@import "base";
button.heart_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  transition: all 200ms ease-out;
  border-radius: 999px;
  gap: 4px;

  .icon {
    width: ptr(20);
    height: ptr(20);
    color: inherit;
  }

  &.transparent {
    background-color: transparent;
    color: $gray-50;

    &.active {
      color: $blue;

      .icon {
        color: $blue;
      }
    }
  }

  &.bright {
    background-color: $dim-5;
    color: $gray-10;

    &:hover {
      background-color: $dim-10;
    }

    &.active {
      background-color: $dim-10;
      color: $white;

      .icon {
        color: $blue;
      }
    }
  }

  &.regular {
    padding: ptr(4) ptr(12);

    &.dark {
      background-color: $bright-5;
      color: $gray-20;

      &:hover {
        background-color: $bright-20;
      }

      &.active {
        background-color: $bright-20;
        color: $white;

        .icon {
          color: $blue;
        }
      }
    }
  }

  &.medium {
    padding: ptr(8) ptr(16);

    &.dark {
      background-color: $dim-30;
      color: $white;

      &:hover {
        background-color: $dim-60;
      }

      &.active {
        background-color: $dim-30;
        color: $white;

        .icon {
          color: $blue;
        }
      }
    }
  }
}
