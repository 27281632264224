@import "base";
html[is_mobile="false"] section.ad {
  position: relative;
  z-index: 0;

  .ad_card {
    width: 100%;
    height: 100%;
    @include flex(space-between, flex-start);

    .content {
      width: 50%;
      padding: ptr(328) 0 0 ptr(188);

      .title_wrapper {
        margin-bottom: ptr(64);

        .sub_title {
          margin-bottom: ptr(12);
          color: $blue-100;
        }

        .title {
          margin-bottom: ptr(24);
        }

        .description {
          margin-bottom: ptr(8);

          & > span {
            color: $blue-100;
          }
        }

        .guide {
          margin-bottom: ptr(64);
          color: $blue-100;
        }
      }
    }

    .image {
      width: 50%;
      height: 100%;
    }
  }

  .buttons {
    position: absolute;
    z-index: 10;
    top: ptr(712);
    left: ptr(188);
    gap: ptr(8);
    @include flex(center, center);

    & > button {
      padding: ptr(10);
    }

    & > button:hover {
      background-color: $blue-100 !important;

      svg {
        color: $blue-10 !important;
      }
    }
  }
}

html[is_mobile="true"] section.ad {
  @include flex(center, center, column);

  .ad_card {
    @include flex(center, center, column);

    gap: ptr(16);

    .content {
      @include flex(flex-start, flex-start, column);

      width: 100%;
      height: ptr(352);
      padding: ptr(60) ptr(32);

      .title_wrapper {
        @include flex(center, flex-start, column);

        gap: ptr(16);
        width: 100%;

        .description {
          & > span {
            color: $blue-100;
          }
        }

        .sub_title {
          color: $blue-100;
        }

        .guide {
          color: $blue-100;
        }
      }
    }

    .image {
      width: 100%;
      aspect-ratio: 375 / 460;
      object-position: center;
    }
  }

  .pagination {
    width: 100%;
    height: ptr(8);
    margin-bottom: ptr(40);

    & > span.bullet {
      background-color: rgb(255 255 255 / 80%);
    }

    & > span.bullet_active {
      background-color: $white;
    }
  }
}
