@import "base";
.wrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(100% + 21vw);
  gap: ptr(60);

  @media screen and (min-width: 760px) {
    height: calc(100% + 160px);
  }

  .image_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ptr(12);
    overflow: hidden;

    .images {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: ptr(16);
    }

    img {
      flex-shrink: 0;
      width: 21%;
      min-width: ptr(80);
      max-width: ptr(160);
    }
  }

  .notice {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 ptr(16);
    gap: ptr(32);

    .body {
      display: flex;
      flex-direction: column;
      gap: ptr(16);
      align-items: center;
      text-align: center;

      .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: ptr(60);
        height: ptr(60);

        border-radius: ptr(8);
        background-color: $blue-100;
        color: $white;

        > span {
          width: ptr(40.5);
          height: ptr(21);
        }
      }

      .text_logo {
        width: 100%;
        height: ptr(32);
        color: $blue-100;
      }
    }

    .button {
      max-width: ptr(400);
    }
  }
}

/* iOS에서 사용할 수 있는 높이 */
@supports (-webkit-touch-callout: none) {
  .wrapper {
    height: -webkit-fill-available;
  }
  .page {
    height: calc(100vh);

    @media screen and (min-width: 760px) {
      height: calc(100vh);
    }
  }
}
