@import "base";
html[is_mobile="false"] {
  .banner {
    position: relative;
    width: 100%;
    height: ptr(740);
    @include flex(center, center);

    .image_wrapper {
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      @include flex(center, center);
    }

    .image {
      object-fit: cover;
      width: 100%;
      min-height: 100%;
    }
  }

  .swiper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include flex(flex-start, center);

    .wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      @include flex(flex-start, center);
    }
  }

  .information {
    position: absolute;
    z-index: 2;
    width: 100%;
    min-width: $pc-layout-body-min-width;
    max-width: $pc-layout-body-max-width;
    height: 100%;
    padding: 0 ptr(32);
    opacity: 0;
    pointer-events: none;
    gap: ptr(24);
    @include flex(center, flex-start, column);

    &.active {
      opacity: 1;
    }

    &.deactivate {
      display: none;
      opacity: 0;
    }

    .contents {
      display: flex;
      flex-direction: column;
      width: fit-content;
      max-width: 100%;
      min-height: ptr(222);
      color: $white;
      pointer-events: auto;
      gap: ptr(16);

      .category {
        min-height: ptr(22);
      }
    }

    &[data-theme="black"] {
      .contents {
        color: $black;
      }
    }

    .button {
      pointer-events: auto;

      .arrow {
        width: ptr(16);
        height: ptr(16);
      }
    }
  }

  .controller {
    position: absolute;
    z-index: 2;
    bottom: ptr(60);
    width: 100%;
    min-width: $pc-layout-body-min-width;
    max-width: $pc-layout-body-max-width;
    height: fit-content;
    padding: 0 ptr(32);
    pointer-events: none;
    @include flex(space-between, flex-end);
  }

  .pagination {
    @include flex(center, center);

    gap: ptr(12);
    transition: color 200ms ease;
    color: $white;

    .pages {
      @include flex(flex-start, center);

      flex-shrink: 0;
      width: ptr(48);
      height: 1px;
      background-color: $bright-20;

      .page {
        height: 100%;
        transition:
          width 1s,
          background 200ms ease;
        background-color: $white;
      }
    }

    & > span {
      min-width: ptr(15);
    }

    &[data-theme="black"] {
      color: $black;

      .page {
        background-color: $black;
      }
    }
  }

  .navigation {
    pointer-events: auto;
    @include flex(center, center);

    gap: ptr(8);
  }
}

html[is_mobile="true"] {
  .banner {
    position: relative;
    width: 100%;
    height: ptr(500);
    background-color: $gray-5;
    @include flex(center, center);

    .swiper {
      width: 100%;
      height: 100%;
      @include flex(flex-start, center);

      .wrapper {
        width: 100%;
        height: 100%;
        @include flex(flex-start, center);

        will-change: transform;
      }
    }

    .image_wrapper {
      position: relative;
      flex: 1 0 100%;
      height: 100%;
      overflow: hidden;
      @include flex(center, center);

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $dim-30;
      }

      .image {
        object-fit: cover;
        width: 100%;
        min-height: 100%;
      }
    }
  }

  .information {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    padding: 0 ptr(32) ptr(52);
    opacity: 0;
    pointer-events: none;
    gap: ptr(24);
    @include flex(flex-end, center, column);

    &.active {
      opacity: 1;
    }

    &.deactivate {
      display: none;
      opacity: 0;
    }

    .contents {
      display: flex;
      flex-direction: column;
      width: fit-content;
      max-width: 100%;
      color: $white;
      text-align: center;
      gap: ptr(12);
    }

    .title {
      min-height: ptr(68);
    }
  }

  .controller {
    position: absolute;
    z-index: 10;
    bottom: ptr(16);
    width: 100%;
    height: fit-content;
    padding: 0 ptr(32);
    pointer-events: none;
    @include flex(center, flex-end);
  }

  .pagination {
    @include flex(center, center);

    gap: ptr(12);
    color: $white;

    .pages {
      @include flex(flex-start, center);

      flex-shrink: 0;
      width: ptr(40);
      height: 2px;
      background-color: $bright-20;

      .page {
        height: 100%;
        transition: width 1s;
        background-color: $white;
      }
    }

    & > span {
      min-width: ptr(15);
    }
  }
}
