@import "base";
html[is_mobile="false"] section.bottom {
  position: relative;
  z-index: 0;
  height: ptr(717) !important;
  padding: ptr(80);
  background-color: $blue-100;
  @include flex(flex-end, center, column);

  .bottom_bg {
    position: absolute;
    top: ptr(80);
    left: 50%;
    width: ptr(624);
    height: ptr(409);
    transform: translateX(-50%);
  }

  .content {
    @include flex(flex-end, center, column);

    z-index: 1;

    .title {
      margin-bottom: ptr(32);
      color: $white;
      text-align: center;
    }

    .submit {
      .button_label {
        @include flex(center, center);
      }
    }
  }
}

html[is_mobile="true"] section.bottom {
  padding: ptr(185) 0;
  background-color: $blue-100;
  gap: ptr(24);
  @include flex(center, center, column);

  .bottom_bg {
    width: 100%;
    max-width: ptr(375);
  }

  .content {
    z-index: 1;
    @include flex(flex-end, center, column);

    gap: ptr(24);
    width: 100%;
    max-width: ptr(375);

    .title {
      padding: 0 ptr(84);
      color: $white;
      text-align: center;
    }

    .submit {
      .button_label {
        @include flex(center, center);

        gap: ptr(4);

        svg {
          width: ptr(16);
          height: ptr(16);
          color: $blue-100;
        }
      }
    }
  }
}
