@import "base";
html[is_mobile="false"] .page {
  position: relative;
  width: 100%;
  height: 100vh;
  @include flex(center, center, column);

  gap: ptr(60);
  padding-top: $default-header-pc_height;
  padding-bottom: ptr(120);
  background-color: $blue-10;

  .container {
    width: 100%;
    padding-top: ptr(80);
    @include flex(center, center, column);

    gap: ptr(32);

    .image_wrapper {
      width: ptr(200);
      height: ptr(200);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .email {
      min-width: ptr(460);
      padding: ptr(32);
      border-radius: ptr(24);
      background-color: $white;
      color: $blue-100;
      @include flex(center, center);
    }

    .content {
      width: 100%;
      @include flex(center, center, column);

      gap: ptr(24);

      .description_wrapper {
        @include flex(center, center, column);

        gap: ptr(8);
        text-align: center;
      }
    }
  }

  .info {
    @include flex(center, center, column);

    gap: ptr(8);

    .info_desc_wrapper {
      @include flex(space-between, center);

      gap: ptr(8);
    }

    .info_desc {
      color: $gray-80;
      text-align: center;
    }
  }
}

html[is_mobile="true"] .page {
  position: relative;
  width: 100%;
  @include flex(center, center, column);

  gap: ptr(60);
  padding: $default-header-mo_height ptr(16) ptr(80) ptr(16);
  background-color: $blue-10;

  .container {
    width: 100%;
    padding-top: ptr(64);
    @include flex(center, center, column);

    gap: ptr(24);

    .image_wrapper {
      width: ptr(200);
      height: ptr(200);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .email {
      width: 100%;
      padding: ptr(24);
      border-radius: ptr(24);
      background-color: $white;
      color: $blue-100;
      @include flex(center, center);
    }

    .content {
      width: 100%;
      @include flex(center, center, column);

      gap: ptr(24);

      .description_wrapper {
        @include flex(center, center, column);

        gap: ptr(8);
        text-align: center;
      }
    }
  }

  .info {
    @include flex(center, center, column);

    gap: ptr(8);

    .info_desc_wrapper {
      @include flex(space-between, center);

      gap: ptr(8);
    }

    .info_desc {
      color: $gray-80;
      text-align: center;
    }
  }
}
