@import "base";
@import "../../../../components/cells/Layout/variables";
@import "../../../../../shared/style/base";

.best_seller_section {
  @include mobile {
    margin: 0;
  }

  .header {
    @include mobile {
      margin-right: $marppleshop-studio-layout-padding-mo;
      margin-left: $marppleshop-studio-layout-padding-mo;
    }
  }

  .body {
    overflow-x: scroll;
    @include hiddenScroll;
  }
}

.best_sellers_table {
  @include mobile {
    min-width: max-content;
    margin-right: $marppleshop-studio-layout-padding-mo;
    margin-left: $marppleshop-studio-layout-padding-mo;
    overflow-x: scroll;
  }

  .name_container {
    display: flex;
    align-items: center;

    .name {
      margin-left: ptr(16);
    }

    .thumbnail {
      width: ptr(60);
      height: ptr(60);
      overflow: hidden;
      border-radius: ptr(8);
    }

    .outlink_icon {
      display: inline-flex;
      width: ptr(16);
      height: ptr(16);
      margin-left: ptr(8);
      color: $black;
    }
  }

  .empty_table {
    color: $gray-50;

    &:hover {
      background-color: unset;
    }

    .text {
      padding-top: ptr(60);
      padding-bottom: ptr(120);
    }
  }
}
