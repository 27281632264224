@import "base";
:global {
  @media screen and (width <= 1920px) {
    html[is_mobile="false"].handmade-creator-landing {
      font-size: calc(1vw * (14 / 19.2)) !important;
    }
  }
  @media screen and (width <= 375px) {
    html[is_mobile="true"].handmade-creator-landing {
      font-size: calc(1vw * (14 / 3.75)) !important;
    }
  }

  .handmade-creator-landing {
    .scroll-block {
      height: 100vh;
      overflow: hidden;
    }
  }
}

html[is_mobile="false"] {
  .page {
    width: 100vw;
    @include flex(center, center, column);

    section {
      width: 100%;
    }
  }

  a.submit {
    position: fixed;
    z-index: 10;
    bottom: ptr(40);
    left: 50%;
    height: ptr(72);
    padding: ptr(19) ptr(64);
    transform: translateX(-50%);
    border-radius: ptr(36);
    font-size: ptr(22) !important;
    @include flex(center, center);
    div {
      display: none;
    }
  }
}

html[is_mobile="true"] {
  .page {
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    @include flex(center, center, column);

    section {
      width: 100%;
    }

    &.no_scroll {
      height: 100vh;
      overflow: hidden;
    }
  }

  a.submit {
    position: fixed;
    z-index: 9;
    bottom: calc(16px + env(safe-area-inset-bottom));
    left: 50%;
    width: calc(100% - 48px);
    height: ptr(60);
    transform: translateX(-50%);
    border-radius: ptr(36);
    font-size: ptr(22) !important;
    @include flex(center, center);
  }
}
