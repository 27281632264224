@import "base";
html[is_mobile="false"] .container {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: $dim-60;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(20px);
  }

  .bg_image {
    display: none;
  }

  .bg {
    display: none;
  }

  .content_wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    overscroll-behavior-y: none;

    .close_wrapper {
      padding: ptr(16) 0;

      @include flex(center, center);
      .close_button {
        width: ptr(40);
        height: ptr(40);
        padding: ptr(10);
        border-radius: 999px;
        background: $bright-5;
        cursor: pointer;

        & > svg {
          width: ptr(20);
          height: ptr(20);
          color: $gray-50;
        }
      }
    }

    .content {
      width: ptr(800);
      margin: 0 auto;
      padding-top: ptr(32);
      gap: ptr(24);

      @include flex(space-between, flex-start, column);
      .sub_title {
        color: $blue;
      }

      .title {
        color: $white;
      }

      .description {
        color: $white;
      }

      .input_form {
        gap: ptr(12);
        width: 100%;

        @include flex(flex-start, flex-start, column);
        .input_row {
          gap: ptr(16);
          width: 100%;

          @include flex(space-between, normal);
          .is_creator {
            width: 100%;
            height: ptr(48);
            padding: ptr(12) 0;
            border-bottom: ptr(1) solid $gray-50;
            gap: ptr(12);

            @include flex(flex-start, inherit);
            .is_creator_field {
              color: $gray-50;

              .asterisk {
                color: $red-80;
              }
            }

            .is_creator_radio_wrapper {
              gap: ptr(12);

              @include flex(space-between, center);
              .radio_button {
                & span {
                  border-color: $gray-50 !important;
                  background-color: transparent !important;
                  color: $gray-50;
                }

                input[checked] ~ span {
                  border-color: $white !important;
                  color: $white;
                }
              }
            }

            &[data-active] {
              border-bottom: ptr(1) solid $white;

              .is_creator_field {
                color: $white;
              }
            }
          }
        }

        .guide {
          color: $gray-50;
        }
      }

      .agree,
      .marketing {
        .agree_button {
          width: max-content;
          margin-bottom: ptr(8);
          color: $white;

          & span {
            background-color: transparent !important;
          }

          input[checked] ~ span {
            border-color: $white !important;
          }
        }

        .agree_content {
          color: $white;
        }
      }
    }

    .button_wrapper {
      width: ptr(800);
      margin: ptr(24) auto ptr(72);

      .submit {
        width: max-content;
        min-width: ptr(236);
        padding: ptr(16) ptr(24);
      }
    }
  }
}

html[is_mobile="true"] .container {
  position: relative;
  width: 100%;
  height: 100%;

  .bg_image {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: $dim-60;
    backdrop-filter: blur(20px);
  }

  .content_wrapper {
    width: 100%;
    height: 100%;

    .close_wrapper {
      padding: ptr(12) 0;

      @include flex(center, center);
      .close_button {
        z-index: 1;
        width: ptr(40);
        height: ptr(40);
        padding: ptr(10);
        border-radius: 999px;
        background: $bright-5;
        cursor: pointer;

        & > svg {
          width: ptr(20);
          height: ptr(20);
          color: $gray-50;
        }
      }
    }

    .content {
      position: relative;
      width: 100%;
      margin: 0 auto;
      padding: ptr(24) 0;

      @include flex(space-between, flex-start, column);
      .sub_title {
        margin-bottom: ptr(16);
        padding: 0 ptr(32);
        color: $blue;
      }

      .title {
        margin-bottom: ptr(16);
        padding: 0 ptr(32);
        color: $white;
        word-break: keep-all;
      }

      .description {
        padding: 0 ptr(32);
        color: $white;
      }

      .input_form {
        gap: ptr(12);
        width: 100%;
        padding: ptr(24) ptr(32);

        @include flex(flex-start, flex-start, column);
        .input_row {
          gap: ptr(12);
          width: 100%;

          @include flex(space-between, center, column);
          .is_creator {
            width: 100%;
            height: ptr(48);
            padding: ptr(12) 0;
            border-bottom: ptr(1) solid $gray-50;
            gap: ptr(12);

            @include flex(flex-start, inherit);
            .is_creator_field {
              color: $gray-50;

              .asterisk {
                color: $red-80;
              }
            }

            .is_creator_radio_wrapper {
              gap: ptr(12);

              @include flex(space-between, center);
              .radio_button {
                & span {
                  border-color: $gray-50 !important;
                  background-color: transparent !important;
                  color: $gray-50;
                }

                input[checked] ~ span {
                  border-color: $white !important;
                  color: $white;
                }
              }
            }

            &[data-active] {
              border-bottom: ptr(1) solid $white;

              .is_creator_field {
                color: $white;
              }
            }
          }

          .text_wrapper {
            width: 100%;
            padding: ptr(12) 0;
            border-bottom: ptr(1) solid $gray-50;

            &.border_active {
              border-bottom: ptr(1) solid $white;
            }

            .input_other {
              width: 100%;
              height: auto;
              min-height: ptr(44);
              max-height: ptr(88);
              border: none;
              outline: none;
              background-color: transparent;
              box-shadow: none;
              color: $white;
              resize: none;

              &::-webkit-scrollbar {
                display: none;
              }

              &::placeholder {
                color: $gray-50;
              }

              &:placeholder-shown {
                color: $gray-50;
              }
            }
          }
        }

        .guide {
          color: $gray-50;
        }
      }

      .agree,
      .marketing {
        margin-bottom: ptr(24);
        padding: 0 ptr(32);

        .agree_button {
          width: max-content;
          margin-bottom: ptr(8);
          color: $white;

          &:hover > span > span {
            border-color: white;
          }

          & span {
            background-color: transparent !important;
          }

          input[checked] ~ span {
            border-color: $white !important;
          }
        }

        .agree_content {
          color: $white;
          word-break: keep-all;

          & > strong {
            display: block;
          }
        }
      }
    }

    .button_wrapper {
      padding: ptr(16);
      padding-bottom: calc(env(safe-area-inset-bottom) + 16px);

      @include flex(center, center);
      .submit {
        padding: ptr(16) ptr(24);
      }
    }
  }
}

.container[data-valid="true"] {
  .guide {
    color: $white !important;
  }
}

.domain_prev {
  margin-right: ptr(4);
  color: $white;
}
