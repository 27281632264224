@import "base";
.product_thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
}

.image {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 1 / 1;
}
