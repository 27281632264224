@import "base";
.button_checkbox {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .checkbox {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox:checked ~ .button {
    border-color: $blue;
    background-color: $blue;
  }

  .checkbox:disabled ~ .button {
    border-color: $gray-20;
    background-color: $gray-5;
  }

  .checkbox:checked ~ .button > .check {
    opacity: 1;
  }

  &:hover > .button_wrapper > .button {
    border-color: $blue;
  }

  .button_wrapper {
    display: flex;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    gap: ptr(8);

    .button {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: ptr(16);
      min-height: ptr(16);
      transition:
        border-color 300ms ease-out,
        background-color 300ms ease-out;
      border: 1px solid $gray-20;
      border-radius: 4px;
      background-color: $white;
    }

    .check {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 1px;
      transition: opacity 300ms ease-out;
      opacity: 0;
      color: $white;
    }
  }

  .label {
    text-align: left;
  }
}
