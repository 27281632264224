@import "base";
@import "./variables";

.modal_container {
  @include flex(center, center);

  display: none;
  position: fixed;
  inset: 0;
  z-index: 1000;

  &.open {
    display: flex;
  }
}

.backdrop {
  position: absolute;
  z-index: 1;
  transition: opacity 0.35s;
  opacity: 0;
  background: $dim-60;
  inset: 0;

  &.open {
    opacity: 1;
  }
}

.modal {
  position: relative;
  z-index: 2;
  overflow: hidden;
  transform: translateY(50%);
  border-radius: ptr(16);
  opacity: 0;
  background: $white;
  animation-fill-mode: forwards;

  &.animate {
    animation-duration: 0.35s;
  }

  &.slide_in {
    animation-name: slideIn;
  }

  &.slide_out {
    animation-name: slideOut;
  }

  &.medium {
    width: ptr(448);
  }

  &.large {
    width: ptr(608);
  }

  .header {
    @include flex(space-between, center);

    padding: ptr(16) ptr(16) ptr(15);
    border-bottom: 1px solid $gray-5;

    .close_btn {
      width: ptr(20);
      @include flex(center, center);
    }
  }

  .body {
    max-height: ptr(610);
    padding: $modal-body-default-padding;
    overflow: hidden auto;
  }

  .footer {
    @include flex(space-between, center);

    padding: ptr(16);
    border-top: 1px solid $gray-5;
  }
}

@keyframes slideIn {
  from {
    //transform: scale(0.3);
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    //transform: scale(1);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    //transform: scale(0.3);
    transform: translateY(0);
    opacity: 1;
  }

  to {
    //transform: scale(1);
    transform: translateY(50%);
    opacity: 0;
  }
}

.test {
  @include flex(center, stretch, column);

  gap: ptr(24);
  max-width: ptr(240);
}

html[is_mobile="true"] {
  .modal {
    &.large {
      width: 100%;
      height: 100dvh;
      border-radius: 0;

      .body {
        height: 100%;
        max-height: initial;
        padding-bottom: calc($modal-body-default-padding + $modal-footer-default-height);
      }

      .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: $modal-footer-default-height;
        background-color: $white;
      }
    }
  }
}
