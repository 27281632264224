@import "base";
html[is_mobile="false"] {
  .creator_cheer_up_profile {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: $default-header-pc_height + $default-sub-header-pc_height + ptr(48);
    gap: ptr(32);
  }

  .thumb {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: ptr(180);
    height: ptr(180);

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .thumb_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 999px;
    background-color: $white;
  }

  .about_sns {
    width: ptr(40);
    height: ptr(40);
    padding: ptr(8);
    border-radius: 99px;
    background: $dim-5;

    img,
    svg {
      width: ptr(24);
      height: ptr(24);
    }
  }

  .about_edit {
    .about_btn_edit {
      width: ptr(32);
      height: ptr(32);
      padding: ptr(6.4);
      border-radius: 99px;
      background: $blue-10;
      color: $blue-100;
    }

    img,
    svg {
      width: ptr(19.2);
      height: ptr(19.2);
    }
  }

  .info {
    flex: 1;
  }

  .header {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: ptr(12);
  }

  .seller_type {
    color: $gray-80;
    letter-spacing: -0.2px;
    line-height: ptr(22);
  }

  .about_title {
    margin-top: ptr(24);
    margin-bottom: ptr(8);
    color: $black;
    letter-spacing: -0.2px;
    line-height: ptr(24);
  }

  .description {
    color: $gray-80;
    letter-spacing: -0.2px;
    line-height: ptr(22);
    word-break: break-word;
    white-space: pre-wrap;
  }

  .like_area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: ptr(90);
  }

  .like_btn {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    padding: 4px 12px;
    border-radius: 9999px;
    background: $dim-5;
    color: $gray-80;
    gap: 4px;

    &.active .like {
      color: $blue;
    }
  }

  .like {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ptr(20);
    height: ptr(20);
    color: $gray-20;
  }

  .like_count {
    color: $gray-80;
  }
}

html[is_mobile="true"] {
  .creator_cheer_up_profile {
    margin-top: $default-header-mo_height + $default-sub-header-mo_height + ptr(20);
  }

  .wrap {
    position: relative;
    width: 100%;
    min-height: ptr(128);
    padding-left: ptr(144);
  }

  .thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: ptr(128);
    height: ptr(128);

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .thumb_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 999px;
    background-color: $white;
  }

  .about_sns {
    width: ptr(32);
    height: ptr(32);
    padding: ptr(6.4);
    border-radius: 99px;
    background: $dim-5;

    img,
    svg {
      width: ptr(19.2);
      height: ptr(19.2);
    }
  }

  .about_edit {
    .about_btn_edit {
      width: ptr(32);
      height: ptr(32);
      padding: ptr(6.4);
      border-radius: 99px;
      background: $blue-10;
      color: $blue-100;
    }

    img,
    svg {
      width: ptr(19.2);
      height: ptr(19.2);
    }
  }

  .info {
    padding-top: ptr(13);
  }

  .header {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: ptr(12);
  }

  .name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .seller_type {
    color: $gray-80;
    letter-spacing: -0.2px;
    line-height: ptr(22);
  }

  .about_title {
    margin: ptr(24) 0 ptr(8);
    color: $black;
    letter-spacing: -0.2px;
    line-height: ptr(24);
  }

  .description {
    margin: 0;
    color: $gray-50;
    letter-spacing: -0.2px;
    line-height: ptr(22);
    word-break: break-word;
    white-space: pre-wrap;
  }

  .like_area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: ptr(90);
    margin-top: ptr(12);
  }

  .like_btn {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: ptr(8) ptr(16);
    border-radius: 9999px;
    background: $dim-5;
    color: $gray-80;
    gap: 4px;

    &.active .like {
      color: $blue;
    }
  }

  .like {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ptr(20);
    height: ptr(20);
    color: $gray-20;
  }

  .like_count {
    color: $gray-80;
  }
}
