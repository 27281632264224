@import "base";
html[is_mobile="false"] {
  .creator_view {
    width: 100%;
    margin: auto;
    padding: ptr(96) 0;
    background: $purple-100;
    @include flex(center, center, column);

    gap: ptr(24);

    .creator_view_container {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      align-items: flex-start;
      width: 100%;
      max-width: $pc-layout-body-max-width;
      padding: 0 ptr(32);
      column-gap: ptr(24);
    }
  }
}

html[is_mobile="true"] {
  .creator_view {
    position: relative;
    width: 100%;
    padding: ptr(60) 0;
    background: $purple-100;
    gap: ptr(16);
    @include flex(center, center, column);

    .creator_view_container {
      display: flex;
      width: 100%;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      @include hiddenScroll;
    }

    .creator_view_card {
      box-sizing: unset;
      flex: 0 0 auto;
      width: 70vw;
      padding-left: 16px;
      scroll-snap-align: start;

      &:last-child {
        padding-right: 16px;
      }
    }
  }
}
