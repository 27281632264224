@import "base";
html[is_mobile="false"] section.case {
  padding: ptr(124) 0;
  background-color: $blue-100;

  & .title {
    margin: 0 auto;
    margin-bottom: ptr(80);
    color: $white;
    text-align: center;
  }

  .swiper {
    z-index: 0;
    padding: 0 ptr(32);
    opacity: 0;

    &.visible {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }

    .swiper_wrapper {
      margin-bottom: ptr(80);

      .slide {
        width: ptr(640);

        .case_card {
          width: ptr(640);

          .image {
            width: 100%;
            height: ptr(360);
            margin-bottom: ptr(19);
            border-radius: ptr(16);

            .image_backdrop {
              position: absolute;
              width: 100%;
              height: ptr(360);
              border-radius: ptr(16);
              background: rgb(20 20 20 / 30%);
              color: $white;
              text-transform: capitalize;
              backdrop-filter: blur(20px);
              @include flex(center, center);
            }
          }

          .row {
            width: 100%;
            @include flex(flex-start, center);

            gap: ptr(8);

            .field {
              width: ptr(120);
              color: $white;
            }

            .value {
              color: $white;
            }
          }
        }
      }
    }

    .pagination {
      position: relative;
      height: ptr(2);
      background: $bright-5;

      .progressbar_fill {
        background: $white;
      }
    }
  }
}

html[is_mobile="true"] section.case {
  padding: ptr(40) 0;
  background-color: $blue-100;

  & .title {
    width: 100%;
    max-width: ptr(375);
    margin: 0 auto;
    margin-bottom: ptr(40);
    padding: 0 ptr(32);
    color: $white;
    text-align: center;
    word-break: keep-all;
  }

  .swiper {
    padding: 0 ptr(32);
    opacity: 0;

    &.visible {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }

    .swiper_wrapper {
      margin-bottom: ptr(103.6);

      .slide {
        width: ptr(308);

        .case_card {
          width: ptr(308);

          .image {
            width: 100%;
            height: ptr(308);
            margin-bottom: ptr(16);
            border-radius: ptr(16);

            .image_backdrop {
              position: absolute;
              width: 100%;
              height: ptr(308);
              border-radius: ptr(16);
              background: rgb(20 20 20 / 30%);
              color: $white;
              text-transform: capitalize;
              backdrop-filter: blur(12px);
              @include flex(center, center);
            }
          }

          .row {
            width: 100%;
            @include flex(flex-start, center);

            gap: ptr(2.4);

            .field {
              width: ptr(60);
              color: $white;
            }

            .value {
              color: $white;
            }
          }
        }
      }
    }

    .pagination {
      position: relative;
      height: ptr(2);
      background: $bright-20;

      .progressbar_fill {
        background: $white;
      }
    }
  }
}
