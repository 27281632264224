@import "base";
html[is_mobile="false"] {
  .container {
    display: flex;
    flex-direction: column;
    min-width: $pc-layout-body-min-width;
    margin-top: $default-header-pc_height + $default-sub-header-pc_height + ptr(32);
    gap: ptr(24);

    .title_container {
      display: flex;
      align-items: end;

      .title {
        width: unset;
        &.full_width {
          width: 100%;
        }
      }

      .sub_title {
        margin-left: ptr(16);
      }
    }

    .body {
      display: flex;
      gap: ptr(32);
    }

    .collapse {
      flex-shrink: 0;
      min-width: ptr(200);
    }

    .product_section {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: ptr(12);
    }

    .product_cards_container {
      display: grid;
      position: relative;
      grid-gap: ptr(32) ptr(16);
      flex: 1 0;

      $breakpoint1: 1440px;
      $breakpoint2: 1280px;

      @media (min-width: #{$breakpoint1}) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }
      @media (min-width: #{$breakpoint2}) and (max-width: #{$breakpoint1 - 1px}) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      @media (max-width: #{$breakpoint2 - 1px}) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    .sort_container {
      display: flex;
      z-index: 2;
      align-items: center;
      justify-content: space-between;

      .right_panel {
        display: flex;
        gap: ptr(16);
      }
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: ptr(56);
    }

    .form_item {
      display: flex;
      flex-direction: column;
    }
  }

  .filter_btn_container {
    display: flex;
    position: fixed;
    z-index: 2;
    bottom: ptr(48);
    left: 50%;
    align-items: center;
    padding: ptr(6) ptr(24);
    transform: translateX(-50%);
    border-radius: ptr(999);
    background-color: $bright-60;
    gap: ptr(16);

    .toggle {
      display: flex;
      align-items: center;

      .icon {
        width: ptr(24);
        height: ptr(24);
        margin-right: ptr(8);
      }
    }

    .remove_container {
      display: flex;
      align-items: center;
      max-width: ptr(503);
      overflow: auto hidden;
      gap: ptr(4);

      @include hiddenScroll;
    }

    .remove {
      display: flex;
      flex-shrink: 0;
      flex-wrap: nowrap;
      align-items: center;
      padding: ptr(7) ptr(16);
      border-radius: ptr(999);
      background-color: $bright-70;

      .icon {
        display: inline-block;
        width: ptr(16);
        height: ptr(16);
        margin-left: ptr(4);
      }
    }

    .reset {
      flex-shrink: 0;
      color: $gray-80;
    }
  }
}

html[is_mobile="true"] {
  .container {
    margin-top: $default-header-pc_height + $default-sub-header-mo_height;
  }

  .title_container {
    display: flex;
    align-items: end;
    width: unset;

    .title {
      width: unset;
      &.full_width {
        width: 100%;
      }
    }

    .sub_title {
      margin-left: ptr(8);
    }
  }

  .product_cards_container {
    display: grid;
    position: relative;
    grid-gap: ptr(32) ptr(16);
    flex: 1 0;

    $breakpoint1: 691px;
    $breakpoint2: 375px;

    @media (min-width: #{$breakpoint1}) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media (min-width: #{$breakpoint2}) and (max-width: #{$breakpoint1 - 1px}) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (max-width: #{$breakpoint2 - 1px}) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  .sort_container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right_panel {
      display: flex;
      align-items: center;
      gap: ptr(8);
      padding: ptr(10) 0;
    }
  }

  .collapse {
    display: none;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ptr(48);
  }

  .filter_btn_container {
    position: fixed;
    z-index: 2;
    right: $marppleshop-layout-mo-padding;
    bottom: ptr(44);
    padding: ptr(6);
    border-radius: ptr(12);
    background-color: $bright-60;

    .toggle {
      display: flex;
      align-items: center;
      padding: ptr(8);

      .icon {
        width: ptr(24);
        height: ptr(24);
      }
    }
  }
}
