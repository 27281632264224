@import "base";
html[is_mobile="false"] .page {
  .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    width: ptr(408);
    height: 100%;
    margin: 0 auto;
    padding-top: $default-header-pc_height;
  }

  .image {
    width: ptr(160);
    margin: 0 auto;
    margin-bottom: ptr(24);
  }

  .title {
    margin-bottom: ptr(8);
    text-align: center;
  }

  .description {
    margin-bottom: ptr(16);
    color: $gray-50;
    text-align: center;
  }

  .email {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ptr(56);
    padding: 10px 16px;
    border-radius: ptr(8);
    background-color: $blue-10;
    color: $blue;
  }

  .buttons {
    display: flex;
    column-gap: ptr(16);
  }

  .resend_button {
    opacity: 1;
  }

  .change_button {
    display: none;
    opacity: 1;
  }
}

html[is_mobile="true"] .page {
  .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: $default-header-mo_height;
  }

  .image {
    width: ptr(160);
    margin: 0 auto;
    margin-bottom: ptr(24);
  }

  .title {
    margin-bottom: ptr(8);
    text-align: center;
  }

  .description {
    margin-bottom: ptr(16);
    color: $gray-50;
    text-align: center;
  }

  .email {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ptr(14);
    padding: 10px 16px;
    border-radius: ptr(8);
    background-color: $blue-10;
    color: $blue;
  }

  .buttons {
    display: flex;
    column-gap: ptr(16);
  }

  .resend_button {
    opacity: 1;
  }

  .change_button {
    display: none;
    opacity: 1;
  }
}
