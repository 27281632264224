@import "base";
button.landing_cta_button,
a.landing_cta_button {
  display: inline-flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: space-between;
  width: ptr(400);
  height: ptr(60);
  padding: ptr(12) ptr(12) ptr(12) ptr(20);
  transition:
    background-color 200ms ease-out,
    box-shadow 200ms ease-out;
  border-radius: ptr(12);
  gap: ptr(8);

  &[data-fit="true"] {
    flex: unset;
    width: fit-content;
  }

  .arrow_button {
    @include flex(center, center);

    width: ptr(40);
    height: ptr(40);
    transition: background 200ms ease-out;
    border-radius: ptr(12);
    background: $blue-100;

    & > svg {
      width: ptr(24);
      height: ptr(24);
      transition: color 200ms ease-out;
      color: $white;
    }
  }

  &.blue_line {
    @include innerBorder($blue-100);

    background-color: $white;
    color: $blue-100;
  }

  &.white {
    background-color: $blue-10;
    color: $blue-100;
  }

  &.blue {
    background-color: $blue-100;
    color: $white;

    .arrow_button {
      background: $blue-10;

      & > svg {
        color: $blue-100;
      }
    }
  }

  &.black {
    background-color: $black;
    color: $white;
  }

  html[is_mobile="true"] & {
    justify-content: center;
    width: ptr(260);
    height: ptr(48);
    padding: ptr(12) ptr(24);
    border-radius: ptr(8);
    gap: ptr(4);
    @include blur40;

    .arrow_button {
      display: none;
    }
  }

  &.hidden {
    visibility: hidden;
  }
}
