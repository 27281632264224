@import "base";
.tooltip_content_wrap {
  display: flex;
  flex-direction: column;
  gap: ptr(8);

  .tooltip_content {
    display: flex;
    flex-direction: column;
  }
}
