@import "base";
@import "../../atoms/Modal/variables";
@import "../../../../shared/style/sub/mixin";

.filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $modal-footer-default-height;
  gap: ptr(24);

  .price {
    display: flex;
    align-items: center;
    gap: ptr(12);
  }

  .product_info {
    display: flex;
    gap: ptr(16);
  }

  .check {
    display: flex;
    gap: ptr(8);
  }

  .title {
    display: inline-block;
    margin-bottom: ptr(8);
    color: $gray-80;
  }

  .filter_container {
    container: filter_container / inline-size;
  }

  .creator_filter,
  .style_filter {
    display: grid;
    grid-gap: ptr(8);
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @container filter_container (max-width: 343px) {
    .creator_filter,
    .style_filter {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .filter_btn {
    width: 100%;
    padding: ptr(8) 0;
  }
}

.product_filter_modal {
  @include pc {
    .reset {
      flex: 2;
      margin-right: ptr(16);
    }

    .confirm {
      flex: 3;
    }
  }
  @include mobile {
    .reset {
      flex-basis: min-content;
      flex-grow: 0;
      width: ptr(52);
      min-width: ptr(52);
      margin-right: ptr(8);
      padding: ptr(16);
    }
  }
}
