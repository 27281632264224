@import "base";
html[is_mobile="false"] section.container {
  position: relative;
  width: 100%;
  height: 100vh;

  .scale_title {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $blue-100;
  }

  .scale_desc {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $blue-10;
    font-size: ptr(120) !important;
  }

  .heart_icon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}

html[is_mobile="true"] section.container {
  position: relative;
  width: 100%;
  height: 100vh;

  .scale_title {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $blue-100;
  }

  .scale_desc {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $blue-10;
    z-index: 1;
  }

  .heart_icon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
}
