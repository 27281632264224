@import "base";
html[is_mobile="false"] section.container {
  .card {
    @include flex(space-between, flex-start);
    width: 100%;
    height: 100vh;

    .craft_video_wrapper {
      position: relative;
      width: 50%;
      height: 100%;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .image {
        position: absolute;
        width: ptr(800);
        height: ptr(800);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .content_wrapper {
      width: 50%;
      height: 100%;
      padding: ptr(160) 0;
      background-color: $white;

      .content {
        height: 100%;
        @include flex(center, center, column);
        gap: ptr(48);
        .craft_title {
          color: $blue-100;
          text-align: center;
          line-height: ptr(52) !important;
        }

        .craft_desc {
          color: $blue-100;
          text-align: center;
          line-height: ptr(39) !important;
        }

        .content_video_wrapper {
          width: ptr(360);
          height: ptr(480);

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }

        .image_wrapper {
          width: ptr(360);
          height: ptr(480);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }
      }
    }
  }
}

html[is_mobile="true"] section.container {
  .card {
    width: 100%;

    .craft_video_wrapper {
      position: relative;
      width: 100%;
      height: 100vh;
      aspect-ratio: 375 / 460;
      object-position: center;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .image {
        position: absolute;
        width: ptr(320);
        height: ptr(320);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .content_wrapper {
      width: 100%;
      height: 100vh;
      margin: auto;
      @include flex(center, center);
      background-color: $white;

      .content {
        height: 100%;
        @include flex(center, center, column);
        gap: ptr(32);
        .craft_title,
        .craft_desc {
          color: $blue-100;
          text-align: center;
        }

        .content_video_wrapper {
          width: ptr(247);
          height: ptr(330);

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }

        .image_wrapper {
          width: ptr(247);
          height: ptr(330);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }
      }
    }
  }
}
