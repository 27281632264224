@import "base";
html[is_mobile="false"] {
  .creator_main_profile {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    min-height: ptr(105);
    margin-top: ptr(35);
  }

  .logo {
    position: relative;
    width: ptr(180);
    margin-right: ptr(24);

    .adult {
      position: absolute;
      top: ptr(-75);
      left: 0;
      border-radius: 9999px;
      background-color: $white;
      color: $gray-20;
    }

    img {
      position: absolute;
      top: ptr(-75);
      left: 0;
      width: ptr(177);
      height: ptr(177);
      border: 3px solid $white;
      border-radius: 9999px;
      background-color: $white;
    }
  }

  .info {
    flex: 1;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ptr(16);
    margin-bottom: ptr(16);
  }

  .name {
    color: $black;
  }

  .about_sns {
    width: ptr(40);
    height: ptr(40);
    padding: ptr(8);
    border-radius: 99px;
    background: $dim-5;

    img,
    svg {
      width: ptr(24);
      height: ptr(24);
      color: $black;
    }
  }

  .about_edit {
    .about_btn_edit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ptr(40);
      height: ptr(40);
      padding: ptr(8);
      border-radius: 99px;
      background: $blue-10;
      color: $blue-100;
    }

    img,
    svg {
      width: ptr(19.2);
      height: ptr(19.2);
    }
  }

  .description {
    color: $gray-80;
    word-break: break-word;
    white-space: pre-wrap;
  }

  .like_area {
    margin-left: ptr(16);
  }

  .like_btn {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 16px;
    border-radius: 9999px;
    background: $dim-5;
    color: $gray-80;
    gap: 4px;

    &.active .like {
      color: $blue;
    }
  }

  .like {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ptr(20);
    height: ptr(20);
    color: $gray-20;
  }

  .like_count {
    color: $gray-80;
  }

  .share_btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ptr(36);
    margin-left: ptr(8);
    border-radius: 9999px;
    background-color: $gray-5;
    aspect-ratio: 1;
  }

  .share_btn_icon {
    display: inline-flex;
    width: ptr(20);
    color: $gray-80;
    aspect-ratio: 1;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    min-height: ptr(105);

    .membership {
      flex: 0 1 0;
      min-width: ptr(160);
      min-height: ptr(48);
    }
  }
}

html[is_mobile="true"] {
  .creator_main_profile {
    position: relative;
    width: 100%;
  }

  .logo {
    position: relative;
    width: ptr(144);

    .adult {
      position: absolute;
      top: ptr(-74);
      left: 0;
      width: ptr(144);
      height: ptr(144);
      border-radius: 9999px;
      background-color: $white;
      color: $gray-20;
    }

    img {
      position: absolute;
      top: ptr(-74);
      left: 0;
      width: ptr(144);
      height: ptr(144);
      border: 3px solid $white;
      border-radius: 9999px;
      background-color: $white;
    }
  }

  .info {
    padding-top: ptr(82);
  }

  .header {
    display: inline-flex;
    align-items: center;
    gap: ptr(12);
    margin-bottom: ptr(8);
  }

  .name {
    color: $black;
  }

  .about_sns {
    width: ptr(40);
    height: ptr(40);
    margin-top: ptr(2);
    padding: ptr(8);
    border-radius: 99px;
    background: $dim-5;

    img,
    svg {
      width: ptr(24);
      height: ptr(24);
      color: $black;
    }
  }

  .about_edit {
    .about_btn_edit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ptr(40);
      height: ptr(40);
      padding: ptr(8);
      border-radius: 99px;
      background: $blue-10;
      color: $blue-100;
    }

    img,
    svg {
      width: ptr(19.2);
      height: ptr(19.2);
    }
  }

  .description {
    color: $gray-80;
    word-break: break-word;
    white-space: pre-wrap;
  }

  .like_area {
  }

  .like_btn {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    padding: ptr(8) ptr(16);
    border-radius: 9999px;
    background: $dim-5;
    color: $gray-80;
    gap: ptr(4);

    &.active .like {
      color: $blue;
    }
  }

  .like {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ptr(20);
    height: ptr(20);
    color: $gray-20;
  }

  .like_count {
    color: $gray-80;
  }

  .share_btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ptr(36);
    margin-left: ptr(8);
    border-radius: 9999px;
    background-color: $gray-5;
    aspect-ratio: 1;
  }

  .share_btn_icon {
    display: inline-flex;
    width: ptr(20);
    color: $gray-80;
    aspect-ratio: 1;
  }

  .buttons {
    display: flex;
    position: absolute;
    top: ptr(36);
    right: 0;
    justify-content: flex-end;
    gap: ptr(8);

    .membership {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 ptr(16);
      border-radius: 9999px;
      background-color: $blue-100;
      color: $white;
      gap: ptr(4);

      &.subscribed {
        background-color: $dim-5;
        color: $blue-100;
      }

      .check {
        display: inline-block;
        width: ptr(16);
        height: ptr(16);
      }
    }
  }
}
