@import "base";
html[is_mobile="true"] {
  .root {
    padding-bottom: calc(env(safe-area-inset-bottom) + 64px);
  }
}

.root {
  display: flex;
  flex-direction: column;
  row-gap: ptr(8);

  section {
    p {
      color: $gray-80;
      font-size: ptr(12);
      font-weight: 500;
      letter-spacing: -0.2px;
      line-height: 17px;
    }

    div {
      p,
      h2,
      ol {
        margin-bottom: ptr(24);
      }

      h2,
      p,
      li {
        color: $gray-80;
        font-size: ptr(12);
        font-weight: 500;
        letter-spacing: -0.2px;
        line-height: 17px;
      }

      h2 {
        font-weight: 700;
      }
    }

    &.mb16 {
      margin-bottom: ptr(16);
    }
  }

  .terms_table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    th,
    td {
      width: auto;
      padding: ptr(8);
      border: 1px solid $gray-20;
      color: $gray-80;
      font-size: ptr(12);
      letter-spacing: -0.2px;
      line-height: 17px;
    }

    th {
      background-color: $gray-5;
      font-weight: 700;
      text-align: center;
    }

    td {
      padding: ptr(8);
      font-weight: 500;
      vertical-align: top;
    }
  }
}
