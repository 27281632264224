@import "base";
@import "../../../../../shared/style/base";

.nav_container {
  list-style: none;

  // normal type
  &:not(.compact) {
    @include pc {
      display: inline-flex;
      height: ptr(90);
      border: 1px solid $gray-10;
      border-radius: ptr(8);
    }
    @include mobile {
      display: flex;
      gap: ptr(8);
    }

    .nav_item {
      color: $gray-80;
      @include pc {
        display: flex;
        flex: 1 0;
        min-width: ptr(200);
        max-width: ptr(220);
        border-right: 1px solid $gray-10;
      }
      @include mobile {
        display: flex;
        flex: 1 0 ptr(100);
        padding: ptr(8);
        border: 1px solid $gray-10;
        border-radius: ptr(8);
        text-align: center;
      }

      &.full_width {
        grid-column: span 2;
      }

      &:last-child {
        @include pc {
          border-right: none !important;
        }
      }

      &.active {
        color: $blue-100;
        @include mobile {
          border-color: inherit;
        }

        .icon_container {
          background-color: $blue-100;

          .icon {
            color: $white;
          }
        }

        .count {
          color: $blue-100;
        }
      }

      .content {
        color: inherit !important;
        @include pc {
          display: flex;
          flex: 1;
          align-items: center;
          padding: 0 ptr(24);
          gap: ptr(16);
        }
        @include mobile {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }

      .icon_container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ptr(40);
        height: ptr(40);
        border-radius: 999px;
        background-color: $gray-5;
        color: inherit;

        .icon {
          color: $gray-50;
        }

        .icon20 {
          @extend .icon;

          width: ptr(20);
          aspect-ratio: 1 / 1;
        }

        .icon24 {
          @extend .icon;

          width: ptr(24);
          aspect-ratio: 1 / 1;
        }
      }

      .text_container {
        display: flex;
        flex-direction: column;
      }

      .count {
        color: black;
      }

      .count_unit {
        @include mobile {
          display: none;
        }
      }
    }
  }

  // compact type
  &.compact {
    display: flex;
    @include pc {
      gap: ptr(12);
      max-width: ptr(1720);
    }
    @include mobile {
      gap: ptr(16);
    }

    .nav_item {
      display: flex;
      flex: 1 0 ptr(120);
      align-items: center;
      justify-content: space-between;
      padding: ptr(16);
      border: 1px solid $gray-20;
      border-radius: ptr(12);

      &.full_width {
        grid-column: span 2;
      }

      .content {
        display: flex;
        flex: 1;
      }

      .text_container {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
      }

      .name {
        color: $black;
      }

      .count {
        color: inherit;
        text-decoration: underline;

        &.zero {
          color: $gray-50;
        }

        &.blue {
          color: $blue-100;
        }

        &.red {
          color: $red-80;
        }
      }

      .count_unit {
        color: $gray-50;
      }
    }
  }
}
