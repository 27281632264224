@import "base";
@import "../../../../shared/style/base";
@import "base_style";
@import "variables";
@import "vertical";
@import "horizontal";

.product_card {
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;

  &.theme_light {
    color: $black;
  }
  &.theme_dark,
  &.theme_color {
    color: $white;
  }

  &.sold_out {
    .thumbnail_container:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      opacity: 0.7;
      background: $product-bg;
    }
  }

  &.hidden {
    transform: translateX(-20px);
    opacity: 0;
  }

  &.fade_in {
    transform: translateX(0);
    opacity: 1;
  }

  .thumbnail_container {
    display: block;
    position: relative;
    flex-shrink: 0;
    width: 100%;
    overflow: hidden;
    border-radius: $border-radius-2x;
    &.adult {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $product-bg;
      color: $gray-20;
      aspect-ratio: 1 / 1;
    }
  }

  .product_name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .adult_verification {
    width: 50%;
    aspect-ratio: 1 / 1;
  }

  .review {
    display: flex;
    gap: ptr(2);
    align-items: center;
    &.theme_light {
      color: $gray-50;
    }
    &.theme_dark,
    &.theme_color {
      color: $gray-20;
    }

    .star_icon {
      display: flex;
      width: ptr(14);
      height: ptr(14);
      margin-right: ptr(2);
    }
  }

  .hidden {
    display: none;
  }

  .like_btn {
    z-index: 1;
    &.right_top {
      position: absolute;
      top: 0;
      right: 0;
    }
    &.right_top_with_padding {
      position: absolute;
      top: ptr(16);
      right: ptr(16);
    }
    &.right {
      position: absolute;
      right: 0;
    }
    &.hidden {
      display: none;
    }
  }
}

html[is_mobile="true"] {
  .product_card {
    &[data-is_horizontal="false"] {
      @include mo_vertical;
    }
    // mobile horizontal
    &[data-is_horizontal="true"] {
      @include mo_horizontal;
    }
  }
}

html[is_mobile="false"] {
  .product_card {
    &[data-is_horizontal="false"] {
      @include pc_vertical;
    }
    // pc horizontal
    &[data-is_horizontal="true"] {
      @include pc_horizontal;
    }
  }
}
