@mixin normal($breakpoint1) {
  @media (min-width: #{$breakpoint1}) {
    @content;
  }
}

@mixin variable($breakpoint1, $breakpoint2) {
  @media (min-width: #{$breakpoint2}) and (max-width: #{$breakpoint1 - 1px}) {
    @content;
  }
}

@mixin narrow($breakpoint2) {
  @media (max-width: #{$breakpoint2 - 1px}) {
    @content;
  }
}

@mixin hide-see-more($n) {
  @for $i from $n through 0 {
    .section_title[data-count="#{$i}"] .see_more {
      display: none;
    }
  }
}

@mixin set-visibility-by-tab($product_limit, $creator_limit, $popup_store_limit, $curation_limit) {
  &.search_page {
    &[data-current_tab="all"] {
      .pagination {
        display: none !important;
      }

      .product_cards_container {
        & > div:nth-child(n + #{$product_limit + 1}) {
          display: none !important;
        }
      }

      .creators_container {
        & > div:nth-child(n + #{$creator_limit + 1}) {
          display: none !important;
        }
      }

      .popup_stores_container {
        & > div:nth-child(n + #{$popup_store_limit + 1}) {
          display: none !important;
        }
      }

      .curations_container {
        & > div:nth-child(n + #{$curation_limit + 1}) {
          display: none !important;
        }
      }
    }
  }
}

@mixin set-responsive($product_limit, $creator_limit, $popup_store_limit, $curation_limit) {
  &.search_page {
    .product_cards_container {
      grid-template-columns: repeat($product_limit, minmax(0, 1fr));
    }

    .creators_container {
      grid-template-columns: repeat($creator_limit, minmax(0, 1fr));
    }

    .popup_stores_container {
      grid-template-columns: repeat($popup_store_limit, minmax(0, 1fr));
    }

    .curations_container {
      grid-template-columns: repeat($curation_limit, minmax(0, 1fr));
    }
  }
}
