@import "base";
@keyframes marquee-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee-right {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

html[is_mobile="false"] section.portfolio {
  padding: ptr(96) 0;
  background-color: $black;
  @include flex(space-between, flex-start, column);

  .title_wrapper {
    margin-bottom: ptr(104);

    .title {
      width: ptr(518);
      margin-bottom: ptr(24);
      margin-left: ptr(188);
      color: $white;
    }

    .description {
      width: ptr(556);
      margin-bottom: ptr(24);
      margin-left: ptr(188);
      color: $white;
    }
  }

  .content {
    position: relative;
    z-index: 0;
    width: 100%;
    height: ptr(576);

    .marquee-wrapper {
      display: flex;
      position: absolute;
      z-index: 0;
      top: 0;
      width: 100%;
      overflow: hidden;

      .marquee-background {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-around;
        min-width: 100%;
        height: ptr(280);
        max-height: ptr(280);
        animation: marquee-left 50s linear infinite;
        gap: ptr(16);
        will-change: transform;
        animation-fill-mode: both;

        .portfolio_card {
          width: ptr(280);
          height: ptr(280);
          border-radius: ptr(32);
          background: $white;

          img.portfolio_image {
            width: 100%;
            height: 100%;
            border-radius: ptr(32);
          }
        }
      }

      .marquee-background[aria-hidden="true"] {
        margin-left: ptr(16);
      }

      &.top {
        top: 0;
      }

      &.bottom {
        top: ptr(296);

        .marquee-background {
          animation: marquee-right 50s linear infinite;
          animation-fill-mode: both;
        }
      }
    }
  }
}

html[is_mobile="true"] section.portfolio {
  padding: ptr(40) 0 ptr(107);
  background-color: $black;
  gap: ptr(60);
  @include flex(center, center, column);

  .title_wrapper {
    @include flex(center, center, column);

    width: 100%;
    max-width: ptr(375);
    padding: ptr(107) ptr(32) 0;
    gap: ptr(16);

    .title {
      color: $white;
      text-align: center;
    }

    .description {
      color: $white;
      text-align: center;
    }
  }

  .content {
    position: relative;
    z-index: 0;
    width: 100%;
    height: ptr(304);

    .marquee-wrapper {
      display: flex;
      position: absolute;
      z-index: 0;
      top: 0;
      width: 100%;
      overflow: hidden;

      .marquee-background {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-around;
        min-width: 100%;
        height: ptr(144);
        max-height: ptr(144);
        animation: marquee-left 50s linear infinite;
        gap: ptr(16);
        will-change: transform;
        animation-fill-mode: both;

        .portfolio_card {
          width: ptr(144);
          height: ptr(144);
          border-radius: ptr(16);
          background: $white;

          img.portfolio_image {
            width: 100%;
            height: 100%;
            border-radius: ptr(16);
          }
        }
      }

      .marquee-background[aria-hidden="true"] {
        margin-left: ptr(16);
      }

      &.top {
        top: 0;
      }

      &.bottom {
        top: ptr(160);

        .marquee-background {
          animation: marquee-right 50s linear infinite;
          animation-fill-mode: both;
        }
      }
    }
  }
}
