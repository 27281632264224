@import "base";
html[is_mobile="false"] {
  .container {
    margin-top: $default-header-pc_height;
    margin-bottom: ptr(120);
  }

  .breadcrumbs {
    margin-bottom: ptr(24);
  }

  .title {
    margin-bottom: ptr(24);
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ptr(48);
  }
}

html[is_mobile="true"] {
  .container {
    margin-top: $default-header-mo_height;
    margin-bottom: ptr(60);
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ptr(48);
  }
}
