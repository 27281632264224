@import "base";
html[is_mobile="false"] {
  .layout {
    padding-top: ptr(40);
  }
}

html[is_mobile="true"] {
  .layout {
    padding-top: ptr(40);
  }
}
