@import "base";
html[is_mobile="false"] section.container {
  .video_container {
    position: relative;
    width: 100%;
    height: 100vh;
    .video_wrapper {
      width: 100%;
      height: 100%;
      video.motion_video {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        &.opacity {
          opacity: 0.2;
        }
      }
      &.blue_bg {
        background-color: $blue-100;
      }
    }

    .slogan {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ptr(400);
      height: ptr(400);
    }

    .video_content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      gap: ptr(24);
      @include flex(center, center, column);

      .title {
        color: $white;
        text-align: center;
      }
      .description {
        color: $white;
        text-align: center;
      }
    }
  }
}

html[is_mobile="true"] section.container {
  .video_container {
    position: relative;
    width: 100%;
    height: 100vh;
    .video_wrapper {
      width: 100%;
      height: 100%;
      video.motion_video {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        &.opacity {
          opacity: 0.2;
        }
      }
      &.blue_bg {
        background-color: $blue-100;
      }
    }

    .slogan {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ptr(240);
      height: ptr(240);
    }

    .video_content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      gap: ptr(24);
      @include flex(center, center, column);

      .title {
        color: $white;
        text-align: center;
      }
      .description {
        color: $white;
        text-align: center;
      }
    }
  }
}
