@import "base";
html[is_mobile="false"] section.popup_store {
  padding: ptr(80);

  > .title {
    margin-bottom: ptr(56);
    text-align: center;
  }

  > .content {
    @include grid(5, ptr(32));

    display: grid;
    grid-template-columns: repeat(5, ptr(327));
    align-items: center;
    justify-content: center;
    margin-bottom: ptr(32);

    .popup_store_card {
      width: ptr(327);
      cursor: pointer;

      .image {
        width: 100%;
        margin-bottom: ptr(16);
        border-radius: ptr(24);
      }

      .content_wrapper {
        width: 100%;

        .category {
          height: ptr(22);
          margin-bottom: ptr(4);
          color: $gray-50;
        }

        .title {
          width: 100%;
          height: ptr(28);
          margin-bottom: ptr(4);
          white-space: inherit;
          @include lineLimit(1);
        }

        .description {
          width: 100%;
          height: ptr(22);
          margin-bottom: ptr(16);
          white-space: inherit;
          @include lineLimit(1);
        }

        .duration {
          height: ptr(20);
          color: $gray-50;
        }
      }
    }
  }

  > button.more {
    display: block;
    width: max-content;
    margin: 0 auto;

    .button_label {
      @include flex(center, center);

      gap: ptr(4);

      svg {
        width: ptr(20);
        height: ptr(20);
        color: $blue-100;
      }
    }

    &:hover {
      .button_label {
        svg {
          color: $blue-10;
        }
      }
    }
  }
}

html[is_mobile="true"] section.popup_store {
  padding: ptr(131) 0 ptr(40);

  > .title {
    margin-bottom: ptr(40);
    color: $blue-100;
    text-align: center;
  }

  .swiper {
    margin-bottom: ptr(115);
    padding: 0 ptr(32);
    opacity: 0;

    &.visible {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }

    .swiper_wrapper {
      margin-bottom: ptr(40);

      .slide {
        width: ptr(308);

        .popup_store_card {
          width: ptr(308);

          .image {
            width: 100%;
            margin-bottom: ptr(16);
            border-radius: ptr(16);
          }

          .content_wrapper {
            width: 100%;

            .category {
              height: ptr(22);
              margin-bottom: ptr(4);
              color: $gray-50;
            }

            .title {
              width: 100%;
              height: ptr(28);
              margin-bottom: ptr(4);
              white-space: inherit;
              @include lineLimit(1);
            }

            .description {
              width: 100%;
              height: ptr(22);
              margin-bottom: ptr(16);
              white-space: inherit;
              @include lineLimit(1);
            }

            .duration {
              height: ptr(20);
              color: $gray-50;
            }
          }
        }
      }
    }

    .pagination {
      position: relative;
      height: ptr(2);
      background: $dim-5;

      .progressbar_fill {
        background: $blue-100;
      }
    }
  }

  > button.more {
    display: block;
    width: max-content;
    margin: 0 auto;
  }
}
