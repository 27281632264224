@import "base";
.container {
  width: 100%;
  @include flex(center, flex-start, column);

  html[is_mobile="false"] & {
    max-width: $pc-layout-body-max-width;
    gap: ptr(24);
  }

  html[is_mobile="true"] & {
    gap: ptr(16);
  }
}

.review_list {
  width: 100%;
  padding: 0 ptr(16);
  gap: ptr(16);

  html[is_mobile="false"] & {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding: 0 ptr(32);
    gap: ptr(24);

    > [data-rune="PhotoReviewCard"] {
      flex: 1 1 0;
    }
  }

  html[is_mobile="true"] & {
    display: flex;
    overflow-x: auto;

    scroll-padding-left: ptr(16);
    scroll-snap-type: x mandatory;

    @include hiddenScroll;
    > [data-rune="PhotoReviewCard"] {
      position: relative;
      flex: none;
      width: ptr(240);
      max-height: ptr(409);
      scroll-snap-align: start;
    }
  }
}
