@import "base";
.form_row {
  @include flex(flex-start, stretch, column);

  align-self: stretch;
  width: 100%;
  gap: ptr(8);

  .field {
    height: ptr(22);
    color: $black;
    gap: ptr(4);
    @include flex(flex-start, flex-start);

    & > svg {
      margin-top: ptr(4);
    }
  }

  .content {
    @include flex(flex-start, stretch);

    gap: ptr(8);
  }

  .description {
    color: $gray-50;
  }
}
