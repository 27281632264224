@import "base";
@import "../../../../shared/style/sub/mixin";

@mixin empty_screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include pc {
    padding: ptr(160) 0;
  }
  @include mobile {
    padding: ptr(120) 0;
  }

  .image {
    aspect-ratio: 1 / 1;
    @include pc {
      width: ptr(160);
    }
    @include mobile {
      width: ptr(120);
    }
  }

  .title {
    margin-top: ptr(24);
    color: $gray-80;
    text-align: center;
  }

  .body {
    margin-top: ptr(8);
    color: $gray-50;
    text-align: center;
  }
}

.shop_empty,
.plus_shop_empty {
  @include empty_screen;
}

.creator_shop_empty {
  @include empty_screen;

  .footer {
    margin-top: ptr(24);
  }
}
