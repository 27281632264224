@import "base";
html[is_mobile="false"] section.interview {
  @include flex(center, center, column);

  padding: ptr(120) ptr(360);
  gap: ptr(80);

  .introduce {
    max-width: ptr(1200);
    height: ptr(588);
    @include flex(center, center);

    gap: 24px;

    & .content {
      position: relative;
      width: 50%;
      height: 100%;
      @include flex(flex-start, flex-start, column);

      padding-top: ptr(48);
      gap: ptr(32);

      & .link {
        @include flex(flex-start, center);

        padding: ptr(8) 0;
        border-bottom: 1px solid var(--Black, #141414);
        gap: ptr(8);

        .arrow_icon {
          @include flex(center, center);

          width: ptr(16);
          height: ptr(16);
        }
      }
    }

    & .video_wrapper {
      position: relative;
      width: 50%;
      aspect-ratio: 1;

      .motion_video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: ptr(8);
      }
    }
  }

  .creator_list {
    max-width: ptr(1200);
    @include flex(center, flex-start);

    gap: ptr(24);

    .creator {
      @include flex(flex-start, flex-start, column);

      gap: ptr(24);

      & .image_wrapper {
        width: ptr(384);
        height: ptr(384);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: ptr(8);
        }
      }

      & .content {
        @include flex(flex-start, flex-start, column);

        gap: ptr(12);

        .title_wrapper {
          @include flex(flex-start, flex-start, column);

          gap: ptr(8);

          .tag_wrapper {
            @include flex(flex-start, center);

            gap: ptr(8);

            .tag {
              padding: ptr(8);
              border-radius: ptr(4);
              background-color: $blue-10;
              color: $blue-100;
            }
          }
        }

        .description_wrapper {
          @include flex(flex-start, flex-start, column);

          gap: ptr(8);

          .description {
            line-height: ptr(28) !important;
          }
        }
      }
    }
  }
}

html[is_mobile="true"] section.interview {
  .introduce {
    align-self: stretch;
    width: 100%;
    padding: ptr(80) ptr(24);
    gap: ptr(32);
    @include flex(center, flex-start, column-reverse);

    & .content {
      @include flex(flex-start, flex-start, column);

      gap: ptr(16);
      flex: 1 0 0;
      align-self: stretch;

      & .link {
        @include flex(flex-start, center);

        padding: ptr(8) 0;
        border-bottom: 1px solid var(--Black, #141414);
        gap: ptr(8);

        .arrow_icon {
          @include flex(center, center);

          width: ptr(16);
          height: ptr(16);
        }
      }
    }

    & .video_wrapper {
      width: 100%;
      aspect-ratio: 327 / 245;

      .motion_video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: ptr(8);
      }
    }
  }

  .slide {
    width: 100%;
    height: ptr(794);
    @include flex(flex-start, center, column);

    gap: ptr(24);

    .swiper {
      width: 100%;

      .swiper_wrapper {
        width: 100%;
        @include flex(flex-start, center);

        .creator {
          width: calc(100% - 72px);
          @include flex(flex-start, center, column);

          gap: ptr(16);

          & .image_wrapper {
            width: 100%;
            aspect-ratio: 1;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: ptr(8);
            }
          }

          & .content {
            width: 100%;
            @include flex(flex-start, flex-start, column);

            gap: ptr(12);

            .title_wrapper {
              @include flex(flex-start, flex-start, column);

              gap: ptr(8);

              .tag_wrapper {
                @include flex(flex-start, center);

                gap: ptr(8);

                .tag {
                  padding: ptr(6);
                  border-radius: ptr(4);
                  background-color: $blue-10;
                  color: $blue-100;
                }
              }
            }

            .description_wrapper {
              @include flex(flex-start, flex-start, column);

              gap: ptr(8);

              .description {
                line-height: ptr(22) !important;
              }
            }
          }
        }
      }
    }
  }

  .pagination {
    @include flex(center, center);

    gap: ptr(12);
    transition: color 200ms ease;
    color: $black;

    .pages {
      @include flex(flex-start, center);

      flex-shrink: 0;
      width: ptr(48);
      height: ptr(1);
      background-color: $dim-10;

      .page {
        height: 100%;
        transition:
          width 1s,
          background 200ms ease;
        background-color: $black;
      }
    }

    & > span {
      min-width: ptr(15);
    }
  }
}
