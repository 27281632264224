@import "base";
@import "../../../../shared/style/base";

.tooltip_content {
  display: flex;
  flex-direction: column;
  gap: ptr(16);
  word-break: break-all;

  .footer_container {
    display: flex;
    flex-direction: column;

    .footer {
      &::before {
        content: "•";
        width: ptr(14);
        margin-right: ptr(6);
      }
    }
  }

  a {
    color: inherit !important;
    font-weight: 700;
    text-decoration: underline;
  }
}
