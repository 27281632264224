$marppleshop-layout-padding: 32px;
$marppleshop-layout-pc-padding: ptr(32);
$marppleshop-layout-mo-padding: 16px;
$pc-max-width: 1960px;
$pc-layout-body-max-width: ptr(1440);
$pc-layout-body-min-width: 960px;
$default-header-pc_height: ptr(72);
$default-sub-header-pc_height: ptr(64);
$default-header-mo_height: ptr(60);
$default-sub-header-mo_height: ptr(64);
$default-sub-header-sub-menu-container-mo_height: ptr(54);
