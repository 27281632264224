@import "../../../../shared/style/base";
@import "./base_style";
@import "../ProductBadge/variables";

@mixin pc_vertical {
  &[data-type="normal"] {
    $_gap: ptr(16);

    @include vertical-base;
    @include info-base {
      margin-top: $_gap;
      padding: 0 ptr(8);
      gap: ptr(4);
    }

    .badge_container {
      position: absolute;
      top: ptr(12);
      left: ptr(12);
      width: calc(100% - #{ptr(12) * 2});
      height: $product_badge_height_pc;
    }

    .tag_container {
      margin-top: ptr(12);
    }
  }

  &[data-type="compact"] {
    $_gap: ptr(8);

    @include vertical-base;
    @include info-base {
      margin-top: $_gap;
      padding: 0 ptr(8);
    }

    .badge_container {
      position: absolute;
      top: ptr(12);
      left: ptr(12);
      width: calc(100% - #{ptr(12) * 2});
      height: $product_badge_height_pc;
    }

    .tag_container {
      margin-top: ptr(12);
    }

    .review {
      display: none;
    }
  }
}

@mixin mo_vertical {
  &[data-type="normal"] {
    $_gap: ptr(16);

    @include vertical-base;
    @include info-base {
      margin-top: $_gap;
      padding: 0 ptr(8);
    }

    .badge_container {
      position: absolute;
      top: ptr(12);
      left: ptr(12);
      width: calc(100% - #{ptr(12) * 2});
      height: $product_badge_height_mo;
    }

    .tag_container {
      margin-top: ptr(12);
    }
  }

  &[data-type="compact"] {
    $_gap: ptr(8);

    @include vertical-base;
    @include info-base {
      margin-top: $_gap;
    }

    .badge_container {
      position: absolute;
      top: ptr(8);
      left: ptr(8);
      width: calc(100% - #{ptr(8) * 2});
      height: $product_badge_height_mo;
    }

    .tag_container {
      margin-top: ptr(8);
    }

    .review,
    .like_btn {
      display: none;
    }
  }
}
