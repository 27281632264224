@import "base";
.upload {
  display: flex;
  align-items: center;
  gap: ptr(8);

  > input {
    display: none;
  }

  .preview {
    display: flex;
    flex: 1;
    align-items: center;
    width: 100%;
    padding: ptr(9) ptr(12);
    border-radius: ptr(4);
    @include innerBorder($gray-10);

    &[data-active="false"] {
      color: $gray-50 !important;
      pointer-events: none;
    }

    &[data-active="true"] {
      color: $black !important;
      text-decoration: underline !important;
    }
  }
}
