@import "base";
html[is_mobile="false"] .section {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 1fr 1fr;
  width: 100%;
  padding-bottom: ptr(16);
  gap: ptr(16);

  .section_card {
    width: 100%;

    & > section {
      width: 100%;
      height: 100%;
    }
  }
}

html[is_mobile="true"] .section {
  width: 100%;
}
