@import "base";
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ptr(40);
}

.container {
  display: flex;
  flex-direction: column;
  gap: ptr(60);
}

.section {
  display: flex;
  flex-direction: column;
  gap: ptr(24);
}

.second_section {
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  min-width: ptr(480);
  gap: ptr(8);

  &[data-fit="true"] {
    width: fit-content;
    min-width: unset;
  }

  .header {
    display: flex;
    align-items: center;
    gap: ptr(8);

    .description {
      color: $gray-50;
    }
  }

  .row {
    display: flex;
    gap: ptr(16);

    input {
      display: none;
    }
  }

  .logo {
    position: relative;
    width: ptr(90);
    height: ptr(90);
    overflow: hidden;
    border-radius: 999px;
  }

  img {
    position: absolute;
    width: 100%;
    object-fit: cover;
    min-height: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: ptr(16);
    color: $gray-50;
  }

  .information {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: ptr(8) ptr(16);
    border-radius: ptr(6);
    background-color: $gray-5;
    color: $gray-80;
    gap: ptr(8);

    .separator {
      width: 1px;
      height: ptr(12);
      background-color: $gray-20;
    }
  }

  .mo_cover_preview,
  .pc_cover_preview {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ptr(960);
    height: ptr(240);
    overflow: hidden;
    border: 1px dashed $gray-20;
    border-radius: ptr(8);
    background-color: $white;
    color: $gray-80;
    text-align: center;
    gap: ptr(16);

    &[data-active="false"] {
      cursor: pointer;
    }

    .guide {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      div {
        &:last-child,
        &:first-child {
          width: ptr(210);
          height: 100%;
          background-color: #d92a0f33;
        }

        &:nth-child(2) {
          width: ptr(540);
          height: 100%;
          border: 0.75px dashed $red-80;
        }
      }
    }

    .delete {
      display: flex;
      position: absolute;
      top: ptr(16);
      right: ptr(16);
      align-items: center;
      justify-content: center;
      width: ptr(32);
      height: ptr(32);
      border-radius: 999px;
      background-color: $dim-30;
      color: $white !important;
    }

    .add {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ptr(60);
      height: ptr(60);
      border-radius: 999px;
      background-color: $blue;
      color: $white !important;
    }

    &[data-active="true"] {
      border: none;
    }
  }

  .mo_cover_preview {
    width: ptr(375);
    height: ptr(638);
  }
}
