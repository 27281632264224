@import "base";
html[is_mobile="false"] section.ad {
  position: relative;
  z-index: 0;

  .ad_card {
    width: 100%;
    height: 100%;
    padding: ptr(140) ptr(188);
    @include flex(space-between, flex-start);

    .content {
      width: ptr(700);
      margin-top: ptr(164);

      .title_wrapper {
        margin-bottom: ptr(64);

        .sub_title {
          margin-bottom: ptr(12);
          color: $blue-100;
        }

        .title {
          margin-bottom: ptr(24);
        }

        .description {
          margin-bottom: ptr(64);
        }
      }

      .buttons {
        @include flex(center, center);

        position: absolute;
        gap: ptr(8);
      }
    }

    .image_wrapper {
      width: ptr(800);
      height: ptr(800);
      padding: ptr(50);

      & > img {
        width: ptr(700);
        height: ptr(700);
      }
    }
  }

  .buttons {
    position: absolute;
    z-index: 10;
    top: ptr(736);
    left: ptr(188);
    gap: ptr(8);
    @include flex(center, center);
  }
}

html[is_mobile="true"] section.ad {
  @include flex(center, center, column);

  padding: ptr(40) 0;
  gap: ptr(24);

  .ad_card {
    @include flex(center, center, column);

    gap: ptr(24);
    width: 100%;

    .content {
      @include flex(flex-start, flex-start, column);

      width: 100%;
      height: ptr(301);
      padding: ptr(43.5) ptr(32) 0;

      .title_wrapper {
        @include flex(center, flex-start, column);

        gap: ptr(16);
        width: 100%;

        .sub_title {
          color: $blue-100;
        }

        .title {
        }
      }
    }

    .image_wrapper {
      width: 100%;
      max-width: ptr(375);
      height: 100%;
      max-height: ptr(375);
      margin-bottom: ptr(24);

      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .pagination {
    position: relative;
    width: 100%;
    height: ptr(8);
  }
}
