@import "base";
.tooltip {
  .trigger_wrapper {
    @include flex(flex-start, center);
    gap: ptr(4);
    color: $gray-80;
  }

  &:hover {
    .trigger {
      color: $blue;
    }
  }

  .trigger {
    width: ptr(24);
    @include flex(center, center);
  }

  &[data-size="small"] {
    .trigger {
      width: ptr(20);
    }
  }

  .content {
    max-width: ptr(400);
    padding: ptr(16);
    overflow: hidden;
    border-radius: ptr(16);
    background: rgb(235 243 254 / 60%);
    color: $blue;
    pointer-events: auto;
    @include blur40;
  }

  html[is_mobile="true"] & {
    .trigger {
      width: ptr(16);
    }
  }
}
