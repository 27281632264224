@import "base";
html[is_mobile="false"] section.question {
  height: auto;
  padding: ptr(120) ptr(240);
  gap: ptr(60);
  @include flex(center, center, column);

  .question_list {
    width: 100%;
    @include flex(center, center, column);

    gap: ptr(16);
  }

  .question_item {
    width: 100%;
    transition: background-color 0.3s ease-out;
    border-radius: ptr(24);
    background-color: $blue-10;
    color: $blue-100;
    @include flex(center, flex-start, column);

    .question_title_wrapper {
      align-self: stretch;
      width: 100%;
      padding: ptr(36) ptr(60);
      cursor: pointer;
      @include flex(space-between, center);

      .toggle_status {
        width: ptr(24);
        height: ptr(24);

        .opened {
          display: none;
        }

        .closed {
          display: block;
        }
      }
    }

    .answer_body {
      @include flex(flex-start, flex-start, column);

      height: 0;
      overflow: hidden;
      transition:
        height 0.3s ease-out,
        padding-bottom 0.3s ease-out;
      border-radius: 0 0 ptr(24) ptr(24);
      gap: ptr(16);

      .answer_wrapper {
        @include flex(flex-start, flex-start, column);

        gap: ptr(16);
        padding: 0 ptr(60);
      }

      p {
        display: block;
        overflow: hidden;
      }

      .bold {
        font-weight: 700 !important;
      }

      .underline {
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
      }

      .link_icon {
        width: ptr(24);
        height: ptr(24);
      }
    }

    &.is_open {
      background-color: $blue-100;
      color: $white;

      .question_title_wrapper {
        .opened {
          display: block;
        }

        .closed {
          display: none;
        }
      }

      .answer_wrapper {
        padding-bottom: ptr(60);
      }
    }
  }
}

html[is_mobile="true"] section.question {
  height: auto;
  padding: ptr(80) ptr(24);
  gap: ptr(32);
  @include flex(center, center, column);

  .question_list {
    width: 100%;
    @include flex(center, center, column);

    gap: ptr(16);
  }

  .question_item {
    width: 100%;
    transition: background-color 0.3s ease-out;
    border-radius: ptr(12);
    background-color: $blue-10;
    color: $blue-100;
    @include flex(center, flex-start, column);

    .question_title_wrapper {
      align-self: stretch;
      width: 100%;
      padding: ptr(16);
      cursor: pointer;
      @include flex(space-between, center);

      .toggle_status {
        width: ptr(20);
        height: ptr(20);

        .opened {
          display: none;
        }

        .closed {
          display: block;
        }
      }
    }

    .answer_body {
      @include flex(flex-start, flex-start, column);

      height: 0;
      overflow: hidden;
      transition:
        height 0.3s ease-out,
        padding-bottom 0.3s ease-out;
      border-radius: 0 0 ptr(12) ptr(12);
      gap: ptr(16);

      .answer_wrapper {
        @include flex(flex-start, flex-start, column);

        gap: ptr(16);
        padding: 0 ptr(16);
      }

      p {
        display: block;
        overflow: hidden;
      }

      .bold {
        font-weight: 700 !important;
      }

      .underline {
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
      }

      .link_icon {
        width: ptr(20);
        height: ptr(20);
      }
    }

    &.is_open {
      background-color: $blue-100;
      color: $white;

      .question_title_wrapper {
        .opened {
          display: block;
        }

        .closed {
          display: none;
        }
      }

      .answer_wrapper {
        padding-bottom: ptr(16);
      }
    }
  }
}
