@import "base";
html[is_mobile="false"] {
  .root {
    background: inherit;
  }
}

html[is_mobile="true"] {
  .root {
    padding: 0 ptr(16);
    background: inherit;
  }
}
