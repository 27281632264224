@import "base";
@import "../../../../shared/style/sub/mixin";

.search_empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ptr(160) 0;
  @include pc {
    margin-top: ptr(32);
  }

  .image {
    aspect-ratio: 1 / 1;
    @include pc {
      width: ptr(160);
    }
    @include mobile {
      width: ptr(120);
    }
  }

  .title {
    margin-top: ptr(24);
    color: $gray-80;
  }

  .body {
    margin-top: ptr(8);
    color: $gray-50;
    text-align: center;
  }
}
