@import "base";
html[is_mobile="false"] section.container {
  padding: ptr(152) 0 ptr(152) ptr(188);
  background-color: $blue-100;

  .content {
    position: relative;

    @include flex(flex-start, flex-start, column);

    gap: ptr(80);

    .title_wrapper {
      @include flex(flex-start, flex-start, column);

      .title {
        margin-bottom: ptr(24);
        color: $white;
      }

      .description {
        height: ptr(142);
        color: $white;
      }
    }

    .table {
      width: ptr(300);

      .row {
        padding: ptr(16) 0;
        border-top: ptr(1) solid $white;
        color: $white;

        &:nth-last-of-type(1) {
          border-bottom: ptr(1) solid $white;
        }
      }
    }

    .bg_mobile {
      position: absolute;
      bottom: ptr(-50);
      left: ptr(409);
      height: ptr(520);
    }

    .bg_pc {
      position: absolute;
      top: 0;
      left: ptr(521);
      height: ptr(746);
    }
  }
}

html[is_mobile="true"] section.container {
  position: relative;
  width: 100%;
  padding: ptr(40) 0;
  background-color: $blue-100;
  @include flex(center, center, column);

  .content {
    position: relative;
    width: 100%;
    height: ptr(732);
    padding: ptr(40) ptr(63);
    gap: ptr(60);

    @include flex(flex-start, center, column);

    .title_wrapper {
      @include flex(flex-start, center, column);

      width: ptr(311);

      .title {
        margin-bottom: ptr(16);
        color: $white;
        text-align: center;
      }

      .description {
        color: $white;
        text-align: center;
      }
    }

    .table {
      display: none;
    }

    .bg_mobile {
      position: absolute;
      bottom: ptr(63);
      left: ptr(28);
      height: ptr(243);
    }

    .bg_pc {
      position: absolute;
      bottom: ptr(63);
      left: ptr(56);
      height: ptr(384);
    }
  }
}
