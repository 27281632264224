@import "base";
.button_close {
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ptr(16);
  height: ptr(16);
  cursor: pointer;

  &[data-hide="true"] {
    display: none;
  }

  .button {
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .close {
    display: flex;
    position: absolute;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: ptr(3);
    border-radius: 99px;
    opacity: 1;
    background-color: $gray-50;
    color: $white;
  }

  &.transparent {
    .close {
      background-color: $bright-10;
    }
  }
}
