@import "base";
@import "../SearchResult/mixins";

html[is_mobile="false"] {
  .container {
    display: flex;
    flex-direction: column;
    margin-top: $default-header-pc_height;
    gap: ptr(24);

    .body {
      display: flex;
      gap: ptr(32);
    }

    .collapse {
      flex-shrink: 0;
      min-width: ptr(200);
    }

    .product_section {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      transform: rotate(0);
      gap: ptr(12);
    }

    .product_cards_container {
      display: grid;
      position: relative;
      grid-auto-rows: min-content;
      grid-gap: ptr(32) ptr(16);
      flex: 1 0;

      @include normal(1281px) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }
      @include variable(1281px, 1024px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      @include narrow(1025px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    .control_panel {
      display: flex;
      z-index: 2;
      align-items: center;
      justify-content: space-between;

      .filter_container {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: end;
        margin-right: ptr(16);
        gap: ptr(16);
      }
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: ptr(56);
    }

    .form_item {
      display: flex;
      flex-direction: column;
    }
  }

  .filter_btn_container {
    display: flex;
    position: sticky;
    z-index: 2;
    bottom: ptr(48);
    align-items: center;
    height: ptr(48);
    margin: 0 auto;
    padding: ptr(6) ptr(24);
    border-radius: ptr(999);
    background-color: $bright-60;
    backdrop-filter: blur(30px);

    .toggle {
      display: flex;
      align-items: center;

      .icon {
        width: ptr(24);
        height: ptr(24);
        margin-right: ptr(8);
      }
    }

    .remove_container {
      display: flex;
      align-items: center;
      max-width: ptr(503);
      margin-left: ptr(16);
      overflow: auto hidden;
      gap: ptr(4);

      @include hiddenScroll;

      &.hidden {
        display: none !important;
      }
    }

    .remove {
      display: flex;
      flex-shrink: 0;
      flex-wrap: nowrap;
      align-items: center;
      padding: ptr(7) ptr(16);
      border-radius: ptr(999);
      background-color: $bright-70;
      cursor: pointer;

      .icon {
        display: inline-block;
        width: ptr(16);
        height: ptr(16);
        margin-left: ptr(4);
      }
    }

    .reset {
      flex-shrink: 0;
      margin-left: ptr(16);
      color: $gray-80;

      &.hidden {
        display: none !important;
      }
    }
  }
}

html[is_mobile="true"] {
  .container {
    margin-top: $default-header-pc_height + $default-sub-header-mo_height;
    overflow-x: hidden;

    .title {
      display: none;
    }
  }

  .product_section {
    overflow: hidden;
  }

  .product_cards_container {
    display: grid;
    position: relative;
    grid-auto-rows: min-content;
    grid-gap: ptr(32) ptr(16);
    flex: 1 0;
    min-height: ptr(524);

    $breakpoint1: 691px;
    $breakpoint2: 375px;

    @media (min-width: #{$breakpoint1}) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media (min-width: #{$breakpoint2}) and (max-width: #{$breakpoint1 - 1px}) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (max-width: #{$breakpoint2 - 1px}) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  .control_panel {
    display: flex;
    align-items: center;
    justify-content: space-between;

    //height: ptr(58);
    margin-bottom: ptr(20);

    .filter_container {
      display: flex;
      gap: ptr(16);
    }
  }

  .collapse {
    display: none;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ptr(48);
  }

  .filter_btn_container {
    position: fixed;
    z-index: 2;
    right: $marppleshop-layout-mo-padding;
    bottom: ptr(44 + 64);
    padding: ptr(6);
    border-radius: ptr(12);
    background-color: $bright-60;
    backdrop-filter: blur(20px);

    &.active {
      color: $blue;
    }

    .toggle {
      display: flex;
      align-items: center;
      padding: ptr(8);

      .icon {
        width: ptr(24);
        height: ptr(24);
      }
    }
  }
}
