@import "base";
.dropdown_item {
  padding: 10px 20px;
}

.controls {
  .close_btn {
    display: inline-flex;

    .icon {
      width: ptr(24);
      aspect-ratio: 1 / 1;
      color: $black;
    }
  }
}
