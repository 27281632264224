@import "base";
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: ptr(80);
  overflow: hidden;

  html[is_mobile="false"] & {
    padding-bottom: ptr(120);
    overflow: visible;
  }
}

.homeContainer {
  width: 100%;
  @include flex(flex-start, center, column);

  gap: ptr(60);

  &[data-is-main="false"] {
    html[is_mobile="true"] & {
      padding-top: ptr(32);
    }
  }

  html[is_mobile="false"] & {
    min-width: $pc-layout-body-min-width;
    padding-top: ptr(96);
    gap: ptr(96);
  }
}

.bannerContainer {
  width: 100%;

  html[is_mobile="false"] & {
    min-width: $pc-layout-body-min-width;
  }
}
