@import "base";
html[is_mobile="true"] {
  .input_wrapper {
    height: ptr(40);
    padding: ptr(9) 0;

    .input {
      line-height: normal !important;
    }
  }
}

.input_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: ptr(8);

  .input_wrapper {
    display: flex;
    position: relative;
    align-items: center;
    align-self: stretch;
    height: ptr(48);
    padding: ptr(12) 0;
    border-bottom: ptr(1) solid $gray-50;

    .input_label {
      position: relative;
      width: 100%;
      height: 100%;

      .input {
        background-color: transparent;
        color: $white;

        &::-webkit-contacts-auto-fill-button {
          display: none !important;
          visibility: hidden;
          position: absolute;
          right: 0;
          pointer-events: none;
        }
      }

      .placeholder {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        color: $gray-50;
        cursor: text;

        .asterisk {
          content: " *";
          color: $red-80;
        }
      }

      .input:focus + .placeholder {
        display: none;
      }
    }
  }

  .helper {
    display: none;
  }

  &.placeholder_hidden {
    .input_wrapper {
      border-bottom: ptr(1) solid $white;

      .input_label {
        .placeholder {
          display: none;
        }
      }
    }
  }

  &[data-error="true"] {
    &[data-has_error_helper="true"] {
      .helper {
        display: flex;
      }
    }

    .input_wrapper {
      border-bottom: ptr(1) solid $red-80;
    }

    .input {
      caret-color: $red-80;
    }
  }

  &[data-has_text_helper="true"] {
    .helper {
      display: flex;
    }
  }

  &[data-has_error_helpers="true"] {
    .helper {
      display: flex;
    }
  }
}

.helper {
  display: flex;
  align-items: flex-start;
  gap: ptr(8);
}

.helper_text {
  color: $gray-50;

  &[data-error="true"] {
    color: $red-80;
  }
}

.helper_error {
  display: flex;
  align-items: center;
  padding: ptr(2) ptr(8);
  border-radius: ptr(4);
  background-color: $gray-5;
  color: $gray-50;
  gap: ptr(2);

  &[data-error="true"] {
    background-color: $red-10;
    color: $red-80;
  }

  &[data-error="false"] {
    background-color: $green-10;
    color: $green;
  }

  .check {
    width: ptr(12);
    height: ptr(12);
    margin-bottom: ptr(2);
  }
}

.controller {
  display: flex;
  align-items: center;
  margin-right: 0;
  padding: 0;
  gap: ptr(8);

  & > label > span {
    background-color: $bright-20 !important;
  }

  & > label:nth-of-type(2) {
    display: none !important;
  }

  .error_icon {
    display: none;
  }

  &[data-error="true"] {
    .error_icon {
      display: block;
      width: ptr(20);
      height: ptr(20);

      & > svg {
        width: ptr(20);
        height: ptr(20);
      }
    }
  }
}
