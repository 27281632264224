@import "base";
html[is_mobile="false"] {
  .page {
    min-width: $pc-layout-body-min-width;
    margin-top: $default-header-pc_height + $default-sub-header-pc_height;

    &.all_crew {
      margin-top: $default-header-pc_height;
    }
  }

  .body {
    padding-top: ptr(32);
    padding-bottom: ptr(120);

    &.all_crew {
      padding-top: ptr(24);
    }
  }

  .photo_review_list {
    display: grid;
    grid-auto-rows: minmax(0, 1fr);
    grid-gap: ptr(48) ptr(24);
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-top: ptr(24);
  }

  .pagination_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: ptr(56);
  }
}

html[is_mobile="true"] {
  .page {
    margin-top: $default-header-mo_height + $default-sub-header-mo_height;

    &.all_crew {
      margin-top: $default-header-pc_height;
    }
  }

  .body {
    padding-top: ptr(24);
    padding-bottom: ptr(60);
  }

  .photo_review_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ptr(16);
    row-gap: ptr(32);
  }

  .pagination_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: ptr(48);
  }
}

.empty {
  @include flex(center, center, column);

  row-gap: ptr(24);
  padding: ptr(160) 0;

  .img_wrap {
    aspect-ratio: 1;

    html[is_mobile="true"] & {
      width: ptr(120);
    }

    html[is_mobile="false"] & {
      width: ptr(160);
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .wrap {
    @include flex(center, center, column);

    row-gap: ptr(8);
  }

  .title {
    color: $gray-80;
  }

  .title_desc {
    color: $gray-50;
    text-align: center;
  }

  button.button {
    min-width: ptr(196);
  }
}
