@import "base";
html[is_mobile="false"] .section {
  width: ptr(320);
  @include flex(flex-start, flex-start, column);

  gap: ptr(16);

  .menu_list_wrapper {
    width: 100%;
    padding: ptr(24);
    border: 1px solid $gray-5;
    border-radius: ptr(16);
    gap: ptr(24);
    @include flex(flex-start, flex-start, column);

    .menu_list_title {
      @include flex(flex-start, flex-start, column);

      gap: ptr(12);
    }

    .menu_list {
      @include flex(flex-start, flex-start, column);

      gap: ptr(12);

      .menu_item {
        @include flex(flex-start, center);

        gap: ptr(12);
        cursor: pointer;

        .icon_wrapper {
          @include flex(center, center);

          width: ptr(32);
          height: ptr(32);
          border-radius: 50%;

          &.blue {
            background-color: $blue-10;
          }

          &.green {
            background-color: $green-10;
          }

          &.brown {
            background-color: $brown-10;
          }
        }

        .icon {
          width: ptr(20);
          height: ptr(20);

          &.blue {
            color: $blue-100;
          }

          &.green {
            color: $green-100;
          }

          &.brown {
            color: $brown-100;
          }
        }
      }
    }
  }

  .link_list_wrapper {
    width: 100%;
    @include flex(flex-start, flex-start, column);

    gap: ptr(16);

    > a,
    > div {
      width: 100%;
    }

    .store_links {
      @include flex(flex-start, center);

      gap: ptr(16);

      .store_link {
        @include flex(flex-start, flex-start, column);

        gap: ptr(8);
        padding: ptr(20);
        border-radius: ptr(16);
        background-color: $gray-1;
        cursor: pointer;

        .store_image_wrapper {
          @include flex(center, center);

          width: ptr(112);
          height: ptr(112);

          img {
            width: ptr(112);
          }
        }
      }
    }

    .help_link {
      width: 100%;
      @include flex(space-between, center);

      gap: ptr(16);
      padding: ptr(24);
      border-radius: ptr(16);
      background-color: $gray-1;
      cursor: pointer;

      .desc_wrapper {
        gap: ptr(4);
        @include flex(flex-start, flex-start, column);
      }

      .desc {
        color: $gray-80;
      }

      .serious_desc_wrapper {
        width: 100%;
        gap: ptr(4);
        @include flex(flex-start, flex-start, column);
      }

      .serious_text {
        width: 100%;
        text-align: right;
      }

      img {
        width: ptr(64);
        height: ptr(64);
        object-fit: cover;
        margin-right: ptr(16);
      }
    }
  }

  .apologize {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ptr(608);
    padding: ptr(40) ptr(32) ptr(24);
    border-radius: ptr(16);
    background-color: $white;
    gap: ptr(24);

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .close {
      position: absolute;
      top: ptr(16);
      right: ptr(16);
      width: ptr(24);
      height: ptr(24);
      color: $black;
    }
  }
}

html[is_mobile="true"] .section {
  width: 100%;
  @include flex(flex-start, flex-start, column);

  gap: ptr(20);
  padding: 0 ptr(16);

  .menu_list_wrapper {
    width: 100%;
    @include flex(flex-start, flex-start, column);

    gap: ptr(20);
    padding: ptr(48) 0 ptr(32);

    .menu_list_title {
      @include flex(flex-start, flex-start, column);

      gap: ptr(16);
    }

    .menu_list {
      @include flex(flex-start, flex-start, column);

      gap: ptr(16);

      .menu_item {
        @include flex(flex-start, center);

        gap: ptr(12);

        .icon_wrapper {
          @include flex(center, center);

          width: ptr(32);
          height: ptr(32);
          border-radius: 50%;

          &.blue {
            background-color: $blue-10;
          }

          &.green {
            background-color: $green-10;
          }

          &.brown {
            background-color: $brown-10;
          }
        }

        .icon {
          width: ptr(20);
          height: ptr(20);

          &.blue {
            color: $blue-100;
          }

          &.green {
            color: $green-100;
          }

          &.brown {
            color: $brown-100;
          }
        }
      }
    }
  }

  .link_list_wrapper {
    width: 100%;
    @include flex(flex-start, flex-start, column);

    gap: ptr(16);

    > a,
    > div {
      width: 100%;
    }

    .store_links {
      width: 100%;
      @include flex(space-between, center);

      gap: ptr(16);

      .store_link {
        @include flex(flex-start, flex-start, column);

        gap: ptr(8);
        padding: ptr(20);
        border-radius: ptr(16);
        background-color: $gray-1;
        cursor: pointer;

        .store_image_wrapper {
          @include flex(center, center);

          width: 100%;
          aspect-ratio: 1;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }
      }
    }

    .help_link {
      width: 100%;
      @include flex(space-between, center);

      gap: ptr(16);
      padding: ptr(24);
      border-radius: ptr(16);
      background-color: $gray-1;
      cursor: pointer;

      .desc_wrapper {
        @include flex(flex-start, flex-start, column);

        gap: ptr(4);
      }

      .desc {
        color: $gray-80;
      }

      .serious_desc_wrapper {
        width: 100%;
        gap: ptr(4);
        @include flex(flex-start, flex-start, column);
      }

      .serious_text {
        width: 100%;
        text-align: right;
      }

      img {
        width: ptr(64);
        height: ptr(64);
        object-fit: cover;
        margin-right: ptr(16);
      }
    }
  }

  .apologize {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: ptr(40) ptr(20);
    background-color: $white;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .close {
      position: absolute;
      top: ptr(16);
      right: ptr(16);
      width: ptr(24);
      height: ptr(24);
      color: $black;
    }
  }
}
