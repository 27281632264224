@import "base";
.product_tag {
  display: inline-block;
  border-radius: ptr(999);
  @include pc {
    padding: ptr(4) ptr(16);
  }
  @include mobile {
    padding: ptr(3) ptr(12);
  }

  &.red,
  &.upcoming {
    background-color: $pink-20;
    color: $red-80;
  }

  &.ranking,
  &.period_limited,
  &.quantity_limited {
    background-color: $blue-10;
    color: $blue;
  }

  &.sold_out {
    background-color: $gray-5;
    color: $black;
  }

  &.nft {
    background-color: $light-purple;
    color: $purple-100;
  }
}
