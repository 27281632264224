@import "base";
@import "../../variables";
@import "../../../../../shared/style/base";

$skeleton-background-color: $gray-5;
$skeleton-shine-color: rgb(255 255 255 / 50%);

.skeleton_container {
  position: absolute;
  inset: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    background-color: $white;
  }

  &.fade_out {
    z-index: -1;

    &::before {
      opacity: 0;
    }

    .skeleton {
      opacity: 0;
    }
  }

  &.no_shine {
    .skeleton::before {
      animation: none;
    }
  }
}

.hidden {
  display: none;
}

.skeleton {
  position: relative;
  z-index: 2;
  height: 100%;
  margin-top: ptr(16);
  overflow: hidden;
  transition: opacity 0.4s ease-out;
  border-radius: ptr(16);
  opacity: 1;
  background-color: $skeleton-background-color;
  color: $gray-50;

  .error_message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    opacity: 1;
    text-align: center;

    &.hidden {
      opacity: 0;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    animation: shine 1.5s infinite;
    background: linear-gradient(
      90deg,
      rgb(255 255 255 / 0%) 0%,
      $skeleton-shine-color 50%,
      rgb(255 255 255 / 0%) 100%
    );
  }
}

@keyframes shine {
  0% {
    left: -100%;
  }

  50% {
    left: 100%;
  }

  100% {
    left: 100%;
  }
}

.dashboard_item {
  display: flex;
  position: relative;
  flex-direction: column;
  min-width: ptr(335);
  @include pc {
    padding: $dashboard_item_padding_pc;
    border: 1px solid $dashboard_border_color;
    border-radius: ptr(16);
  }
  @include mobile {
    margin: 0 $dashboard_item_padding_mo;

    &::before {
      content: "";
      height: ptr(48);
      border-top: 1px solid $dashboard_border_color;
    }
  }

  .header {
    display: flex;
    align-items: end;
    justify-content: space-between;
    height: $dashboard_item_header_height;
  }

  .body {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    overflow: hidden;

    &.border_radius {
      border-radius: ptr(16);
    }

    &.scroll_overflow_x {
      overflow-x: scroll;
      @include hiddenScroll;
    }
  }

  .title {
    display: flex;
    align-items: center;
    gap: ptr(4);
  }

  .sub_title {
    display: inline-block;
    height: ptr(20);
    margin-top: ptr(4);
    color: $gray-50;

    &.hidden {
      display: none;
    }
  }

  .body_template {
    height: 100%;
  }

  .tooltip_container {
    width: ptr(20);
    aspect-ratio: 1 / 1;
  }

  .tooltip_content {
    padding: ptr(24);
    @include pc {
      width: ptr(400);
    }
    @include mobile {
      max-width: ptr(320);
    }
  }

  .error_container {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease-out;
    border-radius: ptr(16);
    background-color: $gray-5;
    color: $gray-50;
    text-align: center;

    @include pc {
      inset: calc($dashboard_item_header_height + $dashboard_item_padding_pc) $dashboard_item_padding_pc
        $dashboard_item_padding_pc;
    }
    @include mobile {
      inset: calc($dashboard_item_header_height + $dashboard_item_padding_mo) $dashboard_item_padding_mo
        $dashboard_item_padding_mo;
    }

    &.hidden {
      opacity: 0;
    }
  }
}
