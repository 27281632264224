@import "base";
@import "../../../../shared/style/base";
@import "../../../../shared/style/typography";

.calendar_selector {
  .calendar_btn {
    @include flex(flex-start, center);

    padding: ptr(6) ptr(11);
    border: 1px solid $black;
    border-radius: ptr(8);
    background: $white;
    color: inherit !important;
    gap: ptr(4);

    svg {
      width: ptr(12);
    }
  }

  html[is_mobile="true"] & {
    @include flex(space-between, center);

    .calendar_period {
      color: $gray-80;
    }

    .calendar_btn {
      padding: ptr(7) 0;
      border: none;
      background: $white;

      svg {
        width: ptr(12);
      }
    }
  }
}

.calendar_wrapper {
  :global {
    .flatpickr-calendar {
      @include flex(flex-start, stretch, column);

      gap: ptr(16);
    }

    .flatpickr-months {
      @include flex(space-between, center);

      .flatpickr-prev-month {
        padding: ptr(10);
        cursor: pointer;

        svg {
          width: ptr(20);
        }
      }

      .flatpickr-month {
        padding: ptr(16) 0;

        .flatpickr-current-month {
          @include flex(center, center);

          gap: ptr(16);
        }

        .cur-month,
        .cur-year {
          @extend .font_24_unica;
          @include base_unica(24, calc(34 / 24), $font-weight-bold);

          color: $black;
          appearance: none;

          &.numInput {
            cursor: none;
            pointer-events: none;
          }
        }

        .flatpickr-monthDropdown-months {
          padding: 0;
          border: none;
          outline: none;
          background: #fff;
          color: var(--Black, #141414);

          /* KR-16/Bold */
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: -0.2px;
          line-height: 24px; /* 150% */
          text-align: center;
          appearance: none;
        }
      }

      .flatpickr-next-month {
        padding: ptr(10);
        cursor: pointer;

        svg {
          width: ptr(20);
        }
      }
    }

    .flatpickr-weekdays {
      .flatpickr-weekdaycontainer {
        @include grid(7);
      }

      .flatpickr-weekday {
        @include flex(center, center);

        width: ptr(50);
        height: ptr(50);
        color: var(--Gray-80, #6b6b6b);

        /* KR-14/Regular */
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.2px;
        line-height: 22px; /* 157.143% */
        text-align: center;
      }
    }

    .flatpickr-days {
      .dayContainer {
        @include grid(7);

        &:hover {
          .flatpickr-day.inRange {
            background: $blue-10;
          }
        }

        .flatpickr-day {
          @include flex(center, center);

          height: ptr(50);
          color: var(--Gray-80, #6b6b6b);

          /* EN-14/Unica-Regular */
          font-family: "Neue Haas Unica Pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.2px;
          line-height: 20px; /* 142.857% */
          text-align: center;

          &.selected {
            background: $blue-10;
          }

          &.flatpickr-disabled,
          &.prevMonthDay,
          &.nextMonthDay {
            color: var(--Gray-20, #d9d9d9);
          }
        }
      }
    }

    .flatpickr-monthSelect-months {
      @include grid(3, ptr(20) ptr(16));

      .flatpickr-monthSelect-month {
        @include flex(center, center);
        @extend .font_16_unica;
        @include base_unica(16, calc(22 / 16), $font-weight-normal);

        width: ptr(125);
        height: ptr(48);
        border-radius: ptr(8);
        color: $gray-80;
        text-align: center;
        cursor: pointer;

        &.selected {
          background-color: $blue;
          color: $white;
        }

        &:hover:not(.selected, .flatpickr-disabled) {
          background-color: $blue-10;
          color: $blue-100;
        }

        &.flatpickr-disabled {
          color: var(--Gray-20, #d9d9d9);
          cursor: not-allowed;
        }
      }
    }
  }

  .calendar_btns {
    @include flex(space-between, center);

    gap: ptr(8);
    margin-top: ptr(16);
    padding-top: ptr(16);
  }
}
