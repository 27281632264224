@import "base";
.empty_list_container {
  .empty_visual {
    @include flex(center, center, column);

    gap: ptr(24);
    padding: ptr(160) 0;

    .empty_img {
      width: ptr(160);
    }

    .empty_title {
      margin-bottom: ptr(8);
      color: $gray-80;
      text-align: center;
    }

    .empty_desc {
      margin-bottom: ptr(8);
      color: $gray-50;
      text-align: center;
    }
  }

  .recent_closed_list {
    @include flex(flex-start, stretch, column);

    gap: ptr(24);
  }

  .list_header {
    @include flex(space-between, center);

    a {
      color: $gray-80;
    }
  }

  .list {
    @include grid(3, ptr(24));
  }

  html[is_mobile="true"] & {
    .empty_visual {
      padding: ptr(120) 0;

      .empty_img {
        width: ptr(120);
      }
    }

    .recent_closed_list {
      gap: ptr(16);
    }

    .list_header {
      h4 {
        color: $gray-80;
      }

      a {
        display: none;
      }
    }

    .list {
      @include grid(1, ptr(16));
    }
  }
}

.archived_list_container {
  .group_container {
    @include flex(flex-start, stretch, column);

    gap: ptr(96);

    .group {
    }

    .group_token {
      @include flex(flex-start, center);

      display: inline-flex;
      height: ptr(40);
      margin-bottom: ptr(24);
      padding: ptr(2) ptr(16);
      border-radius: ptr(8);
      background: $light-blue;
      color: $blue;
      gap: ptr(4);

      svg {
        width: ptr(16);
      }
    }

    .list {
      @include grid(3, ptr(48) ptr(24));
    }
  }

  .see_more {
    @include flex(center, center);

    display: none;
    padding-top: ptr(48);

    .see_more_btn {
      width: ptr(160);
    }
  }

  &.is_more {
    .see_more {
      display: flex;
    }
  }

  html[is_mobile="true"] & {
    .group_container {
      gap: ptr(48);

      .list {
        @include grid(1, ptr(32));
      }
    }

    .group_token {
      height: ptr(38);
      margin-bottom: ptr(16);
      padding: ptr(8) ptr(12);
      gap: ptr(4);
    }
  }
}

.visual_list_container {
  @include flex(center, stretch, column);

  gap: ptr(48);

  .list {
    @include flex(center, stretch, column);

    gap: ptr(24);
  }

  .see_more {
    padding-top: ptr(24);
    text-align: center;

    .btn_text {
      @include flex(center, center);

      gap: ptr(8);

      svg {
        width: ptr(20);
      }
    }
  }

  html[is_mobile="true"] & {
    .list {
      @include flex(flex-start, stretch, column);

      gap: ptr(16);

      > div {
        flex-shrink: 0;
      }
    }

    .see_more {
      display: none;
    }

    &[data-bottom-tab="hidden"] {
      .list {
        height: 100vh;
      }
    }
  }
}
