@import "base";
.button_close {
  @mixin base-transparent {
    &.transparent {
      .close {
        background-color: transparent;
      }
    }
  }

  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ptr(16);
  height: ptr(16);
  cursor: pointer;

  &[data-hide="true"] {
    display: none;
  }

  .button {
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .close {
    display: flex;
    position: absolute;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: ptr(3);
    border-radius: 99px;
    opacity: 1;
  }

  // light theme
  &.light {
    .close {
      background-color: $gray-5;
      color: $gray-50;
    }
    @include base-transparent;
  }

  // dark theme
  &.dark {
    .close {
      background-color: $gray-20;
      color: $white;
    }
    @include base-transparent;
  }
}
