@import "./base_style";
@import "../ProductBadge/variables";

@mixin pc_horizontal {
  // normal
  &[data-type="normal"] {
    $_size: ptr(96);
    $_gap: ptr(16);

    @include horizontal-base;

    .badge_container {
      display: none;
    }

    .thumbnail_container {
      width: $_size;
      height: $_size;
      margin-right: $_gap;
      border-radius: $border-radius-2x;
    }
    @include info-base {
      padding-right: $like-size + calc($_gap / 2);
    }

    .price {
      margin-top: ptr(8);
    }
  }

  // compact
  &[data-type="compact"] {
    @include horizontal-base;

    $_size: ptr(60);
    $_gap: ptr(16);

    .badge_container {
      display: none;
    }

    .thumbnail_container {
      width: $_size;
      height: $_size;
      margin-right: $_gap;
      border-radius: $border-radius;
    }
    @include info-base {
      padding-right: $like-size + calc($_gap / 2);
    }
  }
}

@mixin mo_horizontal {
  &[data-type="normal"] {
    $_size: ptr(88);
    $_gap: ptr(16);
    @include horizontal-base;
    @include info-base;

    .badge_container {
      position: absolute;
      top: ptr(4);
      left: ptr(4);
      width: calc(100% - #{ptr(4) * 2});
      height: $product_badge_height_mo;
    }

    .thumbnail_container {
      width: $_size;
      height: $_size;
      margin-right: $_gap;
      border-radius: $border-radius;
    }

    .price {
      margin-top: ptr(4);
    }
  }

  &[data-type="compact"] {
    $_size: ptr(52);
    $_gap: ptr(12);
    @include horizontal-base;

    .badge_container {
      display: none;
    }

    .thumbnail_container {
      width: $_size;
      height: $_size;
      margin-right: $_gap;
      border-radius: $border-radius;
    }
    @include info-base {
      padding-right: $like-size + calc($_gap / 2);
    }

    .price {
      margin-top: ptr(2);
    }
  }
}
