@import "base";
.recently_product_tab {
  margin-top: 0;
}

.recently_product_tab_list {
  display: grid;
  grid-gap: ptr(48) ptr(16);
  grid-template-columns: repeat(5, minmax(0, 1fr));

  &[data-length="0"] {
    display: unset;
  }
}

.recently_creator_tab {
  margin-top: 0;
}

.recently_creator_tab_list {
  display: grid;
  grid-gap: ptr(24);
  grid-template-columns: repeat(4, minmax(0, 1fr));

  &[data-length="0"] {
    display: unset;
  }
}

html[is_mobile="true"] {
  .recently_product_tab_list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .recently_creator_tab_list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

html[is_mobile="false"] {
  .recently_product_tab_list {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .recently_creator_tab_list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
