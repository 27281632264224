@import "base";
html[is_mobile="false"] section.motion {
  position: relative;
  @include flex(flex-start, center, column);

  height: 100vh;

  .video_container {
    width: 100%;
    height: 100%;

    .video_wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      video.motion_video,
      video.motion_video[poster] {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        filter: contrast(101%);
      }

      video::-webkit-media-controls {
        display: none !important;
      }

      video::-webkit-media-controls-play-button,
      video::-webkit-media-controls-start-playback-button,
      video::-webkit-media-controls-fullscreen-button {
        display: none !important;
      }

      .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgb(28 117 255 / 0%) 46.5%, rgb(28 117 255 / 90%) 86.5%);
      }
    }
  }

  .motion_description {
    @include flex(flex-end, center, column);

    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    padding: ptr(40) ptr(40) ptr(136);

    .title {
      margin-bottom: ptr(8);
      color: $white;
      text-align: center;
    }

    .description {
      color: $white;
      text-align: center;
    }
  }
}

html[is_mobile="true"] section.motion {
  position: relative;
  @include flex(flex-start, center, column);

  height: 100svh;

  .video_container {
    @include flex(flex-start, center, column);

    width: 100%;
    height: 100%;

    .video_wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      video.motion_video,
      video.motion_video[poster] {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        filter: contrast(101%);
      }

      .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgb(28 117 255 / 0%) 46.5%, rgb(28 117 255 / 90%) 86.5%);
      }
    }
  }

  .motion_description {
    @include flex(flex-start, center, column-reverse);

    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 ptr(20) calc(88px + env(safe-area-inset-bottom));
    gap: ptr(12);

    .title {
      color: $white;
      line-height: ptr(36) !important;
      text-align: center;
    }

    .description {
      width: 100%;
      color: $white;
      text-align: center;
    }
  }
}
