@use "sass:map";

@mixin innerBorder($border_color, $border_size: 1px) {
  box-shadow: 0 0 0 $border_size $border_color inset;
}

// flex 설정
// @include flex(space-between, center); // flex row
// @include flex(space-between, center, column); // flex column
@mixin flex($jc, $ai, $di: row) {
  display: flex;
  align-items: $ai;
  justify-content: $jc;
  @if $di != row {
    flex-direction: $di;
  }
}

@mixin grid($col: 2, $gap: 0) {
  display: grid;
  grid-column: $col;
  grid-template-columns: repeat($col, minmax(0, 1fr));
  gap: $gap;
}

// 스크롤바 숨기고 싶을때
// @include hiddenScroll;
@mixin hiddenScroll {
  & {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

// 가로로 스냅 스크롤 주고 싶을때
// @include snapScroll(ptr(40));
@mixin snapScroll($offset) {
  & {
    padding: 0 $offset;
    overflow: auto hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scroll-margin-left: $offset;
    scroll-margin-right: $offset;
    scroll-padding-left: $offset;
    scroll-padding-right: $offset;
    touch-action: cross-slide-x;
  }

  & > * {
    scroll-snap-align: start;
  }
}

// 가로로 스냅 스크롤 주고 싶을때
// @include snapScrollY(ptr(40));
@mixin snapScrollY($offset) {
  & {
    overflow: hidden auto;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    touch-action: cross-slide-y;
  }

  & > * {
    scroll-snap-align: start;
  }
}

// object-fit cover
// @include objectCover;
@mixin objectCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// 말줄임 처리
// @include lineLimit(1);
@mixin lineLimit($line: 2) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  @if $line == 1 {
    white-space: nowrap;
  }
}

@mixin limitLine($l) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $l;
  overflow: hidden;
}

// 백드롭 블러처리
// @include blur10;
@mixin blur10 {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
@mixin blur30 {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}
@mixin blur40 {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
@mixin blur60 {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

// 필터 블러처리
// @include filter10;
@mixin filter10 {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}
@mixin filter30 {
  -webkit-filter: blur(15px);
  filter: blur(15px);
}
@mixin filter40 {
  -webkit-filter: blur(20px);
  filter: blur(20px);
}
@mixin filter60 {
  -webkit-filter: blur(30px);
  filter: blur(30px);
}

@mixin iconBtn($size) {
  & {
    width: ptr($size);
    height: ptr($size);
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
  }

  @include flex(center, center, row);
}

@mixin productOptionLabel() {
  margin-bottom: ptr(16);
  color: $black;
}

@mixin pc() {
  html[is_mobile="false"] & {
    @content;
  }
}

@mixin mobile() {
  html[is_mobile="true"] & {
    @content;
  }
}

@mixin prettyLi($font-size: 16) {
  ol {
    margin: 0;
    padding: 0;
    list-style: none; /* 기본 리스트 스타일 제거 */
    counter-reset: item; /* 숫자 초기화 */
  }

  li {
    position: relative; /* 숫자 위치를 상대적으로 지정 */
    padding-left: ptr($font-size); /* 첫 번째 줄을 맞추기 위해 들여쓰기 조정 */
  }

  li::before {
    content: counter(item) ". "; /* 숫자 생성 */
    position: absolute; /* 숫자를 고정 위치에 설정 */
    top: 0;
    left: 0; /* 숫자의 시작 위치 */
    counter-increment: item; /* 숫자 증가 */
  }
}
