@import "base";
@import "../../../../../shared/style/sub/mixin";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: $pc-layout-body-max-width;
  gap: ptr(24);

  .category_tabs {
    @include pc {
      padding: ptr(8) ptr(32);
    }
    @include mobile {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  .product_list {
    display: grid;
    grid-template-columns: repeat(5, minmax(230px, 1fr));
    width: 100%;
    height: fit-content;
    padding: 0 ptr(32);
    gap: ptr(48) ptr(16);
  }

  .list_container {
    display: flex;
    width: 100%;
    padding: 0 ptr(16);
    overflow: auto hidden;
    gap: ptr(16);
    scroll-padding-left: ptr(16);
    scroll-snap-type: x mandatory;
    @include hiddenScroll;

    .product {
      flex: 0 0 ptr(194);
      width: ptr(194);
      scroll-snap-align: start;
    }

    .product_list {
      @include mobile {
        display: flex;
        flex: none;
        flex-direction: column;
        width: ptr(304);
        padding: 0;
        gap: ptr(16);
        scroll-snap-align: start;
      }
    }
  }
}
