@import "base";
.creator_ranking_container {
}

.creator_ranking {
  @include flex(flex-start, stretch, column);

  gap: ptr(24);
  margin-top: $default-header-pc_height;
  margin-bottom: ptr(120);

  .title {
    @include flex(flex-start, stretch, column);

    gap: ptr(8);
  }

  .list {
    @include flex(flex-start, stretch, column);

    gap: ptr(16);
  }

  .top {
    @include flex(flex-start, stretch, column);

    gap: ptr(32);
  }

  // 모바일 스타일
  html[is_mobile="true"] & {
    margin-top: $default-header-mo_height;
    margin-bottom: ptr(60);

    .title {
      @include flex(flex-start, stretch, column);

      gap: ptr(8);
    }

    .list {
      gap: ptr(16);
    }

    .top {
      @include flex(flex-start, stretch, column);

      gap: ptr(16);
    }

    .calendar_selector {
      width: 100%;
    }
  }
}
