@import "base";
html[is_mobile="false"] section.motion {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: ptr(72) 0;

  .scroll::-webkit-scrollbar {
    display: none;
  }

  .scroll {
    height: 100%;
    overflow: hidden;

    .main_image {
      height: 100%;
      padding: 0 ptr(32);
    }
  }

  .motion_slogan {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: ptr(532.65);
    height: ptr(550);
    transform: translate(-50%, -50%);
  }
}

html[is_mobile="true"] section.motion {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: ptr(140) 0;
  @include flex(flex-start, center);

  .scroll::-webkit-scrollbar {
    display: none;
  }

  .scroll {
    height: 100%;
    overflow: hidden;

    .main_image {
      height: 100%;
      padding: 0 ptr(16);
    }
  }

  .motion_slogan {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: ptr(248);
    height: ptr(248);
    transform: translate(-50%, -50%);
  }
}
