@import "base";
html[is_mobile="false"] .header_container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  .header_content {
    display: flex;
    flex-direction: column;
    gap: ptr(8);

    .description {
      color: $gray-80;
    }
  }

  .header_buttons {
    display: flex;
    align-items: center;
    gap: ptr(8);
  }
}

html[is_mobile="true"] .header_container {
  @include flex(flex-start, flex-start, column);

  width: 100%;
  gap: ptr(16);
  padding: ptr(32) ptr(20) 0;

  .header_content {
    display: flex;
    flex-direction: column;
    gap: ptr(8);

    .description {
      color: $gray-80;
    }
  }

  .header_buttons {
    display: flex;
    align-items: center;
    gap: ptr(8);
  }
}
