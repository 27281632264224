@import "base";
:global {
  @media screen and (width <= 1920px) {
    html[is_mobile="false"].plus-shop-landing {
      font-size: calc(1vw * (14 / 19.2)) !important;
    }
  }
  @media screen and (width <= 375px) {
    html[is_mobile="true"].plus-shop-landing {
      font-size: calc(1vw * (14 / 3.75)) !important;
    }
  }
}

html[is_mobile="false"] .container {
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  @include flex(center, center, column);

  section {
    width: 100%;
  }

  & button.submit {
    position: fixed;
    z-index: 10;
    bottom: ptr(40);
    left: 50%;
    transform: translateX(-50%);
  }
}

html[is_mobile="true"] .container {
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  @include flex(center, center, column);

  section {
    width: 100%;
  }

  & button.submit {
    position: fixed;
    z-index: 10;
    bottom: calc(16px + env(safe-area-inset-bottom));
    left: 50%;
    transform: translateX(-50%);
  }
}

html[is_mobile="false"] .editing {
  height: 100vh;
  overflow: hidden;
}

html[is_mobile="true"] .editing {
  display: none !important;
}
