@import "base";
.button_show {
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ptr(16);
  height: ptr(16);
  cursor: pointer;

  .button {
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .show {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
