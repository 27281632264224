@import "base";
.tabs {
  @include flex(flex-start, center);

  gap: ptr(24);

  &:global(.small) {
    flex-wrap: wrap;
    gap: ptr(16) ptr(24);

    .tab {
      @include flex(flex-start, center);

      border-bottom: 1px solid transparent;
      color: $gray-50;
      cursor: pointer;
      gap: ptr(4);

      &.active {
        border-bottom: 1px solid $black;
        color: $black;
      }

      &:hover {
        border-color: $blue-100;
        color: $blue-100;
      }
    }
  }

  &:global(.large) {
    .tab {
      @include flex(flex-start, center);

      gap: ptr(4);
      color: $gray-20;
      text-transform: uppercase;
      cursor: pointer;

      &.active {
        color: $black;
      }
    }
  }

  // 모바일 스타일
  html[is_mobile="true"] & {
    @include flex(flex-start, center);

    width: 100%;
    overflow-y: auto;
    border-bottom: 1px solid $gray-5;
    @include hiddenScroll;

    &:global(.small) {
      gap: ptr(0);
      flex-wrap: unset;
      overflow-x: auto;
    }

    .tab {
      flex-shrink: 0;
      padding: ptr(7) ptr(12);
      color: $gray-50;

      &.active {
        border-bottom: 1px solid $black;
        color: $black;
      }
    }

    .count {
      margin-left: ptr(4);
    }

    &.mo_no_underline {
      border-bottom: none !important;
    }

    .mo_no_padding {
      padding: 0 !important;
    }

    .mo_no_underline {
      border-bottom: none !important;
    }

    &.circle {
      gap: ptr(8);
      border-bottom: none;

      .tab {
        padding: ptr(7) ptr(16);
        border: 1px solid $gray-5;
        border-radius: ptr(999);

        &.active {
          border: 1px solid $black;
        }
      }
    }

    &.invert {
      &.circle {
        .tab {
          border: 1px solid $bright-20;
          background: $dim-30;
          color: $white;
          backdrop-filter: blur(20px);

          &.active {
            border: 1px solid $white;
            background: $bright-20;
          }
        }
      }
    }
  }
}
