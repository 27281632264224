@import "base";
html[is_mobile="false"] {
  .PlusShopCard {
    position: relative;
    width: 100%;
    min-width: ptr(256);
    height: fit-content;
    min-height: ptr(362);
    overflow: hidden;
    border: 1px solid $gray-5;
    border-radius: ptr(24);
    background: $white;

    .wrap {
      position: relative;
      padding: ptr(32) ptr(24) ptr(24);
    }

    .thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: ptr(16);

      a {
        display: block;
        position: relative;
        width: ptr(144);
        height: ptr(144);
        color: $gray-20;
      }
    }

    .thumb_img {
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      object-fit: contain;
      object-position: center;
      background-color: $gray-5;
    }

    .main_store_logo {
      position: absolute;
      right: ptr(-8);
      bottom: ptr(-3);
      width: ptr(48);
      height: ptr(48);
      object-fit: contain;
    }

    .txt_info {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: stretch;
      margin-bottom: ptr(32);
      gap: ptr(2);
    }

    .name {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $black;
      letter-spacing: -0.2px;
      line-height: ptr(28); /* 140% */
      text-align: justify;
    }

    .seller_type {
      min-height: ptr(22);
      color: $gray-80;
      letter-spacing: -0.2px;
      line-height: ptr(22);
      text-align: justify;
    }

    .products {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: ptr(64);
      gap: ptr(8);
    }

    .product_thumb {
      flex: 0 0 ptr(64);
      overflow: hidden;
      border-radius: ptr(8);

      &:nth-child(4) {
        display: none;
      }

      .adult {
        color: $gray-20;
      }
    }

    .like_area {
      position: absolute;
      top: ptr(24);
      right: ptr(22);
    }

    .like_btn {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      padding: ptr(4) ptr(12);
      border-radius: 9999px;
      background: $bright-5;
      color: $gray-20;
      gap: ptr(4);

      &.active .like {
        color: #1c75ff;
      }

      &.active .like_count {
        color: #fff;
      }
    }

    .like {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: ptr(20);
      height: ptr(20);
      color: #d9d9d9;
    }

    .like_count {
      color: #d9d9d9;
    }
  }
}

html[is_mobile="true"] {
  .PlusShopCard {
    position: relative;
    width: 100%;
    min-height: ptr(286);
    border: 1px solid $gray-5;
    border-radius: ptr(16);
    background: $white;

    .wrap {
      position: relative;
      padding: ptr(24) ptr(16) ptr(16);
    }

    .thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: ptr(10);

      a {
        display: block;
        position: relative;
        width: ptr(100);
        height: ptr(100);
        color: $gray-20;
      }
    }

    .thumb_img {
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      object-fit: contain;
      object-position: center;
      background-color: $gray-5;
    }

    .txt_info {
      margin-bottom: ptr(16);
    }

    .name {
      display: -webkit-box;
      overflow: hidden;
      color: $black;
      text-align: center;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .seller_type {
      display: -webkit-box;
      min-height: ptr(17);
      overflow: hidden;
      color: $gray-80;
      text-align: center;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .products {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      gap: ptr(8);
    }

    .product_thumb {
      display: none;
      flex: 0 0 50%;
      overflow: hidden;
      border-radius: ptr(8);

      &:nth-child(-n + 2) {
        display: block;
      }

      .adult {
        color: $gray-20;
      }
    }

    .like_area {
      position: absolute;
      top: ptr(86);
      left: ptr(136);
    }

    .like_btn {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      padding: ptr(4) ptr(12);
      border-radius: 9999px;
      background: $bright-5;
      color: $gray-20;
      gap: ptr(4);
    }

    .like {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: ptr(20);
      height: ptr(20);
      color: #d9d9d9;

      &.active {
        color: #1c75ff;
      }
    }

    .like_count {
      color: #d9d9d9;

      &.active {
        color: #fff;
      }
    }
  }
}
