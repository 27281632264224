@import "base";
html[is_mobile="false"] section.bottom {
  position: relative;
  @include flex(flex-start, center, column);

  height: 100vh;

  .video_container {
    width: 100%;
    height: 100%;

    .video_wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      video.motion_video,
      video.motion_video[poster] {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
  }

  .content {
    @include flex(center, center, column);

    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    gap: ptr(20);

    .title {
      color: $white;
      text-align: center;
    }

    .description {
      color: $white;
      text-align: center;
    }
  }
}

html[is_mobile="true"] section.bottom {
  position: relative;
  @include flex(flex-start, center, column);

  height: 100vh;

  .video_container {
    @include flex(flex-start, center, column);

    width: 100%;
    height: 100%;

    .video_wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      video.motion_video,
      video.motion_video[poster] {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
  }

  .content {
    @include flex(center, center, column);

    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    gap: ptr(12);

    .title {
      color: $white;
      line-height: ptr(36) !important;
      text-align: center;
    }

    .description {
      width: 100%;
      color: $white;
      text-align: center;
    }
  }
}
