@import "base";
@import "../../variables";
@import "../../../../../shared/style/base";

.container {
  height: $dashboard_item_height2;
}

.legend_container {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  gap: ptr(8);

  .legend_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ptr(96);
    height: ptr(32);
    border-radius: ptr(8);
    cursor: pointer;

    &.total {
      border: 1px solid $blue-100;
      background-color: $white;
      color: $blue-100;
    }

    &.mps {
      background-color: $blue-10;
      color: $blue-100;
    }

    &.tpl {
      background-color: $pink-20;
      color: $red-80;
    }

    &.pbg {
      background-color: $purple-10;
      color: $purple-100;
    }

    &.dtg {
      background-color: $green-10;
      color: $green-100;
    }

    &.inactive {
      border: 1px solid $gray-5;
      background-color: $gray-5;
      color: $gray-50;
    }
  }
}
