@import "base";
html[is_mobile="false"] .plus_content_item {
  flex: 1;
  width: 100%;

  .thumbnail_image {
    width: 100%;
    margin-bottom: ptr(16);
    border-radius: ptr(24);
    aspect-ratio: 442 / 264;
  }

  .title {
    display: block;
    margin-bottom: ptr(4);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sub_title {
    display: block;
    overflow: hidden;
    color: $gray-80;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

html[is_mobile="true"] .plus_content_item {
  flex-shrink: 0;
  width: ptr(288);

  .thumbnail_image {
    width: 100%;
    margin-bottom: ptr(16);
    border-radius: ptr(24);
    aspect-ratio: 442 / 264;
  }

  .title {
    display: block;
    margin-bottom: ptr(4);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sub_title {
    display: block;
    overflow: hidden;
    color: $gray-80;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
