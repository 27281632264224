@import "base";
html[is_mobile="false"] {
  .page {
    margin-top: $default-header-pc_height + 5rem;
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .title {
    margin-bottom: ptr(20);
    padding: 0 20px;
    text-align: center;
  }

  .description {
    margin-bottom: ptr(30);
    padding: 0 20px;
    text-align: center;
  }
}

html[is_mobile="true"] {
  .page {
    margin-top: $default-header-mo_height + 2rem;
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .title {
    margin-bottom: ptr(15);
    padding: 0 16px;
    text-align: center;
  }

  .description {
    margin-bottom: ptr(23);
    padding: 0 16px;
    text-align: center;
  }
}
