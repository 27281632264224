@import "base";
html[is_mobile="false"] {
  .creator_item {
  }

  .thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    a {
      display: block;
      position: relative;
      width: 200px;
      height: 200px;
    }
  }

  .thumb_img {
    width: 100%;
    height: 100%;
    border: 1px solid $gray-5;
    border-radius: 9999px;
    object-fit: cover;
  }

  .name {
    margin-bottom: 12px;
    color: $black;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    span {
      display: flex;
      align-items: center;
    }

    .name_txt {
      @include limitLine(1);
    }

    .mshop_thin_icon {
      width: 16px;
      height: 16px;
      color: $black;
    }
  }

  .like_area {
    display: flex;
    place-content: center center;
  }

  .like_btn {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    padding: 4px 12px;
    overflow: hidden;
    border-radius: 9999px;
    background: $dim-5;
    color: $gray-20;
    text-overflow: ellipsis;
    gap: 4px;

    &.active .like {
      width: 20px;
      height: 20px;
      color: $blue;
    }

    &.active .like_count {
      color: $gray-50;
    }
  }

  .like {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    color: #d9d9d9;
  }

  .like_count {
    color: $gray-50;
  }
}

html[is_mobile="true"] {
  .creator_item {
  }

  .thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    a {
      display: block;
      position: relative;
      width: 100%;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }

  .thumb_img {
    width: 100%;
    height: 100%;
    border: 1px solid $gray-5;
    border-radius: 9999px;
    object-fit: cover;
  }

  .name {
    margin-bottom: ptr(12);
    color: $black;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ptr(8);
    }

    span {
      display: flex;
      align-items: center;
    }

    .name_txt {
      @include limitLine(1);
    }

    .mshop_thin_icon {
      width: ptr(16);
      height: ptr(16);
      color: $black;
    }
  }

  .like_area {
    display: flex;
    place-content: center center;
  }

  .like_btn {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    padding: ptr(4) ptr(12);
    overflow: hidden;
    border-radius: 9999px;
    background: $dim-5;
    color: $gray-20;
    text-overflow: ellipsis;
    gap: ptr(4);

    &.active .like {
      width: ptr(20);
      height: ptr(20);
      color: $blue;
    }

    &.active .like_count {
      color: $gray-50;
    }
  }

  .like {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ptr(16);
    height: ptr(16);
    color: #d9d9d9;
  }

  .like_count {
    color: $gray-50;
  }
}
