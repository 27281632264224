@import "base";
.product_option_quantity_selector {
  .option_container {
    display: flex;
    flex-direction: column;
    row-gap: ptr(8);

    .option {
      display: flex;
      align-items: center;

      .option_text {
        margin-right: auto;
        padding: 4px;
        color: $gray-80;

        & > span {
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;

          &::selection {
            background-color: transparent;
          }
        }
      }

      .button_close_wrap {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $gray-5;
        color: $gray-50;
      }

      .button_count_wrap {
        flex-shrink: 0;
        margin-left: ptr(16);
      }
    }
  }

  .total_price_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ptr(8);
    padding-top: ptr(8);
    border-top: 1px solid $black;

    .total_price_text {
      color: $black;

      &::selection {
        background-color: transparent;
      }
    }

    .total_price {
      color: $black;

      &::selection {
        background-color: transparent;
      }
    }
  }

  .none {
    display: none;
  }
}
