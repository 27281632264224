@import "base";
html[is_mobile="false"] .page {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    margin: ptr(40) 0;
  }

  .tab {
    margin-bottom: ptr(24);
  }

  .content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    gap: ptr(32) ptr(20);

    @media (max-width 1600px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

html[is_mobile="true"] .page {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    margin-bottom: ptr(40);
  }

  .tab {
    margin-bottom: ptr(24);
  }

  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    padding: 0 ptr(20);
    gap: ptr(16);
  }
}
