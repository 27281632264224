@import "base";
.page_title {
  @include flex(space-between, flex-end);

  gap: ptr(8);
  width: 100%;

  .group {
    @include flex(flex-start, center);

    gap: ptr(8);
  }

  .image {
    width: ptr(72);
    height: ptr(72);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .text {
    @include flex(flex-start, flex-start, column);

    gap: ptr(8);

    &.has_image {
      gap: ptr(14);
    }

    &.horizontal {
      flex-direction: row;
      align-items: end;

      .sub_title {
        order: 2;
      }
    }
  }

  .sub_title {
    color: $gray-80;
  }

  .title {
    @include flex(space-between, center);

    h2 {
      @include flex(flex-start, flex-start);

      gap: ptr(8);
      text-transform: uppercase;
    }
  }

  // mobile style
  html[is_mobile="true"] & {
    @include flex(flex-start, flex-start, column);

    .title_group {
      gap: ptr(4);
    }
  }
}
