@import "base";
html[is_mobile="false"] section.portfolio {
  position: relative;
  @include flex(flex-start, center, column);
  height: 100vh;

  .banner {
    position: relative;
    height: 100vh;
    width: 100%;

    .banner_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .content {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      @include flex(center, center, column);

      .description {
        text-align: center;
        color: white;
      }
    }
  }
}

html[is_mobile="true"] section.portfolio {
  position: relative;
  @include flex(flex-start, center, column);
  height: 100vh;

  .banner {
    position: relative;
    height: 100vh;
    width: 100%;
    .banner_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .content {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      @include flex(center, center, column);

      .description {
        text-align: center;
        color: white;
      }
    }
  }
}
