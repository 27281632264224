@import "base";
@import "../../../../../shared/style/base";

.section {
  $gap: ptr(16);

  position: relative;
  width: 100%;
  color: $white;
  @include pc {
    height: ptr(684);
  }
  @include mobile {
    min-height: ptr(518);
  }

  .background_image {
    position: absolute;
    z-index: 1;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blur_backdrop {
    position: absolute;
    z-index: 2;
    inset: 0;
    background-color: $dim-30;
    backdrop-filter: blur(50px);
  }

  .content_wrapper {
    display: flex;
    position: relative;
    z-index: 3;
    top: 0;
    justify-content: center;
  }

  .content {
    width: 100vw;

    @include pc {
      max-width: $pc-layout-body-max-width;
      padding: ptr(96) ptr(32) ptr(48);
    }
    @include mobile {
      padding: ptr(60) 0;
    }
  }

  .product_cards_swiper {
    @include mobile {
      position: relative;
      height: fit-content;
      padding: 0 16px;
      overflow: hidden;
      @include flex(flex-start, center);
    }
  }

  .product_cards_container {
    @include pc {
      display: grid;
      grid-column-gap: ptr(16);
      grid-template: auto 1fr / repeat(2, 1fr) repeat(4, 1fr);
      grid-template-areas:
        "l l i i i i"
        "l l . . . .";
      margin-top: ptr(24);

      .latest {
        grid-area: l;
        margin-right: ptr(16);
      }

      .latest_info {
        display: flex;
        grid-area: i;
        flex-direction: column;
        margin-bottom: ptr(36);
        gap: ptr(4);

        .store_name {
          display: inline-flex;
          align-content: center;
        }

        .icon {
          display: inline-flex;
          width: ptr(16);
          aspect-ratio: 1 / 1;
          margin-left: ptr(4);
        }
      }
    }

    @include mobile {
      display: flex;
      position: relative;
      justify-content: flex-start;
      height: 100%;
      margin-top: ptr(32);
    }
  }

  .product_card {
    @include mobile {
      width: 60vw;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
