@import "base";
html[is_mobile="false"] {
  .root {
    padding-top: ptr(32);
  }

  .title {
    margin-bottom: ptr(16);
    color: $black;
  }

  .sdk_container {
    background: inherit;
  }
}

html[is_mobile="true"] {
  .root {
    min-height: ptr(695);
    margin-top: $default-header-mo_height;
    padding: ptr(24) ptr(16) ptr(60);
    background: inherit;
  }

  .title {
    color: $black;
  }

  .sdk_container {
    background: inherit;
  }
}
