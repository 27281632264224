@import "base";
html[is_mobile="false"] {
  .CreatorCard {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: ptr(344);
    overflow: hidden;
    border-radius: ptr(24);
    background-position: center;
    background-size: cover;

    .bg {
      position: absolute;
      inset: 0;
      background: $dim-30;
      @include blur60;
    }

    .wrap {
      position: relative;
      padding: ptr(32) ptr(24) ptr(24);
    }

    .thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: ptr(16);

      a {
        display: block;
        position: relative;
        width: ptr(112);
        height: ptr(112);
      }
    }

    .adult_thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      background-color: $white;
      color: $gray-20;
      aspect-ratio: 1 / 1;
    }

    .thumb_img {
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      object-fit: cover;
      background-color: $white;
    }

    .profile_adult_thumbnail {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      background-color: $product-bg;
      color: $gray-20;
    }

    .main_store_logo {
      position: absolute;
      right: ptr(-8);
      bottom: ptr(-3);
      width: ptr(48);
      height: ptr(48);
      object-fit: contain;
    }

    .txt_info {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: stretch;
      margin-bottom: ptr(24);
      gap: ptr(2);
    }

    .name {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ptr(8);
        color: $white;
      }

      span {
        display: flex;
        align-items: center;
      }

      .store_name {
        display: -webkit-box;
        flex: 1;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .mshop_thin_icon {
        width: ptr(24);
        height: ptr(24);
        color: $white;
      }
    }

    .seller_type {
      display: -webkit-box;
      min-height: ptr(22);
      overflow: hidden;
      color: $white;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .products {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: ptr(8);
    }

    .product_thumb {
      display: none;
      flex-basis: ptr(87);
      overflow: hidden;
      border-radius: ptr(8);

      &:nth-child(-n + 3) {
        display: block;
      }
    }

    .product_adult_thumb {
      display: flex;
      position: relative;
      flex-basis: ptr(87);
      align-items: center;
      justify-content: center;
      width: 100%;
      overflow: hidden;
      border-radius: ptr(8);
      background-color: $product-bg;
      aspect-ratio: 1 / 1;
    }

    .like_area {
      position: absolute;
      top: ptr(24);
      right: ptr(22);
    }

    .like_btn {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      padding: ptr(4) ptr(12);
      border-radius: 9999px;
      background: $bright-5;
      color: $gray-20;
      gap: ptr(4);

      &.active .like {
        color: $blue;
      }

      &.active .like_count {
        color: $white;
      }
    }

    .like {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: ptr(20);
      height: ptr(20);
      color: $gray-20;
    }

    .like_count {
      color: $gray-20;
    }
  }
}

html[is_mobile="true"] {
  .CreatorCard {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: ptr(236);
    overflow: hidden;
    border-radius: ptr(24);
    background-position: center;
    background-size: cover;

    .bg {
      position: absolute;
      inset: 0;
      background: $dim-30;
      @include blur60;
    }

    .wrap {
      position: relative;
      padding: ptr(24);
    }

    .thumb {
      position: absolute;
      top: ptr(24);
      left: ptr(24);

      a {
        display: block;
        position: relative;
        width: ptr(96);
        height: ptr(96);
      }
    }

    .adult_thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      background-color: $white;
      color: $gray-20;
      aspect-ratio: 1 / 1;
    }

    .thumb_img {
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      object-fit: cover;
      background-color: $white;
    }

    .profile_adult_thumbnail {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      background-color: $product-bg;
      color: $gray-20;
    }

    .main_store_logo {
      position: absolute;
      right: ptr(-8);
      bottom: ptr(-3);
      width: ptr(48);
      height: ptr(48);
      object-fit: contain;
    }

    .txt_info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      height: ptr(96);
      margin-bottom: ptr(24);
      margin-left: calc(96px + 16px);
      gap: ptr(2);
    }

    .name {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ptr(8);
        color: $white;
      }

      span {
        display: flex;
        align-items: center;
      }

      .store_name {
        display: -webkit-box;
        flex: 1;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .mshop_thin_icon {
        width: ptr(20);
        height: ptr(20);
        color: $white;
      }
    }

    .seller_type {
      display: -webkit-box;
      min-height: ptr(22);
      overflow: hidden;
      color: $white;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .products {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      gap: ptr(8);
    }

    .product_thumb {
      flex-basis: 22.79%;
      overflow: hidden;
      border-radius: ptr(8);
    }

    .product_adult_thumb {
      display: flex;
      position: relative;
      flex-basis: 22.79%;
      align-items: center;
      justify-content: center;
      width: 100%;
      overflow: hidden;
      border-radius: ptr(8);
      background-color: $product-bg;
      aspect-ratio: 1 / 1;
    }

    .like_area {
      position: absolute;
      top: ptr(86);
      left: ptr(136);
    }

    .like_btn {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      padding: ptr(4) ptr(12);
      border-radius: 9999px;
      background: $bright-5;
      color: $gray-20;
      gap: 4px;

      &.active .like {
        color: $blue;
      }

      &.active .like_count {
        color: $white;
      }
    }

    .like {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: ptr(20);
      height: ptr(20);
      color: $gray-20;
    }

    .like_count {
      color: #d9d9d9;
    }
  }
}
