@import "base";
html[is_mobile="false"] .plus_creator_logo {
  display: inline-flex;
  align-items: center;
  column-gap: ptr(8);

  &.instant_transition .logo_container {
    transition: none;
  }

  .logo_container {
    display: flex;
    align-items: center;
    padding: ptr(4);
    transition: background-color linear 300ms;
    border-radius: 9999px;
    background-color: transparent;
    gap: ptr(8);

    .plus_logo_img {
      height: ptr(36);
    }

    .profile_img {
      display: inline-block;
      flex-shrink: 0;
      height: ptr(36);
      border-radius: 9999px;
      aspect-ratio: 1;
    }

    .logo_round_box {
      display: inline-block;
      flex-shrink: 0;
      height: ptr(36);
      overflow: hidden;
      border-radius: 9999px;
      background-color: #f3f3f3;
      aspect-ratio: 1;
    }

    .logo_rect_box {
      height: ptr(36);
    }

    .logo_img {
      display: inline-block;
      height: ptr(36);
    }

    .creator_name {
      padding-top: ptr(4);
      color: inherit;
    }

    &.surrounded {
      background-color: #f3f3f3;
    }

    &.dark {
      background-color: $dim-30;
    }

    &.no_bg {
      background-color: transparent !important;
    }
  }
}

html[is_mobile="true"] .plus_creator_logo {
  display: inline-flex;
  column-gap: ptr(8);

  .logo_container {
    display: flex;
    align-items: center;
    padding: ptr(4);
    border-radius: 9999px;
    background-color: transparent;
    gap: ptr(8);

    .profile_img {
      display: inline-block;
      flex-shrink: 0;
      height: ptr(36);
      border-radius: 9999px;
      aspect-ratio: 1;
    }

    .plus_logo_img {
      height: ptr(36);
    }

    .logo_round_box {
      display: inline-block;
      flex-shrink: 0;
      height: ptr(36);
      overflow: hidden;
      border-radius: 9999px;
      background-color: #f3f3f3;
      aspect-ratio: 1;
    }

    &.surrounded {
      background-color: #f3f3f3;
    }
  }
}
