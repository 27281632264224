@import "base";
html[is_mobile="false"] {
  .plus_content_card {
    position: relative;
  }

  [data-show="false"] {
    display: none;
  }

  .thumb_container {
    position: relative;
    width: 100%;
    font-size: 0;
  }

  .thumb_wrapper {
    width: 100%;
    padding-bottom: 59.72850679%;
  }

  .thumbnail {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ptr(24);
  }

  .content {
    box-sizing: border-box;
    width: 100%;
    padding: ptr(16) 0 0; // 20 / 2 * 1.3 (폰트 절반)
    color: black;
  }

  .content_title {
    position: relative;
  }

  .content_title_txt {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
  }

  .content_title_second {
    position: relative;
  }

  .content_description {
    width: 100%;
    margin: ptr(4) 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;

    &.empty_content {
      opacity: 0;
    }
  }

  .content_products {
    display: flex;
    gap: ptr(8);
    margin-top: ptr(16);
  }

  .content_product {
    display: flex;
    flex: 1 1 0;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: ptr(8);
    background-color: #f6f6f6;
    color: #4e4e4e;
    font-size: ptr(16);
    font-weight: 600;
  }

  .empty {
    opacity: 0;
    cursor: auto;
  }

  .content_empty {
    padding-top: 100%;
    opacity: 1;
  }
}

html[is_mobile="true"] {
  .plus_content_card {
    position: relative;
  }

  [data-show="false"] {
    display: none;
  }

  .thumb_container {
    position: relative;
    width: 100%;
    font-size: 0;
  }

  .thumb_wrapper {
    width: 100%;
    padding-bottom: 59.72850679%;
  }

  .thumbnail {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ptr(16);
  }

  .content {
    box-sizing: border-box;
    width: 100%;
    padding-top: ptr(12);
    color: black;
  }

  .content_title {
    position: relative;
  }

  .content_title_txt {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
  }

  .content_title_second {
    position: relative;
  }

  .content_description {
    width: 100%;
    margin: ptr(4) 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;

    &.empty_content {
      opacity: 0;
    }
  }

  .content_products {
    display: flex;
    gap: ptr(8);
    margin-top: ptr(12);
  }

  .content_product {
    display: flex;
    flex: 1 1 0;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: ptr(8);
    background-color: #f6f6f6;
    color: #4e4e4e;
    font-size: ptr(16);
    font-weight: 600;
  }

  .empty {
    opacity: 0;
    cursor: auto;
  }

  .content_empty {
    padding-top: 100%;
    opacity: 1;
  }
}
