@import "base";
.color_picker {
  @include flex(flex-start, center);

  width: 100%;
  height: ptr(42);
  padding: ptr(12);
  border: ptr(1) solid $gray-10;
  border-radius: ptr(4);
  background: $white;
  cursor: pointer;

  .bullet {
    display: block;
    flex-shrink: 0;
    width: ptr(20);
    height: ptr(20);
    border: 1px solid $gray-10;
    border-radius: 50%;
  }

  .input {
    width: 100%;
    margin-left: ptr(12);
    border: none !important;
    cursor: pointer;
  }
}
