@import "base";
html[is_mobile="false"] {
  .page {
    margin-top: $default-header-pc_height;
  }
}

html[is_mobile="true"] {
  .page {
    margin-top: $default-header-mo_height;
  }
}
