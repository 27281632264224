@import "base";
html[is_mobile="false"] .section {
  position: relative;
  width: 100%;

  .box {
    width: 100%;
    border-radius: ptr(16);
    background-color: $gray-1;
  }

  .onboarding_header {
    width: 100%;
    @include flex(flex-start, center);

    gap: ptr(24);
    padding: ptr(28) ptr(32);

    .caution {
      color: $gray-50;
    }

    .header_icon {
      flex-shrink: 0;
      width: ptr(80);
      height: ptr(80);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .header_content {
      width: 100%;
      @include flex(center, flex-start, column);

      gap: ptr(4);
    }

    .header_progress {
      min-width: ptr(72);
      min-height: ptr(72);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .item_list {
    @include flex(flex-start, flex-start, column);

    width: 100%;
    padding: 0 ptr(24) ptr(24);

    .list_title_wrapper {
      @include flex(space-between, center);

      width: 100%;
      padding: ptr(16) ptr(24);
      border-radius: ptr(8);
      background-color: $white;
      cursor: pointer;

      &[data-collaped="true"] {
        border-radius: ptr(8) ptr(8) 0 0;
      }

      .list_title {
        width: 100%;
      }

      .list_info {
        @include flex(flex-end, center);

        gap: ptr(16);

        .progress_count {
          width: max-content;
          color: $gray-50;

          .completed {
            color: $blue-100;
          }
        }

        .arrow_icon {
          width: ptr(16);
          height: ptr(16);
          color: $gray-50;
          cursor: pointer;

          &.upside {
            transform: rotate(180deg);
          }
        }
      }
    }

    .onboard_progress_bar {
      width: 100%;
      height: ptr(2);
      background-color: $blue-100;
    }

    .onboard_item_list {
      @include flex(flex-start, flex-start, column);

      width: 100%;
      padding: ptr(24);
      border-radius: 0 0 ptr(8) ptr(8);
      background-color: $white;
      gap: ptr(24);

      .onboard_item {
        width: 100%;
        @include flex(center, center);

        gap: ptr(16);
        cursor: pointer;

        .onboard_icon_wrapper {
          width: ptr(48);
          height: ptr(48);
          padding: ptr(12);
          border-radius: ptr(8);
          background-color: $gray-5;

          & .icon {
            width: ptr(24);
            height: ptr(24);
            color: $gray-50;
          }
        }

        .onboard_content {
          width: 100%;
          @include flex(flex-start, flex-start, column);

          gap: ptr(4);

          .onboard_title_wrapper {
            @include flex(flex-start, center);

            gap: ptr(4);

            .point {
              @include flex(center, center);

              padding: ptr(2) ptr(4);
              border-radius: ptr(4);
              background-color: $blue-10;
              color: $blue-100;
            }
          }

          .onboard_desc {
            color: $gray-80;
          }
        }

        .onboard_button {
          min-width: ptr(80);
          padding: ptr(9) ptr(16);
          border-radius: ptr(8);
          background-color: $blue-100;
          color: $white;
          text-align: center;
          cursor: pointer;
        }

        .onboard_button_finished {
          @include flex(center, center);

          min-width: ptr(32);
          min-height: ptr(32);
          border-radius: 50%;
          background-color: $green-100;

          .checked_icon {
            width: ptr(16);
            height: ptr(16);
            color: $white;
          }
        }
      }
    }
  }
}

html[is_mobile="true"] .section {
  position: relative;
  width: 100%;
  padding: 0 ptr(16);

  .box {
    width: 100%;
    border-radius: ptr(16);
    background-color: $gray-1;
  }

  .onboarding_header {
    width: 100%;
    @include flex(flex-start, center);

    gap: ptr(24);
    padding: ptr(24);

    .caution {
      color: $gray-50;
    }

    .header_icon {
      display: none;
    }

    .header_content {
      width: 100%;
      @include flex(center, flex-start, column);

      gap: ptr(8);
    }

    .header_progress {
      min-width: ptr(72);
      min-height: ptr(72);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .item_list {
    @include flex(flex-start, flex-start, column);

    width: 100%;
    padding: 0 ptr(16) ptr(16);

    .list_title_wrapper {
      @include flex(space-between, center);

      width: 100%;
      padding: ptr(16);
      border-radius: ptr(8);
      background-color: $white;
      cursor: pointer;

      &[data-collaped="true"] {
        border-radius: ptr(8) ptr(8) 0 0;
      }

      .list_title {
        width: 100%;
      }

      .list_info {
        @include flex(flex-end, center);

        gap: ptr(16);

        .progress_count {
          width: max-content;
          color: $gray-50;

          .completed {
            color: $blue-100;
          }
        }

        .arrow_icon {
          width: ptr(16);
          height: ptr(16);
          color: $gray-50;
          cursor: pointer;

          &.upside {
            transform: rotate(180deg);
          }
        }
      }
    }

    .onboard_progress_bar {
      width: 100%;
      height: ptr(2);
      background-color: $blue-100;
    }

    .onboard_item_list {
      @include flex(flex-start, flex-start, column);

      width: 100%;
      padding: ptr(16);
      border-radius: 0 0 ptr(8) ptr(8);
      background-color: $white;
      gap: ptr(24) ptr(16);

      .onboard_item {
        width: 100%;
        @include flex(center, flex-start);

        gap: ptr(16);

        .onboard_icon_wrapper {
          width: ptr(32);
          height: ptr(32);
          padding: ptr(8);
          border-radius: ptr(8);
          background-color: $gray-5;

          & .icon {
            width: ptr(16);
            height: ptr(16);
            color: $gray-50;
          }
        }

        .onboard_content {
          width: 100%;
          @include flex(flex-start, flex-start, column);

          gap: ptr(4);

          .onboard_title_wrapper {
            @include flex(flex-start, flex-start);

            gap: ptr(4);

            .point {
              @include flex(center, center);

              margin-top: ptr(2);
              padding: ptr(2) ptr(4);
              border-radius: ptr(4);
              background-color: $blue-10;
              color: $blue-100;
            }
          }

          .onboard_desc {
            color: $gray-80;
          }
        }

        .onboard_button {
          min-width: ptr(80);
          padding: ptr(9) ptr(16);
          border-radius: ptr(8);
          background-color: $blue-100;
          color: $white;
          text-align: center;
          cursor: pointer;
        }

        .onboard_button_finished {
          @include flex(center, center);

          min-width: ptr(32);
          min-height: ptr(32);
          border-radius: 50%;
          background-color: $green-100;

          .checked_icon {
            width: ptr(16);
            height: ptr(16);
            color: $white;
          }
        }
      }
    }
  }
}
