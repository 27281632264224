@import "base";
@import "../../../../MarppleShop/shared/style/base";
@import "./variables";

// pc
html[is_mobile="false"] {
  .overview {
    grid-area: overview;
  }

  .sales_goods_type {
    grid-area: sales_goods_type;
  }

  .best_seller {
    grid-area: best_seller;
  }

  .review {
    grid-area: review;
  }

  .dashboard_body {
    @media (width <= 1480px) {
      display: grid;
      grid-gap: ptr(24) ptr(20);
      grid-template-areas:
        "overview overview"
        ". ."
        ". ."
        ". ."
        "sales_goods_type sales_goods_type"
        ". ."
        "review review"
        "best_seller best_seller";
      grid-template-columns: repeat(2, 1fr);
    }

    @media (width >= 1481px) {
      display: grid;
      grid-gap: ptr(24) ptr(20);
      grid-template-areas:
        "overview overview overview"
        ". . ."
        ". . ."
        "sales_goods_type sales_goods_type sales_goods_type"
        ". . review"
        "best_seller best_seller best_seller";
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

// mo
html[is_mobile="true"] {
  .dashboard_body {
    display: grid;
    grid-template-columns: 1fr;
    gap: ptr(44);
  }
}
