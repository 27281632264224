@import "base";
html[is_mobile="false"] {
  .product_list {
    display: grid;
    grid-column-gap: ptr(16);
    grid-row-gap: ptr(32);
    grid-template-columns: repeat(5, minmax(0, 1fr));
    flex: 1 0;
    transition:
      opacity 0.7s ease,
      transform 0.7s ease;
  }
  .empty_list {
    display: flex !important;
  }
}

html[is_mobile="true"] {
  .product_list {
    display: grid;
    grid-column-gap: ptr(16);
    grid-row-gap: ptr(32);
    grid-template-columns: repeat(2, minmax(0, 1fr));
    flex: 1 0;
    transition:
      opacity 0.3s ease,
      transform 0.3s ease;
  }
  .empty_list {
    display: flex !important;
  }
}
