@import "base";
.banner {
  display: inline-flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: ptr(72);
  padding: ptr(16);
  overflow: hidden;
  border-radius: ptr(8);
  background-color: $blue-100;
  gap: ptr(12);
}

.guide {
  display: inline-flex;
  flex-direction: column;
  color: $white;
  gap: ptr(2);

  .desc {
    display: inline-flex;
    gap: ptr(4);
    align-items: center;
  }

  .icon {
    display: inline-flex;
    width: ptr(8);
    height: ptr(8);
  }
}

.img_wrapper {
  display: flex;
  position: absolute;
  top: ptr(5);
  right: ptr(-12);
  align-items: center;
  justify-content: center;
  width: ptr(62);
  height: ptr(62);

  > img {
    width: 100%;
    height: 100%;
  }
}

html[is_mobile="true"] {
  .img_wrapper {
    top: ptr(8);
    right: ptr(16);
    width: ptr(56);
    height: ptr(56);

    > img {
      width: 100%;
      height: 100%;
    }
  }
}
