@import "base";
html[is_mobile="false"] section.container {
  position: relative;
  @include flex(center, center, column);

  padding: ptr(124) 0;
  gap: ptr(80);

  .title_wrapper {
    @include flex(center, center, column);

    gap: ptr(24);

    .title {
      text-align: center;
    }

    .description {
      text-align: center;
    }
  }

  .content {
    width: 100%;
    @include flex(center, center);

    gap: ptr(24);
    padding: 0 ptr(32);

    .slide {
      @include flex(center, center);

      width: ptr(916);
      height: ptr(520);

      .statistics {
        @include flex(space-between, flex-start);

        width: 100%;
        height: 100%;
        padding: ptr(60);
        border-radius: ptr(16);
        background: $blue-10;

        .statistics_title_wrapper {
          .statistics_title {
            margin-bottom: ptr(24);
            color: $gray-80;
          }

          .statistics_description {
            margin-bottom: ptr(8);
            color: $gray-80;
          }

          .statistics_source {
            color: $gray-80;
          }
        }

        .chart {
          height: 100%;

          & > img {
            height: 100%;
          }
        }
      }
    }
  }
}

html[is_mobile="true"] section.container {
  position: relative;
  width: 100%;
  padding: ptr(40) 0;
  gap: ptr(24);
  @include flex(center, center, column);

  .title_wrapper {
    @include flex(flex-start, flex-start, column);

    width: 100%;
    gap: ptr(16);
    padding: ptr(24) ptr(32);

    .description {
      width: ptr(311);
    }
  }

  .content {
    .slide {
      @include flex(center, center);

      padding: 0 ptr(32);

      .statistics {
        @include flex(flex-start, flex-start, column);

        width: 100%;
        max-width: ptr(311);
        padding: ptr(24) ptr(21);
        border-radius: ptr(16);
        background: $blue-10;
        aspect-ratio: 311 / 439;
        gap: ptr(24);

        .statistics_title_wrapper {
          .statistics_title {
            margin-bottom: ptr(8);
            color: $gray-80;
          }

          .statistics_description {
            margin-bottom: ptr(8);
            color: $gray-80;
          }

          .statistics_source {
            color: $gray-80;
          }
        }

        .chart {
          @include flex(center, center);

          width: 100%;
          height: ptr(242);

          & > img {
            height: 100%;
          }
        }
      }
    }
  }

  .pagination {
    position: relative;
    width: 100%;
    height: ptr(8);
  }
}
