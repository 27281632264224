@import "base";
html[is_mobile="false"] .layout {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100dvh;

  .body_container {
    display: flex;
    box-sizing: border-box;
    flex: 1;
    flex-direction: column;
    width: 100%;
    min-width: $pc-layout-body-min-width;
    max-width: $pc-layout-body-max-width;
    margin: 0 auto;
    padding: 0 $marppleshop-layout-padding;
  }

  .footer {
    width: 100%;
    margin-top: ptr(120);
  }

  &.full_width {
    .body_container {
      max-width: unset;
      padding: 0;
    }
  }
}

html[is_mobile="true"] .layout {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  min-height: 100dvh;

  &.has_sub_header_sub_menu_container {
    padding-top: $default-sub-header-sub-menu-container-mo_height;
  }

  .body_container {
    display: flex;
    box-sizing: border-box;
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding: 0 $marppleshop-layout-mo-padding;
  }

  .footer {
    width: 100%;
    margin-top: ptr(60);
  }

  &.full_width {
    .body_container {
      padding: 0;
    }
  }
}
