@import "base";
html[is_mobile="false"] {
  .all_creator_tabs {
  }

  .container {
  }

  .tabs {
    margin-bottom: ptr(32);
  }

  .creator_list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: ptr(24);
  }
}

html[is_mobile="true"] {
  .all_creator_tabs {
    opacity: 1;
  }

  .container {
  }

  .tabs {
    margin-bottom: ptr(24);
  }

  .creator_list_area {
  }

  .creator_list {
    display: flex;
    flex-direction: column;
    gap: ptr(16);
  }
}
