@import "base";
@import "../../../../../shared/style/base";

.container {
  display: inline-flex;
  gap: ptr(8);
  overflow-y: auto;
  @include hiddenScroll;

  .tab_button {
    flex-shrink: 0;
    padding: ptr(5) ptr(15);
    border: 1px solid $gray-10;
    border-radius: ptr(40);
    background-color: $white;
    color: $gray-80 !important; // studio 전역에 적용된 버튼 스타일 제거되면 `!important` 없애기

    &.active {
      border: 1px solid $blue-10;
      background-color: $blue-10;
      color: $blue-100 !important; // studio 전역에 적용된 버튼 스타일 제거되면 `!important` 없애기
    }

    .count {
      margin-left: ptr(4);
    }
  }
}
