@import "base";
@import "../../../../../shared/style/base";

.review_and_cheer_up_container {
  @include pc {
    display: flex;
    gap: ptr(40);
  }
  @include mobile {
    display: flex;
    flex-direction: column;
    gap: ptr(24);
  }

  .review_container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: ptr(24);

    @include pc {
      width: ptr(144);
    }
    @include mobile {
      width: 100%;
    }

    .average_container {
      color: $gray-50;

      .star_icon {
        display: inline-block;
        width: ptr(24);
        height: ptr(24);
        margin-right: ptr(8);
        color: $blue-100;
        vertical-align: bottom;
      }

      .average_score {
        color: $blue-100;
      }

      &.empty {
        display: flex;
        justify-content: center;
        color: $gray-50;
      }
    }

    .count_container {
      display: flex;
      justify-content: space-between;
      color: $gray-50;

      .title {
        color: $gray-80;
      }

      .count {
        text-decoration: underline;
      }

      .count_active {
        color: $blue-100;
      }
    }
  }

  .cheer_up_container {
    flex: 1;

    .cheer_up_table {
      display: flex;
      align-items: center;

      .created_at_column {
        width: 0;
      }

      .title_column {
        border-inline-start: ptr(8) solid transparent;
        text-align: left;
      }

      .status_column {
        width: ptr(100);
      }

      .title {
        color: $gray-80 !important;
        @include lineLimit(1);

        text-decoration: underline;
        white-space: normal;
      }

      &.empty {
        display: flex;
        justify-content: center;
        @include pc {
          margin-top: ptr(40);
        }
        @include mobile {
          td {
            padding-top: ptr(40);
            padding-bottom: ptr(58);
          }
        }

        tbody {
          color: $gray-50;
        }
      }

      tbody {
        color: $gray-80;

        td {
          padding: ptr(8) 0;
        }
      }
    }
  }
}
