@import "base";
@import "../../../../../shared/style/base";

.tab_container {
  margin-top: ptr(12);
  margin-bottom: ptr(16);
  cursor: grab !important;

  &.dragging {
    cursor: grabbing !important;
  }
}

.qna_tab {
  flex: 1;
}

.qna_container {
  display: grid;
  min-height: ptr(176);
  color: $gray-80;

  &:not(.empty) {
    grid-auto-rows: ptr(24);
    grid-gap: ptr(12) ptr(8);
    grid-template-columns: max-content 1fr;
    align-items: center;
  }

  &.hidden {
    display: none;
  }

  &.empty {
    grid-template-columns: 1fr;
  }

  a {
    color: inherit !important;
    text-decoration: underline;
  }

  .title {
    @include lineLimit(1);

    color: inherit !important;
    text-decoration: underline;
    white-space: normal;
  }

  .empty_text {
    display: flex;
    justify-content: center;
    margin-top: ptr(40);
    color: $gray-50;
  }
}
