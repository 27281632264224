@import "base";
html[is_mobile="false"] {
  .page {
    background: inherit;
  }
}

html[is_mobile="true"] {
  .page {
    background: inherit;
  }
}
