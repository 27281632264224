@import "base";
@import "../../../../services/MarppleShop/shared/style/base";

.section_gap {
  margin-bottom: ptr(24);
}

.membership {
  .btn {
    flex-shrink: 0;
  }

  .btn_container {
    display: flex;
  }
}
