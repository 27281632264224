@import "base";
.link {
  display: block;
  margin-top: ptr(12);

  a {
    border-bottom: 1px solid $blue-100;
    color: $blue-100;
  }
}
