@import "base";
html[is_mobile="false"] {
  .root {
    padding-top: ptr(32);
  }
}

html[is_mobile="true"] {
  .root {
    min-height: ptr(695);
    margin-top: $default-header-mo_height;
  }
}
