@import "base";
html[is_mobile="true"] .mshop_logo_with_menu {
  display: flex;
  align-items: center;
  column-gap: ptr(16);

  .menu_icon {
    width: ptr(24);
    height: ptr(24);

    svg {
      height: 100%;
    }
  }

  .shop_icon {
    height: ptr(28);

    svg {
      height: 100%;
    }
  }

  &.transparent {
    color: $white;
  }
}
