@import "base";
html[is_mobile="false"] {
  .page {
    width: 100%;
    min-width: $pc-layout-body-min-width;
    max-width: $pc-layout-body-max-width;
    margin-top: ptr(72);
    margin-right: auto;
    margin-left: auto;
    padding: ptr(32) ptr(32) 0;
  }
}

html[is_mobile="true"] {
  .page {
    width: 100%;
    margin-top: $default-header-mo_height;
  }
}
