@import "./variables";

@mixin vertical-base {
  & {
    display: flex;
    flex-direction: column;
  }
}

@mixin horizontal-base {
  & {
    display: flex;
  }

  .review,
  .tag_container {
    display: none;
  }
}

@mixin info-base {
  .info {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    @content;
  }
}
