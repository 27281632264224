@import "base";
html[is_mobile="false"] .modal_body {
  @include flex(center, center, column);

  width: ptr(608);
  border-radius: ptr(16);
  background: $white;

  .modal_content_wrapper {
    width: 100%;

    .modal_content_items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 100%;

      .modal_content_item {
        display: grid;
        grid-template-rows: 1fr 2fr;
        height: 100%;
        color: $gray-80;

        .content_title {
          @include flex(center, center);

          width: 100%;
          height: 100%;
          padding: ptr(8);
          border: ptr(1) solid $gray-20;
          background-color: $gray-5;
          text-align: center;
        }

        .content_description {
          @include flex(center, center);

          width: 100%;
          height: 100%;
          padding: ptr(8);
          border: ptr(1) solid $gray-20;
          text-align: center;
        }
      }
    }

    .modal_content_tips {
      margin-top: ptr(8);
      color: $gray-80;
    }
  }
}

html[is_mobile="true"] .modal_body {
  width: 100%;

  .modal_content_wrapper {
    width: 100%;

    .modal_content_items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 100%;

      .modal_content_item {
        display: grid;
        grid-template-rows: 1fr 2fr;
        height: 100%;
        border-top: ptr(1) solid $gray-20;
        border-right: ptr(1) solid $gray-20;
        border-bottom: ptr(1) solid $gray-20;
        color: $gray-80;

        &:nth-child(1) {
          border-left: ptr(1) solid $gray-20;
        }

        .content_title {
          @include flex(center, center);

          width: 100%;
          height: 100%;
          padding: ptr(8);
          border-bottom: ptr(1) solid $gray-20;
          background-color: $gray-5;
          text-align: center;
        }

        .content_description {
          @include flex(center, center);

          width: 100%;
          height: 100%;
          padding: ptr(8);
          text-align: center;
        }
      }
    }

    .modal_content_tips {
      margin-top: ptr(8);
      color: $gray-80;
    }
  }
}

.confirm_button_wrapper {
  width: 100%;

  .confirm_button {
    width: 100%;
    height: ptr(56);
    border-radius: ptr(8);
    background-color: $blue-100;
    color: $white;
    @include flex(center, center);

    cursor: pointer;
  }
}
