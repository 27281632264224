@import "base";
@import "../../../../../shared/style/base";
@import "../../../atoms/Modal/variables";

.auto_complete_input_container {
  .highlight {
    &.fill {
      background-color: $blue;
      color: $white;
    }
    &.stroke {
      color: $blue;
    }
  }

  .hidden {
    display: none !important;
  }

  @include pc {
    position: relative;
    grid-column: 1 / end;

    .search_input {
      margin-bottom: ptr(16);
    }

    .auto_complete_container {
      display: flex;
      position: absolute;
      right: 0;
      left: 0;
      flex-direction: column;
      width: ptr(320);
      padding: 0 ptr(12);
      color: $white;
      list-style: none;

      .auto_complete_anchor.focused {
        border-radius: ptr(8);
        background-color: $bright-5;
      }

      .auto_complete {
        @include lineLimit(1);

        padding: ptr(4);
        white-space: normal;
      }
    }
  }

  @include mobile {
    .auto_complete_container {
      display: flex;
      position: absolute;
      z-index: 2;
      right: 0;
      left: 0;
      flex-direction: column;
      height: 100dvh;
      padding: ptr(16) ptr(16) 0;
      background-color: $white;
      color: $black;
      list-style: none;

      &.hidden {
        display: none;
      }

      .auto_complete_anchor {
        padding: ptr(8) ptr(12);

        &.focused {
          border-radius: ptr(8);
          background-color: $bright-5;
        }
      }

      .auto_complete {
        @include limitLine(1);

        white-space: normal;
      }
    }
  }

  .auto_complete_list {
    @include pc {
      margin-top: ptr(16);
    }
  }
}
