@import "base";
html[is_mobile="false"] {
  .cheer_up_empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: ptr(160) 0 ptr(80);
  }

  .image {
    text-align: center;
  }

  .img {
    width: ptr(160);
    aspect-ratio: 1 / 1;
  }

  .title {
    margin-top: ptr(24);
    color: $gray-80;
    text-align: center;
  }

  .body {
    margin-top: ptr(8);
    color: $gray-50;
    text-align: center;
  }

  .btn_area {
    margin-top: ptr(24);
    text-align: center;
  }

  .btn_cheer_up {
    min-width: ptr(196);
  }
}

html[is_mobile="true"] {
  .cheer_up_empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: ptr(80) 0 ptr(40);
  }

  .image {
    text-align: center;
  }

  .img {
    width: ptr(160);
    aspect-ratio: 1 / 1;
  }

  .title {
    margin-top: ptr(24);
    color: $gray-80;
    text-align: center;
  }

  .body {
    margin-top: ptr(8);
    color: $gray-50;
    text-align: center;
  }

  .btn_area {
    margin-top: ptr(24);
    text-align: center;
  }

  .btn_cheer_up {
    min-width: ptr(196);
  }
}
