@import "base";
html[is_mobile="true"],
html[is_mobile="false"] {
  .wrapper {
    width: 40px;
    height: 40px;
    overflow: hidden;
    transition: height 300ms ease-out;
  }

  .floating_button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 8px;
    background: $bright-60;
    cursor: pointer;
  }

  .bg_blue {
    background-color: $blue-100;
    color: white;
  }

  .button_icon {
    width: 24px;
    height: 24px;
  }

  .button_image {
    width: 100%;
    height: 100%;
  }

  .button_blue_dot {
    display: none;
    position: absolute;
    top: 4px;
    right: 4px;
    width: 5px;
    height: 5px;
    border-radius: 999px;
    background-color: $blue-100;

    &[data-active="true"] {
      display: block;
    }
  }

  .zero_height {
    height: 0;
  }

  .hidden {
    display: none;
  }
}
