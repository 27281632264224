@import "base";
:global {
  .toast-message-container {
    z-index: 12 !important;
  }
}

html[is_mobile="true"] {
  .input_wrapper {
    .input_label {
      width: ptr(20);
      height: ptr(20);

      .plus_icon {
        @include flex(center, center);

        width: 100%;
        height: 100%;
        padding: ptr(4);
      }
    }
  }
}

.input_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: ptr(8);

  .input_wrapper {
    display: flex;
    position: relative;
    align-items: center;
    align-self: stretch;
    min-height: ptr(48);
    padding: ptr(12) 0;
    border-bottom: ptr(1) solid $white;

    .preview {
      display: none;
      width: 100%;
      color: $white;

      & > a {
        @include lineLimit(2);

        max-width: calc(100% - 62px);
        word-break: break-all;
      }

      .minus_icon {
        @include flex(center, center);

        flex-shrink: 0;
        width: ptr(22);
        height: ptr(22);
        padding: ptr(4);
        border-radius: ptr(400);
        background: $bright-20;
        cursor: pointer;

        & > svg {
          width: ptr(14);
          height: ptr(14);
        }
      }
    }

    .placeholder {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      padding: ptr(12) 0;
      color: $gray-50;
      cursor: pointer;

      .asterisk {
        content: " *";
        color: $red-80;
      }
    }

    .input_label {
      position: absolute;
      top: 50%;
      right: 0;
      width: ptr(24);
      height: ptr(24);
      transform: translateY(-50%);
      cursor: pointer;

      .input {
        display: none;
      }

      .plus_icon {
        @include flex(center, center);

        width: 100%;
        height: 100%;
        padding: ptr(6);
        border-radius: ptr(666);
        background: $gray-5;

        & > svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .helper_message {
    color: $gray-50;
  }

  &[data-upload="inActive"] {
    & .input_wrapper {
      border-bottom: ptr(1) solid $gray-50;

      .placeholder {
        display: block;
        cursor: text;
      }
    }
  }

  &[data-upload="active"] {
    & .input_wrapper {
      .preview {
        display: inline-flex;
        gap: 0.5rem;
      }
    }
  }

  &[data-error="true"] {
    & .input_wrapper {
      border-bottom: ptr(1) solid $red-80;
    }

    .helper_message {
      color: $red-80;
    }
  }
}
