@import "base";
html[is_mobile="false"] section.container {
  position: relative;
  @include flex(center, center, column);

  padding: ptr(124) 0;
  gap: ptr(80);

  .title_wrapper {
    @include flex(center, center, column);

    padding-top: ptr(107);

    .title {
      text-align: center;
    }
  }

  .content {
    width: 100%;
    @include flex(center, baseline);

    gap: ptr(40);

    .slide {
      @include flex(center, center);

      width: ptr(277);
      height: ptr(418);

      .case_card {
        @include flex(center, center, column);

        gap: ptr(16);

        .case_image {
          width: ptr(208);
          height: ptr(208);

          & > img {
            width: 100%;
            height: 100%;
          }
        }

        .card_title_wrapper {
          @include flex(center, center, column);

          gap: ptr(8);

          .card_title {
            margin-bottom: ptr(8);
            text-align: center;
          }

          .card_description {
            color: $gray-80;
            line-height: ptr(26) !important;
            text-align: center;
          }
        }
      }
    }
  }
}

html[is_mobile="true"] section.container {
  position: relative;
  width: 100%;
  padding: ptr(40) 0;
  gap: ptr(40);
  @include flex(center, center, column);

  .title_wrapper {
    @include flex(center, center, column);

    padding-top: ptr(40);

    .title {
      text-align: center;
    }
  }

  .content {
    width: 100%;
    margin-bottom: ptr(40);

    .slide {
      @include flex(center, center);

      width: 100%;

      .case_card {
        @include flex(flex-start, center, column);

        gap: ptr(16);
        width: ptr(288);
        height: ptr(390);

        .case_image {
          width: ptr(208);
          height: ptr(208);

          & > img {
            width: 100%;
            height: 100%;
          }
        }

        .card_title_wrapper {
          @include flex(center, center, column);

          gap: ptr(8);

          .card_title {
            margin-bottom: ptr(8);
            text-align: center;
          }

          .card_description {
            color: $gray-80;
            text-align: center;
          }
        }
      }
    }
  }

  .pagination {
    position: relative;
    width: calc(100% - 64px);
    height: ptr(2);
    background: $dim-5;

    .progressbar_fill {
      background: $blue-100;
    }
  }
}
