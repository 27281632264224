@import "base";
html[is_mobile="false"] section.statistics {
  background-color: $blue-10;
  @include flex(space-between, flex-start, column);

  & > div.title {
    width: 100%;
    padding: ptr(96) ptr(188) ptr(106);
    color: $blue-100;
  }

  & > div.statistics {
    width: 100%;
    padding-bottom: ptr(32);

    & > div.date {
      padding: ptr(16) ptr(188);
      border-bottom: ptr(1) solid $blue-100;
      color: $blue-100;
    }

    & > div.statistics_row {
      display: grid;
      grid-template-columns: 50% 50%;
      width: 100%;
      padding: ptr(32) ptr(188) ptr(40);
      border-bottom: ptr(1) solid $blue-100;

      & > div.statistics_item {
        @include flex(flex-start, flex-start, column);

        .sub_title {
          margin-bottom: ptr(8);
          color: $blue-100;
        }

        .row_title {
          color: $blue-100;
          font-family: "Bebas Neue Pro", sans-serif;
          font-size: ptr(120);
          font-weight: 700;
          line-height: 80%;
        }
      }
    }
  }
}

html[is_mobile="true"] section.statistics {
  padding: ptr(80) ptr(32) ptr(40);
  background-color: $blue-10;
  @include flex(space-between, flex-start, column);

  gap: ptr(24);

  & > div.title {
    width: ptr(283);
    height: ptr(272);
    color: $blue-100;
  }

  & > div.statistics {
    width: 100%;

    & > div.date {
      padding: ptr(16) 0;
      border-bottom: ptr(1) solid $blue-100;
      color: $blue-100;
    }

    & > div.statistics_row {
      display: grid;
      grid-template-columns: 50% 50%;
      width: 100%;
      padding: ptr(24) 0;
      border-bottom: ptr(1) solid $blue-100;

      & > div.statistics_item {
        @include flex(flex-start, flex-start, column);

        width: 100%;

        .sub_title {
          width: 100%;
          margin-bottom: ptr(8);
          color: $blue-100;
          @include lineLimit(1);
        }

        .row_title {
          color: $blue-100;
          font-family: "Bebas Neue Pro", sans-serif;
          font-size: ptr(40);
          font-weight: 700;
          line-height: 80%;
          @include lineLimit(1);
        }
      }
    }
  }
}
