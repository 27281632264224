@import "base";
.feed_detail_admin {
  width: 100%;

  .body {
  }

  .layout {
    display: flex;
  }

  .save_button {
    display: inline-flex !important;
    align-items: center;
    column-gap: ptr(8) !important;
  }

  .save_button_icon {
    width: ptr(18);
  }

  .floating_save_button {
    visibility: hidden;
    position: fixed;
    z-index: 1;
    right: ptr(40);
    bottom: ptr(40);
    overflow: hidden;
    transition:
      opacity 300ms ease-out,
      visibility 300ms ease-out;
    border-radius: ptr(4);
    opacity: 0;
    &.active {
      visibility: visible;
      opacity: 1;
    }
  }

  .form_container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: ptr(448);
    margin-right: ptr(24);
    padding: ptr(30);
    border: 1px solid $gray-5;
    row-gap: ptr(24);
  }

  .form_section {
    display: block;
  }

  .section_title {
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px !important;
  }

  .section_sub_title {
    margin-left: ptr(8);
    color: $gray-50;
  }

  .section_body {
  }

  .input_item {
    position: relative;
    width: 100%;
    padding: ptr(9) ptr(12);
    border: 1px solid $gray-10;
    border-radius: ptr(4);

    &.with_icon {
      padding-left: ptr(38);

      .input_icon {
        position: absolute;
        top: ptr(9);
        left: ptr(12);
        aspect-ratio: 1;
        width: ptr(18);
      }
    }

    &.with_icon[data-limit] {
      padding-right: ptr(62);
    }

    &[data-type="text"] .typo_limit {
      display: flex;
      position: absolute;
      top: 0;
      right: ptr(12);
      bottom: 0;
      align-items: center;
      color: $gray-50;
      font-size: 12px;
    }

    &[data-type="textarea"] .typo_limit {
      position: absolute;
      right: 12px;
      bottom: 9px;
      background-color: white;
      color: $gray-50;
      font-size: 12px;
    }

    .image_preview {
      display: none;
      position: absolute;
      inset: 0;
      z-index: 2;
      width: 100%;
    }

    .image_preview.on {
      display: inline-block;
    }

    .image_input {
      position: absolute;
      inset: 0;
      z-index: 3;
      opacity: 0;
      cursor: pointer;
    }

    .image_form_replacer {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      transform: translate(-50%, -50%);
    }

    .image_form_placeholder {
      margin: ptr(16) 0 0;
      color: $gray-80;
      text-align: center;
    }

    .form_select {
      width: 100%;
      border: 0;

      &:focus {
        outline: none;
      }
    }

    .form_text {
      width: 100%;
      border: 0 !important;
      resize: none;

      &:focus {
        outline: none;
      }
    }
  }

  .intl_inputs_container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    textarea {
      width: 100%;
      height: ptr(88);
      padding: 0;
      border: 0 !important;
      outline: none !important;
      resize: none;
      scrollbar-width: none;
    }

    textarea::-webkit-scrollbar {
      width: 0;
    }
  }

  .input_plus_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ptr(60);
    height: ptr(60);
    padding: ptr(12);
    border-radius: ptr(9999);
    background-color: $blue;

    img {
      width: ptr(36);
      aspect-ratio: 1;
    }
  }

  .image_form_description_table {
    display: flex;
    margin-top: ptr(8);
    padding: ptr(8) ptr(16);
    column-gap: ptr(8);
    background-color: $gray-5;
  }

  .content_container {
    display: flex;
    min-height: 60vh;
    padding: ptr(32);
    border: 1px solid $gray-5;
  }

  .dropdown {
    width: 100%;
    height: 100%;
  }

  .dropdown_button {
    justify-content: space-between !important;
    width: 100%;
    height: 100%;
    border: 0 !important;
  }

  .feed_content {
    max-width: 896px;

    &:focus {
      outline: none;
    }
  }

  .hashtag_container {
  }

  .hashtag_input_row {
    display: flex;
    margin-bottom: ptr(8);
    column-gap: ptr(8);
  }

  .hashtag_input {
    flex-grow: 1;
    padding: ptr(9) ptr(12);
    border: 1px solid $gray-10;
    border-radius: ptr(4);
  }

  .hashtag_add_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ptr(40);
    height: ptr(40);
    border: 0;
    border-radius: ptr(4);
    color: $gray-50;

    .plus_button_icon {
      width: ptr(20);
    }
  }

  .hashtags_preview {
    display: flex;
    flex-wrap: wrap;
    gap: ptr(8);
  }

  .hashtag_preview_item {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: ptr(4) ptr(8);
    column-gap: ptr(4);
    border: 0;
    color: $gray-80 !important;
  }

  .hashtag_preview_x_icon {
    width: ptr(12);
    height: ptr(12);
    color: $gray-80;
  }

  .related_product_container {
    margin-top: ptr(24);
    padding: ptr(32);
    border: 1px solid $gray-5;
    border-radius: ptr(8);
  }

  .related_product_title {
    display: flex;
    align-items: flex-start;
    margin-bottom: ptr(24);
  }

  .related_product_add_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ptr(132);
    margin-left: auto;
    padding: ptr(12) 0;
    border: 1px solid $blue;
    border-radius: ptr(2);
    background-color: $white;
    color: $blue !important;
  }

  .related_products {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    min-height: ptr(250);
    gap: ptr(24) ptr(16);
  }

  .public_status_form {
    display: flex;
    flex-direction: column;
    row-gap: ptr(8);
  }

  .public_status_form_radio_container {
    display: flex;
    align-items: center;
    column-gap: ptr(24);
  }

  .show_term_container {
    display: flex;
    flex-direction: column;
    row-gap: ptr(8);

    &[data-show="false"] {
      display: none;
    }
  }

  .show_term_input_container {
    display: flex;
    align-items: center;
    column-gap: ptr(8);
  }

  .term_input {
    position: relative;
    flex-grow: 1;
    padding: ptr(9) ptr(12);
    border: 1px solid $gray-10;
    border-radius: ptr(4);
  }

  .term_end_checkbox {
    margin-right: ptr(8);
  }

  .no_end_container {
  }

  .public_status_caution {
    color: $gray-50;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 18px; /* 150% */
  }

  .public_status_caution_icon {
    display: inline-flex;
    width: ptr(16);
    margin-right: ptr(4);
  }

  .related_product_ids {
    display: none;
  }

  .related_products_meta_html {
    position: absolute;
    height: 0;
  }

  .plus_button_icon {
    color: $gray-50;
  }

  .content_and_product_container {
    flex-grow: 1;
  }
}

.add_products_modal {
  width: ptr(520 + 48);
  padding: ptr(24);
  padding-bottom: ptr(94);

  .plus_button_icon {
    color: $white;
  }

  .modal_header {
    margin-bottom: ptr(24);
  }

  .product_url_input_container {
    display: flex;
    column-gap: ptr(8);
  }

  .product_url_input {
    flex-grow: 1;
    padding: ptr(9) ptr(12);
    border: 1px solid $gray-10;
    border-radius: ptr(4);
  }

  .add_product_thumbnails {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: ptr(10) ptr(5);
  }

  .product_add_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ptr(40);
    height: ptr(40);
    border: 0;
    border-radius: ptr(4);
    background-color: $blue;

    .plus_button_icon {
      width: ptr(20);
    }
  }

  .add_products_modal_footer {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    height: ptr(94);
    padding: ptr(24);
    column-gap: ptr(8);
    background-color: $white;
  }

  .add_product_footer_button {
    width: ptr(132);
    height: ptr(46);
    padding: ptr(12) 0;
    border: 1px solid $gray-10;
    border-radius: ptr(2);
    background-color: $white;
    color: $gray-80;

    &.blue {
      border: 0;
      background-color: $blue;
      color: white !important;
    }
  }

  .product_thumbnail_item {
    display: block;
  }
}
