@import "base";
html[is_mobile="false"] section.statistics {
  //height: ptr(1080);
  height: 100vh;
  @include flex(flex-start, flex-start);

  padding: ptr(24);
  gap: ptr(24);

  .left_wrapper {
    position: relative;
    width: 50%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: ptr(8);
    }

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      color: white;
      @include flex(space-around, center);

      .info {
        @include flex(center, center, column);

        gap: ptr(16);

        .count {
          @include flex(center, center);

          svg {
            width: ptr(27);
            height: ptr(27);
            margin-left: ptr(8);
          }
        }
      }
    }

    .source {
      position: absolute;
      bottom: ptr(24);
      left: 50%;
      transform: translate(-50%, 0);
      color: $white;
    }
  }

  .right_wrapper {
    width: 50%;
    height: 100%;
    @include flex(center, center, column);

    gap: ptr(24);

    .content {
      @include flex(center, center, column);

      width: 100%;
      height: 50%;
      gap: ptr(29);

      .title,
      .description {
        text-align: center;
      }

      & div.link {
        @include flex(center, center);

        padding: ptr(12) ptr(20);
        border-radius: ptr(8);
        background-color: var(--Black, #141414);
        color: $white;
        gap: ptr(8);

        .arrow_button {
          width: ptr(16);
          height: ptr(16);
          color: $white;
        }
      }
    }

    .banners {
      width: 100%;
      height: 50%;
      @include flex(center, center, column);

      gap: ptr(24);
      color: $white;

      .banner_row {
        width: 100%;
        height: 50%;
        @include flex(flex-start, center);

        gap: ptr(24);
        overflow: hidden;
      }

      .banner {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: ptr(8);

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: ptr(8);
        }

        .info {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @include flex(center, center, column);

          gap: ptr(16);

          .count {
            @include flex(center, center);

            svg {
              width: ptr(27);
              height: ptr(27);
              margin-left: ptr(8);
            }
          }
        }

        &.banner_sm {
          width: 50%;
        }
      }
    }
  }
}

html[is_mobile="true"] section.statistics {
  @include flex(space-between, flex-start, column);

  padding: ptr(80) ptr(16);
  gap: ptr(24);

  .left_wrapper {
    position: relative;

    video {
      width: 100%;
      aspect-ratio: 343 / 458;
      object-fit: cover;
      border-radius: ptr(8);
    }

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      color: white;
      gap: ptr(16);
      @include flex(space-around, center, column);

      .info {
        @include flex(center, center, column);

        gap: ptr(8);
        width: 100%;

        .count {
          @include flex(center, center);

          svg {
            width: ptr(27);
            height: ptr(27);
            margin-left: ptr(8);
          }
        }
      }
    }

    .source {
      position: absolute;
      bottom: ptr(24);
      left: 50%;
      transform: translate(-50%, 0);
      color: $white;
    }
  }

  .right_wrapper {
    @include flex(center, center, column);

    width: 100%;
    height: 100vh;

    .content {
      @include flex(center, center, column);

      width: 100%;
      height: 50%;
      gap: ptr(16);

      .info {
        width: 100%;
        gap: ptr(32);
      }

      .title,
      .description {
        text-align: center;
      }

      & div.link {
        @include flex(center, center);

        padding: ptr(12) ptr(20);
        border-radius: ptr(8);
        background-color: var(--Black, #141414);
        color: $white;
        gap: ptr(8);

        .arrow_button {
          width: ptr(16);
          height: ptr(16);
          color: $white;
        }
      }
    }

    .banners {
      width: 100%;
      height: fit-content;
      @include flex(center, center, column);

      gap: ptr(16);
      color: $white;

      .banner_row {
        width: 100%;
        height: fit-content;
        @include flex(flex-start, center);

        gap: ptr(16);
        overflow: hidden;
      }

      .banner {
        position: relative;
        width: 100%;
        border-radius: ptr(8);

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: ptr(8);
        }

        .info {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @include flex(center, center, column);

          .title {
            width: max-content;
          }

          .count {
            width: max-content;
            @include flex(center, center);

            svg {
              width: ptr(18);
              height: ptr(18);
              margin-left: ptr(8);
            }
          }
        }

        &.banner_sm {
          width: 50%;
          aspect-ratio: 1;
        }

        &.banner_lg {
          aspect-ratio: 2;
        }
      }
    }
  }
}
