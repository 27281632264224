@import "base";
html[is_mobile="false"] .page {
  position: relative;

  .arrangement_container {
    @include flex(space-between, flex-start);

    width: 100%;
    height: 100%;
    max-height: ptr(1000);
    margin-top: ptr(40);
    gap: ptr(24);

    .creator_container {
      @include flex(flex-start, flex-start, column);

      min-width: 70%;
      padding-top: ptr(24);
      padding-bottom: ptr(32);
      border: 1px solid $gray-5;
      border-radius: ptr(8);
      gap: ptr(24);

      .creator_container_header {
        padding: 0 ptr(24);
        @include flex(flex-start, center);

        gap: ptr(8);

        .creator_container_desc {
          color: $gray-50;
        }
      }

      .creators {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        width: 100%;
        min-height: ptr(500);
        padding: 0 ptr(32);
        gap: ptr(24);
      }
    }

    .clipboard_container {
      @include flex(flex-start, flex-start, column);

      min-width: ptr(480);
      max-width: ptr(480);
      height: 100%;
      padding-top: ptr(24);
      padding-bottom: ptr(32);
      border: 1px solid $gray-5;
      border-radius: ptr(8);
      gap: ptr(24);

      .clipboard_container_header {
        padding: 0 ptr(24);
        @include flex(flex-start, center);

        gap: ptr(8);

        .clipboard_container_desc {
          color: $gray-50;
        }
      }

      .clipboard_body {
        position: sticky;
        width: 100%;
        height: 100%;

        .empty_box_wrapper {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all 0.5s;
          opacity: 0;
          @include flex(center, center, column);

          .empty_box {
            width: ptr(160);
            height: ptr(160);

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .empty_desc {
            width: 100%;
            color: $gray-50;
            text-align: center;
          }

          &.empty {
            opacity: 1;
          }
        }

        .clipboard {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          align-content: start;
          width: 100%;
          height: 100%;
          padding: 0 ptr(32);
          gap: ptr(24);
        }
      }
    }
  }

  .creator_card {
    @include flex(flex-start, center, column);

    width: 100%;
    min-width: ptr(120);
    gap: ptr(16);

    .thumbnail_img_wrapper {
      position: relative;
      width: 100%;
      aspect-ratio: 1;
      overflow: hidden;
      border-radius: ptr(8);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: ptr(8);
      }

      .checkbox {
        @include flex(center, center);

        position: absolute;
        top: ptr(8);
        right: ptr(8);
        width: ptr(18);
        height: ptr(18);
        border: 1px solid $gray-10;
        border-radius: ptr(4);
        background: $white;
        cursor: pointer;

        svg {
          color: $white;
        }
      }
    }

    &.selected {
      .checkbox {
        border: 1px solid $blue-100;
        background-color: $blue-100;
      }
    }

    .creator_desc_wrapper {
      width: 100%;

      .creator_desc {
        width: 100%;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &.drag_ghost {
      opacity: 0.2;
    }

    &.on_drag {
      .creator_desc {
        display: none;
      }
    }
  }
}

html[is_mobile="true"] {
  .page {
  }
}
