$font-family-kr:
  "Pretendard Variable",
  "Pretendard",
  -apple-system,
  "BlinkMacSystemFont",
  "system-ui",
  "Roboto",
  "Helvetica Neue",
  "Segoe UI",
  "Apple SD Gothic Neo",
  "Noto Sans KR",
  "Malgun Gothic",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  sans-serif;
$font-family-en-bebas: "Bebas Neue Pro";
$font-family-en-unica: "Neue Haas Unica Pro";
$font-size-default: 14;
$font-size-default-px: ($font-size-default) * 1px;
$font-size-base: 1rem;
$font-weight-bold: 700;
$font-weight-normal: 500;
$font-weight-regular: 400;
$font-letter-spacing-base: -0.2;
$font-letter-spacing-base-px: ($font-letter-spacing-base) * 1px;
