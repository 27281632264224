@import "base";
.button_count {
  display: inline-flex;
  align-items: center;
  height: ptr(44);
  padding: 0;
  border-radius: 8px;
  background-color: $gray-5;

  .count {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: ptr(42);
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    color: $black;
    font: inherit;
    text-align: center;
    pointer-events: none;
    user-select: none;
    appearance: none;
    -webkit-user-modify: read-write-plaintext-only;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  .plus_button,
  .minus_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $black;
    cursor: pointer;
  }

  .minus_button {
    padding-left: ptr(12);
  }

  .plus_button {
    padding-right: ptr(12);
  }

  .button_disabled {
    color: $gray-20;
    pointer-events: none;
  }
}
