@import "base";
@import "../../components/cells/Layout/variables";
@import "../../../shared/style/base";

.page {
  .tab_wrapper {
    @include flex(flex-start, flex-start, column);

    @include pc {
      gap: ptr(25);
      margin-bottom: ptr(25);
    }

    .tab {
      min-width: ptr(320);
      margin-bottom: 0;
    }

    .filter_wrapper {
      @include flex(center, center, column);

      gap: ptr(25);
      width: 100%;

      @include pc {
        .filter {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          padding: ptr(25);
          gap: ptr(12) ptr(40);
          border: ptr(1) solid $gray-5;
          border-radius: ptr(8);

          .filter_box {
            display: inline-flex;
            align-items: center;
            height: ptr(40);
            line-height: ptr(40);

            .field {
              width: ptr(93);
            }

            .content {
              @include flex(flex-start, flex-start);

              gap: ptr(10);
            }
          }

          .filter_row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: ptr(40);
            line-height: ptr(40);

            .filter_row_left {
              display: flex;
              align-items: center;

              .field {
                width: ptr(93);
              }

              .content {
                @include flex(flex-start, flex-start);

                gap: ptr(10);

                & > label {
                  width: ptr(368);
                }
              }
            }

            .filter_buttons {
              @include flex(flex-end, center);

              gap: ptr(10);

              .reset_button {
                width: ptr(128);
                border-radius: ptr(4);
                color: $gray-80;
              }

              .search_button {
                width: ptr(128);
                border-radius: ptr(4);
              }
            }
          }

          .status_dropdown {
            width: ptr(368);
          }

          .search_dropdown {
            width: ptr(128);
          }
        }
      }

      @include mobile {
        .filter {
          width: 100%;
          padding: ptr(20);
          @include flex(flex-start, flex-start, column);

          gap: ptr(12);

          .filter_box {
            width: 100%;

            .field {
              display: none;
            }

            .content {
              width: 100%;
            }
          }

          .filter_row {
            @include flex(center, center, column);

            gap: ptr(12);
            width: 100%;

            .filter_row_left {
              width: 100%;

              .field {
                display: none;
              }

              .content {
                @include flex(flex-start, flex-start);

                gap: ptr(10);

                & > label {
                  width: fit-content;
                }
              }
            }

            .filter_buttons {
              @include flex(flex-end, center);

              gap: ptr(10);

              .reset_button {
                width: ptr(128);
                border-radius: ptr(4);
                color: $gray-80;
              }

              .search_button {
                width: ptr(128);
                border-radius: ptr(4);
              }
            }
          }

          .status_dropdown {
            width: 100%;
          }

          .search_dropdown {
            width: ptr(128);
          }
        }
      }
    }
  }

  .table_wrapper {
    background-color: $gray-5;

    &.empty {
      overflow: hidden;
    }
    @include pc {
      padding: ptr(24) ptr(24) ptr(32);
      border-radius: ptr(8);
    }

    .table {
      margin-bottom: ptr(24);
      border: $gray-10;
      border-radius: ptr(8);

      @include mobile {
        margin: ptr(20) ptr(16);
      }

      .table_cell {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $gray-5;
      @include mobile {
        padding-bottom: ptr(20);
      }
    }
  }
}
