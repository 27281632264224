@import "base";
html[is_mobile="false"] section.links {
  z-index: 1;
  height: auto;
  @include flex(space-between, flex-start);

  .main_video {
    position: sticky;
    top: 0;
    width: 50%;
    height: 100vh;

    video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }

  .links_wrapper {
    width: 50%;
    background-color: $blue-10;
    @include flex(center, center, column);

    .slogan_wrapper {
      padding: ptr(240) ptr(160);
      gap: ptr(24);
      @include flex(center, center, column);

      .slogan {
        @include flex(center, center, column);

        .message {
          width: ptr(320);
        }

        .logo {
          height: ptr(88);
        }
      }

      .text {
        color: $blue-100;
        text-align: center;
      }
    }

    .link_card {
      width: 100%;
      padding: ptr(100) 0;
      @include flex(center, center, column);

      gap: ptr(60);

      .video_wrapper {
        position: relative;
        width: ptr(480);
        height: ptr(640);

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: ptr(300);
          transform: translate(-50%, -50%);
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: ptr(8);
        }
      }

      .image_wrapper {
        &.size_sm {
          width: ptr(350);
        }

        &.size_md {
          width: ptr(450);
        }

        &.size_lg {
          width: ptr(480);
        }

        img {
          width: 100%;
        }
      }

      .content_wrapper {
        @include flex(center, center, column);

        gap: ptr(40);

        .content {
          @include flex(center, center, column);

          gap: ptr(24);
          color: $blue-100;
          text-align: center;

          .title_wrapper {
            @include flex(center, center, column);

            gap: ptr(16);
          }

          .desc_wrapper {
            @include flex(center, center, column);

            .description {
              line-height: ptr(28) !important;
            }
          }
        }

        & div.link {
          @include flex(center, center);

          padding: ptr(12) ptr(20);
          border-radius: ptr(8);
          background-color: $blue-100;
          color: $white;
          gap: ptr(8);

          .arrow_button {
            width: ptr(16);
            height: ptr(16);
            color: $white;
          }
        }
      }
    }

    .creator_wrapper {
      z-index: 1;
      width: 100%;
      height: ptr(1080);
      padding: ptr(100);
      color: $blue-100;
      text-align: center;
      gap: ptr(32);
      @include flex(center, center, column);

      .slide {
        width: ptr(608);
        @include flex(space-between, center);

        button {
          background-color: $dim-10;

          &:hover {
            background-color: $dim-60;
          }
        }

        .swiper {
          width: ptr(480);
          height: ptr(446);
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .pagination {
        @include flex(center, center);

        gap: ptr(12);
        transition: color 200ms ease;
        color: $blue-100;

        .pages {
          @include flex(flex-start, center);

          flex-shrink: 0;
          width: ptr(48);
          height: ptr(1);
          background-color: $dim-10;

          .page {
            height: 100%;
            transition:
              width 1s,
              background 200ms ease;
            background-color: $blue-100;
          }
        }

        & > span {
          min-width: ptr(15);
        }
      }
    }
  }
}

html[is_mobile="true"] section.links {
  z-index: 1;
  height: auto;
  @include flex(space-between, flex-start, column);

  .main_video {
    width: 100%;
    aspect-ratio: 375 / 500;
    background-color: $blue-10;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .links_wrapper {
    width: 100%;
    background-color: $blue-10;
    @include flex(center, center, column);

    .slogan_wrapper {
      padding: ptr(120) ptr(68);
      gap: ptr(32);
      @include flex(center, center, column);

      .slogan {
        @include flex(center, center, column);

        .message {
          width: ptr(240);
        }

        .logo {
          height: ptr(88);
        }
      }

      .text {
        color: $blue-100;
        text-align: center;
      }
    }

    .link_card {
      padding: ptr(80) 0;
      @include flex(center, center, column);

      gap: ptr(24);

      .video_wrapper {
        position: relative;
        width: ptr(250);
        height: ptr(330);

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: ptr(125);
          transform: translate(-50%, -50%);
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: ptr(8);
        }
      }

      .image_wrapper {
        width: ptr(250);

        img {
          width: 100%;
        }
      }

      .content_wrapper {
        @include flex(center, center, column);

        gap: ptr(16);

        .content {
          @include flex(center, center, column);

          gap: ptr(16);
          color: $blue-100;
          text-align: center;

          .title_wrapper {
            @include flex(center, center, column);

            gap: ptr(8);
          }

          .desc_wrapper {
            @include flex(center, center, column);

            gap: ptr(8);

            .description {
              line-height: ptr(24) !important;
            }
          }
        }

        & div.link {
          @include flex(center, center);

          padding: ptr(12) ptr(20);
          border-radius: ptr(8);
          background-color: $blue-100;
          color: $white;
          gap: ptr(8);

          .arrow_button {
            width: ptr(16);
            height: ptr(16);
            color: $white;
          }
        }
      }
    }

    .creator_wrapper {
      width: 100%;
      padding: ptr(120) 0;
      color: $blue-100;
      text-align: center;
      gap: ptr(32);
      @include flex(center, center, column);

      .slide {
        .swiper {
          width: auto;
          max-width: ptr(327);
          height: auto;
          max-height: ptr(360);

          img {
            width: auto;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }
      }

      .pagination {
        @include flex(center, center);

        gap: ptr(12);
        transition: color 200ms ease;
        color: $blue-100;

        .pages {
          @include flex(flex-start, center);

          flex-shrink: 0;
          width: ptr(48);
          height: ptr(1);
          background-color: $dim-10;

          .page {
            height: 100%;
            transition:
              width 1s,
              background 200ms ease;
            background-color: $blue-100;
          }
        }

        & > span {
          min-width: ptr(15);
        }
      }
    }
  }
}
