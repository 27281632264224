@import "base";
@import "../../../shared/style/base";

.page {
  @include pc {
    margin-top: $default-header-pc_height;
  }
  @include mobile {
    margin-top: $default-header-mo-height;
  }
}

.body {
  @include pc {
    margin-top: ptr(24);
  }
  @include mobile {
  }

  .product_cards_container {
    margin-top: ptr(24);
  }

  .pagination_container {
    display: flex;
    justify-content: center;
  }
}
