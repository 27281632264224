@import "base";
.alert {
  @include flex(center, stretch, column);

  gap: ptr(32);
  width: ptr(311);
  padding: ptr(20) ptr(8) ptr(8);

  .text {
    @include flex(center, stretch, column);

    gap: ptr(12);
    text-align: center;
  }

  .message {
    color: $gray-80;
  }

  .button {
    @include flex(space-between, center);

    gap: ptr(12);
  }
}

.test {
  @include flex(center, stretch, column);

  gap: ptr(24);
  max-width: ptr(240);
}
