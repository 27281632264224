@import "base";
.container {
  width: 100%;
  gap: ptr(16);

  @include flex(center, flex-start, column);

  html[is_mobile="false"] & {
    max-width: $pc-layout-body-max-width;
    gap: ptr(24);
  }
}

.curation_list {
  display: flex;
  width: 100%;
  padding: 0 ptr(16);
  gap: ptr(16);

  html[is_mobile="true"] & {
    overflow-x: auto;

    scroll-padding-left: ptr(16);
    scroll-snap-type: x mandatory;

    @include hiddenScroll;
  }

  html[is_mobile="false"] & {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding: 0 ptr(32);
    gap: ptr(24);
  }

  .curation_item {
    display: block;
    scroll-snap-align: start;

    html[is_mobile="true"] & {
      position: relative;
      flex-shrink: 0;
      width: ptr(240);
    }
  }
}
