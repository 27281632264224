@import "base";
html[is_mobile="false"] .dropdown {
  display: inline-flex;
  position: relative;

  $icon_size: ptr(12);
  $btn_padding_vertical: ptr(12);
  $option_padding_vertical: ptr(16);

  .name {
    position: relative;
  }

  .virtual_name {
    flex-shrink: 0;
    opacity: 0;
  }

  .floating_name {
    position: absolute;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.center {
      right: 0;
      left: 0;
    }
  }

  .option_name {
    display: flex;
    position: relative;
    flex-shrink: 0;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: ptr(7) $btn_padding_vertical;
    border: 1px solid $black;
    border-radius: ptr(8);
    background: $white;
    gap: ptr(4);

    .icon {
      flex-shrink: 0;
      width: $icon_size;
      height: $icon_size;
      line-height: 0;
    }
  }

  .option_container {
    display: none;
    position: absolute;
    z-index: 10;
    flex-direction: column;
    align-items: flex-end;
    min-width: ptr(128);
    padding: ptr(4) ptr(0);
    border: 1px solid $black;
    border-radius: ptr(8);
    background: $bright-95;

    @include blur60;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.top {
      top: 0;
      transform: translateY(-100%) translateY(-#{ptr(12)});
    }

    &.bottom {
      bottom: 0;
      transform: translateY(100%) translateY(#{ptr(12)});
    }

    &.fit_width {
      min-width: unset;
    }

    &.with_margin {
      left: calc(-1 * $option_padding_vertical);
    }
  }

  .option {
    display: flex;
    position: relative;
    align-items: flex-start;
    align-self: stretch;
    padding: ptr(8) $option_padding_vertical;
    color: $gray-80;
    cursor: pointer;
    gap: ptr(10);

    &:hover {
      color: $black;
    }

    &.selected {
      color: $black;
    }
  }

  &.is_opened {
    .button .icon {
      transform: rotate(180deg);
    }

    .option_container {
      display: flex;
    }
  }

  &.without_container .button {
    padding: 0;
    border: 0;
  }
}

html[is_mobile="true"] .dropdown {
  display: inline-flex;
  position: relative;

  $icon_size: ptr(12);
  $btn_padding_vertical: ptr(10);
  $option_padding_vertical: ptr(16);

  .name {
    position: relative;
  }

  .virtual_name {
    flex-shrink: 0;
    opacity: 0;
  }

  .floating_name {
    position: absolute;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.center {
      right: 0;
      left: 0;
    }
  }

  .option_name {
    display: flex;
    position: relative;
    flex-shrink: 0;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: ptr(6) $btn_padding_vertical;
    border: 1px solid $black;
    border-radius: ptr(8);
    background: $white;
    gap: ptr(4);

    .icon {
      flex-shrink: 0;
      width: $icon_size;
      height: $icon_size;
      line-height: 0;
    }
  }

  .option_container {
    display: none;
    position: absolute;
    z-index: 10;
    flex-direction: column;
    align-items: flex-end;
    min-width: ptr(128);
    padding: ptr(4) ptr(0);
    border: 1px solid $black;
    border-radius: ptr(8);
    background: $bright-95;

    @include blur60;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.top {
      top: 0;
      transform: translateY(-100%) translateY(-#{ptr(12)});
    }

    &.bottom {
      bottom: 0;
      transform: translateY(100%) translateY(#{ptr(12)});
    }

    &.fit_width {
      min-width: unset;
    }

    &.with_margin {
      left: calc(-1 * $option_padding_vertical);
    }
  }

  .option {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    padding: ptr(8) $option_padding_vertical;
    color: $gray-80;
    cursor: pointer;
    gap: ptr(10);

    &:hover {
      color: $black;
    }

    &.selected {
      color: $black;
    }
  }

  &.is_opened {
    .button .icon {
      transform: rotate(180deg);
    }

    .option_container {
      display: flex;
    }
  }

  &.without_container .button {
    padding: 0;
    border: 0;
  }
}
