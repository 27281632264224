@import "base";
html[is_mobile="false"] .page {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ptr(40);

  :global {
    .ButtonClose {
      display: none !important;
    }
  }

  .header {
    @include flex(center, center);

    gap: ptr(8);
  }

  .container {
    width: 100%;
    @include flex(space-between, stretch);

    gap: ptr(24);

    .form_content {
      width: 50%;
      padding: ptr(32);
      border: ptr(1) solid $gray-5;
      border-radius: ptr(8);
      gap: ptr(32);
      @include flex(flex-start, flex-start, column);

      .exposure_type {
        width: 100%;
        @include flex(flex-start, flex-start, column);

        gap: ptr(8);

        .exposure_buttons {
          @include flex(flex-start, center);

          gap: ptr(24);
        }
      }

      .form_row_wrapper {
        width: 100%;
        @include flex(space-between, center);

        gap: ptr(8);
      }
    }

    .preview {
      width: 50%;
      padding: ptr(32);
      border-radius: ptr(8);
      background: $gray-5;

      .preview_title {
        margin-bottom: ptr(24);
      }
    }
  }

  .bottom_buttons {
    display: none;
  }
}

html[is_mobile="true"] .page {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ptr(32);

  :global {
    .ButtonClose {
      display: none !important;
    }
  }

  .header {
    display: none;
  }

  .container {
    width: 100%;
    @include flex(center, center, column);

    gap: ptr(24);
    padding: 0 ptr(20);

    .form_content {
      width: 100%;
      @include flex(flex-start, flex-start, column);

      gap: ptr(32);

      .exposure_type {
        width: 100%;
        @include flex(flex-start, flex-start, column);

        gap: ptr(8);

        .exposure_buttons {
          @include flex(flex-start, center);

          flex-wrap: wrap;
          gap: ptr(24);
          row-gap: ptr(8);
        }
      }

      .form_row_wrapper {
        width: 100%;
        @include flex(flex-start, flex-start, column);

        gap: ptr(32);
      }
    }

    .preview {
      width: 100%;

      .preview_title {
        margin-bottom: ptr(24);
      }
    }
  }

  .bottom_buttons {
    position: sticky;
    bottom: 0;
    padding: ptr(20);
    background: $white;
    gap: ptr(8);
    @include flex(center, center);
  }
}

.display_none {
  display: none;
}
