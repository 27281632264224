@import "base";
html[is_mobile="true"] .bottom_tab_bar {
  z-index: 10;
  width: 100%;
  background-color: $bright-60;
  pointer-events: all;

  @include blur60;

  .tab_container {
    display: flex;
    height: 62px;
    padding: 12px 16px 8px;
    column-gap: 4px;
  }

  .tab {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    row-gap: 4px;
  }

  .tab.selected {
    color: $blue;
  }

  .icon {
    width: 24px;
    height: 24px;
  }
}
