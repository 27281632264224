@import "base";
.breadcrumbs {
  .steps {
    @include flex(flex-start, center);

    gap: ptr(8);
    color: $gray-50;

    &.black {
      .step:last-child {
        color: $black;
        font-weight: 700;
      }
    }

    &.white {
      .step:last-child {
        color: $white;
        font-weight: 700;
      }
    }
  }

  .arrow {
    display: inline-flex;
    align-items: center;
    width: ptr(14);
  }
}
