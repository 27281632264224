@import "base";
.creator_raking_large_wrapper {
  gap: ptr(32);
  @include flex(flex-start, stretch, column);

  &.empty_products {
    gap: ptr(16);
  }

  // mobile
  html[is_mobile="true"] & {
    gap: ptr(16);
  }
}

.creator_ranking_large {
  position: relative;
  height: ptr(400);
  padding: ptr(32);
  overflow: hidden;
  border-radius: ptr(24);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @include flex(center, center);

  .backdrop {
    position: absolute;
    z-index: 0;
    border-radius: ptr(24);
    background: $dim-60;
    inset: 0;
    @include blur60;
  }

  .thumbnail {
    position: relative;
    z-index: 1;
    width: ptr(369);
    max-width: 100%;

    //height: ptr(272);
    mask-image: url("//s3.marpple.co/files/u_218933/2024/5/original/4dbeb505a99f381b35350b029c408df5bfdbe3f61.png");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: $gray-20;
    }
  }

  .container {
    position: absolute;
    inset: 0;
    padding: ptr(32);
    color: $white;
    @include flex(space-between, flex-start, column);
  }

  .title {
    gap: ptr(8);
    text-transform: uppercase;
    @include flex(flex-start, flex-start, column);
  }

  .top1 {
    gap: ptr(12);
    margin-bottom: ptr(8);

    @include flex(flex-start, center);
    svg {
      width: ptr(8);
    }
  }

  .up_down {
    @include flex(center, center);

    gap: ptr(6);

    .up_down_num {
      margin-left: ptr(6);
    }
  }

  .store_name {
    @include flex(flex-start, center);

    gap: ptr(8);
    margin-bottom: ptr(4);

    svg {
      flex-shrink: 0;
      width: ptr(28);
    }
  }

  .seller_type {
    color: $gray-20;
  }

  // mobile
  html[is_mobile="true"] & {
    height: auto;
    padding: ptr(24);
    border-radius: ptr(16);
    aspect-ratio: 343 / 398;

    .backdrop {
      border-radius: ptr(16);
    }

    .thumbnail {
      z-index: 1;
      width: ptr(237);
    }

    .container {
      padding: ptr(16);
    }

    .meta {
      max-width: 100%;
    }

    .title {
      gap: ptr(8);
      @include flex(flex-start, flex-start, column);
    }

    .up_down {
      gap: ptr(6);
      @include flex(center, center);
    }

    .store_name {
      margin-bottom: ptr(8);

      span {
        @include limitLine(1);
      }

      svg {
        width: ptr(24);
      }
    }
  }
}

.top_ranker_products {
  &:not(.empty_products) {
    @include grid(5, ptr(16));

    margin-bottom: ptr(60);
  }

  // mobile
  html[is_mobile="true"] & {
    &:not(.empty_products) {
      margin-right: ptr(-16);
      margin-bottom: ptr(48);
      margin-left: ptr(-16);
      gap: ptr(16);

      @include flex(flex-start, flex-start);

      @include hiddenScroll;
      @include snapScroll(ptr(16));

      > div {
        flex-shrink: 0;
        width: ptr(160);
      }
    }
  }
}
