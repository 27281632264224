@import "base";
button.button_keyword {
  padding: ptr(4) ptr(12);
  border-radius: ptr(8);

  &.dark {
    border: 1px solid $bright-60;
    color: $white;
  }

  &.light {
    border: 1px solid $gray-20;
    color: $black;
  }
}
