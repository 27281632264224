@import "base";
@import "../DashboardTable.module";
@import "../../../../../shared/style/base";

.product_count_container {
  display: flex;
  gap: ptr(8);
  margin-top: ptr(12);

  .goods {
    color: $blue-100;
  }

  .stocked {
    color: $red-80;
  }

  .creator {
    color: $purple-100;
  }

  .digital {
    color: $green-100;
  }

  $td_padding: ptr(12) 0;
  $fixed_column_width: ptr(100);

  .count_table {
    width: 100%;
    border-collapse: collapse;

    thead {
      display: flex;
      height: ptr(48);
      border-radius: $dashboard_header_border_radius;
      background-color: $dashboard_header_color;

      tr {
        display: flex;
        flex: 1;
        gap: ptr(4);
      }

      th {
        flex: 1;
        align-content: center;
        min-width: ptr(40);
        word-break: keep-all;

        &:first-child {
          flex: 0 0 $fixed_column_width;
        }
      }

      td {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: ptr(40);
        padding: ptr(13) 0;
      }
    }

    tbody {
      display: flex;
      flex-direction: column;

      tr {
        display: flex;
        flex: 1;
        gap: ptr(4);
      }

      td {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: $td_padding;

        &:first-child {
          flex: 0 0 $fixed_column_width;
        }
      }
    }

    .count {
      color: inherit !important;
      text-decoration: underline;
    }
  }

  .sold_out_count_table {
    width: ptr(56);
    border-collapse: collapse;

    .count {
      color: inherit !important;
      text-decoration: underline;
      text-decoration-color: transparent;
    }

    .sold_out_column {
      width: 0;
      min-width: ptr(56);
    }

    thead {
      display: flex;
      height: ptr(48);
      border-radius: $dashboard_header_border_radius;
      background-color: $dashboard_header_color;

      tr {
        display: flex;
        flex: 1;
      }

      th {
        flex: 1;
        align-content: center;
      }

      td {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ptr(13) 0;
      }
    }

    tbody {
      display: flex;
      flex-direction: column;

      tr {
        display: flex;
        flex: 1;
      }

      td {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: $td_padding;
      }
    }
  }
}
