@import "base";
.container {
  width: 100%;
  @include flex(center, flex-start, column);

  gap: ptr(24);

  html[is_mobile="false"] & {
    max-width: $pc-layout-body-max-width;
  }
}

.content {
  display: flex;
  width: 100%;
  padding: 0 ptr(16);
  gap: ptr(16);

  html[is_mobile="false"] & {
    padding: 0 ptr(32);
    gap: ptr(32);
  }

  html[is_mobile="true"] & {
    flex-direction: column;
  }

  .creator_list {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    html[is_mobile="false"] & {
      flex-direction: column;
      padding: ptr(10) 0;
      gap: ptr(16);
    }

    html[is_mobile="true"] & {
      gap: ptr(8);
    }
  }

  .creator_button {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 999px;
    cursor: pointer;

    html[is_mobile="false"] & {
      width: ptr(80);
      height: ptr(80);
      padding: ptr(4);

      &[data-active="true"] {
        @include innerBorder($blue, 2px);

        > img {
          opacity: 1;
        }
      }
    }

    html[is_mobile="true"] & {
      width: ptr(62);
      max-width: 96px;
      height: ptr(62);
      padding: ptr(3);

      &[data-active="true"] {
        @include innerBorder($blue, 1px);

        > img {
          opacity: 1;
        }
      }
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 999px;
      opacity: 0.5;
    }
  }

  .live_frame_container {
    flex-shrink: 0;
    overflow: hidden;
    border-radius: ptr(24);

    html[is_mobile="false"] & {
      width: ptr(896);
    }

    html[is_mobile="true"] & {
      width: 100%;
      margin-top: ptr(8);
    }
  }

  .live_frame {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    border-radius: ptr(24);
    background-color: $gray-5;

    > iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      transform: translate3d(-50%, -50%, 0);
      border-radius: ptr(24);
    }
  }

  .live_content_container {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    html[is_mobile="true"] & {
      gap: ptr(24);
    }
  }

  .live_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ptr(24);

    html[is_mobile="true"] & {
      gap: ptr(16);
    }

    .body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: ptr(16);
    }

    .button_label {
      display: inline-flex;
      gap: ptr(8);

      .button_icon {
        width: ptr(20);
        height: ptr(20);
      }
    }
  }

  .product_container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: ptr(24);

    html[is_mobile="false"] & {
      gap: ptr(16);
    }

    .product {
      width: 100%;
    }
  }
}
