@import "base";
html[is_mobile="false"] section.container {
  position: relative;
  @include flex(center, center, column);

  padding: ptr(233) ptr(182.5) ptr(211) ptr(185.5);
  gap: ptr(80);
  background-color: $blue-10;

  .title_wrapper {
    @include flex(center, center, column);

    gap: ptr(16);

    .title {
      text-align: center;
    }

    .description {
      text-align: center;
    }
  }

  .content {
    @include flex(center, center);

    gap: ptr(20);

    .solution_card {
      @include flex(center, center, column);

      width: ptr(242);
      height: ptr(264);
      padding: ptr(48) 0;
      border: ptr(2) solid $white;
      border-radius: ptr(24);
      background: $bright-20;
      gap: ptr(16);

      .solution_image {
        width: ptr(96);
        height: ptr(96);

        & > img {
          width: 100%;
          height: 100%;
        }
      }

      .card_title {
        text-align: center;
      }
    }
  }
}

html[is_mobile="true"] section.container {
  position: relative;
  width: 100%;
  padding: ptr(40) 0;
  background-color: $blue-10;
  gap: ptr(24);
  @include flex(center, center, column);

  .title_wrapper {
    @include flex(center, center, column);

    gap: ptr(12);
    padding-top: ptr(23);

    .title {
      text-align: center;
    }

    .description {
      text-align: center;
    }
  }

  .content {
    padding: ptr(32);
    @include grid(2, ptr(16));

    .solution_card {
      @include flex(center, center, column);

      width: ptr(147.5);
      height: ptr(140);
      padding: ptr(16) 0;
      border: ptr(2) solid $white;
      border-radius: ptr(24);
      background: $bright-20;
      gap: ptr(8);

      .solution_image {
        width: ptr(64);
        height: ptr(64);

        & > img {
          width: 100%;
          height: 100%;
        }
      }

      .card_title {
        text-align: center;
      }
    }
  }
}
