@import "base";
.creator_rating_collapse_list {
  @include flex(flex-start, stretch, column);

  gap: ptr(16);
}

.creator_raking_collapse_item {
  padding: ptr(32);
  border-radius: ptr(24);
  background: $gray-5;

  &.active {
    background: $blue;
    color: $white;

    .up_down svg path {
      fill: $white !important;
    }

    .up_down {
      color: $white !important;
    }

    .arrow {
      transform: rotate(180deg);
    }

    .seller_type {
      color: $white;
    }

    .products {
      display: grid;
    }
  }

  .toggler {
    @include flex(flex-start, center);

    gap: ptr(24);
    cursor: pointer;
  }

  .rank {
    @include flex(flex-start, center);

    gap: ptr(4);
  }

  .rank_num {
    display: block;
    flex-shrink: 0;
    width: ptr(40);
    text-align: center;
  }

  .up_down {
    @include flex(flex-start, center);

    gap: ptr(6);
    width: ptr(38);

    svg {
      width: ptr(8);
    }

    &.up {
      color: $red-80;
    }

    &.down {
      color: $blue;
    }

    &.new {
      color: $blue;
    }

    &.zero {
      color: $gray-50;

      svg {
        width: ptr(12);
      }
    }
  }

  .up_down_num {
    display: block;
    width: ptr(24);
    aspect-ratio: 1 / 1;
  }

  .profile {
    flex-shrink: 0;
    width: ptr(136);
    height: ptr(136);
    overflow: hidden;
    border-radius: 50%;
    background: #fff;

    img {
      @include objectCover;
    }
  }

  .meta {
    @include flex(flex-start, flex-start, column);

    gap: ptr(4);
    margin-right: auto;
  }

  .seller_name {
    @include flex(flex-start, center);

    gap: ptr(4);

    svg {
      flex-shrink: 0;
      width: ptr(24);
    }
  }

  .seller_type {
    color: $gray-50;
  }

  .arrow {
    flex-shrink: 0;
    width: ptr(32);
  }

  .products {
    display: none;
    grid-column: 5;
    grid-template-columns: repeat(5, 1fr);
    margin-top: ptr(32);
    gap: ptr(16);

    &.empty {
      grid-column: 1;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  // 모바일 스타일
  html[is_mobile="true"] & {
    padding: ptr(16) ptr(12);
    border-radius: ptr(16);

    &.active {
      background: $blue;
      color: $white;

      .up_down svg path {
        fill: $white !important;
      }

      .arrow {
        transform: rotate(180deg);
      }

      .seller_type {
        color: $white;
      }

      .products {
        display: grid;
      }
    }

    .toggler {
      @include flex(flex-start, center);

      gap: ptr(8);
    }

    .rank {
      @include flex(flex-start, center, column);

      gap: ptr(4);
    }

    .rank_num {
      width: ptr(32);
    }

    .up_down {
      @include flex(flex-start, center);

      gap: ptr(4);
      width: auto;

      svg {
        width: ptr(6);
      }

      &.zero {
        svg {
          width: ptr(10);
        }
      }
    }

    .up_down_num {
      width: auto;
      aspect-ratio: 1 / 1;
    }

    .profile {
      flex-shrink: 0;
      width: ptr(60);
      height: ptr(60);
      margin-right: ptr(4);
    }

    .meta {
      gap: ptr(0);
      flex: 1;
      overflow: hidden;
    }

    .seller_name {
      @include flex(flex-start, center);

      gap: ptr(4);
      width: 100%;

      span {
        @include lineLimit(1);

        display: block;
      }

      svg {
        width: ptr(20);
      }
    }

    .seller_type {
      color: $gray-50;
    }

    .arrow {
      width: ptr(20);
      margin-right: ptr(4);
    }

    .products {
      display: none;
      grid-column: 4;
      grid-template-columns: repeat(4, 1fr);
      margin-top: ptr(32);
      gap: ptr(8);

      &.empty {
        grid-column: 1;
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .product_thumbnail {
      overflow: hidden;
      border-radius: ptr(8);
    }
  }
}
