@import "base";
@mixin hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.shop_product_category {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 100%;

  $search-height: ptr(60);
  $banner-height: ptr(132);

  &[data-tabbar="true"] {
    padding-bottom: ptr(62);
  }

  .search {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $search-height;
    padding: ptr(10) ptr(16);
    column-gap: ptr(16);

    .search_input {
      width: 100%;
    }

    .close {
      display: inline-block;
      width: ptr(24);
      height: ptr(24);
      cursor: pointer;
    }
  }

  .cate_slider {
    display: flex;
    position: relative;
    width: 100%;
    height: calc(100% - #{$search-height} - #{$banner-height});
  }

  .cate_container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    overscroll-behavior: none;
    @include hide-scrollbar;
  }

  .cate_list_container {
    flex: 0.8;
    background-color: $gray-5;
  }

  .cate_item_container {
    flex: 1;
    padding-bottom: 100%;
  }

  .cate_list_active {
    background-color: $white !important;
  }

  .cate_view {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: ptr(54);
    padding: 0 ptr(16);
    overflow: hidden;
  }
}
