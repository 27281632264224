@import "base";
.creator_view_list_item {
  display: flex;
  position: relative;
  flex-direction: column;

  .thumbnail_container {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: ptr(16);
    overflow: hidden;
    border-radius: ptr(24);

    html[is_mobile="true"] & {
      border-radius: ptr(16);
    }
  }

  .thumbnail {
    aspect-ratio: 3/4;
    width: 100%;
  }

  &.short .thumbnail {
    aspect-ratio: 4/3;
  }

  .overlay {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: $dim-30;
    color: $white;
    inset: 0;
  }

  .numbering {
    position: absolute;
    top: ptr(24);
  }

  .creator_name {
    padding: 0 ptr(16);
    text-align: center;
    text-transform: uppercase;
  }

  .title {
    display: inline-block;
    width: 100%;
    margin-bottom: ptr(4);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sub_title {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .show_info_box {
    margin-top: ptr(16);
  }
}
