@import "base";
@import "../../../../shared/style/base";

html[is_mobile="false"] .header_outer_container {
  position: relative;
  z-index: 5;

  .search_module {
    $_gap: ptr(16);

    position: fixed;
    z-index: 5;
    top: $_gap;
    right: $_gap;
    left: $_gap;
    margin: 0 auto;
  }
}

html[is_mobile="true"] .header_outer_container {
  position: relative;
  z-index: 5;

  .mobile_category_modal {
    /* absolute 였던 이유는 모른다 */
    position: fixed;
    z-index: 5;
    top: 0;
    width: 100%;
    height: 100dvh;
    transform: translateX(-100%);
    transition: transform ease-out 600ms;
    background-color: $white;

    &.on {
      transform: translateX(0%);
    }
  }
}

html[is_mobile="false"] .header {
  position: absolute;
  z-index: 5;
  top: 0;
  width: 100%;

  //overflow: hidden;

  &.fixed {
    position: fixed;
  }

  // 부가적인 부분 구분
  .core {
    width: 100%;
    min-width: $pc-layout-body-min-width;
    height: $default-header-pc_height;
    background-color: $white;
    color: $black;
  }

  .sub_header_container {
    width: 100%;
    min-width: $pc-layout-body-min-width;
  }

  .content {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: $pc-layout-body-max-width;
    margin: 0 auto;
    padding: ptr(16) $marppleshop-layout-padding;
  }

  .left_logo_wrapper {
    display: inline-flex;
    cursor: pointer;

    svg {
      height: 100%;
    }
  }

  .left_part {
    display: flex;
    height: ptr(24);

    //margin: ptr(8) ptr(60) 0 0;
    margin-right: ptr(60);
  }

  .center_part {
  }

  .right_part {
    display: flex;
    margin-left: auto;
  }

  .menu_container {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    column-gap: ptr(36);

    //margin-top: ptr(8);
  }

  .menu {
    color: inherit;

    &.current {
      color: $blue;
    }

    &:hover {
      color: $blue;
    }
  }

  .search_bar_container {
    width: ptr(480);
    &:focus {
      background-color: blue;
    }
  }

  .search_module {
    &.hidden {
      display: none;
    }
  }

  .icons {
    display: flex;
    align-items: center;
    margin-left: ptr(24);
    column-gap: ptr(16);
    color: $black;

    //margin-top: ptr(8);
    .icon {
      display: inline-block;
      width: ptr(24);
      height: ptr(24);
      color: inherit;
    }

    svg {
      width: 100%;
      cursor: pointer;
    }
  }

  &.initial {
    transition: transform 0.3s ease-out;
  }

  &.pinned {
    transform: translateY(0);
  }

  &.unpinned {
    transform: translateY(-#{$default-header-pc_height});
  }

  .core,
  .search_bar_container {
    transition: background-color linear 300ms; // subheader 의 transition 이랑 일치하도록
  }

  .left_logo_wrapper,
  .menu_container,
  .left_logo_wrapper svg,
  .icons {
    transition: color ease 300ms;
  }

  &.instant_bg_change {
    .core,
    .search_bar_container {
      transition: none;
    }

    .left_logo_wrapper,
    .menu_container,
    .left_logo_wrapper svg,
    .icons {
      transition: none;
    }
  }
}

html[is_mobile="false"] .header.transparent {
  .core {
    background-color: transparent;
    color: $white;
  }

  .left_logo_wrapper {
    color: $white;
  }

  .search_bar_container {
    background-color: transparent;
  }

  .menu_container {
    color: $white;
  }

  .left_logo_wrapper svg {
    color: $white;
  }

  .icons {
    color: $white;
  }
}

html[is_mobile="true"] .header {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;

  &.fixed {
    position: fixed;
  }

  .core {
    height: $default-header-mo_height;
    padding: 0 ptr(16);
    background-color: $white;
    color: $black;
  }

  .content {
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
  }

  .left_part {
    display: flex;
    z-index: 1;
    align-items: center;

    &.full {
      flex-grow: 1;
    }
  }

  .back_arrow_btn {
    width: ptr(24);
    height: ptr(24);
    margin-right: ptr(8);

    svg {
      height: 100%;
    }
  }

  .center_part {
    position: absolute;
    z-index: 0;
    left: 50%;
    max-width: 49%;
    overflow: hidden;
    transform: translateX(-50%);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .right_part {
    display: flex;
    z-index: 1;
    align-items: center;
    margin-left: auto;
  }

  .close_btn {
    width: ptr(24);
    height: ptr(24);

    svg {
      height: 100%;
    }
  }

  .left_logo_wrapper {
    display: inline-flex;
    height: ptr(24);

    svg {
      height: 100%;
    }
  }

  .menu_container {
    display: flex;
    align-items: center;
    margin-top: ptr(8);
    column-gap: ptr(36);
  }

  .search_bar_container {
    width: ptr(480);
    margin-left: auto;
  }

  .icons {
    display: flex;
    margin-left: auto;
    column-gap: ptr(16);
    color: $black;

    .icon {
      display: inline-block;
      width: ptr(24);
      height: ptr(24);
      color: inherit;
    }

    .search_icon {
      @extend .icon;
    }

    svg {
      width: 100%;
      cursor: pointer;
    }
  }

  &.initial {
    transition: transform 0.3s ease-out;
  }

  &.pinned {
    transform: translateY(0);
  }

  &.unpinned {
    transform: translateY(-#{$default-header-mo_height});
  }

  .core,
  .search_bar_container {
    transition: background-color linear 300ms;
  }

  .left_logo_wrapper,
  .menu_container,
  .back_arrow_btn,
  .icons {
    transition: color ease 300ms;
  }
}

html[is_mobile="true"] .header.transparent {
  background-color: transparent;

  .core {
    background-color: transparent;
    color: $white;
  }

  .back_arrow_btn {
    color: $white;
  }

  .search_bar_container {
    background-color: transparent;
  }

  .menu_container {
    color: $white;
  }

  .left_logo_wrapper svg {
    color: $white;
  }

  .icons {
    color: $white;
  }
}

html[is_mobile="false"] .header_wrapper {
  position: relative;
  z-index: 5;
  width: 100%;

  &.initial {
    transition: transform 0.3s ease-out;
  }

  &.pinned {
    transform: translateY(0);
  }

  &.unpinned {
    transform: translateY(-#{$default-header-pc_height});
  }
}

html[is_mobile="true"] .header_wrapper {
  position: absolute;
  z-index: 5;
  width: 100%;

  &.is_fixed {
    position: fixed;
  }

  &.initial {
    transition: transform 0.3s ease-out;
  }

  &.pinned {
    transform: translateY(0);
  }

  &.unpinned {
    transform: translateY(-#{$default-header-mo_height});
  }
}

html[is_mobile="false"] .gnb {
  display: block;
  position: absolute;
  z-index: 5;
  top: $default-header-pc_height;
  left: 0;
  width: 100%;
  min-width: $pc-layout-body-min-width;
}
