@import "base";
@import "../../variables";

.container {
  height: $dashboard_item_height1;

  .body_template {
    display: flex;
    flex-direction: column;
  }
}
