@import "base";
.date_period_container {
  @include flex(space-between, center);

  width: 100%;
  height: ptr(40);
  cursor: pointer;

  .duration {
    width: ptr(9);
    color: $gray-50;
  }

  @include pc {
    gap: ptr(16);
  }

  @include mobile {
    gap: ptr(4);
  }

  .input_wrapper {
    position: relative;

    @include pc {
      width: ptr(232);
      height: ptr(40);
    }

    @include mobile {
      width: 100%;
      height: ptr(40);
    }

    .icon {
      position: absolute;
      top: ptr(4);
      right: ptr(8);
      width: ptr(32);
      height: ptr(32);
      border-radius: ptr(2);
      background: $gray-5;
      cursor: pointer;
      @include flex(center, center);

      & > svg {
        width: ptr(20);
        height: ptr(20);
      }
    }
  }

  .start_time_input,
  .end_time_input {
    width: 100%;
    height: 100%;
    padding: ptr(4) ptr(8) ptr(4) ptr(12);
    border: ptr(1) solid $gray-10;
    border-radius: ptr(4);
  }
}
