@import "base";
@import "../../../../shared/style/base";

$dashboard_header_color: $gray-5;
$dashboard_header_border_radius: ptr(8);

.table {
  width: 100%;
  border-spacing: 0;

  &.empty {
    tbody {
      tr:hover {
        background-color: unset;
      }

      tr {
        padding: ptr(60) 0;
        color: $gray-50;
      }
    }
  }

  thead {
    overflow: hidden;
    border-radius: $dashboard_header_border_radius;
    background-color: $dashboard_header_color;
    color: $black;

    th {
      padding: ptr(12) 0;

      &:first-child {
        border-start-start-radius: $dashboard_header_border_radius;
        border-end-start-radius: $dashboard_header_border_radius;
      }

      &:last-child {
        border-start-end-radius: $dashboard_header_border_radius;
        border-end-end-radius: $dashboard_header_border_radius;
      }
    }
  }

  tbody {
    text-align: center;
  }
}
