@import "base";
.popup_item {
  position: relative;
  width: 100%;
  cursor: pointer;

  &.sortable_drag_ghost {
    visibility: hidden;
  }

  &.sortable_drag_on {
    .popup_delete {
      display: none;
    }

    .popup_date {
      display: none;
    }
  }

  .popup_delete {
    position: absolute;
    top: ptr(-11);
    right: ptr(-11);
  }

  .popup_image_box {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: ptr(8);
    background: #f0f0f0;
    aspect-ratio: 382 / 509;

    .popup_image {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .popup_title_box {
    width: 100%;
    @include flex(flex-end, flex-start, column);

    margin-top: ptr(16);
    gap: ptr(16);

    .popup_title {
      width: 100%;
      @include lineLimit(1);
      word-break: break-word;
      white-space: normal;
    }

    .popup_date {
      color: $gray-50;
    }
  }

  .popup_statuses {
    @include flex(center, center);

    position: absolute;
    top: ptr(8);
    right: ptr(8);

    .popup_status {
      @include flex(center, center);

      padding: ptr(2) ptr(6);
      border: ptr(1) solid $green-100;
      border-radius: ptr(4);
      background: $white;
      color: $green-100;

      &::before {
        content: "";
        display: block;
        width: ptr(14);
        height: ptr(14);
        margin-right: ptr(4);
        background-image: url("//s3.marpple.co/files/u_1396787/2020/11/original/0cdf2841d33902b37040f20e3bcc57cadb2968441.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: ptr(14);
      }

      &[data-status="private"] {
        border: ptr(1) solid $gray-50;
        color: $gray-50;

        &::before {
          background-image: url("//s3.marpple.co/files/u_1396787/2020/11/original/c4278bcd4c48c7b216f7af22a13e3a7416f71bf31.png");
        }
      }

      &[data-status="reservation"] {
        border: ptr(1) solid $purple-100;
        background-color: $purple-10;
        color: $purple-100;

        &::before {
          background-image: url("//s3.marpple.co/files/u_2283830/2024/5/original/e5cd762dee880fb238876a0831a2e6de438cf1781.png");
        }
      }
    }
  }
}

.popup_add_item {
  @include flex(center, center, column);

  min-height: ptr(382);
  border: ptr(1) dashed $gray-20;
  border-radius: ptr(6);
  gap: ptr(16);

  .popup_icon_box {
    @include flex(center, center, column);

    gap: ptr(16);

    .popup_text {
      color: $gray-80;
      text-align: center;
      white-space: pre-line;
    }

    .popup_add_icon {
      width: ptr(60);
      height: ptr(60);
      border-radius: 50%;
      background: #3c89f9
        url("//s3.marpple.co/files/u_1093672/2020/9/original/01a700139bed7f43db3a597323abce7bfa19a8d51.png")
        no-repeat center;
      background-size: 30px;
    }
  }
}

html[is_mobile="true"] .popup_item {
  .popup_title_box {
    margin-top: ptr(8);
    gap: ptr(4);

    .popup_date {
      @include flex(flex-start, flex-start, column);

      gap: ptr(4);

      .divider {
        display: none;
      }
    }
  }
}

html[is_mobile="true"] .popup_add_item {
  min-height: ptr(283);
}
