@import "base";
@import "../../../../shared/style/sub/mixin";

@mixin show-bold-text() {
  & > .text {
    opacity: 0;
  }

  & > .bold {
    opacity: 1;
  }
}

@mixin show-normal-text() {
  & > .text {
    opacity: 1;
  }

  & > .bold {
    opacity: 0;
  }
}

@mixin on-hover() {
  &:hover {
    border: 1px solid $gray-20;
    background-color: $blue-10;
    color: $black;
  }
}

.button_filter {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: ptr(8) ptr(16);
  transition: all 200ms ease-out;
  border-radius: ptr(8);
  color: $black !important;

  .text {
    position: absolute;
    opacity: 1;
  }

  .bold {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
  }

  &:not(.active) {
    border: 1px solid $gray-20;
    @include show-normal-text;
    @include pc {
      @include on-hover;
    }
  }

  &.active {
    border: 1px solid $blue;
    background-color: $white;
    color: $blue !important;
    @include show-bold-text;
    @include pc {
      @include on-hover;
    }
  }
}
