@import "base";
.empty_list {
  @include flex(flex-start, center, column);

  width: 100%;
  padding: ptr(32) 0;
  border-radius: ptr(16);
  text-align: center;
  gap: ptr(32);

  h4 {
    margin-bottom: ptr(8);
  }

  &.white {
    background: $bright-5;

    h4 {
      color: $white;
    }

    p {
      color: $blue-10;
    }
  }

  &.black {
    background: $white;

    h4 {
      color: $black;
    }

    p {
      color: $gray-50;
    }
  }
}
