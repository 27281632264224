@import "base";
.popup_store_detail_page {
  // mobile style
  html[is_mobile="true"] & {
    margin-top: $default-header-mo_height;
  }
}

.popup_store_detail {
  @include flex(flex-start, stretch, column);

  margin-bottom: ptr(120);

  html[is_mobile="true"] & {
    margin-bottom: ptr(60);
  }
}

.cover_image {
  position: relative;
  margin: 0;
  aspect-ratio: 1920 / 1000;

  img {
    width: 100%;
  }

  .breadcrumbs {
    position: absolute;
    top: $default-header-pc_height;
    left: 50%;
    width: ptr(1440);
    padding: ptr(4) ptr(32);
    transform: translateX(-50%);
  }

  // mobile style
  html[is_mobile="true"] & {
    aspect-ratio: 375 / 200;

    .breadcrumbs {
      display: none;
    }
  }
}

.summary {
  @include flex(flex-start, stretch, column);

  max-width: ptr(800 + 160);
  margin: 0 auto;
  padding: ptr(80);
  text-align: center;
  gap: ptr(32);

  .title {
    margin-bottom: ptr(16);
  }

  .event_date {
    color: $gray-80;
  }

  .progress {
    padding-top: ptr(32);
  }

  html[is_mobile="true"] & {
    max-width: 100%;
    margin: 0;
    padding: ptr(60) ptr(16);
    gap: ptr(32);

    .event_date {
      color: $gray-50;
    }
  }
}

.content {
  :global {
    h2,
    h3,
    p {
      max-width: ptr(800);
      margin: 0 auto;
      text-align: center;
    }

    h2 {
      font-family: Pretendard;
      font-size: ptr(48);
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.2px;
      line-height: 1.25;
    }

    h3 {
      font-family: Pretendard;
      font-size: ptr(24);
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.2px;
      line-height: 1.41;
    }

    p {
      font-family: Pretendard;
      font-size: ptr(24);
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.2px;
      line-height: 1.41;
    }

    .embed {
      display: none;
    }

    .embed_show.embed-one-row {
      @include flex(center, center);
    }

    .align-center {
      text-align: center;
    }

    .popup-store-images-in-editor {
      .resize_wrapper {
        @include flex(space-between, center);

        gap: 0;

        img {
          width: 100%;
        }
      }
    }

    .img_container {
      position: relative;

      .resize_wrapper + p.caption {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: ptr(8) ptr(14);
        background: $black;
        color: $white;
        font-size: ptr(14);
      }
    }

    .caption_img {
      position: relative;
      width: 100%;
      font-size: 0;

      .caption {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: ptr(8) ptr(14);
        background: $black;
        color: $white;
        font-size: ptr(14);
      }
    }

    .editor-button-container {
      @include flex(center, center);
    }

    .FeedProductListPreviewView {
      padding: ptr(80) 0;
      background: $black;

      .popup-store-products-preview {
        &__text {
          color: $white;
          text-align: center;
        }

        &__title {
          margin-bottom: ptr(24);
          color: $white;
          font-family: "Bebas Neue Pro";
          font-size: ptr(40);
          font-style: normal;
          font-weight: 700;
          line-height: 0.9;
          text-transform: uppercase;
        }

        &__description {
          margin-bottom: ptr(48);
          color: $white;
          font-family: Pretendard;
          font-size: ptr(24);
          font-style: normal;
          font-weight: 700;
          line-height: 1.41;
          text-transform: uppercase;
        }

        &__products {
          @include flex(center, center);

          width: 100%;
          max-width: ptr(1440);
          margin: 0 auto;
          overflow-x: hidden;
        }

        &__list {
          position: relative;
          width: auto;

          //max-width: ptr(1376);
          padding: 0 0 ptr(48);
          overflow: hidden;

          .swiper-wrapper {
            @include flex(flex-start, flex-start);

            flex-wrap: nowrap;
          }
        }

        &__item {
          flex-shrink: 0;
          width: ptr(326);
        }

        &__item:not(:last-child) {
          margin-right: ptr(24);
        }
      }

      .swiper-pagination-progressbar {
        position: absolute;
        inset: auto 0 0;
        height: ptr(4);
        background-color: rgba($white, 0.2);

        .swiper-pagination-progressbar-fill {
          background: $white;
        }
      }
    }

    html[is_mobile="true"] & {
      h2,
      h3,
      p {
        max-width: none;
        margin: 0;
        padding: 0 ptr(16);
      }

      h2 {
        font-family: Pretendard;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 39px; /* 139.286% */
      }

      h3 {
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 28px; /* 157.143% */
      }

      p {
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.2px;
        line-height: 28px; /* 140% */
      }

      .img_container {
        .resize_wrapper + p.caption {
          padding: ptr(4) ptr(12);
          font-size: ptr(10);
          line-height: 1.4;
        }
      }

      .caption_img {
        .caption {
          padding: ptr(4) ptr(12);
          font-size: ptr(10);
          line-height: 1.4;
        }
      }

      .FeedProductListPreviewView {
        padding: ptr(60) 0;

        .popup-store-products-preview {
          &__description {
            font-size: ptr(16);
            line-height: 1.5;
          }

          &__list {
            padding-bottom: 0;

            .swiper-wrapper {
              display: inline-flex;
              flex-wrap: nowrap;
              max-width: none;
              padding: 0 ptr(16);
              gap: ptr(16);
              @include flex(flex-start, flex-start);
              @include snapScroll(ptr(16));

              &::-webkit-scrollbar {
                display: none;
              }
            }
          }

          &__item {
            flex-shrink: 0;
            width: ptr(160);
            margin-right: 0;
          }
        }
      }
    }
  }
}

.related_products {
  @include flex(flex-start, stretch, column);

  max-width: ptr(1440);
  margin: 0 auto;
  padding: ptr(80) ptr(32) 0;
  gap: ptr(32);

  .related_title {
    margin-bottom: ptr(16);
    text-align: center;
    text-transform: uppercase;
  }

  .related_description {
    text-align: center;
  }

  .product_list {
    @include grid(5, ptr(48) ptr(24));
  }

  &:has(.product_list:empty) {
    display: none;
  }

  html[is_mobile="true"] & {
    max-width: none;
    margin: 0;
    padding: ptr(60) ptr(16) 0;
    gap: ptr(24);

    .product_list {
      @include grid(2, ptr(32) ptr(16));
    }
  }
}
