@import "base";
@import "../../../../shared/style/sub/mixin";
@import "../ProductBadge/variables";

.badge_container {
  display: flex;
  z-index: 1;
  flex-wrap: wrap;
  overflow: hidden;
  gap: ptr(2);

  &.hidden {
    display: none;
  }
}
