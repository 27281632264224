@import "base";
.button_container {
  display: flex;
  flex-direction: column;
  gap: ptr(8);

  .button {
    flex: 0 1 0;
  }
}
