@import "base";
html[is_mobile="false"] .visual_card {
  position: relative;
  overflow: hidden;
  border-radius: ptr(24);
  color: $white;
  aspect-ratio: 1376 / 546;
  isolation: isolate;

  &:hover {
    .image img {
      transform: scale(1.1);
    }

    .title {
      opacity: 0;
    }

    .remain {
      opacity: 0;
    }
  }

  &:not(.upcoming):hover {
    .wrapper {
      opacity: 0;
    }
  }

  &.upcoming {
    .wrapper {
      background: $dim-60;
      @include blur60;
    }
  }

  .image {
    position: absolute;
    inset: 0;

    img {
      @include objectCover;

      transform: scale(1);
      transition: transform 0.5s;
    }
  }

  .wrapper {
    @include flex(space-between, stretch, column);

    position: relative;
    height: 100%;
    padding: ptr(40);
    transition: opacity 0.5s;
    opacity: 1;
    background: $dim-60;
    color: inherit;
  }

  .meta {
    @include flex(flex-start, flex-start, column);

    gap: ptr(24);
  }

  .badges {
    @include flex(flex-start, center);

    gap: ptr(8);

    .badge {
      padding: ptr(8) ptr(12);
      border-radius: ptr(8);
      background: $blue;
      color: $white;
      text-transform: uppercase;
    }
  }

  .title {
    transition: opacity 0.5s;

    //white-space: pre-line;
    opacity: 1;
  }

  .status {
    @include flex(flex-start, stretch, column);

    gap: ptr(32);
  }

  .status_text {
    @include flex(space-between, center);

    .current_value {
      @include flex(flex-start, center);

      gap: ptr(16);
    }
  }

  .status_bar {
    height: ptr(8);
    overflow: hidden;
    background: $white;

    .progress {
      width: 100%;
      height: 100%;
      background: $blue;
    }
  }

  .upcoming {
    @include flex(space-between, flex-end);
  }

  .remain {
    @include flex(flex-start, flex-start, column);

    transition: opacity 0.5s;
    opacity: 1;
    gap: ptr(16);

    .date_time {
      @include flex(flex-start, center);

      gap: ptr(16);
    }

    .date {
      @include flex(flex-start, center);

      gap: ptr(4);
    }

    .rate {
      text-transform: uppercase;
    }
  }

  .subscribe {
  }

  .subscribe_btn {
    @include flex(center, center);

    gap: ptr(8);
    padding: ptr(20) ptr(32);
    border-radius: ptr(16);
    background: $blue;

    svg {
      width: ptr(40);
    }
  }
}

html[is_mobile="true"] .visual_card {
  position: relative;
  overflow: hidden;
  border-radius: ptr(16);
  color: $white;
  isolation: isolate;
  aspect-ratio: 343 / 457;

  &.upcoming {
    .wrapper {
      border-radius: 0;
      background: $dim-60;
      @include blur60;
    }
  }

  .image {
    position: absolute;
    inset: 0;

    img {
      @include objectCover;

      transition: none;
    }
  }

  .wrapper {
    @include flex(flex-end, stretch, column);

    position: relative;
    height: 100%;
    padding: ptr(16);
    transition: none;
    opacity: 1;
    background: linear-gradient(180deg, rgb(20 20 20 / 0%) 0%, rgb(20 20 20 / 60%) 100%);
  }

  .meta {
    @include flex(flex-start, flex-start, column);

    gap: ptr(12);
    transition: opacity 0.5s;
  }

  .badges {
    @include flex(flex-start, center);

    gap: ptr(3) ptr(8);

    .badge {
      padding: ptr(3) ptr(8);
      border-radius: ptr(8);
      background: $blue;
      color: $white;
      text-transform: uppercase;
    }
  }

  .title {
    transition: opacity 0.5s;
    opacity: 1;
    white-space: normal;
    @include lineLimit(3);
  }

  .description {
    white-space: pre-line;
  }

  .see_more {
    color: $bright-70;
  }

  .status {
    @include flex(flex-start, stretch, column);

    gap: ptr(32);
    transition: opacity 0.5s;
  }

  .status_text {
    @include flex(space-between, center);

    .current_value {
      @include flex(flex-start, center);

      gap: ptr(16);
    }
  }

  .status_bar {
    height: ptr(8);
    overflow: hidden;
    background: $white;

    .progress {
      width: 100%;
      height: 100%;
      background: $blue;
    }
  }

  .upcoming {
    @include flex(flex-start, stretch, column);

    flex-direction: column-reverse;
    gap: ptr(16);
    transition: opacity 0.5s;
  }

  .remain {
    @include flex(flex-start, flex-start, column);

    gap: ptr(8);
    transition: none;

    .date_time {
      @include flex(flex-start, center);

      gap: ptr(16);
    }

    .date {
      @include flex(flex-start, center);

      gap: ptr(4);
    }

    .rate {
      text-transform: uppercase;
    }
  }

  .subscribe {
  }

  .subscribe_btn {
    @include flex(center, center);

    gap: 0;
    padding: ptr(12);
    border-radius: 50%;

    span {
      display: none;
    }

    svg {
      width: ptr(24);
    }
  }
}
