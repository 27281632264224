@import "base";
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ptr(40);
}

.container {
  width: 100%;
  @include flex(space-between, stretch);

  gap: ptr(24);

  .form_content {
    width: 50%;
    padding: ptr(32);
    border: ptr(1) solid $gray-5;
    border-radius: ptr(8);
    gap: ptr(32);
    @include flex(flex-start, flex-start, column);

    .form_row_wrapper {
      width: 100%;
      @include flex("space-between", "center");

      gap: ptr(8);
    }
  }

  .preview {
    width: 50%;
    padding: ptr(32);
    border-radius: ptr(8);
    background: $gray-5;

    .preview_title {
      margin-bottom: ptr(24);
    }
  }

  :global {
    .ButtonClose {
      display: none !important;
    }
  }
}
