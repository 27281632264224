@import "base";
.container {
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: space-between;
  padding: ptr(9) ptr(12);
  border: 1px solid $gray-10;
  border-radius: ptr(4);
  background-color: $white;
  gap: ptr(8);

  &:focus {
    border: 1px solid $blue;
  }

  .icon {
    display: flex;
    align-self: flex-start;
    justify-content: flex-start;
    width: ptr(18);
    padding-top: ptr(2);
    object-fit: contain;
  }

  .input {
    flex: 1 1 0;
    width: 100%;
    padding: 0;
    overflow-y: hidden;
    border: none !important;
    outline: none;
    resize: none;

    &::placeholder {
      color: $gray-50;
    }
  }

  .information {
    display: flex;
    align-items: center;
    align-self: flex-end;
    color: $gray-50;
    gap: ptr(2);
  }

  .reset {
    cursor: pointer;
  }
}
