@import "base";
textarea.input,
input.input {
  flex: 1 1 0;
  width: 100%;
  border: none !important;
  color: $black;
  resize: none;
  caret-color: $blue;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: $gray-50;
  }

  &[disabled] {
    color: $gray-10;
  }

  &[data-readonly="true"] {
    color: $black;
  }
}

.input_container {
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: ptr(9) ptr(12);
  border-radius: ptr(4);
  gap: ptr(8);
  @include innerBorder($gray-10);

  &[data-line="true"] {
    align-items: flex-start;

    .input_controller {
      align-self: flex-end;
    }
  }

  &[disabled] {
    background-color: $gray-5;
  }

  &[data-error="true"] {
    @include innerBorder($red-80);

    .input {
      caret-color: $red-80;
    }
  }

  &[data-error="false"] {
    &:focus-within {
      @include innerBorder($blue);
    }
  }
}

.input_controller {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ptr(8);

  .maxlength {
    display: inline-flex;
    gap: ptr(2);
    color: $gray-50;
  }
}
