@import "base";
@import "../../../../shared/style/base";

.selector_container {
  color: $gray-50;
}

.selector_btn {
  @include pc {
    height: ptr(32);
    border: 1px solid $gray-50 !important;
  }
  @include mobile {
    height: ptr(32);
    padding: 0 !important;
  }
}
