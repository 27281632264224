@import "base";
@import "../variables";
@import "../../../../shared/style/base";
@import "../../../components/cells/Layout/variables";

.order_overview {
  @include mobile {
    margin: 0 $marppleshop-studio-layout-padding-mo;
    border-bottom: 1px solid $dashboard_border_color;

    &:last-child {
      border-bottom: none;
    }
  }

  .header {
    display: inline-flex;
    align-items: center;
    padding: ptr(16) 0;

    .date {
      margin-left: ptr(12);
      color: $gray-50;
    }
  }

  .statuses_container {
    @include mobile {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: ptr(8);
    }
  }
}
