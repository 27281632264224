@import "base";
html[is_mobile="false"] {
  .body {
    display: flex;
    flex-direction: column;
    margin-top: $default-header-pc_height;
    margin-bottom: ptr(120);
    row-gap: ptr(24);
  }

  .title_section {
    display: flex;
    justify-content: space-between;
  }

  .checkbox_wrap {
    flex-shrink: 0;
    align-self: flex-end;
  }

  .tabs {
  }

  .banner {
    position: relative;
    width: 100%;
    height: ptr(200);
    border-radius: ptr(24);
    background-color: $blue-100;
    @include flex(center, center);
  }

  .banner_img {
    height: 100%;
  }

  .banner_text_wrap {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    flex-direction: column;
    transform: translateX(-50%) translateY(-50%);
    color: $white;
    row-gap: ptr(4);
    @include flex(center, center);
  }

  .products {
  }
}

html[is_mobile="true"] {
  .body {
    margin-top: $default-header-mo_height;
    padding-top: ptr(24);
  }

  .title_section {
    display: flex;
    justify-content: space-between;
    margin: 0 ptr(16) ptr(16);
  }

  .checkbox_wrap {
    flex-shrink: 0;
    align-self: flex-end;
  }

  .title {
    width: auto;
  }

  .tabs {
    margin-bottom: ptr(16);

    .padding_0_16 {
      padding: 0 ptr(16);
    }
  }

  .banner {
    position: relative;
    height: ptr(100);
    margin: 0 ptr(16) ptr(24);
    overflow: hidden;
    border-radius: ptr(16);
    background-color: $blue-100;
    @include flex(center, center);
  }

  .banner_img {
    height: 100%;
  }

  .banner_text_wrap {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    flex-direction: column;
    width: 100%;
    transform: translateX(-50%) translateY(-50%);
    color: $white;
    row-gap: ptr(4);
    @include flex(center, center);
  }

  .products {
    padding: 0 ptr(16);
  }
}
