@import "base";
@import "../../../style/base";

button.action_button,
a.action_button {
  display: inline-flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition:
    background-color 200ms ease-out,
    box-shadow 200ms ease-out;

  &[data-fit="true"] {
    flex: unset;
    width: fit-content;
  }

  html[data-marketplace_name="NESS"] & {
    flex: unset;
    width: unset;
  }

  &.size_regular {
    height: ptr(40);
    padding: ptr(9) ptr(16);
    gap: ptr(4);
    border-radius: ptr(8);
    @include blur40;

    html[data-marketplace_name="NESS"] & {
      height: unset;
    }
  }

  &.size_medium {
    height: ptr(48);
    padding: ptr(9) ptr(16);
    gap: ptr(4);
    border-radius: ptr(8);
    @include blur40;

    html[data-marketplace_name="NESS"] & {
      height: unset;
    }
  }

  &.size_large {
    height: ptr(56);
    padding: ptr(16) ptr(24);
    gap: ptr(8);
    border-radius: ptr(12);

    html[is_mobile="true"] & {
      height: ptr(52);
      gap: ptr(4);
      border-radius: ptr(8);
      @include blur40;
    }

    html[data-marketplace_name="NESS"] & {
      height: unset;
    }
  }

  &.type_white {
    background-color: $blue-10;
    color: $blue;

    &:hover {
      background-color: $blue;
      color: $white;
    }
  }

  &.type_blue {
    background-color: $blue;
    color: $white;

    &:hover {
      background-color: $blue-10;
      color: $blue;
    }
  }

  &.type_line {
    @include innerBorder($gray-20);

    background-color: $white;
    color: $gray-80;

    &:hover {
      @include innerBorder($blue);

      color: $blue;
    }
  }

  &.type_disabled {
    background-color: $gray-5;
    color: $gray-50;
    cursor: default;
  }
}
