@import "base";
html[is_mobile="false"] {
  .floating_box {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 24px 24px auto;
    padding: 6px;
    overflow: hidden;
    transition: opacity 300ms ease-out;
    border-radius: 12px;
    background-color: $dim-10;
    pointer-events: all;
    gap: 6px;

    /* Blur 40 */
    backdrop-filter: blur(20px);
  }

  .transparent {
    opacity: 0;
  }

  .hidden {
    display: none;
  }
}

html[is_mobile="true"] {
  .floating_box {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 16px 16px auto;
    padding: 6px;
    transition: opacity 300ms ease-out;
    border-radius: 12px;
    background-color: $dim-10;
    pointer-events: all;
    gap: 6px;

    /* Blur 40 */
    backdrop-filter: blur(20px);
  }

  .transparent {
    opacity: 0;
  }

  .hidden {
    display: none;
  }
}
