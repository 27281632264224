@import "base";
html[is_mobile="false"] .container {
  @include flex(flex-start, flex-start);

  background-color: $gray-1;

  .sidebar {
    @include flex(flex-start, flex-start, column);

    flex: 1;
    padding: ptr(48) ptr(32);
    gap: ptr(32);

    .category {
      width: 100%;
      @include flex(flex-start, flex-start, column);

      gap: ptr(4);

      .cate_title {
        color: #141414;
      }

      .cate_left {
        color: $gray-50;

        .completed {
          color: $blue-100;
        }
      }

      .progress_bar_wrapper {
        position: relative;
        width: 100%;
        height: ptr(4);
        margin-top: ptr(12);

        .progress_bar {
          width: 100%;
          height: ptr(4);
          border-radius: ptr(2);
          background-color: $gray-10;

          &.progress_bar_blue {
            position: absolute;
            top: 0;
            left: 0;
            background-color: $blue-100;
          }
        }
      }
    }

    .onboarding_list {
      @include flex(flex-start, flex-start, column);

      gap: ptr(20);

      .onboarding_item {
        @include flex(flex-start, center);

        gap: ptr(8);
        color: #141414;
        cursor: pointer;

        .icon_wrapper {
          @include flex(center, center);

          width: ptr(28);
          height: ptr(28);
          border-radius: 50%;
          background-color: $gray-10;

          &.check_icon {
            background-color: $blue-10;
          }

          &.current_icon {
            background-color: $blue-100;
          }

          .icon {
            width: ptr(16);
            height: ptr(16);
            color: $gray-50;

            &.check_icon {
              color: $blue-100;
            }

            &.current_icon {
              color: $white;
            }
          }
        }

        &[data-selected="true"] {
          color: $blue-100;
        }
      }
    }
  }

  .main {
    @include flex(flex-start, flex-start, column);

    background-color: $white;

    .header {
      width: 100%;
      @include flex(flex-end, center);

      padding: ptr(18) ptr(16) ptr(10) 0;

      .close_icon {
        width: ptr(20);
        height: ptr(20);
        cursor: pointer;
      }
    }

    .body {
      @include flex(flex-start, flex-start, column);

      gap: ptr(24);
      padding: 0 ptr(32);

      .content {
        @include flex(flex-start, flex-start, column);

        gap: ptr(8);

        .index {
          color: $blue-100;
        }

        .title_wrapper {
          @include flex(flex-start, center);

          gap: ptr(8);

          .title {
            color: #141414;
          }

          .point {
            @include flex(center, center);

            padding: ptr(2) ptr(4);
            border-radius: ptr(4);
            background-color: $blue-10;
            color: $blue-100;
          }
        }

        .desc {
          color: $gray-80;
        }
      }

      .image_wrapper {
        width: ptr(400);
        height: ptr(300);
        overflow: hidden;
        border-radius: ptr(16);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .footer {
      width: 100%;
      @include flex(space-between, center);

      padding: ptr(32);

      .page {
        color: $gray-50;
      }

      .button {
        @include flex(center, center);

        min-width: ptr(120);
        padding: ptr(9) ptr(24);
        border-radius: ptr(8);
        background-color: $blue-100;
        color: $white;
        cursor: pointer;

        .complete {
          @include flex(center, center);

          gap: ptr(4);

          .icon {
            width: ptr(14);
            height: ptr(14);
          }
        }
      }
    }
  }
}

html[is_mobile="true"] .container {
  @include flex(flex-start, center, column);

  height: 100%;

  .header {
    position: relative;
    width: 100%;
    @include flex(flex-end, center);

    padding: ptr(16);
    border-bottom: 1px solid $gray-5;

    .cate_title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .close_icon {
      width: ptr(20);
      height: ptr(20);
      cursor: pointer;
    }
  }

  .swiper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include flex(flex-start, center);

    .wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      @include flex(flex-start, center);
    }
  }

  .card {
    width: 100%;
    @include flex(flex-start, flex-start, column);

    gap: ptr(24);
    padding: ptr(32) ptr(24);

    .content {
      @include flex(flex-start, flex-start, column);

      gap: ptr(8);
      min-height: ptr(150);

      .index {
        color: $blue-100;
      }

      .title_wrapper {
        @include flex(flex-start, center);

        gap: ptr(8);

        .title {
          color: #141414;
        }

        .point {
          @include flex(center, center);

          padding: ptr(2) ptr(4);
          border-radius: ptr(4);
          background-color: $blue-10;
          color: $blue-100;
        }
      }

      .desc {
        color: $gray-80;
      }
    }

    .image_wrapper {
      position: relative;
      width: 100%;
      padding-bottom: 74.9235474%;
      overflow: hidden;
      border-radius: ptr(16);

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .pagination {
    width: 100%;
    @include flex(center, center);

    gap: ptr(8);
    margin-bottom: ptr(10);

    & > span.bullet {
      width: ptr(10);
      height: ptr(10);
      border-radius: 50%;
      background-color: $gray-10;
    }

    & > span.bullet_active {
      background-color: $blue-100;
    }
  }
}

html[is_mobile="true"] .footer {
  width: 100%;
  height: ptr(52);
  @include flex(center, center);

  .button {
    width: 100%;
    height: 100%;
    @include flex(center, center);

    border-radius: ptr(8);
    background-color: $blue-100;
    color: $white;
    cursor: pointer;

    .complete {
      width: 100%;
      @include flex(center, center);

      gap: ptr(4);

      .icon {
        width: ptr(14);
        height: ptr(14);
      }
    }
  }
}
