@import "base";
html[is_mobile="false"] {
  .randing_header {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    min-height: ptr(72);
    transform: translateY(0);
    transition:
      color 0.5s,
      transform 0.3s,
      background-color 0.5s linear;
    background-color: transparent;
    color: $white;
    will-change: transform;

    &.bg_white {
      background-color: $white !important;
      color: $blue-100 !important;
    }

    &.light {
      background-color: $white;
      color: $blue-100;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &:global(.headroom--pinned) {
      transform: translateY(0);
    }

    &:global(.headroom--unpinned) {
      transform: translateY(ptr(-72));
    }

    &:global(.headroom--top) {
      background-color: transparent;
      color: $white;
    }

    &:global(.headroom--not-top) {
      background-color: $bright-70;
      color: $blue-100;
      backdrop-filter: blur(6px);

      .popup_store_division {
        .division_item {
          color: $gray-50;

          &.active {
            color: $blue-100;
          }
        }
      }
    }

    .logo {
      display: flex;
      position: absolute;
      top: 0;
      left: ptr(32);
      align-items: center;
      height: ptr(72);

      a {
        color: inherit;
      }
    }

    .mp_logo {
      display: inline-block;
      width: ptr(166);
      height: ptr(32);

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .current_menu {
      display: flex;
      align-items: center;
      justify-content: center;
      height: ptr(72);
      cursor: pointer;
      gap: ptr(16);
    }

    .current_menu_title {
    }

    .current_menu_arrow {
      width: ptr(20);
      height: ptr(20);
    }

    .popup_store_division {
      @include flex(space-between, center);

      gap: ptr(16);
      position: absolute;
      top: 0;
      right: ptr(32);
      height: ptr(72);

      .division_item {
        color: $bright-60;

        &.active {
          color: $white;
          font-weight: 700 !important;
        }
      }

      .divide {
        width: ptr(1);
        height: ptr(12);
        background: rgb(255 255 255 / 60%);
      }
    }

    .menus {
      display: none;
    }

    .menus_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: ptr(6) 0 ptr(20);
      gap: ptr(8);
    }

    .menu a {
      color: inherit;
    }

    &[data-open="true"] {
      backdrop-filter: blur(10px);

      .current_menu {
        background: rgb(20 20 20 / 1%);
      }

      .menus {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: rgb(20 20 20 / 1%);
      }
    }
  }
}

html[is_mobile="true"] {
  .randing_header {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    min-height: ptr(56);
    transform: translateY(0);
    transition:
      color 0.5s,
      transform 0.3s,
      background-color 0.5s linear;
    background-color: transparent;
    color: $white;
    will-change: transform;

    &.bg_white {
      background-color: $white !important;
      color: $blue-100 !important;
    }

    &.light {
      background-color: $white;
      color: $blue-100;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &:global(.headroom--pinned) {
      transform: translateY(0);
    }

    &:global(.headroom--unpinned) {
      transform: translateY(ptr(-56));

      .popup_store_division {
        display: none;
      }
    }

    &:global(.headroom--top) {
      background-color: transparent;
      color: $white;
    }

    &:global(.headroom--not-top) {
      background-color: $bright-70;
      color: $blue-100;
      backdrop-filter: blur(6px);

      .popup_store_division {
        .division_item {
          color: $blue-100;
          font-weight: 400 !important;

          &.active {
            border-bottom: 2px solid $blue-100;
            color: $blue-100;
            font-weight: 700 !important;
          }
        }
      }
    }

    .logo {
      display: flex;
      position: absolute;
      top: 0;
      left: ptr(16);
      align-items: center;
      height: ptr(56);

      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        color: inherit;
      }
    }

    .mp_logo {
      display: inline-block;
      width: ptr(46);
      height: ptr(24);

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .current_menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: ptr(56);
      padding-right: ptr(16);
      cursor: pointer;
      gap: ptr(12);
    }

    .current_menu_title {
    }

    .current_menu_arrow {
      width: ptr(20);
      height: ptr(20);
    }

    .popup_store_division {
      @include flex(flex-start, center);

      visibility: hidden;
      width: 100%;
      padding: 0 ptr(16);
      transition:
        color 0.5s,
        transform 0.3s,
        background-color 0.5s linear;

      &.visible {
        visibility: visible;
      }

      .division_item {
        padding: ptr(8) ptr(12) ptr(12);
        color: $bright-60;

        &.active {
          border-bottom: ptr(2) solid $white;
          color: $white;
        }
      }
    }

    .menus {
      display: none;
    }

    .menu a {
      color: inherit;
    }

    .menus_wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      padding: ptr(6) ptr(48) ptr(20);
      gap: ptr(8);
    }

    &[data-open="true"] {
      backdrop-filter: blur(10px);

      .popup_store_division {
        display: none;
      }

      .current_menu {
        background: rgb(20 20 20 / 1%);
      }

      .menus {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        background: rgb(20 20 20 / 1%);
      }
    }
  }
}
