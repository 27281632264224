@import "base";
html {
  .progress {
    position: relative;
    width: ptr(72);
    height: ptr(72);
    color: $blue-100;

    .circle_progress {
      transform: rotate(-90deg);
    }

    .frame,
    .bar {
      fill: none;
    }

    .frame {
      stroke: $gray-10;
    }

    .bar {
      stroke-linecap: round;
      stroke: $blue-100;
    }

    &[data-size="small"] {
      width: ptr(40);
      height: ptr(40);
    }

    &[data-bar_color="white"] {
      color: $white;

      .frame {
        stroke: $bright-20;
      }

      .bar {
        stroke: $white;
      }
    }
  }

  .value {
    display: inline-flex;
    position: absolute;
    inset: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .icon {
    width: ptr(16);
    height: ptr(16);
  }
}
