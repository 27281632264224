@import "base";
html[is_mobile="false"] {
  .creator_ranking_container {
    width: 100%;
    max-width: $pc-layout-body-max-width;
    gap: ptr(24);
    @include flex(center, flex-start, column);
  }

  .creator_ranking {
    width: 100%;
    @include flex(center, center, row);

    gap: ptr(48);
    padding: 0 ptr(32);
  }

  .rank {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    min-width: ptr(32);
    row-gap: ptr(6);

    .change {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: ptr(32);
      gap: ptr(4);
    }

    .triangle {
      width: ptr(6);
      height: ptr(6);
    }

    .empty {
      width: ptr(32);
      height: ptr(20);
    }

    .red {
      color: $red-80;
    }

    .blue {
      color: $blue;
    }

    span:last-child {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      gap: ptr(4);
    }

    &[data-horizontal="true"] {
      flex-direction: row;
      gap: ptr(8);
      justify-content: flex-start;

      .change {
        justify-content: flex-start;
        min-width: ptr(38);
        gap: ptr(6);
      }

      .triangle {
        width: ptr(8);
        height: ptr(8);
      }
    }
  }

  .creator_top_ranker_card_container {
    width: fit-content;
  }

  .creator_top_ranker_card {
    position: relative;
    flex-shrink: 0;
    width: ptr(378);
    height: ptr(567);
    overflow: hidden;
    border-radius: ptr(24);

    .background {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .main {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: ptr(32);
      background: $dim-60;
      @include blur60;

      .header {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: ptr(4);
        color: $white;
      }

      .thumbnail {
        position: absolute;
        z-index: -1;
        top: ptr(88);
        left: 0;
        width: 100%;
        padding: 0 ptr(32);

        .thumbnail_mask {
          width: ptr(304);
          height: ptr(272);
          mask-image: url("//s3.marpple.co/files/u_218933/2024/5/original/4dbeb505a99f381b35350b029c408df5bfdbe3f61.png");
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: $gray-20;
          }
        }
      }

      .bottom {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: ptr(24);

        .information {
          display: inline-flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          color: $white;

          .name_wrapper {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            gap: ptr(8);
          }

          .name {
            max-width: 100%;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .icon {
            flex-shrink: 0;
            width: ptr(24);
            height: ptr(24);
          }
        }

        .products_container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          min-height: ptr(96);
          gap: ptr(12);

          .product {
            display: inline-flex;
            flex: 0 1 ptr(96);
            align-items: center;
            justify-content: center;
            width: 100%;
            overflow: hidden;
            border-radius: ptr(8);
            aspect-ratio: 1/1;
          }
        }
      }
    }
  }

  .creator_ranker_list {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: center;

    .row {
      display: flex;
      align-items: center;
      gap: ptr(48);
      align-self: stretch;
      justify-content: space-between;
    }

    .first {
      padding-bottom: ptr(40);
    }

    .second {
      padding: ptr(40) 0;
      border-top: 1px solid $gray-5;
      border-bottom: 1px solid $gray-5;
    }

    .third {
      padding-top: ptr(40);
    }
  }

  .creator_ranker_card {
    display: flex;
    flex: 1 1 33%;
    align-items: center;
    gap: ptr(16);

    .thumbnail {
      flex-shrink: 0;
      width: ptr(112);
      height: ptr(112);
      border: 1px solid $gray-5;
      border-radius: 999px;
      background-color: $white;
      object-fit: cover;
    }

    .name {
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      align-items: flex-start;
      gap: ptr(4);

      span:last-child {
        color: $gray-50;
      }

      span {
        width: 100%;
        max-width: ptr(102);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

html[is_mobile="true"] {
  .creator_ranking_container {
    width: 100%;
    @include flex(center, flex-start, column);

    gap: ptr(16);
  }

  .creator_ranking {
    width: 100%;
    @include flex(center, center, column);

    gap: ptr(24);
  }

  .rank {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: ptr(32);
    padding-bottom: ptr(2);
    row-gap: ptr(4);

    .change {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      min-width: ptr(32);
      gap: ptr(4);
    }

    .triangle {
      width: ptr(6);
      height: ptr(6);
    }

    .empty {
      width: ptr(32);
      height: ptr(20);
    }

    .red {
      color: $red-80;
    }

    .blue {
      color: $blue;
    }

    span:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ptr(4);
    }

    &[data-horizontal="true"] {
      flex-direction: row;
      gap: ptr(12);
      justify-content: flex-start;

      .change {
        justify-content: flex-start;
        min-width: ptr(38);
        gap: ptr(6);
      }

      .triangle {
        width: ptr(8);
        height: ptr(8);
      }
    }
  }

  .creator_top_ranker_card_container {
    position: relative;
    width: 100%;
    padding: 0 ptr(16);
  }

  .creator_top_ranker_card {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    height: ptr(430);
    overflow: hidden;
    border-radius: ptr(24);

    .background {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .main {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: ptr(24);
      background: $dim-60;
      @include blur60;

      .header {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: ptr(4);
        color: $white;
      }

      .thumbnail {
        position: absolute;
        z-index: -1;
        top: ptr(72);
        left: 50%;
        width: ptr(237);
        height: ptr(215);
        transform: translate3d(-50%, 0, 0);

        .thumbnail_mask {
          width: 100%;
          height: 100%;
          mask-image: url("//s3.marpple.co/files/u_218933/2024/5/original/4dbeb505a99f381b35350b029c408df5bfdbe3f61.png");
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: $gray-20;
          }
        }
      }

      .bottom {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: ptr(16);

        .information {
          display: inline-flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          color: $white;

          .name_wrapper {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            gap: ptr(8);
          }

          .name {
            max-width: 100%;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .icon {
            flex-shrink: 0;
            width: ptr(24);
            height: ptr(24);
          }

          .type {
            color: $gray-20;
          }
        }

        .products_container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          min-height: ptr(68);
          gap: ptr(8);

          .product {
            display: inline-flex;
            flex: 0 1 calc((100% - ptr(8 * 3)) / 4);
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: ptr(8);
            aspect-ratio: 1/1;
          }
        }
      }
    }
  }

  .creator_ranker_list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 ptr(16);
    overflow-x: auto;
    gap: ptr(16);
    scroll-padding-left: ptr(24);
    scroll-snap-type: x mandatory;
    @include hiddenScroll;

    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ptr(24);
      align-self: stretch;
      justify-content: space-between;
      scroll-snap-align: start;
    }
  }

  .creator_ranker_card {
    display: flex;
    align-items: center;
    gap: ptr(8);
    width: ptr(304);

    .thumbnail {
      flex-shrink: 0;
      width: ptr(72);
      height: ptr(72);
      margin-right: ptr(4);
      border: 1px solid $gray-5;
      border-radius: 999px;
      background-color: $white;
      object-fit: cover;
    }

    .name {
      display: flex;
      flex: 1 1 0;
      flex-direction: column;
      align-items: flex-start;
      gap: ptr(4);

      span:last-child {
        color: $gray-50;
      }

      span:first-child {
        padding: ptr(2) 0;
      }

      span {
        width: 100%;
        max-width: ptr(180);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
