@import "base";
@import "../../../../../shared/style/base";

.adjustment_section {
  @include pc {
    display: grid;
    grid-template-columns: 35% 1fr;
    gap: ptr(60);
  }
  @include mobile {
    display: grid;
    grid-template-columns: 1fr;
    gap: ptr(24);
  }

  .adjustment_rows_container {
    display: flex;
    flex-direction: column;
    margin-top: ptr(24);
    color: $gray-50;
    gap: ptr(16);

    .value {
      color: $black !important;
    }

    a.value {
      text-decoration: underline !important;
    }

    .adjustment_row {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }
}
