@import "base";
html[is_mobile="false"] section.benefit {
  background-color: $blue-10;
  @include flex(center, center, column);
  gap: ptr(48);
  padding: ptr(120) ptr(360);

  .content {
    @include flex(center, center, column);
    gap: ptr(16);

    .title {
      color: $blue-100;
      text-align: center;
    }

    .description {
      color: $blue-100;
      text-align: center;
    }
  }

  .card_list_wrapper {
    @include flex(center, center, column);
    gap: ptr(24);
  }

  .card_list {
    @include flex(center, center);
    gap: ptr(24);

    .card {
      width: ptr(588);
      height: ptr(588);
      @include flex(center, center, column);
      padding: ptr(56) ptr(32);
      background-color: $white;
      border-radius: ptr(16);
      gap: ptr(32);

      .title_wrapper {
        @include flex(center, center, column);
        gap: ptr(16);

        .sub_title {
          @include flex(center, center);
          color: $white;
          background-color: $blue-100;
          border-radius: ptr(999);
          padding: ptr(4) ptr(16);
        }

        .title {
          text-align: center;
        }
      }

      .image_wrapper {
        width: ptr(180);
        height: ptr(180);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .description_wrapper {
        @include flex(center, center, column);
        gap: ptr(16);

        .description {
          text-align: center;
          line-height: ptr(28) !important;
          span {
            color: $blue-100;
          }
        }
        .sub_description {
          text-align: center;
          color: $gray-50;
        }
        .sub_description_pc {
          @include flex(center, center, column);
          gap: ptr(4);
        }
        .sub_description_link {
          @include flex(center, center);
          text-align: center;
          color: $blue-100;
          gap: ptr(8);

          svg {
            width: ptr(16);
            height: ptr(16);
          }
        }
      }
    }
  }

  .info_wrapper {
    @include flex(center, center, column);
    gap: ptr(4);
    .info_title {
      text-align: center;
      color: $gray-50;
      margin-bottom: ptr(4);
    }

    .info_desc_wrapper {
      @include flex(center, center);
      gap: ptr(6);

      svg {
        color: $gray-50;
      }
    }

    .info_desc {
      text-align: center;
      color: $gray-50;
    }
  }
}

html[is_mobile="true"] section.benefit {
  @include flex(center, center, column);
  gap: ptr(32);
  padding: ptr(80) ptr(24);
  background-color: $blue-10;

  .content {
    @include flex(center, center, column);
    gap: ptr(16);

    .title {
      color: $blue-100;
      text-align: center;
    }

    .description {
      color: $blue-100;
      text-align: center;
    }
  }

  .card_list_wrapper {
    @include flex(center, center, column);
    gap: ptr(16);
  }

  .card_list {
    @include flex(center, center, column);
    gap: ptr(16);

    .card {
      width: ptr(327);
      @include flex(center, center, column);
      padding: ptr(32) ptr(24);
      background-color: $white;
      border-radius: ptr(16);
      gap: ptr(24);

      .title_wrapper {
        @include flex(center, center, column);
        gap: ptr(16);

        .sub_title {
          @include flex(center, center);
          color: $white;
          background-color: $blue-100;
          border-radius: ptr(999);
          padding: ptr(4) ptr(16);
        }

        .title {
          text-align: center;
        }
      }

      .image_wrapper {
        width: ptr(180);
        height: ptr(180);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .description_wrapper {
        @include flex(center, center, column);
        gap: ptr(8);

        .description {
          text-align: center;
          line-height: ptr(21) !important;
          span {
            color: $blue-100;
          }
        }
        .sub_description {
          text-align: center;
          color: $gray-50;
        }
        .sub_description_link {
          @include flex(center, center);
          text-align: center;
          color: $blue-100;
          gap: ptr(8);

          svg {
            width: ptr(16);
            height: ptr(16);
          }
        }
      }
    }
  }
  .info_wrapper {
    @include flex(flex-start, flex-start, column);
    gap: ptr(4);
    width: 100%;

    .info_title {
      text-align: left;
      color: $gray-50;
    }

    .info_desc_wrapper {
      @include flex(center, flex-start);
      gap: ptr(6);

      svg {
        margin-top: ptr(10);
        color: $gray-50;
      }
    }

    .info_desc {
      text-align: left;
      margin-left: ptr(6);
      color: $gray-50;
    }
  }
}
