@import "base";
.membership {
  display: none;

  &[data-existed="true"] {
    display: block;
    min-height: ptr(565);
  }

  html[is_mobile="true"] & {
    min-height: ptr(408);
    padding: 0 ptr(16);

    &[data-memberships="1"] {
      min-height: ptr(376);
    }
  }
}

html[is_mobile="false"] {
  .frame_not_click {
    position: fixed;
    z-index: 100;
    inset: 0;
    background-color: transparent;
  }

  .page {
    width: 100%;
    min-width: $pc-layout-body-min-width;
    margin-right: auto;
    margin-bottom: ptr(120);
    margin-left: auto;
  }

  .page.no_banner {
    margin-top: calc(#{$default-sub-header-pc_height} + #{$default-header-pc_height + ptr(50)});
  }

  .banner {
    position: relative;
    height: ptr(480);
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgb(20 20 20 / 30%) 0%, rgb(20 20 20 / 0%) 100%);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .title {
    position: relative;

    .title_more {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .see_more {
      color: $gray-80;
    }
  }

  .store_main_wrap {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    min-width: $pc-layout-body-min-width;
    max-width: $pc-layout-body-max-width;
    margin: 0 auto;
    padding: ptr(96) ptr(32) 0;
    gap: ptr(96);
  }

  .creator_profile {
    width: 100%;
    min-width: $pc-layout-body-min-width;
    max-width: $pc-layout-body-max-width;
    min-height: ptr(105);
    margin: 0 auto;
    padding: 0 ptr(32);
  }

  .section_wrapper {
    display: flex;
    flex-direction: column;
    gap: ptr(24);
  }

  .product_cards_container {
    display: grid;
    position: relative;
    grid-gap: ptr(32) ptr(16);
    flex: 1 0;

    $breakpoint1: 1440px;
    $breakpoint2: 1280px;

    @media (min-width: #{$breakpoint1}) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    @media (min-width: #{$breakpoint2}) and (max-width: #{$breakpoint1 - 1px}) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    @media (max-width: #{$breakpoint2 - 1px}) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  .btn_more_area {
    text-align: center;
  }

  .btn_more {
    min-width: ptr(180);
  }

  .cheer_up_list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    min-height: ptr(236);
    column-gap: ptr(16);
  }

  .photo_review_list {
    display: grid;
    grid-auto-rows: minmax(0, 1fr);
    grid-gap: ptr(48) ptr(24);
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

html[is_mobile="true"] {
  .frame_not_click {
    position: fixed;
    z-index: 100;
    inset: 0;
    background-color: transparent;
  }

  .page {
    width: 100%;
    margin-bottom: ptr(120);
  }

  .page.no_banner {
    margin-top: $default-header-pc_height;
  }

  .banner {
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgb(20 20 20 / 30%) 0%, rgb(20 20 20 / 0%) 100%);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      vertical-align: top;
    }
  }

  .title {
    position: relative;
    padding: 0 ptr(16);

    .title_more {
      position: absolute;
      right: ptr(16);
      bottom: 0;
    }

    .see_more {
      color: $gray-80;
    }
  }

  .store_main_wrap {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    padding-top: ptr(48);
    gap: ptr(48);
  }

  .menus {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: ptr(64);
    margin-top: ptr(6);
    overflow-x: auto;
    gap: ptr(8);
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    @include hiddenScroll;
  }

  .menu {
    box-sizing: unset;
    flex: 0 0 auto;
    scroll-snap-align: start;

    &:first-child {
      padding-left: ptr(16);
    }

    &:last-child {
      padding-right: 16px;
    }
  }

  .menu_a {
    display: flex;
    box-sizing: unset;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    padding: ptr(7) ptr(16);
    border: 1px solid $gray-20;
    border-radius: 999px;
    background: $white;
    color: $gray-80;
    scroll-snap-align: start;
    @include blur40;

    &:hover,
    &:active {
      border-color: $black;
      color: $black;
    }
  }

  .creator_profile {
    width: 100%;
    padding: 0 ptr(16);
  }

  .section_wrapper {
    display: flex;
    flex-direction: column;
    gap: ptr(24);
  }

  .product_cards_container {
    display: grid;
    position: relative;
    grid-gap: ptr(32) ptr(16);
    flex: 1 0;
    padding: 0 ptr(16);

    $breakpoint1: 691px;
    $breakpoint2: 375px;

    @media (min-width: #{$breakpoint1}) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media (min-width: #{$breakpoint2}) and (max-width: #{$breakpoint1 - 1px}) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (max-width: #{$breakpoint2 - 1px}) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  .btn_more_area {
    text-align: center;
  }

  .btn_more {
    min-width: ptr(180);
  }

  .cheer_up_list {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    @include hiddenScroll;

    > div {
      box-sizing: unset;
      flex: 0 0 auto;
      width: 70vw;
      padding-left: 16px;
      scroll-snap-align: start;

      &:last-child {
        padding-right: 16px;
      }
    }
  }

  .photo_review_list {
    display: flex;
    overflow-x: auto;
    scroll-padding-left: ptr(16);
    scroll-snap-type: x mandatory;
    @include hiddenScroll;

    > div {
      box-sizing: unset;
      flex: 0 0 auto;
      width: ptr(240);
      max-height: ptr(409);
      padding-left: 16px;
      scroll-snap-align: start;

      &:last-child {
        padding-right: 16px;
      }
    }
  }
}
