@import "base";
html[is_mobile="false"] {
  .page {
    width: 100%;
  }

  .inner_body {
    margin-top: ptr(24);
  }

  .title {
    color: $black;
  }

  .description {
    color: $gray-80;
  }

  .h3 {
  }

  .ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: ptr(24) 0 0;
    padding: ptr(12);
    border-radius: ptr(4);
    background: $gray-100;
    list-style: none;
  }

  .li {
    display: flex;

    &::before {
      content: "· ";
      margin-right: ptr(10);
    }
  }

  .form {
    margin: ptr(24) 0 0;
  }

  .form_li {
    display: flex;
    align-items: center;
    border: 1px solid $gray-10;
    border-radius: ptr(4);
    background: $white;
    gap: ptr(24);
  }

  .form_head {
    display: flex;
    align-items: center;
    align-self: stretch;
    width: ptr(240);
    padding: ptr(19) ptr(24);
    background: $gray-5;
    color: $black;
    gap: ptr(4);
  }

  .form_body {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: ptr(10);
    gap: ptr(10);

    &[status="fail"] {
      .ga_input {
        border-color: $red-80 !important;
      }

      .ga_file_txt {
        display: block;
        color: $red-80;
      }
    }
  }

  .ga_input {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    width: ptr(600);
    padding: ptr(9) ptr(12);
    border: 1px solid $gray-10 !important;
    border-radius: ptr(4) !important;
    background: $white !important;
    gap: ptr(8);
  }

  .ga_file_txt {
    display: none;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ptr(24);
    gap: ptr(10);
  }

  .btn_cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ptr(132);
    padding: ptr(13) ptr(24);
    border: 1px solid $gray-10;
    border-radius: ptr(4);
    background: $white;
    color: $gray-80;
    gap: ptr(8);
  }

  .btn_save {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ptr(132);
    padding: ptr(13) ptr(24);
    border-radius: ptr(4);
    background: $gray-10 !important;
    color: $gray-50 !important;
    gap: ptr(8);

    &[status="pass"] {
      background: $blue-100 !important;
      color: $white !important;
    }
  }
}

html[is_mobile="true"] {
  .page {
    width: 100%;
  }

  .inner_body {
    margin-top: ptr(24);
  }

  .title {
    color: $black;
  }

  .description {
    color: $gray-80;
  }

  .h3 {
  }

  .ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: ptr(24) 0 0;
    padding: ptr(12);
    border-radius: ptr(4);
    background: $gray-100;
    list-style: none;
  }

  .li {
    display: flex;

    &::before {
      content: "· ";
      margin-right: ptr(10);
    }
  }

  .form {
    margin: ptr(24) 0 0;
  }

  .form_li {
    display: flex;
    flex-direction: column;
    border: 1px solid $gray-10;
    border-radius: ptr(4);
    background: $white;
  }

  .form_head {
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: ptr(19) ptr(24);
    background: $gray-5;
    color: $black;
    gap: ptr(4);
  }

  .form_body {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    justify-content: flex-start;
    padding: ptr(10);
    gap: ptr(10);

    &[status="fail"] {
      .ga_input {
        border-color: $red-80 !important;
      }

      .ga_file_txt {
        display: block;
        color: $red-80;
      }
    }
  }

  .ga_input {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    width: 100%;
    padding: ptr(9) ptr(12);
    border: 1px solid $gray-10 !important;
    border-radius: ptr(4) !important;
    background: $white !important;
    gap: ptr(8);
  }

  .ga_file_txt {
    display: none;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ptr(24);
    gap: ptr(10);
  }

  .btn_cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ptr(132);
    padding: ptr(13) ptr(24);
    border: 1px solid $gray-10;
    border-radius: ptr(4);
    background: $white;
    color: $gray-80;
    gap: ptr(8);
  }

  .btn_save {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ptr(132);
    padding: ptr(13) ptr(24);
    border-radius: ptr(4);
    background: $gray-10 !important;
    color: $gray-50 !important;
    gap: ptr(8);

    &[status="pass"] {
      background: $blue-100 !important;
      color: $white !important;
    }
  }
}
