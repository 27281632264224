@import "base";
.image_container {
  @include flex(center, center, column);

  position: relative;
  border-radius: ptr(8);
  cursor: pointer;

  &[data-is_upload="true"] {
    .image {
      width: 100%;
      height: 100%;
      border-radius: ptr(8);
    }

    .trash_icon {
      position: absolute;
      top: ptr(16);
      right: ptr(16);
      width: ptr(32);
      height: ptr(32);
      padding: ptr(6);
      border-radius: ptr(99);
      background-color: $dim-30;
      cursor: pointer;
      backdrop-filter: blur(15px);

      & > svg {
        width: 100%;
        height: 100%;
        color: $white;
      }
    }
  }

  &[data-is_upload="false"] {
    border: ptr(1) dashed $gray-20;

    .plus_icon {
      width: ptr(48);
      height: ptr(48);
      padding: ptr(12);
      color: $gray-20;

      & > svg {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      @include flex(center, center, column);

      gap: ptr(4);

      .description {
        color: $gray-80;
      }

      .image_description {
        color: $gray-50;
      }
    }
  }

  & > input {
    display: none;
  }
}
