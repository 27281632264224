@import "base";
@keyframes hideElement {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes showElement {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hideScaleElement {
  0% {
    opacity: 1;
  }

  100% {
    width: ptr(112);
    height: ptr(112);
    opacity: 0;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
}

.video {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

  iframe {
    pointer-events: none;
  }
}

.thumbnail {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  cursor: pointer;

  &[data-video_show="true"] {
    animation: showElement 300ms ease-out;
  }

  &[data-video_show="false"] {
    animation: hideElement 300ms ease-out;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    width: ptr(80);
    height: ptr(80);
    transform: translate(-50%, -50%);
    cursor: pointer;

    &[data-video_show="true"] {
      animation: showElement 300ms ease-out;
    }

    &[data-video_show="false"] {
      animation: hideScaleElement 300ms ease-out;
    }
  }
}
