@import "base";
.score_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .score {
    white-space: nowrap;

    .star {
      display: inline-block;
      width: ptr(16);
      height: ptr(16);
      margin-right: ptr(2);
      background: url("//s3.marpple.co/files/u_2308668/2024/4/original/28137197cd5a35fee38531263c8ff9a856cfc9731.png")
        no-repeat center center;
      background-size: ptr(16) auto;

      &.fill {
        background-image: url("//s3.marpple.co/files/u_2308668/2024/4/original/62c1c5e9e64dcdbf684e8dec0b6b8444ee3a81f01.png");
      }
    }
  }

  .created_at {
    color: $gray-50;
  }
}

html[is_mobile="false"] {
  .photo_review_card {
    aspect-ratio: 326 / 544;
    @include flex(space-between, flex-start, column);

    row-gap: ptr(16);
    cursor: pointer;
  }

  .review_photo {
    width: 100%;
    overflow: hidden;
    border: 1px solid $gray-5;
    border-radius: ptr(24);
    aspect-ratio: 326 / 435;
    @include flex(center, center);

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .product_container {
    display: flex;
    width: 100%;
    height: ptr(60);
    column-gap: ptr(16);

    .product_info_wrap {
      flex: 1;
      width: 100%;
      padding-right: ptr(8);
      color: $gray-80;
      @include flex(center, flex-start, column);
    }

    .product_name {
      @include lineLimit(1);

      white-space: normal;
    }

    .product_thumbnail_wrap {
      flex: 0 0 ptr(60);
      overflow: hidden;
      border-radius: ptr(8);
    }
  }
}

html[is_mobile="true"] {
  .photo_review_card {
    aspect-ratio: 343 / 547;
    @include flex(space-between, flex-start, column);

    row-gap: ptr(12);
    cursor: pointer;
  }

  .review_photo {
    width: 100%;
    overflow: hidden;
    border: 1px solid $gray-5;
    border-radius: ptr(16);
    aspect-ratio: 343 / 458;
    @include flex(center, center);

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .product_container {
    display: flex;
    width: 100%;
    height: ptr(48);
    column-gap: ptr(12);

    .product_info_wrap {
      flex: 1;
      width: 100%;
      padding-right: ptr(8);
      color: $gray-80;
      @include flex(center, flex-start, column);
    }

    .product_name {
      @include lineLimit(1);

      white-space: normal;
    }

    .product_thumbnail_wrap {
      flex: 0 0 ptr(48);
      overflow: hidden;
      border-radius: ptr(8);
    }
  }
}
