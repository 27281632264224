@import "base";
.adult_verification {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  background-color: $product-bg;
  aspect-ratio: 1 / 1;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    color: $gray-20;
    aspect-ratio: 1 / 1;
  }
}
