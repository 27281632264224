@import "base";
html[is_mobile="false"] .container {
  width: ptr(420);
  height: ptr(608);
  border-radius: ptr(16);

  &[data-preview="true"] {
    pointer-events: none;

    * {
      pointer-events: none;
    }

    .swiper_wrapper {
      z-index: 0;
    }
  }

  &[data-preview="false"] {
    position: absolute;
    z-index: 2;
    top: ptr(236);
    left: calc(50% + #{ptr(268)});
  }

  .backdrop {
    display: none;
  }

  .popup_content {
    width: 100%;
    height: 100%;

    .swiper {
      position: relative;
      width: 100%;
      height: ptr(560);
      overflow: hidden;
      border-top-left-radius: ptr(16);
      border-top-right-radius: ptr(16);
      background-color: $gray-20;

      .pagination {
        display: flex;
        position: absolute;
        z-index: 1;
        bottom: ptr(16);
        left: 50%;
        transform: translateX(-50%);
        gap: ptr(6);

        .bullet {
          width: ptr(6);
          height: ptr(6);
          border-radius: ptr(99);
          background-color: $dim-30;
        }

        .active_bullet {
          width: ptr(16);
          background-color: $dim-60;
        }
      }

      .swiper_slide {
        width: ptr(420);
        height: ptr(560);

        &[data-link_url] {
          cursor: pointer;
        }

        & > img {
          width: 100%;
          height: 100%;
        }
      }

      .close_icon {
        position: absolute;
        z-index: 1;
        top: ptr(24);
        right: ptr(24);
        width: ptr(24);
        height: ptr(24);
        cursor: pointer;

        & > svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .button_container {
      @include flex(space-between, center);

      height: ptr(48);
      padding: ptr(12) ptr(16);
      border-bottom-right-radius: ptr(16);
      border-bottom-left-radius: ptr(16);

      .show_today {
        cursor: pointer;
      }

      .close {
        cursor: pointer;
      }
    }
  }
}

:global(html.product-detail[is_mobile="false"]) .container[data-preview="false"] {
  position: absolute;
  z-index: 2;
  top: ptr(236);
  left: calc(50% + #{ptr(144)});
}

html[is_mobile="true"] .container {
  width: 100%;
  height: 100%;

  .backdrop {
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    transition: opacity 0.35s;
    background: $dim-60;
    inset: 0;
  }

  .popup_content {
    position: fixed;
    top: 50svh;
    left: 50vw;
    width: calc(100% - 32px);
    max-width: ptr(420);
    transform: translate(-50%, -50%);
    border-radius: ptr(16);

    .swiper {
      position: relative;
      width: 100%;
      overflow: hidden;
      border-top-left-radius: ptr(16);
      border-top-right-radius: ptr(16);
      background-color: $gray-20;
      aspect-ratio: 420 / 560;

      .pagination {
        display: flex;
        position: absolute;
        z-index: 1;
        bottom: ptr(16);
        left: 50%;
        transform: translateX(-50%);
        gap: ptr(6);

        .bullet {
          width: ptr(6);
          height: ptr(6);
          border-radius: ptr(99);
          background-color: $dim-30;
        }

        .active_bullet {
          width: ptr(16);
          background-color: $dim-60;
        }
      }

      .swiper_slide {
        width: 100%;
        height: 100%;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .close_icon {
        position: absolute;
        z-index: 1;
        top: ptr(24);
        right: ptr(24);
        width: ptr(28);
        height: ptr(28);
        cursor: pointer;

        & > svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .button_container {
      @include flex(space-between, center);

      height: ptr(48);
      padding: ptr(12) ptr(16);
      border-bottom-right-radius: ptr(16);
      border-bottom-left-radius: ptr(16);

      .show_today {
        cursor: pointer;
      }

      .close {
        cursor: pointer;
      }
    }
  }

  &[data-preview="true"] {
    pointer-events: none;

    * {
      pointer-events: none;
    }

    .swiper_wrapper {
      z-index: 0;
    }

    .backdrop {
      display: none;
    }

    .popup_content {
      position: relative;
      width: 100%;
      max-width: ptr(420);
      transform: translate(0, 0);
      border-radius: ptr(16);
      inset: 0;
    }
  }

  &[data-preview="false"] {
    position: absolute;
    inset: 0;
    z-index: 1000;
  }
}
