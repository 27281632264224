@import "base";
html[is_mobile="false"] {
  .my_page_empty_screen {
    display: none;
    padding: ptr(160) 0;
    text-align: center;

    &[data-visible="true"] {
      width: 100%;
      @include flex(center, center, column);
      padding: ptr(160) 0;
    }
  }

  .my_page_empty_screen_img {
    margin-bottom: ptr(24);
  }

  .my_page_empty_screen_img_inner {
    width: ptr(160);
  }

  .my_page_empty_screen_title {
    margin-bottom: ptr(8);
    color: $gray-80;
  }

  .my_page_empty_screen_title_desc {
    margin-bottom: ptr(24);
    color: $gray-50;
  }

  .my_page_empty_screen_button {
    width: ptr(196);
  }
}

html[is_mobile="true"] {
  .my_page_empty_screen {
    display: none;
    margin-top: 56px;
    text-align: center;

    &[data-visible="true"] {
      width: 100%;
      @include flex(center, center, column);
    }
  }

  .my_page_empty_screen_img {
    margin-bottom: 24px;
  }

  .my_page_empty_screen_img_inner {
    width: 120px;
  }

  .my_page_empty_screen_title {
    margin-bottom: ptr(8);
    color: $gray-80;
  }

  .my_page_empty_screen_title_desc {
    margin-bottom: ptr(24);
    color: $gray-50;
  }

  .my_page_empty_screen_more {
    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: ptr(196);
      height: ptr(56);
      padding: 0 ptr(24);
      border: none;
      border-radius: ptr(12);
      background-color: $blue-100;
      color: $white;
    }
  }
}
