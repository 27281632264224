@import "base";
@keyframes hideElement {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes showElement {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.button_label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ptr(8);

  html[is_mobile="true"] & {
    gap: ptr(4);
  }
}

.button_icon {
  display: inline-block;
  width: ptr(20);
  height: ptr(20);

  html[is_mobile="true"] & {
    width: ptr(16);
    height: ptr(16);
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ptr(32);

  html[is_mobile="true"] & {
    gap: ptr(24);
  }
}

.wrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  html[is_mobile="false"] & {
    height: ptr(1008);
  }

  html[is_mobile="true"] & {
    min-height: ptr(648);
    padding: ptr(60) 0;
  }
}

.swiper {
  display: flex;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
  width: ptr(830 * 3);
  height: ptr(480);

  html[is_mobile="true"] & {
    width: ptr(339 * 3);
    height: ptr(218);
  }

  .swiper_wrapper_one {
    transform: translate3d(ptr(830), 0, 0);

    html[is_mobile="true"] & {
      transform: translate3d(ptr(339), 0, 0);
    }
  }

  .swiper_wrapper {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .image_wrapper {
    display: flex;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: ptr(830);
    height: 100%;
    padding: 0 ptr(55);
    opacity: 0;

    html[is_mobile="true"] & {
      width: ptr(339);
      padding: 0 ptr(6);
    }

    .image_container::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: background-color 600ms ease-out;
      background-color: $dim-30;
    }

    &.active {
      opacity: 1;

      .image_container::after {
        background-color: transparent;
      }

      .image_container {
        width: 100%;
        height: 100%;

        html[is_mobile="true"] & {
          width: 100%;
          height: 100%;
        }
      }
    }

    &.prev {
      opacity: 1;

      .image_container {
        html[is_mobile="true"] & {
          transform: translate3d(10%, 0, 0);
        }
      }
    }

    &.next {
      opacity: 1;

      .image_container {
        html[is_mobile="true"] & {
          transform: translate3d(-10%, 0, 0);
        }
      }
    }

    .image_container {
      position: relative;
      width: 75%;
      height: 75%;
      overflow: hidden;
      transition:
        width 600ms ease-out,
        height 600ms ease-out,
        transform 300ms linear;
      border-radius: ptr(24);

      html[is_mobile="true"] & {
        width: 80%;
        height: 80%;
        transform: translate3d(0, 0, 0);
      }

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.image_background_container {
  position: absolute;
  width: 100%;
  height: 100%;

  .image_background {
    @include filter60;

    width: 100%;
    height: 100%;
    object-fit: cover;

    &[data-fade-out="true"] {
      animation: hideElement 600ms ease-out;
    }

    &[data-fade-in="true"] {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      animation: showElement 600ms ease-out;
    }
  }
}

.container {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $dim-60;
  gap: ptr(24);

  .title_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: $white;
    gap: ptr(16);
  }

  .section_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ptr(8);
  }
}

.popup_title_container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: ptr(8);

  .popup_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-height: ptr(154);
    color: inherit;
    gap: ptr(16);

    html[is_mobile="true"] & {
      height: ptr(170);
    }

    .popup_title_main {
      display: inline-flex;
      gap: ptr(16);

      html[is_mobile="true"] & {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: ptr(4);
      }
    }

    &[data-fade-out="true"] {
      animation: hideElement 600ms ease-out;
    }

    &[data-fade-in="true"] {
      position: absolute;
      top: 0;
      animation: showElement 600ms ease-out;
      opacity: 0;
    }

    > span {
      text-align: center;
    }
  }
}

.progress_container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: ptr(720);
  color: $white;
  gap: ptr(8);

  html[is_mobile="true"] & {
    width: ptr(327);
  }

  .progress_title_container {
    position: relative;
    width: 100%;
    height: ptr(28);

    html[is_mobile="true"] & {
      height: ptr(22);
    }

    .progress_title {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &[data-fade-out="true"] {
        animation: hideElement 600ms ease-out;
      }

      &[data-fade-in="true"] {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        animation: showElement 600ms ease-in;
        opacity: 0;
      }
    }
  }

  .percent {
    display: inline-flex;
    gap: ptr(4);

    &[data-done="true"] {
      color: $blue;
    }
  }

  .progress {
    position: relative;
    width: 100%;
    height: ptr(6);
    overflow: hidden;
    border-radius: ptr(999);
    background-color: $dim-10;

    html[is_mobile="true"] & {
      height: ptr(4);
    }

    .value {
      width: 100%;
      height: 100%;
      transition: width 600ms ease-out;
      border-radius: ptr(999);
      background-color: $blue;
    }
  }

  .remain_date {
    @include flex(flex-start, center);

    gap: ptr(8);
  }

  .current {
    @include flex(flex-start, center);

    gap: ptr(16);
  }

  .timer {
    @include flex(flex-start, center);

    gap: ptr(4);
  }
}

.status_container {
  position: relative;
  width: ptr(720);
  min-height: ptr(28);

  html[is_mobile="true"] & {
    width: ptr(327);
    min-height: ptr(20);
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: $white;

    &[data-fade-out="true"] {
      animation: hideElement 600ms ease-out;
    }

    &[data-fade-in="true"] {
      position: absolute;
      top: 0;
      animation: showElement 600ms ease-out;
      opacity: 0;
    }
  }
}

.swiper_container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ptr(480);

  html[is_mobile="true"] & {
    height: ptr(218);
  }
}

.swiper_navigation {
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  width: ptr(880);
  pointer-events: none;

  .icon {
    width: ptr(40);
    height: ptr(40);
    color: $white;
    cursor: pointer;
    pointer-events: auto;
  }
}
