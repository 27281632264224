@import "base";
html[is_mobile="false"] .text_wrapper {
  position: relative;
  width: 100%;
  padding: ptr(12) 0;
  border-bottom: ptr(1) solid $gray-50;
  @include flex(space-between, center);

  &.border_active {
    border-bottom: ptr(1) solid $white;
  }

  .textarea {
    width: calc(100% - 16px);
    height: auto;
    min-height: ptr(48);
    max-height: ptr(96);
    border: none;
    border-radius: 0;
    outline: none;
    background-color: transparent;
    box-shadow: none;
    color: $white;
    resize: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &::placeholder {
      color: $gray-50;
    }

    &:placeholder-shown {
      color: $gray-50;
    }
  }

  .controller {
    @include flex(center, center);

    width: ptr(16);
    height: ptr(16);
    padding: ptr(4);

    & > label > span {
      background-color: $bright-20 !important;
    }
  }
}

html[is_mobile="true"] .text_wrapper {
  position: relative;
  width: 100%;
  padding: ptr(12) 0;
  border-bottom: ptr(1) solid $gray-50;
  @include flex(space-between, center);

  &.border_active {
    border-bottom: ptr(1) solid $white;
  }

  .textarea {
    width: calc(100% - 16px);
    height: auto;
    min-height: ptr(44);
    max-height: ptr(88);
    border: none;
    border-radius: 0;
    outline: none;
    background-color: transparent;
    box-shadow: none;
    color: $white;
    resize: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &::placeholder {
      color: $gray-50;
    }

    &:placeholder-shown {
      color: $gray-50;
    }
  }

  .controller {
    @include flex(center, center);

    width: ptr(16);
    height: ptr(16);
    padding: ptr(4);

    & > label > span {
      background-color: $bright-20 !important;
    }
  }
}
