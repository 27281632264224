@import "base";
html[is_mobile="false"] .container {
  @include flex(space-between, center);

  height: ptr(26);
  margin-bottom: ptr(24);

  .tab_container {
    @include flex(center, center);

    .tab {
      position: relative;
      margin-right: ptr(24);
      cursor: pointer;

      &[data-selected="true"] {
        font-weight: 700;

        span {
          &::after {
            content: "";
            position: absolute;
            bottom: ptr(-6);
            left: 0;
            width: 100%;
            height: ptr(2);
            background-color: $blue-100;
          }
        }
      }
    }
  }

  .custom_dropdown {
    position: relative;

    .dropdown_selected {
      @include flex(center, center);

      font-size: ptr(15);
      font-weight: 600;
      letter-spacing: ptr(-0.3);
      line-height: ptr(24);
      cursor: pointer;
      gap: ptr(4);

      .dropdown_icon {
        @include flex(center, center);

        width: ptr(12);
        height: ptr(12);

        & > svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .dropdown_options {
      display: none;
      position: absolute;
      z-index: 1;
      top: 100%;
      right: 0;
      border: solid 1px #e5e5e5;
      border-radius: 2px;
      background-color: #fff;
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%);
      text-align: center;
      white-space: nowrap;

      .dropdown_option {
        padding: 13px 70px;
        cursor: pointer;

        &:hover {
          background-color: #3c89f9;
          color: #fff;
        }
      }
    }
  }
}

html[is_mobile="true"] .container {
  @include flex(space-between, center);

  height: ptr(26);
  margin-bottom: ptr(24);
  padding: 0 ptr(20);

  .tab_container {
    @include flex(center, center);

    .tab {
      position: relative;
      margin-right: ptr(24);
      cursor: pointer;

      &[data-selected="true"] {
        font-weight: 700;

        span {
          &::after {
            content: "";
            position: absolute;
            bottom: ptr(-6);
            left: 0;
            width: 100%;
            height: ptr(2);
            background-color: $blue-100;
          }
        }
      }
    }
  }

  .custom_dropdown {
    position: relative;

    .dropdown_selected {
      @include flex(center, center);

      font-size: ptr(15);
      font-weight: 600;
      letter-spacing: ptr(-0.3);
      line-height: ptr(24);
      cursor: pointer;
      gap: ptr(4);

      .dropdown_icon {
        @include flex(center, center);

        width: ptr(12);
        height: ptr(12);

        & > svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .dropdown_options {
      display: none;
      position: absolute;
      z-index: 1;
      top: 100%;
      right: 0;
      border: solid 1px #e5e5e5;
      border-radius: 2px;
      background-color: #fff;
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%);
      text-align: center;
      white-space: nowrap;

      .dropdown_option {
        padding: 13px 70px;
        cursor: pointer;

        &:hover {
          background-color: #3c89f9;
          color: #fff;
        }
      }
    }
  }
}
