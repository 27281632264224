@import "base";
.loading_container {
  $size: 48px;

  display: flex;
  position: absolute;
  z-index: 1;
  inset: 0;
  align-items: center;
  justify-content: center;

  &.hidden {
    display: none;
  }

  .img {
    width: $size;
    height: $size;
    opacity: 0.9;
    background: url("//s3.marpple.co/files/u_2283830/2024/2/original/69948301f4d417f6e021e3d4b423414925d38b381.gif")
      no-repeat;
    background-size: $size $size;
  }
}
