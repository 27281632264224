@import "base";
html[is_mobile="false"] section.story {
  height: auto;
  @include flex(space-between, flex-start);

  .navigation_wrapper {
    position: sticky;
    top: 0;
    width: fit-content;

    .navigation {
      padding: ptr(152) ptr(48) ptr(408);
      @include flex(flex-start, flex-start, column);

      gap: ptr(64);

      .nav_items {
        @include flex(flex-start, flex-start, column);

        cursor: pointer;

        .nav_item {
          @include flex(flex-start, center);

          width: ptr(200);
          padding: ptr(8) ptr(0);
          gap: ptr(10);
          border-top: ptr(1) solid var(--Black, #141414);

          &.last {
            border-top: ptr(1) solid var(--Black, #141414);
            border-bottom: ptr(1) solid var(--Black, #141414);
          }
        }
      }
    }
  }

  .story_wrapper {
    width: 100%;
    height: auto;
    padding: ptr(160) ptr(48) ptr(260);
    @include flex(flex-start, flex-start, column);

    gap: ptr(120);

    .title {
      width: 100%;
      @include flex(flex-end, flex-start, column);
    }

    .story_section {
      position: relative;
      width: 100%;

      &.section_1 {
        .left_image {
          width: 50%;
          aspect-ratio: 664 / 884;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }

        .inner_section_layout {
          width: 50%;
          @include flex(flex-start, flex-start, column);

          gap: ptr(32);

          .right_image {
            width: 100%;
            aspect-ratio: 664 / 496;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: ptr(8);
            }
          }
        }
      }

      &.section_2 {
        .inner_section_layout {
          width: 50%;
          @include flex(flex-start, flex-start, column);

          .left_image {
            width: 66%;
            aspect-ratio: 1;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: ptr(8);
            }
          }
        }

        .right_image {
          width: 45%;
          aspect-ratio: 587/782;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }
      }

      &.section_3 {
        .left_image {
          width: 33%;
          aspect-ratio: 432 / 575;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }

        .right_image {
          width: 33%;
          aspect-ratio: 432 / 575;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }
      }

      &.section_4 {
        .inner_section_layout {
          width: 50%;
          @include flex(flex-start, flex-start, column);

          gap: ptr(32);

          .left_image {
            width: 100%;
            aspect-ratio: 664 / 496;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: ptr(8);
            }
          }
        }

        .right_image {
          width: 50%;
          aspect-ratio: 664 / 884;

          //width: ptr(664);
          //height: ptr(884);
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }
      }

      &.section_5 {
        position: relative;

        .section_layout_start {
          width: 100%;
          @include flex(flex-start, flex-start);

          gap: ptr(32);

          .left_image {
            width: 33%;
            aspect-ratio: 432 / 575;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: ptr(8);
            }
          }
        }

        .right_image {
          position: absolute;
          right: 0;
          bottom: ptr(-100);
          width: 45%;
          aspect-ratio: 587 / 395;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }
      }

      &.section_layout_start,
      & .section_layout_start {
        @include flex(flex-start, flex-start);

        gap: ptr(32);
      }

      &.section_layout_between {
        @include flex(space-between, flex-start);

        gap: ptr(32);
      }

      & .inner_section_layout {
        @include flex(flex-start, flex-start, column);

        gap: ptr(32);
      }

      & .bottom_right {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .story_content {
        @include flex(flex-start, flex-start, column);

        gap: ptr(32);

        & .link {
          @include flex(flex-start, center);

          padding: ptr(8) 0;
          border-bottom: 1px solid var(--Black, #141414);
          gap: ptr(8);

          svg {
            width: ptr(16);
            height: ptr(16);
          }
        }
      }
    }
  }
}

html[is_mobile="true"] section.story {
  padding: ptr(80) ptr(24);
  @include flex(space-between, flex-start);

  .story_wrapper {
    width: 100%;
    height: auto;
    @include flex(flex-start, flex-start, column);

    gap: ptr(60);

    .title {
      width: 100%;
      @include flex(flex-end, flex-start, column);

      gap: ptr(24);
    }

    .image_wrapper {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: ptr(8);
      }
    }

    .story_section {
      position: relative;
      width: 100%;

      .swiper {
        overflow: hidden;
      }

      .section_wrapper {
        width: fit-content;
        @include flex(flex-start, flex-start, column);

        gap: ptr(32);
      }

      &.section_layout_start,
      & .section_layout_start {
        width: 100%;
        @include flex(flex-start, flex-start, column);

        gap: ptr(32);
      }

      &.section_layout_end,
      & .section_layout_end {
        width: 100%;
        @include flex(flex-start, flex-end, column);

        gap: ptr(32);
      }

      & .inner_section_1 {
        width: ptr(280);
        height: ptr(372);

        & .swiper_slide {
          width: ptr(280);
          height: ptr(372);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }
      }

      & .inner_section_2 {
        width: ptr(300);
        height: ptr(300);

        & .swiper_slide {
          width: ptr(300);
          height: ptr(300);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }
      }

      & .inner_section_3 {
        position: relative;
        width: 100%;
        height: ptr(658);

        .top_image {
          position: absolute;
          top: 0;
          left: 0;
          width: ptr(280);
          height: ptr(372);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }

        .bottom_image {
          position: absolute;
          right: 0;
          bottom: 0;
          width: ptr(240);
          height: ptr(318);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }
      }

      & .inner_section_4 {
        width: ptr(300);
        height: ptr(225);

        & .swiper_slide {
          width: ptr(300);
          height: ptr(225);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }
      }

      &.inner_section_5 {
        width: 100%;

        .top_image {
          width: ptr(280);
          height: ptr(372);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }

        .bottom_image {
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ptr(8);
          }
        }
      }

      .story_content {
        @include flex(flex-start, flex-start, column);

        gap: ptr(8);

        & .link {
          margin-top: ptr(8);
          @include flex(flex-start, center);

          padding: ptr(8) 0;
          border-bottom: 1px solid var(--Black, #141414);
          gap: ptr(8);

          svg {
            width: ptr(16);
            height: ptr(16);
          }
        }
      }
    }
  }
}
