@import "base";
html[is_mobile="false"] {
  .root {
    padding: ptr(32) ptr(16);
  }

  .section_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: ptr(16);
    padding-bottom: ptr(24);
    border-bottom: 1px solid $gray-5;
  }

  .buttons {
    display: flex;
    align-items: center;
    column-gap: ptr(8);

    .button_print,
    .button_pdf {
      min-width: ptr(120);
      max-height: ptr(40);
    }
  }

  .result_price {
    color: $black;
  }
}

html[is_mobile="true"] {
  .root {
    background: inherit;
  }
}
