@import "base";
html[is_mobile="false"] {
  .root {
    padding-top: ptr(24);
  }
}

html[is_mobile="true"] {
  .root {
    min-height: ptr(695);
    padding: ptr(24) 0 ptr(60);
    background: inherit;
  }
}
