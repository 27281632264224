@import "base";
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto 0;
  gap: ptr(80);

  html[is_mobile="true"] & {
    margin: ptr(60) auto 0;
    padding: ptr(48) ptr(24);
    gap: ptr(48);
  }

  .logo {
    width: ptr(167);
    height: ptr(32);
    color: $blue;

    > img {
      height: 100%;
      object-fit: cover;
    }
  }

  .loading {
    width: ptr(72);
    height: ptr(72);
    color: $blue;

    > img {
      height: 100%;
      object-fit: cover;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    text-align: center;

    &[data-gap="8"] {
      gap: ptr(8);
    }

    &[data-gap="16"] {
      gap: ptr(16);
    }

    &[data-gap="48"] {
      gap: ptr(48);
    }
  }

  .congrats,
  .timer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ptr(480);
    height: ptr(320);

    html[is_mobile="true"] & {
      width: ptr(320);
      height: ptr(214);
    }

    > img {
      height: 100%;
      object-fit: cover;
    }
  }

  .congrats {
    padding: 0;
  }

  .title {
    color: $gray-80;
  }

  .guide {
    color: $gray-50;
  }

  .cs {
    color: $blue;
  }
}
