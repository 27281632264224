@import "base";
@import "../../../../../shared/style/base";

.section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: ptr(16);
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ptr(100);
  border-radius: ptr(12);
  background-color: $gray-5;
  color: $gray-80;

  .count_container {
    margin-top: ptr(4);
  }

  .count {
    color: $black;
  }
}
