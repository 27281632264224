@import "base";
html[is_mobile="true"] {
  .container {
    display: flex;
    height: ptr(60);
    margin: ptr(4) ptr(16);
    border-radius: ptr(8);
    background-color: $black;

    .text_container {
      display: inline-flex;
      z-index: 1;
      flex-direction: column;
      align-items: center;
      margin-left: ptr(16);
      padding: ptr(10) 0;
      color: $white;

      .text {
        line-height: 100% !important;
      }
    }

    .image_container {
      position: relative;
      z-index: 0;
      width: 100%;
      height: 100%;
    }

    .clover_image {
      display: flex;
      position: absolute;
      right: ptr(-6);
      bottom: ptr(-2);
      width: ptr(84);
      aspect-ratio: 1 / 1;
    }

    .background_image {
      position: absolute;
      right: ptr(42);
      height: 100%;
    }
  }
}
