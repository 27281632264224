@import "base";
@import "../../../../shared/style/sub/mixin";

// 추상 클래스에 사용되는 스타일입니다.
// base style 만 추가해주세요.
.table_container {
  display: flex;
  overflow-x: auto;
  @include hiddenScroll;

  .abstract_table {
    position: relative;
    flex: 1;
    background-color: $white;

    .header {
      display: grid;
      place-items: center center;
    }

    .body {
      position: relative;

      .table_row {
        display: grid;
        align-content: center;
        overflow: hidden;
        color: inherit;
        list-style: none;
        justify-items: center;
      }

      .table_cell {
        display: flex;
        align-items: center;
        width: 100%;

        &.left {
          justify-content: start;
          text-align: start;
        }

        &.center {
          justify-content: center;
          text-align: center;
        }

        &.right {
          justify-content: end;
          text-align: end;
        }
      }
    }
  }
}
