@import "base";
html[is_mobile="true"] {
  .controller {
    padding-right: ptr(8);
  }

  .timer {
    padding-right: ptr(8);
  }
}

.controller {
  display: flex;
  align-content: center;
  padding-right: ptr(16);
  gap: ptr(12);

  &[data-input-start="false"] {
    display: none;
  }
}

.timer {
  display: flex;
  align-content: center;
  padding-right: ptr(16);
  color: $blue;

  &[data-end="true"] {
    color: $gray-50;
  }
}
