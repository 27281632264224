@import "base";
@import "../../../../../services/MarppleShop/shared/style/base";

.membership_setting_section {
  .title_container {
    display: flex;
    align-items: center;
    margin-bottom: ptr(8);
  }

  .btn_container {
    display: flex;
    flex-wrap: wrap;
    gap: ptr(8);
    margin-top: ptr(8);
  }

  .btn {
    flex-shrink: 0;
  }
}
