@import "base";
@import "./mixins";
@import "../../../shared/style/sub/mixin";

.search_page {
  .hidden {
    display: none !important;
  }

  .pointed {
    color: $blue;
  }

  .tab_container {
    margin-bottom: ptr(48);
  }

  .section {
    &:not(:last-child) {
      margin-bottom: ptr(96);
    }

    .section_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: ptr(24);
    }

    .count {
      @extend .pointed;

      margin-left: ptr(4);
    }

    .see_more {
      color: $gray-80;
    }
  }

  .product_section,
  .plus_shop_section,
  .creator_section,
  .popup_store_section,
  .curation_section {
    @extend .section;
  }

  .pagination {
    justify-content: center;
  }
}

// pc
html[is_mobile="false"] {
  .search_page {
    $breakpoint1: 1440px;
    $breakpoint2: 1280px;

    min-width: $pc-layout-body-min-width;
    max-width: $pc-layout-body-max-width;
    margin-top: $default-header-pc_height;

    .recommend_section {
      margin-top: ptr(48);

      .product_cards_container {
        margin-top: ptr(24);
      }
    }

    .title_container {
      margin-top: ptr(32);
      margin-bottom: ptr(16);
    }

    .product_cards_container {
      display: grid;
      position: relative;
      grid-gap: ptr(48) ptr(16);
    }

    .plus_shops_container {
      display: grid;
      position: relative;
      grid-gap: ptr(24);
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .creators_container {
      display: grid;
      position: relative;
      grid-gap: ptr(24) ptr(16); // FIXME
    }

    .popup_stores_container {
      display: grid;
      grid-gap: ptr(48) ptr(24);
    }

    .curations_container {
      display: grid;
      grid-gap: ptr(48) ptr(24);
    }

    // 전체 탭일 때
    &[data-current_tab="all"] {
      @include normal($breakpoint1) {
        $product_limit: 6;
        $creator_limit: 6;
        $popup_store_limit: 4;
        $curation_limit: 4;
        @include set-responsive($product_limit, $creator_limit, $popup_store_limit, $curation_limit);
        @include set-visibility-by-tab($product_limit, $creator_limit, $popup_store_limit, $curation_limit);
      }

      @include variable($breakpoint1, $breakpoint2) {
        $product_limit: 5;
        $creator_limit: 6;
        $popup_store_limit: 3;
        $curation_limit: 4;
        @include set-responsive($product_limit, $creator_limit, $popup_store_limit, $curation_limit);
        @include set-visibility-by-tab($product_limit, $creator_limit, $popup_store_limit, $curation_limit);
      }

      @include narrow($breakpoint2) {
        $product_limit: 4;
        $creator_limit: 5;
        $popup_store_limit: 3;
        $curation_limit: 4;
        @include set-responsive($product_limit, $creator_limit, $popup_store_limit, $curation_limit);
        @include set-visibility-by-tab($product_limit, $creator_limit, $popup_store_limit, $curation_limit);
      }
    }

    // 전체 탭 아닐 때
    &:not([data-current_tab="all"]) {
      @include normal($breakpoint1) {
        $product_limit: 6;
        $creator_limit: 4;
        $popup_store_limit: 4;
        $curation_limit: 4;
        @include set-responsive($product_limit, $creator_limit, $popup_store_limit, $curation_limit);
        @include set-visibility-by-tab($product_limit, $creator_limit, $popup_store_limit, $curation_limit);
      }

      @include variable($breakpoint1, $breakpoint2) {
        $product_limit: 5;
        $creator_limit: 4;
        $popup_store_limit: 3;
        $curation_limit: 4;
        @include set-responsive($product_limit, $creator_limit, $popup_store_limit, $curation_limit);
        @include set-visibility-by-tab($product_limit, $creator_limit, $popup_store_limit, $curation_limit);
      }

      @include narrow($breakpoint2) {
        $product_limit: 4;
        $creator_limit: 4;
        $popup_store_limit: 3;
        $curation_limit: 4;
        @include set-responsive($product_limit, $creator_limit, $popup_store_limit, $curation_limit);
        @include set-visibility-by-tab($product_limit, $creator_limit, $popup_store_limit, $curation_limit);
      }
    }
  }
}

// mobile
html[is_mobile="true"] {
  .search_page {
    $_padding: 16px;
    $_product-card-min-width: 100px;
    $_product-card-max-width: 216px;

    margin-top: $default-header-mo_height;

    .title_container {
      display: none;
    }

    .section {
      margin: 0 $_padding;
    }

    .product_cards_container {
      display: grid;
      position: relative;
      grid-gap: ptr(16) 8px;
    }

    .pagination {
      margin-top: ptr(58);
    }

    @mixin set-grid-columns($columns) {
      grid-template-columns: repeat($columns, 1fr);
    }

    // FIXME: @kjj 다른 컨테이너도 적용 가능하도록 mixin 수정
    @mixin responsive-container-grid($breakpoints) {
      @each $min-width, $columns, $padding in $breakpoints {
        @container (min-width: #{calc(#{$min-width} * #{$columns} + #{$padding} * (#{$columns} - 1))}) {
          .creators_container {
            grid-template-columns: repeat($columns, 1fr);
          }
        }
      }
    }

    .recommend_section {
      $_margin: ptr(16);

      .section_title {
        margin-left: $_margin;
        color: $gray-80;
      }

      .recommended_products_container {
        display: flex;
        flex-wrap: nowrap;
        margin-top: ptr(16);
        overflow-x: auto;
        gap: ptr(12);
        @include hiddenScroll;

        .product_card {
          width: ptr(128);

          &:first-child {
            margin-left: $_margin;
          }

          &:last-child {
            margin-right: $_margin;
          }
        }
      }
    }

    .creator_section,
    .plus_shop_section,
    .popup_store_section,
    .curation_section {
      margin-top: ptr(48);
    }

    .creator_section {
      container-type: inline-size;
    }

    .plus_shops_container {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: ptr(24);
    }

    .creators_container {
      display: grid;
      position: relative;
      grid-gap: ptr(32) 16px;
    }

    .popup_stores_container {
      display: grid;
      grid-gap: ptr(32) 16px;
    }

    .curations_container {
      display: grid;
      grid-gap: ptr(32) 16px;
    }

    @mixin hide-children-over($limit) {
      & > div:nth-child(n + #{$limit + 1}) {
        display: none !important;
      }
    }

    // FIXME: @kjj 함수로 수정
    // 전체 탭일 때
    &[data-current_tab="all"] {
      .product_cards_container {
        @include narrow(240px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        @include variable(348px, 240px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include variable(456px, 348px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        @include variable(691px, 456px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        @include variable(1366px, 691px) {
          grid-column-gap: 16px;
          grid-template-columns: repeat(5, minmax(0, 1fr));
          @include hide-children-over(5);
        }
        @include normal(1366px) {
          grid-column-gap: 16px;
          grid-template-columns: repeat(6, minmax(0, 1fr));
        }
      }

      .creators_container {
        @include narrow(240px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
          @include hide-children-over(6);
        }
        @include variable(348px, 240px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          @include hide-children-over(6);
        }
        @include variable(456px, 348px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
          @include hide-children-over(6);
        }
        @include variable(691px, 456px) {
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }
        @include variable(1366px, 691px) {
          grid-column-gap: 16px;
          grid-template-columns: repeat(5, minmax(0, 1fr));
          @include hide-children-over(5);
        }
        @include normal(1366px) {
          grid-column-gap: 16px;
          grid-template-columns: repeat(6, minmax(0, 1fr));
          @include hide-children-over(6);
        }
      }

      .popup_stores_container,
      .curations_container {
        @include variable(691px, 456px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include variable(1366px, 691px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include normal(1366px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
          @include hide-children-over(3);
        }
      }
    }

    // 전체 탭 아닐 때
    &:not([data-current_tab="all"]) {
      // FIXME: @kjj 함수로 수정
      .product_cards_container {
        display: grid;
        position: relative;
        grid-auto-rows: min-content;
        grid-gap: ptr(32) ptr(16);
        flex: 1 0;
        min-height: ptr(524);

        $breakpoint1: 691px;
        $breakpoint2: 375px;

        @media (min-width: #{$breakpoint1}) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        @media (min-width: #{$breakpoint2}) and (max-width: #{$breakpoint1 - 1px}) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @media (max-width: #{$breakpoint2 - 1px}) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      //  @include narrow(240px) {
      //    grid-template-columns: repeat(1, minmax(0, 1fr));
      //  }
      //  @include variable(348px, 240px) {
      //    grid-template-columns: repeat(2, minmax(0, 1fr));
      //  }
      //  @include variable(456px, 348px) {
      //    grid-template-columns: repeat(3, minmax(0, 1fr));
      //  }
      //  @include variable(691px, 456px) {
      //    grid-template-columns: repeat(4, minmax(0, 1fr));
      //  }
      //  @include variable(1366px, 691px) {
      //    grid-column-gap: 16px;
      //    grid-template-columns: repeat(3, minmax(0, 1fr));
      //  }
      //  @include normal(1366px) {
      //    grid-column-gap: 16px;
      //    grid-template-columns: repeat(6, minmax(0, 1fr));
      //  }
      //}

      // 크리에이터
      @include responsive-container-grid(
        ((288px, 2, 16px), (288px, 3, 16px), (288px, 4, 16px), (288px, 5, 16px))
      );

      .popup_stores_container,
      .curations_container {
        @include narrow(691px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        @include variable(1366px, 691px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include normal(1366px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }
    }
  }
}
