@import "base";
.limited_edition_container {
  width: 100%;
  padding: ptr(60) 0;
  background-color: $black;
  @include flex(center, center, column);

  html[is_mobile="false"] & {
    padding: ptr(96) 0;
  }
}

.limited_edition_wrapper {
  width: 100%;
  @include flex(center, flex-start, column);

  gap: ptr(24);

  html[is_mobile="false"] & {
    max-width: $pc-layout-body-max-width;
  }
}

.limited_edition {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  padding: 0 ptr(16);
  gap: ptr(32) ptr(16);

  html[is_mobile="false"] & {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: ptr(24);
    padding: 0 ptr(32);
  }
}
