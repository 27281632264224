@import "base";
.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: $pc-layout-body-max-width;
  gap: ptr(24);
}

.list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ptr(32);
  gap: ptr(24);

  html[is_mobile="true"] & {
    padding: 0 ptr(16);
    overflow: auto hidden;
    scroll-padding-left: ptr(16);
    scroll-snap-type: x mandatory;
    @include hiddenScroll;
  }
}

.creator {
  display: flex;
  flex: 0 0 ptr(176);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ptr(176);
  gap: ptr(16);

  html[is_mobile="true"] & {
    max-width: ptr(120);
    gap: ptr(12);
    scroll-snap-align: start;
  }

  .image_container {
    flex-shrink: 0;
    width: 100%;
    overflow: hidden;
    border: 1px solid $gray-5;
    border-radius: 999px;
    aspect-ratio: 1/1;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .name_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: ptr(2);

    .name_wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: ptr(8);

      html[is_mobile="true"] & {
        gap: ptr(4);
      }
    }

    .name {
      max-width: 100%;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .icon {
      width: ptr(20);
      height: ptr(20);
    }

    .category {
      width: 100%;
      overflow: hidden;
      color: $gray-80;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
