@import "base";
.page {
  margin-top: $default-header-pc_height;
  padding-top: ptr(24);

  html[is_mobile="true"] & {
    margin-top: $default-header-mo_height;
    margin-bottom: ptr(80);
    padding-top: 0;

    &.full {
      margin-top: 0;
    }
  }
}

.popupstore {
  margin-bottom: ptr(120);

  .list:not(.archived) {
    @include flex(center, stretch, column);

    gap: ptr(24);
  }

  .tabs {
    margin: ptr(24) 0 ptr(32);
  }

  html[is_mobile="true"] & {
    position: relative;
    margin-bottom: 0;
    padding: 0 ptr(16);

    .tabs {
      margin: 0 ptr(-16) ptr(16);
      padding: calc($default-header-mo_height + ptr(12)) ptr(16) ptr(12);

      &.sticky {
        position: sticky;
        z-index: 10;
        top: 0;
        transition: top 0.35s;
        background: $white;

        &:global(.unpinned) {
          top: calc($default-header-mo_height * -1);
        }
      }
    }

    &.full {
      padding: 0;

      .tabs {
        position: fixed;
        z-index: 1;
        top: $default-header-mo_height;
        right: 0;
        left: 0;
        margin: 0;
        padding: ptr(8) ptr(16);
      }
    }
  }
}

.tooltip {
  @include flex(center, stretch, column);

  gap: ptr(16);
  max-width: ptr(320 - 16 * 2);
  color: $blue;
  text-align: center;

  p {
    word-break: keep-all;
  }

  .tooltip_link {
    @include flex(center, center);

    gap: ptr(4);
    padding: ptr(4) 0;
    cursor: pointer;

    svg {
      width: ptr(16);
    }
  }
}

.subscribe_form {
  .form_body {
    @include flex(flex-start, stretch, column);

    gap: ptr(24);
    padding-bottom: ptr(16);
  }

  .form_section {
    @include flex(flex-start, stretch, column);

    gap: ptr(8);
  }

  .form_input {
    @include flex(flex-start, center);

    width: 100%;
    height: ptr(32);
    border-bottom: 1px solid $gray-10;

    &::placeholder {
      font-weight: 400;
    }
  }

  .form_guide {
    padding: ptr(16);
    border-radius: ptr(8);
    background: $gray-5;
    color: $gray-80;

    li {
      padding-left: ptr(10);
      list-style: "·";
    }
  }

  .form_action {
    @include flex(space-between, center);

    gap: ptr(16);
    margin: 0 ptr(-16);
    padding: ptr(16) ptr(16) 0;
    border-top: 1px solid $gray-5;
  }
}
