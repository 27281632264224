@import "base";
html[is_mobile="false"] {
  .container {
    margin-top: $default-header-pc_height;
    margin-bottom: ptr(120);
  }

  .tooltip {
    @include flex(center, stretch, column);

    gap: ptr(16);
    max-width: ptr(320 - 16 * 2);
    color: $blue;
    text-align: center;

    p {
      word-break: keep-all;
      white-space: pre-line;
    }

    .tooltip_link {
      @include flex(center, center);

      gap: ptr(4);
      padding: ptr(4) 0;
      cursor: pointer;

      svg {
        width: ptr(16);
      }
    }
  }

  .tooltip_icon {
    color: $blue;
  }

  .title {
    margin-bottom: ptr(24);
    padding-top: ptr(24);
  }

  .svg_title {
    display: inline-block;
    width: ptr(246);
    height: ptr(35);
    color: $blue;
  }

  .creator_plus {
    @include flex(flex-start, stretch, column);

    gap: ptr(24);
    margin-bottom: ptr(120);

    .list {
      @include grid(5, ptr(24));
    }
  }
}

html[is_mobile="true"] {
  .container {
    margin-top: $default-header-mo_height;
    margin-bottom: ptr(120);
  }

  .tooltip {
    @include flex(center, stretch, column);

    gap: ptr(16);
    max-width: ptr(320 - 16 * 2);
    color: $blue;
    text-align: center;

    p {
      word-break: keep-all;
      white-space: pre-line;
    }

    .tooltip_link {
      @include flex(center, center);

      gap: ptr(4);
      padding: ptr(4) 0;
      cursor: pointer;

      svg {
        width: ptr(16);
      }
    }
  }

  .tooltip_icon {
    color: $blue;
  }

  .svg_title {
    width: ptr(172);
    height: ptr(24);
    color: $blue;
  }

  .title {
    margin-bottom: ptr(24);
  }

  .creator_plus {
    @include flex(flex-start, stretch, column);

    gap: ptr(24);
    margin-bottom: ptr(90);

    .wrap {
      position: relative;
      padding: 0 ptr(16);
    }

    .list {
      @include grid(2, ptr(16));
    }
  }
}
