@import "base";
.button_radio {
  display: flex;
  position: relative;
  flex: 1 1 0;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &[data-fit="true"] {
    flex: unset;
    width: fit-content;
  }

  &:hover > .radio_wrapper > .button {
    border-color: $blue;
  }

  .radio_wrapper {
    display: flex;
    position: relative;
    align-items: center;
    gap: ptr(8);

    .radio {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .radio:checked ~ .button {
      border-width: ptr(4);
      border-color: $blue;
    }

    .radio:disabled ~ .button {
      border-width: ptr(4);
      border-color: $gray-20;
      background-color: $gray-5;
    }

    .button {
      width: ptr(16);
      height: ptr(16);
      transition:
        border-width 300ms ease-out,
        border-color 300ms ease-out;
      animation-duration: 300ms;
      animation-timing-function: ease-out;
      border: 1px solid $gray-20;
      border-radius: 99px;
      background-color: $white;
    }
  }
}
