@import "base";
html[is_mobile="false"] {
  .wrap {
    width: 100%;
    margin: calc(#{$default-sub-header-pc_height} + #{$default-header-pc_height}) auto 120px auto;
  }

  .title_wrapper {
    @include flex(center, flex-end);

    width: 100%;
  }

  .title {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .control_panel {
    @include flex(flex-end, center);

    width: 100%;
    margin-bottom: 24px;
  }

  .creators {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

html[is_mobile="true"] {
  .wrap {
    margin-top: calc(#{$default-sub-header-mo_height} + #{$default-header-mo_height});
    margin-bottom: 120px;
  }

  .title {
    margin-top: 24px;
  }

  .control_panel {
    @include flex(flex-end, center);

    width: 100%;
    height: ptr(42);
    padding: ptr(4) 0;
  }

  .creators {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
