@import "base";
@import "../../../../../../shared/style/base";
@import "../../variables";

.like {
  width: $like-size;
  height: $like-size;
  aspect-ratio: 1 / 1;

  &.theme_light {
    color: $gray-20;

    &.active {
      color: $blue;
    }
  }

  &.theme_dark {
    color: $gray-80;

    &.active {
      color: $blue;
    }
  }

  &.theme_color {
    color: $bright-20;

    &.active {
      color: $white;
    }
  }
}
