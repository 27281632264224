@import "base";
@import "../../components/cells/Layout/variables";
@import "../../../shared/style/base";

.order_overview {
  .tabs_container {
    @include mobile {
      padding: 0 $marppleshop-studio-layout-padding-mo;
      overflow-x: scroll;
      @include hiddenScroll;
    }
  }

  .table_container {
    margin-top: ptr(24);
    background-color: $gray-5;

    &.empty {
      overflow: hidden;
    }
    @include pc {
      padding: ptr(24);
      border-radius: ptr(8);
    }

    &.with_pagination {
      @include pc {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .table {
    @include mobile {
      margin: ptr(20) ptr(16);
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-5;
    @include pc {
      padding-bottom: ptr(32);
      border-bottom-right-radius: ptr(8);
      border-bottom-left-radius: ptr(8);
    }
    @include mobile {
      padding-bottom: ptr(20);
    }
  }

  .goods_type_badge {
    padding: ptr(4) ptr(8);
    border-radius: ptr(6);

    &.mps {
      background-color: $blue-10;
      color: $blue-100;
    }

    &.tpl {
      background-color: $pink-20;
      color: $red-80;
    }

    &.pbg {
      background-color: $purple-10;
      color: $purple-100;
    }

    &.dtg {
    }
  }

  .option {
    @include lineLimit(2);

    white-space: break-spaces;
  }

  .name_container {
    display: flex;
    align-items: center;
    @include pc {
      gap: ptr(16);
    }
    @include mobile {
      gap: ptr(8);
    }

    .product_thumbnail {
      width: ptr(40);
      height: ptr(40);
      overflow: hidden;
      border-radius: ptr(4);
    }

    .name {
      @include limitLine(2);
    }
  }
}
