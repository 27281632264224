@import "base";
@keyframes hideElement {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes showElement {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.container {
  position: relative;
  width: 100%;
  @include flex(center, center);
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ptr(400);
  overflow: hidden;

  html[is_mobile="true"] & {
    height: 100%;
  }

  .dim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $dim-60;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(20px);
  }
}

.content_container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: ptr(96);
  will-change: opacity;

  html[is_mobile="true"] & {
    padding: 0;
  }

  &[data-hide="true"] {
    animation: hideElement 600ms ease-out;
  }

  &[data-show="true"] {
    position: absolute;
    top: 0;
    left: 0;
    animation: showElement 600ms ease-out;
  }
}

.content {
  display: flex;
  z-index: 1;
  width: 100%;
  padding: 0 ptr(16);
  gap: ptr(16);

  html[is_mobile="true"] & {
    flex-direction: column;
    min-height: ptr(723);
    padding: ptr(60) ptr(16);
    gap: ptr(24);
  }

  html[is_mobile="false"] & {
    max-width: $pc-layout-body-max-width;
    padding: 0 ptr(32);
    gap: ptr(32);
  }

  .live_frame_container {
    flex-shrink: 0;

    html[is_mobile="false"] & {
      width: ptr(912);
    }

    html[is_mobile="true"] & {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: ptr(12);
    }
  }

  .live_frame {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;
    border-radius: ptr(24);
    background-color: $gray-5;

    > iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      transform: translate3d(-50%, -50%, 0);
      border-radius: ptr(24);
    }
  }

  .live_content_container {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .live_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: ptr(432);
    gap: ptr(16);

    html[is_mobile="true"] & {
      gap: ptr(24);
    }
  }

  .body {
    width: 100%;
    color: $white;
    @include lineLimit(2);

    html[is_mobile="true"] & {
      height: ptr(68);
    }
  }

  .button_label {
    display: inline-flex;
    align-items: center;
    gap: ptr(4);

    .button_icon {
      width: ptr(16);
      height: ptr(16);
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(8);

    html[is_mobile="true"] & {
      flex: 1 1 0;
      justify-content: space-between;
      height: 100%;
      gap: ptr(24);
    }

    .container {
      html[is_mobile="true"] & {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: ptr(24);
      }
    }

    .navigation {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: ptr(12);

      html[is_mobile="true"] & {
        justify-content: flex-start;
      }
    }

    .product_container {
      display: flex;
      position: relative;
      width: 100%;
      gap: ptr(16);

      html[is_mobile="true"] & {
        gap: ptr(24);
        flex-direction: column;
      }

      .product {
        width: ptr(208);

        html[is_mobile="true"] & {
          width: 100%;
        }
      }
    }
  }
}

.pagination {
  @include flex(center, center);

  gap: ptr(12);
  color: $white;

  .pages {
    @include flex(flex-start, center);

    flex-shrink: 0;
    width: ptr(40);
    height: 1px;
    background-color: $bright-20;

    .page {
      height: 100%;
      background-color: $white;
    }
  }

  & > span {
    min-width: ptr(15);
  }
}
