@import "base";
html[is_mobile="false"] .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: ptr(556);
  gap: ptr(8);

  .thumbnail {
    width: 100%;
    margin-bottom: ptr(8);
    border-radius: ptr(24);
    cursor: pointer;
  }

  .creator_name {
    color: $gray-50;
  }

  .title {
    @include lineLimit(2);

    cursor: pointer;
  }

  .bottom_box {
    width: 100%;
    color: $gray-50;
    @include flex(space-between, center);

    .date {
      color: $gray-50;
    }

    .visitor_count {
      color: $blue-100;
      @include flex(center, center);

      gap: ptr(4);

      & > svg {
        width: ptr(20);
        height: ptr(20);
      }
    }
  }
}

html[is_mobile="true"] .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 ptr(32);
  gap: ptr(8);

  .thumbnail {
    width: 100%;
    margin-bottom: ptr(8);
    border-radius: ptr(24);
    cursor: pointer;
  }

  .creator_name {
    color: $gray-50;
  }

  .title {
    @include lineLimit(2);

    text-align: left;
    cursor: pointer;
  }

  .bottom_box {
    width: 100%;
    color: $gray-50;
    @include flex(space-between, center);

    .date {
      color: $gray-50;
    }

    .visitor_count {
      color: $blue-100;
      @include flex(center, center);

      gap: ptr(4);

      & > svg {
        width: ptr(20);
        height: ptr(20);
      }
    }
  }
}
