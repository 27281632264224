@import "base";
.dropdown {
  position: relative;
  width: 100%;
  height: ptr(48);
  padding: ptr(12) 0;
  border-bottom: ptr(1) solid $gray-50;
  user-select: none;

  & input {
    display: none;
  }

  .dropdown_header {
    @include flex(space-between, center);

    background-color: transparent;
    color: $gray-50;
    cursor: pointer;

    span.asterisk {
      color: $red-80;
    }

    span.icon_arrow {
      color: $gray-50;

      & > svg {
        width: ptr(14);
        height: ptr(14);
      }
    }
  }

  .dropdown_options {
    position: absolute;
    z-index: 1;
    top: calc(100% - 8px);
    left: 0;
    width: 100%;
    padding: ptr(8) 0;
    overflow-y: auto;
    border: ptr(1) solid $bright-60;
    border-radius: ptr(8);
    background: $bright-20;
    backdrop-filter: blur(20px);
  }

  &[data-active="true"] {
    border-bottom: ptr(1) solid $white;

    .dropdown_header {
      color: $white;

      span.asterisk {
        display: none;
      }

      span.icon_arrow {
        color: $white;
      }
    }
  }
}

.option {
  height: ptr(30);
  padding: ptr(4) ptr(12);
  color: $gray-5;
  cursor: pointer;

  &:hover {
    background-color: $blue-100;
  }

  &.selected {
    background-color: $blue-100;
  }
}
