@import "base";
@import "../../../../shared/style/sub/mixin";

@mixin set-input-color($color) {
  & .input {
    color: $color;
  }
}

@mixin set-input-placeholder-color($color) {
  & .input::placeholder {
    color: $color;
  }
}

@mixin set-search-icon-color($color) {
  & .search_icon {
    color: $color;
  }
}

.input_search {
  $default-color: $black;
  $default-placeholder-color: $gray-80;

  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ptr(8);
  background-color: $dim-5;
  @include pc {
    padding: ptr(8) ptr(16);
  }
  @include mobile {
    padding: ptr(8) ptr(12);
  }

  &.backdrop_filter {
    backdrop-filter: blur(20px);
  }

  // transparent
  &.transparent {
    background-color: transparent;
    color: $white;

    @include set-input-color($white);
    @include set-input-placeholder-color($white);
  }

  &.placeholder_gray50 {
    @include set-input-placeholder-color($gray-50);
  }

  .input {
    display: inline-block;
    width: 100%;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: $default-color;
    caret-color: $blue-100;

    &::placeholder {
      color: $default-placeholder-color;
    }
  }

  .reset_icon {
    height: ptr(16);
    margin-left: ptr(12);
    aspect-ratio: 1 / 1;
  }

  .search_icon {
    display: inline-block;
    height: ptr(24);
    margin-left: ptr(12);
    cursor: pointer;
    aspect-ratio: 1 / 1;

    svg {
      height: 100%;
    }
    @include mobile {
      color: $gray-50;
    }
  }
}
