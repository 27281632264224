@import "base";
html[is_mobile="false"] section.container {
  position: relative;
  height: ptr(1080);
  background-color: $gray-5;

  .title_wrapper {
    padding: ptr(188) ptr(188) 0;

    .title {
      margin-bottom: ptr(24);
    }

    .description {
      margin-bottom: ptr(48);
    }

    .button {
      width: max-content;
      min-width: ptr(234);

      & > svg {
        width: ptr(20);
        height: ptr(20);
      }
    }
  }

  .bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: ptr(1515);
  }
}

html[is_mobile="true"] section.container {
  position: relative;
  width: 100%;
  height: ptr(812);
  padding: ptr(60) ptr(32);
  background-color: $gray-5;

  .title_wrapper {
    .title {
      margin-bottom: ptr(16);
    }

    .description {
      margin-bottom: ptr(48);
    }

    .button {
      display: none;
    }
  }

  .bg {
    position: absolute;
    right: ptr(-196);
    bottom: 0;
    width: ptr(900);
  }
}
