@import "base";
.popup_store_item_card {
  position: relative;
  @include flex(flex-start, stretch, column);

  gap: ptr(16);

  .image {
    position: relative;
    overflow: hidden;
    border-radius: ptr(18);
    aspect-ratio: 442 / 332;

    img {
      @include objectCover;
    }

    &.dimmed {
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        background: $dim-30;
        @include blur30;
      }
    }
  }

  .badges {
    position: absolute;
    top: ptr(16);
    left: ptr(16);
    @include flex(flex-start, center);

    gap: ptr(4);

    .badge {
      padding: ptr(4) ptr(8) ptr(5);
      border-radius: ptr(4);
      background: $black;
      color: $white;
    }
  }

  .meta {
    @include flex(flex-start, stretch, column);

    gap: ptr(8);
  }

  .title {
    @include lineLimit(2);

    a {
      white-space: pre-line;
    }
  }

  .score {
    @include flex(flex-start, center);

    gap: ptr(8);
    color: $blue;

    .separation {
      display: block;
      width: 1px;
      height: ptr(14);
      background: $blue;
    }
  }

  .store {
    @include limitLine(1);
  }

  .date {
    color: $gray-50;
  }

  // 모바일 스타일
  html[is_mobile="true"] & {
    gap: ptr(8);

    .image {
      aspect-ratio: 343 / 258;
      border-radius: ptr(16);
    }

    .badges {
      top: ptr(12);
      left: ptr(12);

      .badge {
        padding: ptr(1) ptr(4) ptr(2);
      }
    }

    .meta {
      @include flex(flex-start, stretch, column);

      gap: ptr(4);
    }

    .score {
      gap: ptr(4);

      .separation {
        height: ptr(14);
      }
    }

    .date {
      color: $gray-50;
    }
  }
}
