@import "base";
@import "./variables";
@import "../../../../shared/style/base";

.layout {
  color: $black;

  .no_padding {
    padding: 0 !important;
  }

  .footer {
    margin: 0 $marppleshop-studio-layout-padding-mo;

    &.no_padding {
      padding: 0;
    }
  }
}
