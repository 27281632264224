@import "base";
html[is_mobile="false"] {
  .container {
    min-width: $pc-layout-body-min-width;
    margin-bottom: ptr(96);
  }

  .main_visual {
    position: relative;
    height: ptr(740);
    margin-bottom: ptr(96);
  }

  .creator_ranking {
    width: 100%;
    max-width: $pc-layout-body-max-width;
    margin: 0 auto;
  }

  .tooltip {
    @include flex(center, stretch, column);

    gap: ptr(16);
    max-width: ptr(320 - 16 * 2);
    color: $blue;
    text-align: center;

    p {
      word-break: keep-all;
      white-space: pre-line;
    }

    .tooltip_link {
      @include flex(center, center);

      gap: ptr(4);
      padding: ptr(4) 0;
      cursor: pointer;

      svg {
        width: ptr(16);
      }
    }
  }

  .tooltip_icon {
    color: $blue;
  }

  .creator_plus {
    width: 100%;
    max-width: $pc-layout-body-max-width;
    @include flex(center, center, column);

    gap: ptr(24);
    margin: ptr(96) auto;

    .svg_title {
      display: inline-block;
      width: ptr(246);
      height: ptr(35);
      color: $blue;
    }

    .list {
      padding: 0 ptr(32);
      @include grid(5, ptr(24));
    }
  }

  .creator_view {
    width: 100%;
    margin: auto;
    padding: ptr(96) 0;
    background: $purple-100;
    @include flex(center, center, column);

    gap: ptr(24);

    .creator_view_container {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      align-items: flex-start;
      width: 100%;
      max-width: $pc-layout-body-max-width;
      padding: 0 ptr(32);
      column-gap: ptr(24);
    }
  }

  .all_creator {
    width: 100%;
    max-width: $pc-layout-body-max-width;
    margin: ptr(96) auto 0;
    @include flex(center, center, column);

    gap: ptr(24);

    .wrap {
      position: relative;
      width: 100%;
      padding: 0 ptr(32);
    }

    .see_more {
      min-width: ptr(180);
    }
  }
}

html[is_mobile="true"] {
  .container {
    margin-bottom: ptr(60);
  }

  .main_visual {
    position: relative;
    margin-bottom: ptr(60);
  }

  .tooltip {
    @include flex(center, stretch, column);

    gap: ptr(16);
    max-width: ptr(320 - 16 * 2);
    color: $blue;
    text-align: center;

    p {
      word-break: keep-all;
      white-space: pre-line;
    }

    .tooltip_link {
      @include flex(center, center);

      gap: ptr(4);
      padding: ptr(4) 0;
      cursor: pointer;

      svg {
        width: ptr(16);
      }
    }
  }

  .tooltip_icon {
    color: $blue;
  }

  .creator_plus {
    @include flex(center, center, column);

    gap: ptr(16);
    margin-top: ptr(60);
    margin-bottom: ptr(60);

    .svg_title {
      width: ptr(172);
      height: ptr(24);
      color: $blue;
    }

    .list {
      padding: ptr(16);
      @include grid(2, ptr(16));
    }

    .see_more {
      min-width: ptr(180);
    }
  }

  .creator_view {
    position: relative;
    width: 100%;
    padding: ptr(60) 0;
    background: $purple-100;
    gap: ptr(16);
    @include flex(center, center, column);

    .creator_view_container {
      display: flex;
      width: 100%;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      @include hiddenScroll;
    }

    .creator_view_card {
      box-sizing: unset;
      flex: 0 0 auto;
      width: 70vw;
      padding-left: 16px;
      scroll-snap-align: start;

      &:last-child {
        padding-right: 16px;
      }
    }
  }

  .all_creator {
    position: relative;
    width: 100%;
    margin-top: ptr(60);
    gap: ptr(16);
    @include flex(center, center, column);

    .wrap {
      position: relative;
      width: 100%;
      margin: 0 auto;
      padding: 0 ptr(16);
    }

    .see_more {
      min-width: ptr(120);
    }
  }
}
