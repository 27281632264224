@import "base";
html[is_mobile="true"] {
  .input_wrapper {
    height: ptr(40);
    padding: ptr(9) 0;
  }

  .controller {
    padding-right: ptr(8);
  }
}

.input_container {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: ptr(8);

  .helper {
    display: none;
  }

  &[data-error="true"] {
    &[data-has_error_helper="true"] {
      .helper {
        display: flex;
      }

      html[is_mobile="true"] & {
        .helper {
          display: none;
        }
      }
    }

    .border {
      background-color: $red-80;
    }

    .input {
      caret-color: $red-80;
    }
  }

  &[data-error="false"] {
    .input:focus ~ .border {
      background-color: $blue;
    }
  }

  &[data-has_text_helper="true"] {
    .helper {
      display: flex;
    }

    html[is_mobile="true"] & {
      .helper {
        display: none;
      }
    }
  }

  &[data-has_error_helpers="true"] {
    .helper {
      display: flex;
    }
  }
}

.input_wrapper {
  display: flex;
  position: relative;
  align-items: center;
  align-self: stretch;
  height: ptr(48);
  padding: ptr(12) 0;
  background-color: $white;

  &[disabled] {
    background-color: $gray-5;
  }
}

.input {
  gap: ptr(16);
  flex: 1 1 0;
  width: 100%;
  color: $black;
  caret-color: $blue;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &[type="number"] {
    appearance: textfield;
  }

  &::placeholder {
    color: $gray-50;
  }

  &[disabled] {
    color: $gray-10;
  }

  &[data-readonly="true"] {
    color: $black;
  }
}

.controller {
  display: flex;
  align-content: center;
  padding-right: ptr(16);
  gap: ptr(12);

  &[data-input-start="false"] {
    display: none;
  }
}

.border {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: $gray-10;
}

.title {
  display: flex;
  align-items: center;
  gap: ptr(4);

  .require_dot {
    display: flex;
    align-items: flex-start;
    height: ptr(24);
    padding-top: 6px;

    .dot {
      display: inline-flex;
      position: relative;
      width: ptr(4);
      height: ptr(4);
    }
  }
}

.helper {
  display: flex;
  align-items: flex-start;
  gap: ptr(8);
}

.helper_text {
  color: $gray-50;

  &[data-error="true"] {
    color: $red-80;
  }
}

.helper_error {
  display: flex;
  align-items: center;
  padding: ptr(2) ptr(8);
  border-radius: ptr(4);
  background-color: $gray-5;
  color: $gray-50;
  gap: ptr(2);

  &[data-error="true"] {
    background-color: $red-10;
    color: $red-80;
  }

  &[data-error="false"] {
    background-color: $green-10;
    color: $green;
  }

  .check {
    width: ptr(12);
    height: ptr(12);
    margin-bottom: ptr(2);
  }
}
