@import "base";
@import "utils";
@import "../../../../../../www/css/creator/style_mixins";
@import "../../../../shared/style/base";

html[is_mobile="false"] .search_module {
  $default-padding: ptr(16);
  $default-margin: ptr(16);

  @mixin section-title {
    .title {
      display: inline-block;
      margin-bottom: ptr(16);
      color: $gray-50;
    }
  }

  display: grid;
  grid-row-gap: ptr(24);
  grid-template-columns: ptr(320) ptr(64) ptr(320);
  min-width: ptr(659);
  max-width: ptr(1408);
  height: ptr(342);
  padding: ptr(8) ptr(32) ptr(32);
  border-radius: ptr(16);
  background-color: $dim-60;
  @include blur30;

  &.shake {
    animation: shake 0.3s ease-in-out;
  }

  &.visible {
    visibility: visible;
    transition: opacity 500ms ease-in-out;
    opacity: 1;
  }

  &.hiding {
    transition: opacity 500ms ease-in-out;
    opacity: 0;
  }

  &.hidden {
    visibility: hidden;
  }

  .input_section {
    grid-column: 1 / end;
    color: $gray-80;

    .search_input {
      border-radius: ptr(8);
      background-color: $dim-30;
    }
  }

  .placeholder {
    color: $gray-50;
  }

  .recent_section {
    grid-column: 1 / 2;
    padding: 0 ptr(12);

    &.hidden {
      visibility: hidden;
    }
    @include section-title;

    .header {
      display: flex;
      justify-content: space-between;

      .remove_all {
        color: $gray-20;

        &.hidden {
          display: none;
        }
      }
    }

    .recent_search_wrap {
      display: block;
    }

    .recent_section {
      display: inline-flex;
      gap: ptr(8);
      align-items: center;

      .label {
        @include limit-line(1);
      }
    }

    .recent_search {
      display: inline-flex;
      gap: ptr(8);
      align-items: center;

      &.light {
        color: black;
      }

      &.dark {
        color: $white;
      }

      .label {
        @include limit-line(1);
      }
    }

    .searches_container {
      display: flex;
      flex-direction: column;
      gap: ptr(8);

      .empty {
        color: $gray-20;
      }
    }
  }

  .popular_section {
    grid-column: 3 / 4;
    padding: 0 ptr(12);
    justify-self: end;

    @include section-title;
    .searches_container {
      display: flex;
      flex-wrap: wrap;
      gap: ptr(8);
    }
  }

  .curation_section {
    grid-column: 4 / 5;
    width: ptr(260);
    justify-self: end;

    .title {
      display: none;
    }
  }

  .recommend_section {
    display: none;
  }

  @media (width <= 1023px) {
    .curation_section {
      display: none;
    }
  }
}

html[is_mobile="true"] .search_module {
  $default-padding: ptr(16);
  $default-margin: ptr(16);

  @mixin section-hidden {
    &.hidden {
      display: none;
    }
  }
  @mixin section-title {
    .title {
      display: inline-block;
      margin-bottom: ptr(16);
      color: $gray-80;
    }
  }

  .input_section {
    display: flex;
    align-items: center;
    padding: 0 $default-padding ptr(10);

    .search_input {
      width: 100%;
    }

    .close_icon {
      display: inline-block;
      height: ptr(24);
      aspect-ratio: 1 / 1;
      margin-left: $default-margin;
    }
  }

  .recent_section {
    margin-top: $default-margin;
    color: $gray-50;
    @include section-title;

    .header {
      display: flex;
      align-items: start;
      justify-content: space-between;
      padding: 0 $default-padding;

      .remove_all {
        &.hidden {
          display: none;
        }
      }
    }

    .searches_container {
      display: flex;
      gap: ptr(16);
      overflow-y: auto;
      @include hide-scrollbar;

      .recent_search {
        display: inline-flex;
        flex-shrink: 0;
        align-items: center;
        gap: ptr(8);

        &.light {
          color: black;
        }

        &.dark {
          color: $white;
        }

        &:first-child {
          padding-left: $default-margin;
        }

        &:last-child {
          padding-right: $default-margin;
        }
      }

      .empty {
        margin-left: $default-margin;
      }
    }
  }

  .popular_section {
    margin-top: ptr(32);
    padding: 0 $default-padding;
    color: $gray-50;
    @include section-title;

    .searches_container {
      display: flex;
      flex-wrap: wrap;
      gap: ptr(8);
    }
  }

  .curation_section {
    display: none;
  }

  .recommend_section {
    margin-top: ptr(32);
    @include section-title;

    .title {
      margin-left: $default-margin;
    }

    .products_container {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      @include hiddenScroll;

      .product_card {
        width: ptr(128);

        &:first-child {
          padding-left: $default-margin;
        }

        &:last-child {
          padding-right: $default-margin;
        }
      }
    }
  }
}
