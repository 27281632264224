@import "base";
.curation_item {
  position: relative;

  &.dark {
    .title {
      color: $white;
    }

    .subtitle {
      color: $gray-50;
    }
  }

  &.short {
    .thumbnail {
      aspect-ratio: 4/3;
    }

    .title {
      margin-top: ptr(8);
    }
  }

  .img_container {
    display: flex;
    width: 100%;
    overflow: hidden;
    border-radius: ptr(24);

    html[is_mobile="true"] & {
      border-radius: ptr(16);
    }
  }

  .thumbnail {
    width: 100%;
    aspect-ratio: 3/4;
  }

  .category {
    position: absolute;
    top: ptr(12);
    left: ptr(12);
    padding: 1px 4px 2px;
    border-radius: ptr(4);
    background-color: black;
    color: white;
  }

  .title {
    display: block;
    margin-top: ptr(16);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .subtitle {
    display: block;
    margin-top: ptr(4);
    overflow: hidden;
    color: $gray-80;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  $_tag-height: ptr(24);

  .tags {
    display: flex;
    flex-wrap: wrap;
    height: $_tag-height;
    margin-top: ptr(16);
    overflow: hidden;
    column-gap: ptr(8);
  }

  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $_tag-height;
    padding: 0 ptr(8);
    border-radius: ptr(4);
    background-color: $gray-5;
    color: $gray-80;

    html[is_mobile="true"] & {
      padding: ptr(1.5) ptr(4);
    }
  }

  .products {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-top: ptr(16);
    gap: ptr(8);
  }

  .product_item {
    flex: 1;
    overflow: hidden;
    border-radius: ptr(8);
    background-color: #f6f6f6;
    color: #4e4e4e;
  }

  .empty {
    opacity: 0;
    cursor: auto;
  }

  .content_empty {
    padding-top: 100%;
    opacity: 1;
  }

  .show_info_box {
    margin-top: ptr(16);
  }
}
