@import "base";
html[is_mobile="false"] .plus_content_list_page {
  .body {
    width: 100%;
    margin-top: calc(#{$default-sub-header-pc_height} + #{$default-header-pc_height});
    padding-top: ptr(32);
  }

  .page_description {
    margin-bottom: ptr(8);
    color: #6b6b6b;
  }

  .page_title {
    margin-bottom: ptr(32);
  }

  .content_list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: ptr(60) ptr(24);
  }

  .homeContainer {
    width: 100%;
    min-width: $pc-layout-body-min-width;
    padding-top: ptr(96);
    gap: ptr(96);
    @include flex(flex-start, center, column);
  }

  .bannerContainer {
    width: 100%;
    min-width: $pc-layout-body-min-width;
  }
}

html[is_mobile="true"] {
  .body {
    width: 100%;
    margin-top: calc(#{$default-sub-header-mo_height} + #{$default-header-mo_height});
    padding-top: ptr(24);
  }

  .content_list {
    display: grid;
    row-gap: ptr(48);
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .page_description {
    margin-bottom: ptr(4);
    color: #6b6b6b;
  }

  .page_title {
    margin-bottom: ptr(16);
  }
}
