@import "base";
@import "../../../../shared/style/base";
@import "variables";

.product_badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ptr(4);
  gap: ptr(2);

  @include pc {
    min-width: $product_badge_height_pc;
    height: $product_badge_height_pc;
  }
  @include mobile {
    min-width: $product_badge_height_mo;
    height: $product_badge_height_mo;
  }

  &.horizontal_padding8 {
    padding: 0 ptr(8);
  }

  &.horizontal_padding4 {
    padding: 0 ptr(4);
  }

  &.square {
    aspect-ratio: 1 / 1;
  }

  &.sold_out,
  &.closed {
    background-color: $gray-50;
    color: $white;
  }

  &.limited {
    background-color: $blue;
    color: $white;
  }

  &.ranking {
    background-color: $white;
    color: $blue;

    &.top_rank {
      background-color: $blue;
      color: $white;
    }
  }

  &.quantity_limited,
  &.period_limited {
    background-color: $bright-60;
    color: $blue;
    @include blur10;
  }

  &.nft {
    background-color: $purple-100;
    color: $pink-20;
  }

  &.upcoming {
    background-color: $pink-20;
    color: $red-80;
  }

  &.realtime {
    background-color: $orange-100;
    color: $brown-10;
  }

  &.membership {
    background-color: $black;
    color: $white;
  }

  .icon {
    display: inline-flex;

    &.large {
      width: ptr(16);
      height: ptr(16);
    }

    &.medium {
      width: ptr(14);
      height: ptr(14);
    }

    &.small {
      width: ptr(12);
      height: ptr(12);
    }
  }
}
