@import "base";
html[is_mobile="false"] {
  .page {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: $default-header-pc_height;
    padding: ptr(96) 0;
    gap: ptr(24);
  }

  .status {
    color: $gray-10;
    font-size: ptr(320) !important;
    line-height: 70% !important;
    text-align: center;
  }

  .status_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image {
      position: relative;
      width: ptr(360);
      height: ptr(360);
      margin-top: ptr(-100);

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: ptr(8);
    align-items: center;
    justify-content: center;
    text-align: center;

    .description {
      color: $gray-50;
      text-align: center;
    }
  }
}

html[is_mobile="true"] {
  .page {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: $default-header-mo_height;
    padding: ptr(120) 0;
    gap: ptr(24);
  }

  .status {
    color: $gray-10;
    font-size: ptr(160) !important;
    line-height: 70% !important;
    text-align: center;
  }

  .status_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image {
      position: relative;
      width: ptr(240);
      height: ptr(240);
      margin-top: ptr(-50);

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: ptr(8);
    align-items: center;
    justify-content: center;
    text-align: center;

    .description {
      color: $gray-50;
      text-align: center;
    }
  }
}
