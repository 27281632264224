@import "base";
.page {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    margin-bottom: ptr(40);
  }

  .tab {
    margin-bottom: ptr(24);
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: ptr(32) ptr(20);

  @media (max-width 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
