@import "base";
html[is_mobile="false"] {
  .container {
    box-sizing: border-box;
  }
}

html[is_mobile="true"] {
  .container {
  }
}
