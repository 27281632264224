@import "base";
html[is_mobile="false"] {
  .container {
    display: flex;
    flex-direction: column;
  }

  .cate_list {
    padding: 0 ptr(16);
    border-radius: ptr(16);
    cursor: pointer;
    user-select: none;

    .title {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      padding: ptr(12) 0;

      .name.bold {
        display: none;
      }
    }

    &.opened {
      background: $gray-5;

      .cate_items {
        height: auto;
        opacity: 1;
        pointer-events: initial;
      }

      .title {
        .bold {
          display: initial;
        }

        .medium {
          display: none;
        }
      }
    }

    .cate_items {
      height: 0;
      padding-bottom: ptr(8);
      opacity: 0;
      pointer-events: none;
    }

    .cate_item {
      padding: ptr(8) 0;
      list-style: none;

      .medium {
        color: $gray-80;
      }

      .bold {
        display: none;
      }

      &.active {
        .bold {
          display: initial;
          color: $black;
        }

        .medium {
          display: none;
        }
      }
    }
  }

  .hidden {
    display: none !important;
  }
}

html[is_mobile="true"] .collapse {
  .cate_list {
    padding: ptr(12) ptr(16);
    border-radius: ptr(16);

    &.opened {
      background: $gray-5;
    }
  }

  .cate_item {
    list-style: none;

    &.opened {
      color: blue;
    }
  }

  .cate_items {
    display: block;
  }

  .hidden {
    display: none !important;
  }

  .title {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
}
