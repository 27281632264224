@import "base";
button.button {
  display: inline-flex;
  flex: 0 1 0;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: ptr(132);
  min-height: ptr(48);
  padding: 0 ptr(24);
  border-radius: ptr(4);
  cursor: pointer;
  gap: ptr(8);

  &:disabled {
    background-color: $gray-10;
    color: $gray-50 !important;
    cursor: default;
  }

  &[data-size="small"] {
    min-width: ptr(96);
    min-height: ptr(40);
  }

  &[data-size="medium"] {
    min-width: ptr(132);
    min-height: ptr(48);
  }

  &[data-size="large"] {
    flex: 1;
    width: 100%;
    min-height: ptr(52);
  }

  &[data-color="line_gray"] {
    @include innerBorder($gray-10);

    background: $white;
    color: $gray-80 !important;
  }

  &[data-color="line_sky"] {
    @include innerBorder($blue);

    background: $white;
    color: $blue !important;
  }

  &[data-color="white"] {
    background: $blue-10;
    color: $blue !important;
  }

  &[data-color="sky"] {
    background: $blue;
    color: $white !important;
  }
}
