@import "base";
html[is_mobile="false"] {
  .banner {
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: ptr(640);
    padding: ptr(124) 0 ptr(60);

    .banner_wrapper {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      min-width: $pc-layout-body-min-width;
      max-width: $pc-layout-body-max-width;
      height: 100%;
      padding: 0 ptr(32);
    }

    .cover_image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $gray-80;
    }

    .dim {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $dim-60;
    }

    .swiper_container {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: ptr(712);
      gap: ptr(16);
    }
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .container {
      display: flex;
      width: 100%;
      height: 100%;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .swiper {
    width: 100%;
    overflow: hidden;
    border-radius: ptr(16);

    .wrapper {
      display: flex;
      width: 100%;
    }

    .item_wrapper {
      display: block;
      position: relative;
      flex-shrink: 0;
      width: 100%;
      padding-bottom: 56.25%;
    }
  }

  .information {
    z-index: 1;
    width: ptr(480);
    @include flex(center, flex-start, column);

    gap: ptr(24);

    .contents {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: $white;
      pointer-events: auto;
      gap: ptr(16);
    }

    .icon_wrapper {
      display: flex;
      gap: ptr(16);

      .icon {
        display: block;
        width: ptr(24);
        height: ptr(24);
        color: $white;
      }
    }
  }

  .controller {
    z-index: 1;
    bottom: ptr(60);
    width: 100%;
    height: fit-content;
    pointer-events: none;
    @include flex(space-between, center);
  }

  .pagination {
    @include flex(center, center);

    gap: ptr(12);
    color: $white;

    .pages {
      @include flex(flex-start, center);

      flex-shrink: 0;
      width: ptr(48);
      height: 1px;
      background-color: $bright-20;

      .page {
        height: 100%;
        transition: width 1s;
        background-color: $white;
      }
    }

    & > span {
      min-width: ptr(15);
    }
  }

  .navigation {
    pointer-events: auto;
    @include flex(center, center);

    gap: ptr(8);
  }
}

html[is_mobile="true"] {
  .banner {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: ptr(400);
    padding: ptr(92) 0 ptr(32);

    .banner_wrapper {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      gap: ptr(28);
    }

    .cover_image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $gray-80;
    }

    .dim {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $dim-60;
    }

    .swiper_container {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0 ptr(16);
      gap: ptr(16);
    }
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .container {
      display: flex;
      width: 100%;
      height: 100%;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .swiper {
    width: 100%;
    overflow: hidden;
    border-radius: ptr(16);

    .wrapper {
      display: flex;
      width: 100%;
    }

    .item_wrapper {
      display: block;
      position: relative;
      flex-shrink: 0;
      width: 100%;
      padding-bottom: 56.25%;
    }
  }

  .information {
    z-index: 1;
    width: 100%;
    @include flex(center, flex-start, column);

    padding: 0 ptr(16);

    .contents {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: $white;
      pointer-events: auto;
      gap: ptr(12);
    }

    .icon_wrapper {
      display: flex;
      gap: ptr(16);

      .icon {
        display: block;
        width: ptr(24);
        height: ptr(24);
        color: inherit;
      }
    }
  }

  .controller {
    z-index: 1;
    bottom: ptr(60);
    width: 100%;
    height: fit-content;
    pointer-events: none;
    @include flex(space-between, center);
  }

  .pagination {
    @include flex(center, center);

    gap: ptr(12);
    color: $white;

    .pages {
      @include flex(flex-start, center);

      flex-shrink: 0;
      width: ptr(40);
      height: 1px;
      background-color: $bright-20;

      .page {
        height: 100%;
        transition: width 1s;
        background-color: $white;
      }
    }

    & > span {
      min-width: ptr(15);
    }
  }
}
