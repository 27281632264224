@import "base";
html[is_mobile="false"] .sub_header {
  position: relative;
  width: 100%;
  height: $default-sub-header-pc_height;
  overflow: hidden;
  transition:
    background-color linear 300ms,
    color linear 300ms; // header 의 transition 이랑 일치하도록

  background-color: $white;

  &.instant_bg_change {
    transition: none;
  }

  .sub_header_core {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: $pc-layout-body-max-width;
    margin: 0 auto;
    padding: ptr(10) ptr(32);
  }

  .menu_container {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: ptr(10) 0;
    gap: ptr(100) ptr(32); // 안보이게 일부러 크게 잡음
  }

  .menu_item {
    flex-shrink: 0;
    color: inherit;
  }

  .selected_menu_item {
    color: $blue;
  }

  .logo_container {
    flex-shrink: 0;
    margin-right: ptr(48);
  }

  .see_more_btn {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    margin-top: ptr(10);
    margin-left: ptr(80);
    column-gap: ptr(8);
    opacity: 0;
  }

  .see_more_btn_icon {
    width: ptr(12);
    height: ptr(12);
    line-height: 0;

    svg {
      height: 100%;
    }
  }

  .see_more_btn_icon.minus {
    display: none;
  }

  &.overflow_menu {
    .see_more_btn {
      opacity: 1;
    }
  }

  &.opened {
    height: auto;
    overflow: visible;

    .menu_container {
      row-gap: ptr(12);
    }

    .see_more_btn_icon.minus {
      display: inline-block;
    }

    .see_more_btn_icon.plus {
      display: none;
    }
  }

  &.transparent {
    background-color: transparent;
    color: $white;
  }
}

html[is_mobile="true"] .sub_header {
  width: 100%;
  background-color: $white;

  .first_container {
    display: flex;
    align-items: center;
    height: $default-sub-header-mo_height;
    padding-left: ptr(16);
  }

  .back_btn {
    display: inline-block;
    width: ptr(24);
    height: ptr(24);
    margin-right: ptr(8);

    svg {
      height: 100%;
    }
  }

  .menu_container {
    position: relative;
    margin-left: ptr(16);
    overflow: hidden;
  }

  .sub_menu_container {
    display: flex;
    flex-wrap: nowrap;
    max-height: $default-sub-header-sub-menu-container-mo_height;
    overflow-x: auto;
    background-color: $gray-5;
    @include hiddenScroll;

    .sub_menu__row {
      display: flex;
      position: relative;
      flex-shrink: 0;
      padding: ptr(12) ptr(16);
      color: $gray-80;
      gap: ptr(16);

      &[data-selected="false"] {
        display: none;
      }
    }

    .sub_menu_btn {
    }

    .sub_menu_btn_active {
      color: $black;
    }
  }

  .menu_wrapper {
    width: 100%;
    padding: ptr(12) 0;
  }

  .menu_row {
    display: flex;
    column-gap: ptr(8);
    @include hiddenScroll;
  }

  .menu_btn {
    display: inline-block;
    flex-shrink: 0;
    padding: ptr(7) ptr(16);
    border: 1px solid $gray-20;
    border-radius: 999px;
    color: $gray-80;
    user-select: none;

    &[data-selected="true"] {
      border-color: $black;
      color: $black;
    }
  }

  .background_filter {
    position: absolute;
    top: 0;
    width: ptr(40);
    height: 100%;
    transition: opacity 0.3s linear;
    opacity: 0;
  }

  .background_filter_left {
    left: 0;
    background: linear-gradient(90deg, #fff 0%, rgb(255 255 255 / 0%) 100%);
  }

  .background_filter_right {
    right: 0;
    background: linear-gradient(270deg, #fff 0%, rgb(255 255 255 / 0%) 100%);
  }

  &.large_logo .menu_container {
    margin-left: ptr(8);
  }

  &.empty_logo .first_container {
    padding-left: 0;
  }

  &.empty_logo .menu_container {
    margin-left: 0;
    padding-left: ptr(16);
  }
}
