@import "base";
.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: $pc-layout-body-max-width;
  gap: ptr(24);
}

.list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ptr(32);
  gap: ptr(24);

  html[is_mobile="true"] & {
    gap: ptr(16);
    padding: 0 ptr(16);
    overflow-x: auto;

    scroll-snap-type: x mandatory;
    scroll-padding-left: ptr(16);

    @include hiddenScroll;
  }

  .item {
    width: ptr(442);

    html[is_mobile="true"] & {
      flex-shrink: 0;
      width: ptr(288);
      scroll-snap-align: start;
    }
  }
}
