@import "base";
html[is_mobile="false"] {
  .page_body {
    margin-top: $default-header-pc_height;
    padding-top: ptr(24);
  }

  .title_description {
    margin-bottom: ptr(8);
    color: $gray-80;
  }

  .page_tabs {
    margin-bottom: ptr(36);
  }

  .category_tabs {
    margin-bottom: ptr(36);
  }

  .curation_list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: ptr(96) ptr(24);
  }

  .pagination_container {
    display: flex;
    justify-content: center;
    margin-top: ptr(56);
  }
}

html[is_mobile="true"] {
  .page_body {
    margin-top: $default-header-mo_height;
    padding-top: ptr(24);
  }

  .page_title {
    padding-left: ptr(16);
  }

  .category_tabs {
    position: relative;
    padding-left: ptr(16);
    overflow: hidden;
  }

  .category_wrapper {
    width: 100%;
    margin-top: ptr(24);
  }

  .category_row {
    display: flex;
    column-gap: ptr(8);
    @include hiddenScroll;
  }

  .category_button {
    display: inline-block;
    flex-shrink: 0;
    padding: ptr(7) ptr(16);
    border: 1px solid $gray-20;
    border-radius: 999px;
    color: $gray-80;
    user-select: none;

    &[data-selected="true"] {
      border-color: $black;
      color: $black;
    }
  }

  .curation_list {
    display: flex;
    flex-direction: column;
    margin-top: ptr(16);
    padding: 0 ptr(16);
    row-gap: ptr(32);
  }

  .pagination_container {
    display: flex;
    justify-content: center;
    margin-top: ptr(48);
  }
}
