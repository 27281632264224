@import "base";
button.button_arrow {
  @include flex(center, center);

  flex-shrink: 0;
  width: ptr(40);
  height: ptr(40);
  padding: ptr(8);
  transition: background-color 200ms ease-out;
  border-radius: 999px;
  color: $white;
  @include blur10;

  &.type_blue {
    background-color: $blue-10;

    .arrow {
      color: $blue-100;
    }

    &:hover {
      background-color: $bright-5;
    }
  }

  &.type_bright {
    background-color: $bright-20;

    &:hover {
      background-color: $bright-5;
    }
  }

  &.type_dim {
    background-color: $dim-30;

    &:hover {
      background-color: $dim-60;
    }
  }

  .arrow {
    width: ptr(20);
    height: ptr(20);
  }

  &.left {
    transform: rotateY(180deg);
  }
}
