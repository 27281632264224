@import "base";
html[is_mobile="false"] .gnb_pc {
  display: none;
  width: 100%;
  min-width: 1024px;
  padding: ptr(16) ptr(32) ptr(40);
  background-color: $white;

  .width_container {
    max-width: $pc-layout-body-max-width;
    height: 100%;
    margin: 0 auto;
  }

  &.opened {
    display: block;
  }

  .template {
    display: flex;
    width: 100%;
    max-width: $pc-layout-body-max-width;
    height: 100%;
    padding: 0 $marppleshop-layout-padding;

    &.hide {
      display: none;
    }
  }

  .shop_template {
    height: ptr(596);
    @media (width <= 1280px) {
      height: ptr(761);

      // gnb 메뉴 폰트 사이즈가 12kr 인데, 폰트의 렌더링 최솟값이 10px 이라서 그 이하로 가야하는 viewport 범위에서는 비율이 깨짐
      // 따라서 깨지는 곳 부터는 최소 높이를 잡아줘야만 함
      min-height: 614px;
    }
  }

  .shop_left {
    display: flex;
    flex-direction: column;
    width: ptr(140);
    height: 100%;
    margin-right: ptr(16);
    row-gap: ptr(16);
  }

  .shop_right {
    display: flex;
    flex-grow: 1;
    column-gap: ptr(16);
  }

  .shop_right_column {
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: ptr(32);
  }

  .creator_left {
    display: flex;
    flex-direction: column;
    width: ptr(160);
    row-gap: ptr(32);
  }

  .creator_middle {
    margin-left: ptr(32);

    .creator_menus {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: repeat(6, 1fr);
      margin-top: ptr(8);
      gap: ptr(8);
    }

    .menu_item {
      width: ptr(144);
      color: $gray-80;
    }
  }

  .creator_right {
    display: flex;
    margin-left: auto;
    column-gap: ptr(24);
    @media screen and (width <= 1280px) {
      display: none;
    }
  }

  .creator_banner {
    display: block;
  }

  .creator_banner_best_creator_image {
    width: ptr(260);
    aspect-ratio: 4/3;
    object-fit: cover;
    object-position: center;
    border-radius: ptr(16);
  }

  .creator_banner_best_creator_default_image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ptr(260);
    border-radius: ptr(16);
    background-color: $gray-5;
    aspect-ratio: 4/3;
  }

  .creator_banner_default_icon {
    width: ptr(125);
    opacity: 0.4;
    color: $gray-80;
  }

  .creator_banner_plus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ptr(260);
    border-radius: ptr(16);
    background-color: $gray-5;
    aspect-ratio: 4/3;
  }

  .creator_banner_plus_logo {
    height: 100%;
  }

  .creator_banner_title {
    margin-top: ptr(8);
    margin-bottom: ptr(4);
  }

  .creator_banner_link {
    display: flex;
    align-items: center;
    color: $gray-80;
  }

  .creator_banner_link_icon {
    display: inline-flex;
    width: ptr(16);
    margin-left: ptr(4);
    aspect-ratio: 1;
  }
}

html[is_mobile="false"] .gnb_pc_menu_group {
  display: inline-flex;
  flex-direction: column;
  row-gap: ptr(8);

  .menu_group_icon {
    display: inline-flex;
    align-items: center;
    width: ptr(16);
    aspect-ratio: 1;
  }

  .menu_group_title {
    display: inline-flex;
    align-items: center;
    column-gap: ptr(4);
  }

  .menu_container {
    display: flex;
    flex-flow: column wrap;
    row-gap: ptr(8);
  }

  .menu_item {
    color: $gray-80;
  }
}
