@import "base";
html[is_mobile="false"] {
  .cheer_up_card {
    position: relative;
    padding: ptr(16);
    border-radius: ptr(16);

    &:nth-child(1) {
      background-color: $blue-10;
    }

    &:nth-child(2) {
      background-color: $brown-10;
    }

    &:nth-child(3) {
      background: rgb(255 228 255 / 40%);
    }

    &:nth-child(4) {
      background-color: $purple-10;
    }

    &:nth-child(5) {
      background-color: rgb(0 148 68 / 10%);
    }
  }

  .wrap {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ptr(12);
    padding-top: ptr(8);
  }

  .profile_img {
    width: ptr(48);
    height: ptr(48);
    border-radius: ptr(48);
    object-fit: cover;
  }

  .name {
    margin-bottom: ptr(4);
    color: $black;
    text-align: center;
    @include lineLimit(1);
  }

  .created_at {
    position: absolute;
    top: ptr(16);
    right: ptr(16);
    color: $gray-50;
  }

  .body {
    color: $black;
    text-align: center;
    @include lineLimit(3);
  }

  .controls {
    display: flex;
    position: absolute;
    right: 0;
    bottom: ptr(6);
    left: ptr(16);
    align-items: center;
    justify-content: space-between;
    gap: ptr(20);
  }

  .comment {
    display: flex;
    align-items: center;
    gap: ptr(8);
  }

  .comment_profiles {
    position: relative;
    height: ptr(24);
    overflow: hidden;
  }

  .comment_profile {
    display: inline-block;
    position: relative;

    &:nth-child(1) {
      z-index: 3;
    }

    &:nth-child(2) {
      z-index: 2;
      margin-left: ptr(-10);
    }

    &:nth-child(3) {
      z-index: 1;
      margin-left: ptr(-10);
    }
  }

  .comment_profile_img {
    width: ptr(24);
    height: ptr(24);
    border-radius: 999px;
  }

  .comment_count {
    color: $gray-50;
  }

  .like {
    background: inherit;
  }
}

html[is_mobile="true"] {
  .cheer_up_card {
    &:nth-child(1) .wrap {
      background-color: $blue-10;
    }

    &:nth-child(2) .wrap {
      background-color: $brown-10;
    }

    &:nth-child(3) .wrap {
      background: rgb(255 228 255 / 40%);
    }

    &:nth-child(4) .wrap {
      background-color: $purple-10;
    }

    &:nth-child(5) .wrap {
      background-color: rgb(0 148 68 / 10%);
    }
  }

  .wrap {
    position: relative;
    width: 70vw;
    min-height: ptr(246);
    padding: ptr(16) ptr(16) ptr(40);
    border-radius: ptr(16);
  }

  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ptr(16);
    padding-top: ptr(8);
  }

  .profile_img {
    width: ptr(48);
    height: ptr(48);
    border-radius: ptr(48);
    object-fit: cover;
  }

  .name {
    margin-bottom: ptr(4);
    color: $black;
    text-align: center;
  }

  .created_at {
    position: absolute;
    top: ptr(16);
    right: ptr(16);
    color: $gray-50;
  }

  .body {
    color: $black;
    text-align: center;
    @include lineLimit(3);
  }

  .controls {
    display: flex;
    position: absolute;
    right: 0;
    bottom: ptr(6);
    left: ptr(16);
    align-items: center;
    justify-content: space-between;
    gap: ptr(20);
  }

  .comment {
    display: flex;
    align-items: center;
    gap: ptr(8);
  }

  .comment_profiles {
    position: relative;
    height: ptr(24);
    overflow: hidden;
  }

  .comment_profile {
    display: inline-block;
    position: relative;

    &:nth-child(1) {
      z-index: 3;
    }

    &:nth-child(2) {
      z-index: 2;
      margin-left: ptr(-10);
    }

    &:nth-child(3) {
      z-index: 1;
      margin-left: ptr(-10);
    }
  }

  .comment_profile_img {
    width: ptr(24);
    height: ptr(24);
    border-radius: 999px;
  }

  .comment_count {
    color: $gray-50;
  }

  .like {
    background: inherit;
  }
}
