@import "base";
html[is_mobile="false"] {
  .scl_page {
    padding-top: $default-header-pc_height;

    h2 {
      padding-top: ptr(48);
      color: $black;
    }

    h3 {
      margin: ptr(32) 0 ptr(8);
      color: $black;
    }

    p {
      margin: ptr(8) 0 0;
      color: $black;
    }

    .cs {
      display: flex;
      align-items: center;
      gap: ptr(32);
    }

    .step_img {
      width: 100%;
    }
  }
}

html[is_mobile="true"] {
  .scl_page {
    padding-top: $default-header-mo_height;

    h2 {
      padding-top: ptr(24);
      color: $black;
    }

    h3 {
      margin: ptr(32) 0 ptr(8);
      color: $black;
    }

    p {
      margin: ptr(8) 0 0;
      color: $black;
    }

    .step_img {
      width: 100%;
    }
  }
}
