@import "base";
html[is_mobile="false"] {
  .monthly_creator_banner {
    position: relative;
    z-index: 1;
    width: 100%;
    height: ptr(740);
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .bg {
    position: absolute;
    inset: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &::after {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      background-color: $dim-30;
      @include blur60;
    }
  }

  .w_br {
    display: block;
  }

  .info {
    position: relative;
    z-index: 4;
    width: 100%;
  }

  .up_down {
    @include flex(flex-start, center);

    gap: ptr(6);
    width: ptr(38);

    svg {
      width: ptr(8);
    }

    &.up {
      color: $red-80;
    }

    &.down {
      color: $blue;
    }

    &.new {
      color: $blue;
    }

    &.zero {
      color: $blue;
    }
  }

  .up_down_num {
    display: block;
    aspect-ratio: 1 / 1;
  }

  .title {
    position: absolute;
    z-index: 2;
    top: ptr(112);
    left: ptr(32);
    color: $white;
    font-weight: 700;
    line-height: 90%;
    text-transform: uppercase;
  }

  .thumb {
    display: none;
    position: absolute;
    top: ptr(164);
    left: ptr(199);
    width: ptr(533);
    height: ptr(480);
  }

  .thumb_a {
    display: block;
    width: 100%;
    height: 100%;
    mask-image: url("//s3.marpple.co/files/u_218933/2024/5/original/4dbeb505a99f381b35350b029c408df5bfdbe3f61.png");
    mask-size: cover;
    mask-repeat: no-repeat;
    mask-position: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: $gray-20;
    }
  }

  .info_body {
    position: absolute;
    z-index: 1;
    top: ptr(443);
    left: ptr(32);
    color: $white;

    .rank {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: ptr(12);
      margin-bottom: ptr(8);
      color: $white;
      line-height: 90%;

      .rank_a {
        color: inherit;
      }
    }

    .name {
      margin-bottom: ptr(4);

      .name_a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: ptr(356);
        color: $white;
        gap: ptr(8);
      }

      .creator_name {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .mshop_thin_icon {
        display: flex;
        flex: 0 0 ptr(28);
        width: ptr(28);
        height: ptr(28);
        color: $white;
      }
    }

    .seller_type {
      min-height: ptr(22);
      margin-bottom: ptr(24);
      color: $gray-20;
    }
  }

  .products {
    display: grid;
    grid-template-columns: repeat(4, ptr(80));
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: ptr(12);
  }

  .product_thumb {
    flex-basis: ptr(80);
    overflow: hidden;
    border-radius: ptr(8);
  }

  .swiper {
    position: absolute;
    top: ptr(164);
    right: ptr(200);
    left: ptr(220);
    height: ptr(480);
    overflow: visible;
    opacity: 0;
  }

  .swiper_wrap {
    position: relative;
    align-items: center;
    width: ptr(1440);
    height: 100%;
    margin: 0 auto;
  }

  .swiper_slide {
    position: relative;
    width: ptr(280);
    transition: opacity 0.4s;
    opacity: 0;

    &::after {
      content: " ";
      display: block;
      position: absolute;
      inset: 0;
    }

    .number {
      display: flex;
      position: absolute;
      top: 50%;
      left: ptr(36);
      flex-direction: column;
      align-items: center;
      margin-top: ptr(-130);
      transform: translateY(-50%);
      color: $white;
      gap: ptr(8);

      .up_down {
        width: auto;
      }
    }

    .thumbnail {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-bottom: 16px;

      .adult {
        display: block;
        width: ptr(280);
        height: ptr(280);
        color: $gray-20;
      }

      a {
        display: block;
        position: relative;
        width: ptr(280);
        height: ptr(280);
      }
    }

    .thumb_img {
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      object-fit: cover;
      background-color: $white;
    }

    .txt_info {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      margin-top: ptr(182);
      transform: translateY(-50%);
    }

    .name {
      .name_a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: $white;
      }

      span {
        display: flex;
        align-items: center;
      }

      .creator_name {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .mshop_thin_icon {
        display: flex;
        flex: 0 0 ptr(28);
        width: ptr(28);
        height: ptr(28);
        color: $white;
      }
    }

    .seller_type {
      min-height: 22px;
      color: $gray-20;
      text-align: center;
    }
  }

  .swiper_slide.swiper_slide_active {
    display: block;
    flex-basis: ptr(533);
    opacity: 1;

    &::after {
      display: none;
    }

    .number {
      opacity: 0;
    }

    .thumbnail {
      width: ptr(533);
      height: ptr(480);
    }

    .thumbnail.thumbnail_adult {
      a {
        mask: unset;
      }
    }

    .thumbnail a {
      display: block;
      width: 100%;
      height: 100%;
      mask-image: url("//s3.marpple.co/files/u_218933/2024/5/original/4dbeb505a99f381b35350b029c408df5bfdbe3f61.png");
      mask-size: cover;
      mask-repeat: no-repeat;
      mask-position: center;

      .adult {
        display: block;
        width: 100%;
        height: 100%;
        color: $gray-20;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 0;
        background-color: $white;
        object-fit: cover;
      }
    }

    .txt_info {
      display: none;
    }
  }

  .swiper_slide.swiper_slide_prev {
    opacity: 0;

    .number {
      display: none;
    }

    .txt_info {
      display: none;
    }
  }

  .swiper_slide.swiper_slide_next {
    display: block !important;
    opacity: 1;

    &::after {
      display: none;
    }

    & + .swiper_slide {
      display: block !important;
      opacity: 0.9;
      filter: blur(2px);

      &::after {
        display: none;
      }
    }

    & + .swiper_slide + .swiper_slide {
      display: block !important;
      opacity: 0;
    }
  }

  .swiper_prev {
    position: absolute;
    top: ptr(460);
    right: ptr(-94);
    left: auto;
    background: none;
  }

  .swiper_next {
    position: absolute;
    top: ptr(460);
    right: ptr(-144);
    background: none;
  }
}

html[is_mobile="true"] {
  .monthly_creator_banner {
    position: relative;
    width: 100%;
    height: ptr(500);
    overflow: hidden;
  }

  .bg {
    position: absolute;
    inset: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &::after {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      background-color: $dim-30;
      @include blur60;
    }
  }

  .w_br {
    display: block;
  }

  .info {
    position: relative;
    width: 100%;
  }

  .info2 {
    display: none;
    position: relative;
    width: 100%;
  }

  .up_down {
    @include flex(flex-start, center);

    gap: ptr(4);
    width: auto;

    svg {
      width: ptr(6);
    }

    &.up {
      color: $red-80;
    }

    &.down {
      color: $blue;
    }

    &.new {
      color: $blue;
    }

    &.zero {
      color: $blue;
    }
  }

  .up_down_num {
    width: auto;
    aspect-ratio: 1 / 1;
  }

  .title {
    position: absolute;
    z-index: 2;
    top: ptr(120);
    left: ptr(16);
    color: $white;
    font-weight: 700;
    line-height: 90%;
    text-transform: uppercase;
  }

  .thumb {
    position: absolute;
    top: ptr(204);
    right: ptr(16);

    //width: ptr(233);
    width: 60%;
    padding-top: 54%;
  }

  .thumb_a {
    display: block;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    mask-image: url("//s3.marpple.co/files/u_218933/2024/5/original/4dbeb505a99f381b35350b029c408df5bfdbe3f61.png");
    mask-size: cover;
    mask-repeat: no-repeat;
    mask-position: center;

    &.thumbnail_adult {
      mask: unset;
    }

    .adult {
      display: block;
      width: 100%;
      height: 100%;
      color: $gray-20;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: $white;
    }
  }

  .info_body {
    position: absolute;
    z-index: 1;
    top: ptr(310);
    right: ptr(16);
    left: ptr(16);
    color: $white;

    .rank {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: ptr(12);
      margin-bottom: ptr(8);
      color: $white;
      line-height: 90%; /* 54px */

      .rank_a {
        color: inherit;
      }
    }

    .name {
      margin-bottom: ptr(4);

      .name_a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 95%;
        color: $white;
        line-height: ptr(39);
        gap: ptr(8);
      }

      span {
        display: flex;
        align-items: center;
      }

      .creator_name {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .mshop_thin_icon {
        display: flex;
        flex: 0 0 ptr(28);
        width: ptr(28);
        height: ptr(28);
        color: $white;
      }
    }

    .seller_type {
      display: -webkit-box;
      min-height: ptr(22);
      margin-bottom: ptr(24);
      overflow: hidden;
      color: $gray-20;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }

  .products {
    display: none;
  }

  .swiper {
    position: absolute;
    top: 0;
    right: ptr(0);
    left: ptr(0);
    height: 100%;
    opacity: 0;
  }

  .swiper_wrap {
  }

  .swiper_slide {
    position: relative;
    width: 100%;
    opacity: 0;

    .number {
      position: absolute;
      top: 0;
      left: 0;
      color: $white;
    }

    .thumbnail {
      display: flex;
      position: absolute;
      inset: 0;
      align-items: center;
      justify-content: center;

      a {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
      }
    }

    .thumb_img {
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      object-fit: cover;
      background-color: $white;
    }

    .name {
      .name_a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: $white;
      }

      span {
        display: flex;
        align-items: center;
      }

      .creator_name {
        @include limitLine(1);
      }

      .mshop_thin_icon {
        width: ptr(24);
        height: ptr(24);
        color: $white;
      }
    }

    .seller_type {
      min-height: 22px;
      color: $gray-20;
      text-align: center;
    }
  }

  .swiper_prev,
  .swiper_next {
    display: none;
  }

  .page_current {
    position: absolute;
    bottom: ptr(16);
    left: ptr(16);
    color: $white;
  }

  .swiper_pagination {
    position: absolute;
    bottom: ptr(25);
    left: ptr(39);
    width: ptr(40);
    height: ptr(2);

    .progressbar {
      background-color: $white;
    }
  }

  .page_length {
    position: absolute;
    bottom: ptr(16);
    left: ptr(91);
    color: $white;
  }
}
