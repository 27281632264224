@import "base";
.container {
  display: flex;
  width: 100%;
  padding: 0 ptr(16);
  gap: ptr(8);

  html[is_mobile="false"] & {
    max-width: $pc-layout-body-max-width;
    padding: 0 ptr(32);

    &[data-fit="true"] {
      padding: 0;
    }
  }

  &[data-fit="true"] {
    padding: 0;
  }

  .image {
    position: relative;
    width: ptr(72);
    height: ptr(72);

    > img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

.main_section_title {
  width: 100%;
  gap: ptr(4);
  @include flex(center, flex-start, column);

  html[is_mobile="false"] & {
    gap: ptr(8);
  }

  .description {
    color: $gray-80;

    &.white {
      color: $white;
    }
  }

  .title_container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    gap: ptr(8);

    .title {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      color: $black;
      gap: ptr(4);

      html[is_mobile="false"] & {
        gap: ptr(8);
      }
    }

    .see_more {
      color: $gray-80;
    }

    &.white {
      .title {
        color: $white;
      }

      .see_more {
        color: $gray-20;
      }
    }
  }
}
