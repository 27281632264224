@import "base";
@import "../../../../shared/style/base";

.btn_container {
  display: flex;
  flex-wrap: wrap;
}

.gap8 {
  gap: ptr(8);
}
