@import "base";
html[is_mobile="false"] {
  .page {
  }

  .related_container {
    display: flex;
    column-gap: ptr(24);
  }
}

html[is_mobile="true"] {
  .page {
  }

  .related_item {
    width: ptr(240);
  }
}
