@import "base";
html[is_mobile="false"] .footer {
  display: block;
  margin: 0 auto;
  border-top: 1px solid $gray-5;
  background-color: $white;

  .section1 {
    display: flex;
    justify-content: space-between;
    min-width: $pc-layout-body-min-width;
    max-width: $pc-layout-body-max-width;
    margin: 0 auto;

    .left {
      display: flex;
      flex-direction: column;
      padding: ptr(48) ptr(32);
      row-gap: ptr(24);
    }

    .right {
      display: flex;
      flex-direction: column;
      width: 25.88%;
      padding: ptr(48) ptr(32);
      border-left: 1px solid $gray-5;
    }

    .nav {
      display: flex;
      flex-wrap: wrap;
      max-width: ptr(915);
      gap: ptr(12) ptr(24);

      //max-width: ptr(810);
    }
  }

  .divider {
    border-top: 1px solid $gray-5;
  }

  .marpple_logo {
    height: ptr(36);

    svg {
      height: 100%;
    }
  }

  .info_container {
    display: flex;
    flex-direction: column;
    row-gap: ptr(6);
  }

  .info_first_row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: ptr(8);
  }

  .info_splitter {
    display: inline-block;
    width: 1px;
    height: ptr(12);
    background: $gray-5;
  }

  .customer_center_phone_row {
    display: flex;
    margin-bottom: ptr(16);
    column-gap: ptr(8);
  }

  .customer_center_info {
    display: flex;
    flex-direction: column;
    row-gap: ptr(4);
  }

  .sns_container {
    display: flex;
    margin-top: ptr(32);
    column-gap: ptr(8);
  }

  .sns_icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ptr(28);
    height: ptr(28);
    border-radius: 50%;
    background-color: $black;
    aspect-ratio: 1;

    svg {
      width: ptr(20);
      height: ptr(20);
      color: $white;
    }
  }

  .section2 {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    min-width: $pc-layout-body-min-width;
    max-width: $pc-layout-body-max-width;
    margin: 0 auto;
    padding: ptr(12) ptr(32);
  }

  .lang_dropdown {
    .dropdown_option {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .lang_icon {
      width: ptr(18);
      height: ptr(18);
      margin-right: ptr(8);
    }
  }

  .lang_dropdown_button {
    width: ptr(115);
  }

  .inicis_button {
    margin-left: 4px;
    text-decoration: underline;
  }
}

html[is_mobile="true"] .footer {
  background-color: $white;

  .section1 {
    border-top: 1px solid $gray-5;

    .left {
      display: flex;
      flex-direction: column;
      padding: ptr(48) ptr(16);
      row-gap: ptr(24);
    }

    .right {
      display: flex;
      flex-direction: column;
      padding: 0 ptr(16) ptr(48);
      border-left: 1px solid $gray-5;
    }

    .nav {
      display: flex;
      flex-wrap: wrap;
      gap: ptr(8) ptr(12);
    }
  }

  .marpple_logo {
    height: ptr(32);

    svg {
      height: 100%;
    }
  }

  .info_container {
    display: flex;
    flex-direction: column;
    row-gap: ptr(12);
    color: $gray-80;
    word-break: break-all;
  }

  .info_first_row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: ptr(4) ptr(8);
  }

  .info_row {
    display: none;
  }

  .info_container.is_opened {
    .info_row {
      display: block;
    }

    .info_open_icon {
      transform: rotate(180deg);
    }
  }

  .info_splitter {
    display: inline-block;
    width: 1px;
    height: ptr(12);
    background: $gray-5;
  }

  .info_open_btn {
    display: flex;
    align-items: center;
    color: $black;
  }

  .info_open_icon {
    display: inline-flex;
    width: ptr(12);
    height: ptr(12);
    margin-left: ptr(8);
  }

  .customer_center_phone_row {
    display: flex;
    margin-bottom: ptr(16);
    column-gap: ptr(8);
  }

  .customer_center_info {
    display: flex;
    flex-direction: column;
    row-gap: ptr(6);
  }

  .sns_container {
    display: flex;
    margin-top: ptr(24);
    column-gap: ptr(13.7);
  }

  .sns_icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ptr(28);
    height: ptr(28);
    border-radius: 50%;
    background-color: $black;
    aspect-ratio: 1;

    svg {
      width: ptr(20);
      height: ptr(20);
      color: $white;
    }
  }

  .section2 {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    padding: ptr(12) ptr(16);
    border-top: 1px solid $gray-5;
  }

  .lang_dropdown {
    .dropdown_option {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .lang_icon {
      width: ptr(18);
      height: ptr(18);
      margin-right: ptr(8);
    }
  }

  .lang_dropdown_button {
    width: ptr(115);
  }

  .inicis_button {
    margin-left: 4px;
    text-decoration: underline;
  }
}
