@import "base";
.popup_store_progress {
  &.large {
    @include flex(flex-start, stretch, column);

    gap: ptr(32);

    .values {
      @include flex(space-between, center);

      text-transform: uppercase;
    }

    .current {
      @include flex(flex-start, center);

      gap: ptr(16);
    }

    .progress_bar {
      height: ptr(8);
      overflow: hidden;
      background: $white;

      .progress {
        width: 100%;
        height: 100%;
        background: $blue;
      }
    }

    .remain_date {
      @include flex(flex-start, center);

      gap: ptr(8);
    }
  }

  &.small {
    @include flex(flex-start, stretch, column);

    gap: ptr(8);

    .values {
      @include flex(space-between, center);
    }

    .current {
      @include flex(flex-start, center);

      gap: ptr(8);
      color: $blue;
    }

    .progress_bar {
      height: ptr(6);
      overflow: hidden;
      background: rgb(20 20 20 / 10%);

      .progress {
        width: 100%;
        height: 100%;
        background: $blue;
      }
    }

    .remain_date {
      @include flex(flex-start, center);

      gap: ptr(8);
    }
  }
}

.timer {
  @include flex(flex-start, center);

  gap: ptr(16);

  html[is_mobile="true"] & {
    gap: ptr(8);
  }
}
