@import "base";
html[is_mobile="false"] {
  .floating_bottom_container {
    pointer-events: none;
  }

  .floating_part {
    display: flex;
    position: fixed;
    z-index: 10;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
  }
}

html[is_mobile="true"] {
  .floating_bottom_container {
    pointer-events: none;
  }

  .floating_part {
    display: flex;
    position: fixed;
    z-index: 10;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
  }
}
