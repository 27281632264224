@import "base";
html[is_mobile="false"] .header {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  min-height: ptr(72);
  transform: translateY(0);
  transition:
    background-color 0.5s,
    transform 0.3s,
    background-color 0.5s linear;
  background-color: rgb(255 255 255 / 60%);
  will-change: transform;
  @include flex(space-between, center);

  &.bg_white {
    background-color: $white !important;
    color: $blue-100 !important;
  }

  &.light {
    background-color: $white;
    color: $blue-100;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:global(.headroom--top) {
    background-color: transparent;
    color: $white;
  }

  &:global(.headroom--not-top) {
    background-color: $bright-70;
    color: $blue-100;
    backdrop-filter: blur(6px);

    .popup_store_division {
      .division_item {
        color: $gray-50;

        &.active {
          color: $blue-100;
        }
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    height: ptr(72);
    margin-left: ptr(24);

    .handmade_logo {
      display: inline-block;
      width: ptr(271.87);
      height: ptr(28);
    }
  }

  .menus {
    @include flex(flex-end, center);

    height: ptr(72);
    margin-right: ptr(24);
    cursor: pointer;
    gap: ptr(40);

    .menu {
      @include flex(center, center);

      color: $blue-100;
    }

    .menu_button {
      width: ptr(87);
      height: ptr(40);
      padding: ptr(16) ptr(8);
      border-radius: ptr(8);
      background-color: $blue-100;
      color: $blue-10;
    }

    .arrow_wrapper {
      @include flex(center, center);

      width: ptr(16);
      height: ptr(16);
    }
  }
}

html[is_mobile="true"] .header {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: ptr(56);
  padding: ptr(12) ptr(16);
  transform: translateY(0);
  transition:
    background-color 0.5s,
    transform 0.3s,
    background-color 0.5s linear;
  background-color: rgb(255 255 255 / 60%);
  will-change: transform;
  @include flex(space-between, center);

  &.bg_white {
    background-color: $white !important;
    color: $blue-100 !important;
  }

  &.light {
    background-color: $white;
    color: $blue-100;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:global(.headroom--top) {
    background-color: transparent;
    color: $white;
  }

  &:global(.headroom--not-top) {
    background-color: $bright-70;
    color: $blue-100;
    backdrop-filter: blur(6px);

    .popup_store_division {
      .division_item {
        color: $gray-50;

        &.active {
          color: $blue-100;
        }
      }
    }
  }

  .logo {
    width: ptr(70);
    height: fit-content;
    @include flex(center, center);

    a {
      @include flex(center, center);

      width: 100%;
      height: 100%;
    }

    .handmade_logo {
      @include flex(center, center);

      z-index: 20;
      width: 100%;
      height: 100%;
    }
  }

  .icons {
    width: ptr(56);
    height: ptr(56);
    @include flex(center, center);

    gap: ptr(16);

    .share_btn,
    .hamburger_btn {
      @include flex(center, center);

      z-index: 20;
    }
  }

  .hamburger_menus_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $bright-60;
    backdrop-filter: blur(30px);
  }

  .hamburger_menus {
    width: 100%;
    padding-top: ptr(104);
    padding-right: ptr(24);
    overflow: hidden;
    cursor: pointer;
    gap: ptr(32);
    @include flex(flex-start, flex-end, column);

    .menu {
      color: $blue-100;
    }

    .arrow_wrapper {
      display: inline-block;
      width: ptr(20);
      height: ptr(20);
    }
  }
}

html[is_mobile="false"] .toast_position {
  bottom: ptr(128) !important;
}
