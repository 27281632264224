@import "base";
.icons {
  display: grid;
  grid-column: 4;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1000px;
  margin: auto;
  color: #141414;
  gap: 70px;

  .icon_wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .icon {
      transition: transform 0.3s;
    }

    &:hover .icon {
      transform: scale(1.5);
    }

    .name {
      margin-top: 20px;
    }
  }

  .name {
    //  position: absolute;
    //  left: 50%;
    //  transform: translateX(-50%);
  }
}

.cart_count {
  display: inline-flex;
  position: absolute;
  top: -8.33%;
  right: -8.33%;
  align-items: center;
  justify-content: center;
  width: 58.33%;
  border-radius: 999px;
  background-color: $red-80;
  color: $white;
  aspect-ratio: 1;

  .number {
    position: absolute;
    bottom: 0.5px;
  }
}
