@import "base";
.my_page_menu {
  max-width: $pc-layout-body-max-width;
  @include flex(start, center);
  gap: ptr(48);
  margin: ptr(104) auto 0;
  padding: 0 32px;
}

.my_page_menu_items {
  @include flex(start, center);
  gap: ptr(24);
  a {
    color: $gray-50;
    &[data-active="true"] {
      color: $black;
      border-bottom: 1px solid $black;
    }
  }
}
