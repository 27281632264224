@import "base";
.date_period_container {
  @include flex(space-between, center);

  width: 100%;
  height: ptr(42);
  color: $white;
  cursor: pointer;

  .duration {
    width: ptr(9);
  }

  .start_time_input,
  .end_time_input {
    width: calc((100% - 17px) / 2);
    padding: ptr(12);
    border: ptr(1) solid $gray-10;
    border-radius: ptr(4);
  }
}

html[is_mobile="true"] .date_period_container {
  @include flex(center, center, column);

  gap: ptr(8);
  height: auto;

  .duration {
    display: none;
  }

  .start_time_input,
  .end_time_input {
    width: 100%;
  }
}
