@import "base";
html[is_mobile="false"],
html[is_mobile="true"] {
  @mixin hover {
    border-radius: ptr(8);
    background-color: $gray-5;
    @include blur40;
  }

  .pagination {
    display: flex;
    align-items: center;
    user-select: none;
  }

  .pages {
    display: flex;
    align-items: center;
    gap: ptr(4);
  }

  .page {
    min-width: ptr(32);
    height: ptr(32);
    color: $gray-50 !important;

    &.active {
      color: $black !important;
    }

    &:hover {
      @include hover;
    }
  }

  .navigations {
    display: flex;
    gap: ptr(4);

    &.prev {
      margin-right: ptr(16);
    }

    &.next {
      margin-left: ptr(16);
    }
  }

  .navigation {
    width: ptr(24);
    height: ptr(24);
    color: $black;

    &.disabled {
      color: $gray-20;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .hidden {
    display: none !important;
  }
}
