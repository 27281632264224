@import "base";
html[is_mobile="false"] section.card {
  width: 100%;
  padding: ptr(24);
  border: 1px solid $gray-5;
  border-radius: ptr(16);
  gap: ptr(20);
  @include flex(flex-start, flex-start, column);

  .header {
    width: 100%;
    @include flex(space-between, center);

    .date {
      color: $gray-50;
    }
  }

  .nav_container {
    width: 100%;
    list-style: none;
    @include flex(flex-start, flex-start, column);

    gap: ptr(16);

    .nav_tab_wrapper {
      width: 100%;

      li.nav_tab {
        width: 100%;
        color: $gray-80;
        @include flex(space-between, center);

        .first_li {
          color: $blue-100;
        }
      }
    }
  }
}

html[is_mobile="true"] section.card {
  width: 100%;
  @include flex(flex-start, flex-start, column);

  gap: ptr(20);
  padding: ptr(20) ptr(16);

  .header {
    width: 100%;
    @include flex(space-between, center);

    .date {
      color: $gray-50;
    }
  }

  .nav_container {
    width: 100%;
    list-style: none;
    @include flex(flex-start, flex-start, column);

    gap: ptr(16);

    .nav_tab_wrapper {
      width: 100%;

      li.nav_tab {
        width: 100%;
        color: $gray-80;
        @include flex(space-between, center);

        .first_li {
          color: $blue-100;
        }
      }
    }
  }
}
